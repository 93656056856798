import React from "react";
import { useTranslation } from "react-i18next";

export const LocalPostSkeleton: React.FunctionComponent = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`dashboardPage.localPostItem.${key}`);

  return (
    <div className="local-post-item">
      <div className="local-post-top-info">
        <div className="left-title">...</div>
        <div className="right-title">...</div>
      </div>
      <div className="local-post-bottom-container">
        <div className="local-post-img-container">
          <div className="no-image">
            <div>{t("loading")}</div>
          </div>
        </div>
        <div className="summary">...</div>
      </div>
    </div>
  );
};
