import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import DropdownSelect from '../../../components/FormElement/DropdownSelect';
import { useCategories, useLabels, useLocationMunis } from '../../../hooks/queries';
import { CustomDashboardParams } from '../../../models/CustomDashboardParams';
import { FacilityFilterConditionData, createDefaultConditionFormData } from '../../../models/FacilityFilterConditionData';
import { convertToGroupData } from '../../../models/MunicipalityData';
import { convertCustomDashboardParamsToFormData } from '../../../utils/conditionFormDataHelpers';
import { CurrentFilters } from '../../FacilityListComponents/CurrentFilters/CurrentFilters';

import './style.scss';

interface ICustomDashboardSectionProps {
  customDashboards?: CustomDashboardParams[]
  activeDashboard?: CustomDashboardParams
  userId?: number
  organizationId: string
  onOpenCreateEditCustomDashboard: (isCreate: boolean) => void
}

const CustomDashboardSection: React.FunctionComponent<ICustomDashboardSectionProps> = (props) => {
  const { activeDashboard, customDashboards, userId, organizationId, onOpenCreateEditCustomDashboard } = props;

  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`dashboardPage.${key}`);

  const history = useHistory();
  const { data: labels } = useLabels(
    _.pickBy({
      organization_id: organizationId,
    }),
    true
  );
  const {data: categories} = useCategories(true);
  const {data: municipalitiesData} = useLocationMunis(userId, organizationId, true);

  const [customDashboardConditionFormData, setCustomDashboardConditionFormData] = useState<FacilityFilterConditionData>(createDefaultConditionFormData());

  useEffect(() => {
    if(activeDashboard && municipalitiesData && labels && categories) {
      let groupedMunis = undefined;
      if(municipalitiesData) {
        groupedMunis = convertToGroupData(municipalitiesData);
      }

      const formData = convertCustomDashboardParamsToFormData(
        activeDashboard.conditions,
        groupedMunis,
        categories,
        labels
      )
      setCustomDashboardConditionFormData(formData);
    }
  }, [municipalitiesData, labels, categories, activeDashboard]);

  const onSelectCustomDashboards = (dashboardTitle: string) => {
    if(dashboardTitle === t('back_to_main')) {
      history.push(`/dashboard/organization/${organizationId}/`);
      return;
    }

    if(!customDashboards) { return; }
    const dashboard = customDashboards.find(item => item.title === dashboardTitle);

    if(dashboard) {
      history.push(`/dashboard/organization/${organizationId}/customDashboard/${dashboard.id}`);
    }
  }

  const dropdownOptions = customDashboards?
    [
      ...(activeDashboard ? [t('back_to_main')] : []), 
      ...customDashboards.map(item => item.title)
    ] :
    []
  
  return <>
    <div className="select-custom-dashboard">
      <DropdownSelect
        value={activeDashboard?.title ?? ''} 
        items={dropdownOptions} 
        placeholder={'custom_dashboard'}
        onChange={onSelectCustomDashboards} 
      />
      <button className="btn btn-blue" onClick={() => onOpenCreateEditCustomDashboard(true)}>
        {t('create_new_custom_dashboard')}
      </button>
    </div>
    { 
      activeDashboard &&
        <div className="dashboard-group custom-dashboard-select">
          <div className="group-title">
            {t('custom_dashboard')}
            <div className="line"></div>
          </div>
          <div className="custom-dashboard-select__bar">
            {
              activeDashboard && [
                <h3 key={`activeDashboardTitle-${activeDashboard.title}`}>{t('current_dashboard')}：{activeDashboard.title}</h3>,
                <button key="createEditDashboardButton" className="btn btn-border" onClick={() => onOpenCreateEditCustomDashboard(false)}>
                  {t('edit_custom_dashboard')}
                </button>
              ]
            }
          </div>
          <CurrentFilters 
            conditionFormData={customDashboardConditionFormData}   
          />
        </div>
    }
  </>
}

export default CustomDashboardSection;