import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useMe } from "../../hooks/queries";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IRootPageProps {}

const RootPage: React.FunctionComponent<IRootPageProps> = () => {
  const { data: me } = useMe();
  const history = useHistory();

  useEffect(() => {
    if (me?.type === "loc_admin") {
      if (!!me.linked_to_google_account) {
        if (me.organization?.id) {
          history.push(`/locations/organization/${me.organization.id}`);
        } else {
          history.push("/locations");
        }
      } else {
        history.push(`/management/locations`);
      }
    } else if (me?.type === "org_admin") {
      history.push(`/dashboard/organization/${me.organization_id || ""}`);
    } else if (me?.type === "admin") {
      history.push("/admin/organizations");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);
  return (
    <React.Fragment>
      <div>loading</div>
    </React.Fragment>
  );
};

export default RootPage;
