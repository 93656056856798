import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import DataSvc from "../../../../services/dataSvc";

interface IReviewScoresProps extends RouteComponentProps<any> {
  onLoading(loadingText: string | void): void;
  onLoaded(): void;
  commonErrorHandler: (err: string) => string | undefined;
}
const ReviewScores: React.FunctionComponent<IReviewScoresProps> = ({
  onLoading,
  onLoaded,
  commonErrorHandler,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`internaltoolsPage.reviewScores.${key}`);
  const [selectedFile, setSelectedFile] = useState<Blob | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [resultMessage, setResultMessage] = useState<string>("");

  const getReviewScores = (): void => {
    setResultMessage("");
    onLoading(t("do_not_close_window"));
    if (!selectedFile) {
      setErrorMessage(t("input_location"));
      return;
    }

    setErrorMessage("");

    const formData = new FormData();
    formData.append("file", selectedFile);

    // Call API
    DataSvc.getPlaceScores(formData)
      .then((data) => {
        const { csvString } = data;
        if (!csvString) {
          setErrorMessage(t("can_not_get_scores"));
          return;
        }

        const blob = new Blob([csvString], { type: "text/csv" });
        const file = window.URL.createObjectURL(blob);
        window.location.assign(file);
        setResultMessage(t("retrieval_complete"));
      })
      .catch((err) => {
        const filteredError = commonErrorHandler(err);
        setErrorMessage(filteredError ?? "");
      })
      .finally(onLoaded);
  };

  const onSelectedFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files: FileList | null = e.currentTarget.files;
    setSelectedFile(files !== null ? files[0] : null);
  };

  return (
    <React.Fragment>
      <h1 className="main-title">{t("main_title")}</h1>
      <h2 className="sub-title">{t("input")}</h2>
      <section className="card">
        <p>{t("section_1_title_1")}</p>
        <p>
          {t("section_1_title_2")}
          <a href="/samples/review_score_sample_upload.csv">
            {t("section_1_content_1")}
          </a>
          {t("section_1_content_2")}
          <strong>{t("section_1_content_3")}</strong>
        </p>
        <p>
          <input
            onChange={onSelectedFile}
            className="input"
            type="file"
            id="file"
            name="file"
            placeholder="CSV"
            accept=".csv"
          />
        </p>
        <p>
          <button
            type="button"
            className="internal-tools-button"
            onClick={getReviewScores}
          >
            {t("execute")}
          </button>
        </p>
        {errorMessage && <h3 id="error">{errorMessage}</h3>}
      </section>
      <h2 className="sub-title">{t("result")}</h2>
      {resultMessage && (
        <section className="card">
          <p>{resultMessage}</p>
        </section>
      )}
    </React.Fragment>
  );
};

export default withRouter(ReviewScores);
