/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import { InputBox } from "../../components/FormElement/InputBox";
import { ModalConfirm } from "../../components/ModalConfirm";
import DataSvc from "../../services/dataSvc";
import { checkFormatEmail } from "../../utils/formatValidation";

import "./styles.scss";

type IDeleteGoogleLinkPageProps = RouteComponentProps<any>;

const DeleteGoogleLinkPage: React.FunctionComponent<
  IDeleteGoogleLinkPageProps
> = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`deleteGoogleLinkPage.${key}`);

  const [emailToClearGoogleData, setEmailToClearGoogleData] =
    useState<string>("");
  const [
    showClearGoogleConfirmationModal,
    setShowClearGoogleConfirmationModal,
  ] = useState<boolean>(false); // false
  const [successMessage, setSuccessMessage] = useState<string>("");

  // on Click Register
  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailToClearGoogleData(event.currentTarget.value);
  };

  const onClickSubmit = () => {
    setSuccessMessage("");
    if (checkFormatEmail(emailToClearGoogleData)) {
      setShowClearGoogleConfirmationModal(true);
    } else {
      toast.error(t("email_format_wrong"));
    }
  };

  const onClickConfirmation = async () => {
    await deleteGoogleLink.mutateAsync().then(() => {
      setSuccessMessage(t("operation_successful"));
    });
  };

  const deleteGoogleLink = useMutation(() => {
    return DataSvc.deleteGoogleLink(emailToClearGoogleData);
  });

  return (
    <>
      <div className="right-content delete-google-link">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("delete_google_link")}</div>
          <div className="rights"></div>
          <div className="line"></div>
        </div>

        <div className="white-border-panel">
          <div className="delete-google-link__main">
            <h3>{t("input_email_here")}</h3>
            <div className="delete-google-link__main__form">
              <InputBox
                value={emailToClearGoogleData}
                onChange={() => {}}
                onChangeEvent={onChangeEmail}
                placeholder={t("email")}
              />
              <a
                className="btn btn-blue"
                onClick={() => {
                  onClickSubmit();
                }}
              >
                {t("confirm")}
              </a>
            </div>
            {successMessage ? (
              <>
                <hr />
                <p className="delete-google-link__success-message">
                  {successMessage}
                </p>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {showClearGoogleConfirmationModal && (
        <ModalConfirm
          title={"please_confirm_to_delete_google_link"}
          secondaryDescription={emailToClearGoogleData}
          cancelLabel={"cancel"}
          confirmLabel={"go"}
          onClose={() => {
            setShowClearGoogleConfirmationModal(false);
          }}
          onConfirm={() => {
            onClickConfirmation();
            setShowClearGoogleConfirmationModal(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(DeleteGoogleLinkPage);
