import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  LineChart,
  Legend,
  CartesianGrid,
  Tooltip,
  Line,
  XAxis,
  YAxis,
} from "recharts";

import {
  OrgDailyMetricsSettingData,
  OrgDailyMetricsData,
} from "../../../models/MetricsLineChartData";

import "./styles.scss";

export interface ILineChartItemProps {
  width: number;
  height: number;
  dataList: OrgDailyMetricsData;
}

export const LineChartItem: React.FunctionComponent<ILineChartItemProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`dashboardPage.lineChartItem.${key}`);

  const { width, height, dataList } = props;

  const [dataListShown, setDataListShown] = useState<OrgDailyMetricsData>();

  useEffect(() => {
    if (dataList) {
      const dataListTemp = dataList;

      dataListTemp.dataForLine.forEach((item) => {
        const keys = Object.keys(item);
        keys.forEach((itemKey) => {
          if (itemKey !== "label" && item[itemKey]) {
            item[itemKey] = parseFloat(item[itemKey]);
          } else if (itemKey === "label") {
            item[itemKey] = moment(item[itemKey]).format("YYYY/MM/DD");
          }
        });
      });

      setDataListShown(_.cloneDeep(dataListTemp));
    }
  }, [dataList]);

  return (
    <>
      {!!dataListShown && (
        <LineChart
          width={width}
          height={height}
          data={dataListShown.dataForLine}
        >
          <Legend
            iconType="plainline"
            wrapperStyle={{ fontSize: "12px", fontWeight: 700 }}
          />
          <CartesianGrid strokeDasharray="3 2" />
          <XAxis
            dataKey="label"
            tick={{ fontSize: 13 }}
            style={{ fontFamily: "Inter" }}
            interval={5}
            tickFormatter={(value: string) =>
              moment(new Date(value).toISOString()).format("MM/DD")
            }
          />
          <YAxis
            tick={{ fontSize: 13 }}
            tickCount={6}
            style={{ fontFamily: "Inter" }}
            tickFormatter={(value: number) =>
              new Intl.NumberFormat("en").format(value)
            }
          />
          <Tooltip
            formatter={(value) =>
              new Intl.NumberFormat("en").format(value as number)
            }
            labelFormatter={(value) => {
              return `${t("date")}: ${value}`;
            }}
          />
          {dataListShown.dataSources.map(
            (item: OrgDailyMetricsSettingData, index: number) => (
              <React.Fragment key={index}>
                <Line
                  type="linear"
                  name={item.label}
                  dataKey={item.fieldName}
                  stroke={item.color}
                  strokeWidth={4}
                  strokeDasharray={item.style === "solid" ? "0" : "5 3"}
                  dot={false}
                />
              </React.Fragment>
            )
          )}
        </LineChart>
      )}
    </>
  );
};

export default LineChartItem;
