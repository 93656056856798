import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

export interface IHeaderProps {
  setModalPrivacyPolicyAndTermsServiceType: (value: string) => void;
  setShownModalConfirmLogout: (value: boolean) => void;
}

export const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`header.${key}`);

  const {
    setModalPrivacyPolicyAndTermsServiceType,
    setShownModalConfirmLogout,
  } = props;

  return (
    <>
      <header className={`header`}>
        <div className="left-logo">
          <a href="/" className="icons btn-logo" aria-label="Home"></a>
        </div>
        <div className="right-link flex">
          <ul className="lists">
            <li className="list-item">
              <button
                className="link"
                onClick={() => {
                  setModalPrivacyPolicyAndTermsServiceType("termsService");
                }}
              >
                {t("terms_of_service")}
              </button>
            </li>
            <li className="list-item">
              <button
                className="link"
                onClick={() => {
                  setModalPrivacyPolicyAndTermsServiceType("privacyPolicy");
                }}
              >
                {t("privacy_policy")}
              </button>
            </li>
          </ul>
          <button
            className="btn-logout"
            onClick={() => setShownModalConfirmLogout(true)}
          >
            <i className="icons icon-logout"></i>
            <span className="txt">{t("logout")}</span>
          </button>
        </div>
      </header>
    </>
  );
};

export default Header;
