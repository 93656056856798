export interface MunicipalityData {
  name: string;
  kana_name: string;
  type: 'pref' | 'muni';
  external_id: number;
  parent_external_id: number;
  id: number;
  created_at: string;
  created_by: number;
  last_modified_at: string;
  last_modified_by: number;
  checked?: boolean;
}

export interface MunicipalityGroupData {
  name: string;
  muniList: MunicipalityData[];
}

export const convertToGroupData = (municipalities: MunicipalityData[]): MunicipalityGroupData[] => {
  const prefArray = municipalities.filter((item) => {
    return item.type === 'pref';
  });

  const municipalitiesArray: MunicipalityGroupData[] = [];

  prefArray.forEach((itemPref) => {
    const muniArray = municipalities.filter((itemMuni) => {
      return (
        itemMuni.type === 'muni' &&
        itemMuni.parent_external_id === itemPref.external_id
      );
    });

    municipalitiesArray.push({
      name: itemPref.name,
      muniList: muniArray,
    });
  });

  return municipalitiesArray;
}
