import React from "react";
import "./styles.scss";

export interface ITipsIconProps {
  tipsLabel: string;
  position?: "top" | "bottom";
  scale?: number;
  iconMargin?: number;
}

export const TipsIcon: React.FunctionComponent<ITipsIconProps> = (props) => {
  const { tipsLabel, position = "top", scale = 1 } = props;

  const iconStyle = {
    transform: `scale(${scale})`,
  };

  return (
    <span className="question-module">
      <i className="icons icon-question" style={iconStyle}></i>
      <span
        className={`pink-tips ${position}`}
        dangerouslySetInnerHTML={{ __html: tipsLabel.replace(/\n/g, "<br/>") }}
      />
      <span className={`triangle ${position}`} />
    </span>
  );
};

export default TipsIcon;
