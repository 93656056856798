import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  LineChart,
  Legend,
  CartesianGrid,
  Tooltip,
  Line,
  XAxis,
  YAxis,
} from "recharts";

import {
  OrgDailyMetricsSettingData,
  OrgDailyMetricsData,
} from "../../../../models/MetricsLineChartData";

import "./styles.scss";

export interface ILineChartItemProps {
  width: number;
  height: number;
  dataList: OrgDailyMetricsData;
  previousYearDataList?: OrgDailyMetricsData;
  usePrevYear: boolean;
}

const combinePreviousYearData = (
  currentYear: OrgDailyMetricsData,
  prevYear: OrgDailyMetricsData
): OrgDailyMetricsData => {
  const currentYearDataLength = currentYear.dataForLine.length;
  return {
    title: currentYear.title,
    dataSources: [...currentYear.dataSources, ...prevYear.dataSources],
    dataForLine: [...currentYear.dataForLine, ...prevYear.dataForLine].reduce(
      (prevValue, currentValue, index) => {
        if (index < currentYearDataLength) {
          return [...prevValue, currentValue];
        } else {
          const indexOfSamePeriod = index - currentYearDataLength;
          const samePeriodTemp = {
            ...prevValue[indexOfSamePeriod],
            ...currentValue,
          };
          prevValue[indexOfSamePeriod] = samePeriodTemp;
          return prevValue;
        }
      },
      []
    ),
  };
};

export const LineChartItem: React.FunctionComponent<ILineChartItemProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`dashboardPage.lineChartItem.${key}`);

  const { width, height, dataList, previousYearDataList, usePrevYear } = props;

  const [dataListShown, setDataListShown] = useState<OrgDailyMetricsData>();

  useEffect(() => {
    if (dataList) {
      let dataListTemp = dataList;

      if (previousYearDataList) {
        dataListTemp = combinePreviousYearData(dataList, previousYearDataList);
      }

      dataListTemp?.dataForLine.forEach((item) => {
        const keys = Object.keys(item);
        keys.forEach((itemKey) => {
          if (itemKey !== "name" && item[itemKey]) {
            item[itemKey] = parseFloat(item[itemKey]);
          }
        });
      });

      setDataListShown(_.cloneDeep(dataListTemp));
    }
  }, [dataList, previousYearDataList, usePrevYear]);

  return (
    <>
      {!!dataListShown && (
        <LineChart
          width={width}
          height={height}
          data={dataListShown.dataForLine}
        >
          <Legend
            iconType="plainline"
            wrapperStyle={{ fontSize: "12px", fontWeight: 700 }}
          />
          <CartesianGrid strokeDasharray="3 2" />
          <XAxis
            dataKey="name"
            tick={{ fontSize: 13 }}
            style={{ fontFamily: "Inter" }}
          />
          <YAxis
            tick={{ fontSize: 13 }}
            tickCount={6}
            style={{ fontFamily: "Inter" }}
            tickFormatter={(value: number) =>
              new Intl.NumberFormat("en").format(value)
            }
          />
          <Tooltip
            formatter={(value) =>
              new Intl.NumberFormat("en").format(value as number)
            }
            labelFormatter={(value) => {
              return `${t("date")}: ${value}`;
            }}
          />
          {dataListShown.dataSources.map(
            (item: OrgDailyMetricsSettingData, index: number) => (
              <React.Fragment key={index}>
                <Line
                  type="linear"
                  name={item.label}
                  dataKey={item.fieldName}
                  stroke={item.color}
                  strokeWidth={6}
                  dot={false}
                  strokeDasharray={item.style === "dashed" ? "8 4" : undefined}
                />
              </React.Fragment>
            )
          )}
        </LineChart>
      )}
    </>
  );
};

export default LineChartItem;
