import ReactGA from 'react-ga4';

import { AccountData } from '../models/AccountData';

let currentMeId: string;

export const setUserDimensions = (me?: AccountData) => {
  if(!me || currentMeId === me.uuid) { return }

  const { uuid, type, organization } = me;
  currentMeId = uuid;

  ReactGA.set({'userId': uuid})
  ReactGA.gtag('set', 'user_properties', {
    'accountType': type,
    'organization': organization?.name,
  });

}

export const modalViewGAEvent = (modalName: string) => {
  ReactGA.send({
    hitType: 'pageview',
    page: `${window.location.pathname}/${modalName}`
  })
}