import i18next from "i18next";

import {
  OrgGroupAggregationChartItemData,
  OrgGroupAggregationMetricsData,
} from "../../../../models/ChartCollectionData";

export interface IBarChartProps {
  chartDetailsData: OrgGroupAggregationMetricsData;
}

export const compileToDataSource = (
  chartDetailsData: OrgGroupAggregationMetricsData
) => {
  const chartDataTemp: any = {
    title: "",
    dataSources: [
      {
        label: i18next.t("analysisCollectionsPage.collectionChartArea.count"),
        fieldName: "value",
        style: "",
        color: "#4753EF",
      },
    ],
    dataForLine: [],
  };

  chartDataTemp.dataForLine = chartDetailsData.data.map(
    (item: OrgGroupAggregationChartItemData) => {
      return {
        name: item.groups[0],
        value: item.value,
      };
    }
  );

  chartDataTemp.dataForLine.sort(function (a: any, b: any) {
    return b.value - a.value;
  });

  let otherCount = 0;
  for (let i = 10; i < chartDataTemp.dataForLine.length; i++) {
    otherCount += chartDataTemp.dataForLine[i].value;
  }

  chartDataTemp.dataForLine.splice(10);
  if (otherCount > 0) {
    chartDataTemp.dataForLine.push({
      name: i18next.t("analysisCollectionsPage.collectionChartArea.others"),
      value: otherCount,
    });
  }

  return chartDataTemp;
};
