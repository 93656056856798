/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import { ArrangedUseLimitsData } from "../../../models/FeatureUseLimitsData";
import Pagination from "../../Pagination";
import { IndividualSettingItem } from "../IndividualSettingItem";
import { IndividualSettingListHeader } from "../IndividualSettingListHeader/Index";
import ListTopBar from "../ListTopBar";
import "./styles.scss";

export interface IFunctionIndividualSettingListProps {
  arrangedUseLimits: ArrangedUseLimitsData[];
  filterFormData: any;
  totalCount: number;
  targetType: string;
  onChangeFilterFormData: (filterFormData: any) => void;
  onClickEdit: (useLimit: ArrangedUseLimitsData) => void;
}

const FunctionIndividualSettingList: React.FunctionComponent<
  IFunctionIndividualSettingListProps
> = ({
  arrangedUseLimits,
  filterFormData,
  totalCount,
  onChangeFilterFormData,
  targetType,
  onClickEdit,
}) => {
  return (
    <>
      <div className="white-border-panel">
        <div className="table-data table-data-grid-pc-only">
          <ListTopBar
            filterFormData={filterFormData}
            onChangeFilterFormData={(filterFormData: any) => {
              onChangeFilterFormData(filterFormData);
            }}
            targetType={targetType}
          />
          <IndividualSettingListHeader targetType={targetType} />
          {arrangedUseLimits.map((item) => (
            <IndividualSettingItem
              key={item.target_id}
              arrangedUseLimits={item}
              onClickEdit={() => onClickEdit(item)}
            />
          ))}
        </div>

        <Pagination
          filterFormData={filterFormData}
          totalRowsNumber={totalCount}
          onChangeFilterFormData={(filterFormData: any) => {
            onChangeFilterFormData(filterFormData);
          }}
        />
      </div>
    </>
  );
};

export default FunctionIndividualSettingList;
