import React from "react";
import { useTranslation } from "react-i18next";

import { LocalPostPreviewCallToAction } from "./LocalPostPreviewCallToAction";
import { LocalPostPreviewCouponCode } from "./LocalPostPreviewCouponCode";
import { LocalPostPreviewEventSchedule } from "./LocalPostPreviewEventSchedule";
import { LocationData } from "../../../../../models/LocationData";
import {
  CreateLocalPostState,
  LocalPostTopicType,
} from "../../../CreateLocalPostForm/utils";

import "./styles.scss";

interface ILocalPostPreview {
  localPost: CreateLocalPostState;
  locationData: LocationData | undefined;
}

export const LocalPostPreview: React.FunctionComponent<ILocalPostPreview> = ({
  localPost,
  locationData,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.modalCreateLocalPost.modalPreview.${key}`);

  const src = React.useMemo<string>(() => {
    if (localPost.media.length === 0) {
      return "";
    }
    return URL.createObjectURL(localPost.media[0]);
  }, [localPost]);

  return (
    <div className="local-post-preview-wrapper">
      <div className="top">
        <div className="location-title">
          {/* Todo: Create a common <Logo /> component once we've sorted out logos */}
          {false && <div className="location-logo"></div>}
          <div className="location-name-wrapper">
            <span className="location-name">{locationData?.name}</span>
            <span className="post-time">{t("post_time")}</span>
          </div>
        </div>
        <div className="options"></div>
      </div>
      {src !== "" && (
        <div className="image-wrapper">
          <img src={src} alt="to-be-uploaded"></img>
        </div>
      )}
      {(localPost.topicType === LocalPostTopicType.EVENT ||
        localPost.topicType === LocalPostTopicType.OFFER) && (
        <div className="event-details">
          <div className="left">
            <div className="event-title">{localPost.event.title}</div>
            <LocalPostPreviewEventSchedule
              schedule={localPost.event.schedule}
              topicType={localPost.topicType}
            />
          </div>
          { localPost.topicType === LocalPostTopicType.OFFER && <div className="offer-tag"></div>}
        </div>
      )}
      <div className="text-body">{localPost.text}</div>
      <LocalPostPreviewCouponCode
        topicType={localPost.topicType}
        couponCode={localPost.offer.couponCode}
        schedule={localPost.event.schedule}
      />
      <LocalPostPreviewCallToAction localPost={localPost} />
      {localPost.topicType === LocalPostTopicType.OFFER &&
        localPost.offer.termsConditions.length > 0 && (
          <div className="terms">{localPost.offer.termsConditions}</div>
        )}
      <div className="share"></div>
    </div>
  );
};
