/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FacilityFilterConditionData } from '../../../models/FacilityFilterConditionData';

import './styles.scss'

export interface ICurrentFiltersProps {
  conditionFormData: FacilityFilterConditionData;
  onClickClearFilterCondition?: () => void
}

export const CurrentFilters: React.FunctionComponent<ICurrentFiltersProps> = ({ conditionFormData, onClickClearFilterCondition }) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`facilityListPage.facilityList.listTopBar.${key}`)

  const getLabelsList = (dataArray: any[]) => {
    const newDataArray: string[] = dataArray.map(item => {
      return item.name
    })
    
    return newDataArray.join(', ');
  }

  return (<div className="current-filters">
  <div className="current-filters-top">
    <h3>{t('active_filters')}</h3>
    {
      onClickClearFilterCondition && <a href="#javascript" className='btn btn-border'
        onClick={(event) => {
          onClickClearFilterCondition();
          event.preventDefault();
        }}>
        {t('clear_condition')}
      </a>
    }
  </div>
  <div className="label-area">
    {conditionFormData.hasLodging?.shouldInclude !== undefined && [
      <div className="left-txt" key="hasLodging-left">
        {_t('facilityListPage.modalFilterCondition.profBoolLabels.has_lodging.label')} : 
      </div>,
      <div className="labels" key="hasLodging-labels">
        {_t(`facilityListPage.modalFilterCondition.profBoolLabels.has_lodging.${conditionFormData.hasLodging.shouldInclude}`)}
      </div>
    ]}
    {conditionFormData.healthIndex.length > 0 && [
      <div className="left-txt" key="healthIndex-left">
        {t('health_index')} : 
      </div>,
      <div className="labels" key="healthIndex-labels">
        {getLabelsList(conditionFormData.healthIndex)}
      </div>
    ]}
    {
      conditionFormData.municipalities.map((item, index) => (
        <React.Fragment key={index}>
          {item.muniList.length > 0 && [
            <div className="left-txt" key={`filter-${index}-left`}>
              {item.name} : 
            </div>,
            <div className="labels" key={`filter-${index}-text`}>
              {getLabelsList(item.muniList)}
            </div>
          ]}
        </React.Fragment>
      ))
    }
    {conditionFormData.categories.length > 0 && [
      <div className="left-txt" key="cats-left">
        {t('categories')} : 
      </div>,
      <div className="labels" key="cats-labels">
        {getLabelsList(conditionFormData.categories)}
      </div>
    ]}
    {conditionFormData.labels.length > 0 && [
      <div className="left-txt" key="labels-left">
        {t('labels')} : 
      </div>,
      <div className="labels" key="labels-labels">
        {getLabelsList(conditionFormData.labels)}
      </div>
    ]}
    {conditionFormData.profBooleans.length > 0 && 
      conditionFormData.profBooleans.map(item => [
        <div className="left-txt" key="labels-left">
          {_t(`facilityListPage.modalFilterCondition.profBoolLabels.${item.key}.label`)} : 
        </div>,
        <div className="labels" key="labels-labels">
          {_t(`facilityListPage.modalFilterCondition.profBoolLabels.${item.key}.${item.shouldInclude ? 'true' : 'false'}`)}
        </div>
      ]
    )}
  </div>
</div>)
}