import React from "react";
import { useTranslation } from "react-i18next";

import { useMe } from "../../../hooks/queries";
import { getSortCriteria } from "../ListTopBar";
import "./styles.scss";

export interface IFacilityListHeaderProps {
  filterFormData: any;
  onChangeFilterFormData: (filterFormData: any) => void;
}

export const FacilityListHeader: React.FunctionComponent<
  IFacilityListHeaderProps
> = ({ filterFormData, onChangeFilterFormData }) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityListPage.facilityList.${key}`);
  const { data: me } = useMe();

  const { order, sort, sortBy: currentSortBy } = filterFormData;

  const isOrgUser = () => {
    return me?.type !== "loc_admin";
  };

  const performSort = (fieldName: string, filterString: string) => {
    let nextSort: string;
    if (fieldName === sort && order === "asc") {
      nextSort = `${filterString} ↓`;
    } else {
      nextSort = `${filterString} ↑`;
    }

    const criteria = getSortCriteria(nextSort);

    onChangeFilterFormData({
      ...filterFormData,
      sortBy: nextSort,
      ...criteria,
    });
  };

  const createButton = (fieldName: string, filterString: string) => (
    <button onClick={() => performSort(fieldName, filterString)}>
      {sort === fieldName ? currentSortBy : filterString}
    </button>
  );

  return (
    <div className="row-th facility-list-header">
      <div className="col-th facility-name">
        <div className="spacing">
          {createButton("name", t("facility_name"))}
        </div>
      </div>
      <div className="col-th score center">
        <div className="spacing">
          {createButton("stats.score", t("health_index"))}
        </div>
      </div>
      <div className="col-th profile-views text-right">
        <div className="spacing">
          {createButton("stats.profile_views", t("profile_views"))}
          <div className="change">
            {createButton(
              "stats.profile_views_change_percentage",
              `(${t("profile_views_change_percentage")})`
            )}
          </div>
        </div>
      </div>
      <div className="col-th direction-search-count text-right">
        <div className="spacing">
          {createButton(
            "stats.direction_search_count",
            t("direction_search_count")
          )}
          <div className="change">
            {createButton(
              "stats.direction_search_count_change_percentage",
              `(${t("direction_search_count_change_percentage")})`
            )}
          </div>
        </div>
      </div>
      <div className="col-th muni center">
        <div className="spacing">
          {createButton("prefecture+city", t("municipality"))}
        </div>
      </div>
      <div className="col-th category center">
        <div className="spacing">{createButton("category", t("category"))}</div>
      </div>
      {isOrgUser() && (
        <div className="col-th labels">
          <div className="spacing">{t("labels")}</div>
        </div>
      )}
    </div>
  );
};
