/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import {useTranslation} from 'react-i18next';

import { modalViewGAEvent } from '../../../utils/ga';
import './styles.scss';

export interface IModalConfirmSendMessageProps {
  messageTitle: string
  messageText: string
  replyEmail: string
  userEmail: string
  bccTo: string
  onClose: () => void
  onToConfirm: () => void
  conditions?: React.ReactNode
}

export const ModalConfirmSendMessage: React.FunctionComponent<IModalConfirmSendMessageProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`modalSendMessage.${key}`)
  const tEmailForm = (key: string) => _t(`emailForm.${key}`)

  const { messageTitle, messageText, replyEmail, userEmail, bccTo, onClose, onToConfirm, conditions } = props;
 
  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent('input-message-indiv-fac-confirm');
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (    
    <div className="modal-default modal-send-message modal-send-message-confirm">
      <div className="modal-mains">
        <a className='btn-close'
          onClick={() => {
            onClose()
          }}>
        </a>
        <div className="modal-send-message-body">
          <div className="top-title">
            {t('check_before_send')}
          </div>
          <div className="modal-send-message-body_conditions">
            {
              conditions ? conditions : null
            }
          </div>
          <hr />
          <div className="message-form">
            <h2 className='top-title'>{tEmailForm('message_details')}</h2>
            <h3>{tEmailForm('message_title')}</h3>
            <p>{messageTitle}</p>
            <h3>{tEmailForm('message_body')}</h3>
            <p>{messageText}</p>
            <h3>{tEmailForm('reply_to')}</h3>
            <p>{!!replyEmail ? replyEmail : userEmail}</p>
            {
              !!bccTo && <>
                <h3>{tEmailForm('bcc_to')}</h3>
                <p>{!!bccTo ? bccTo : ''}</p>
              </>
            }
          </div>
          <div className="bottom-btns">
            <a className='btn btn-border'
              onClick={() => {
                onClose()
              }}>
              {t('back')}
            </a>
            <a className='btn btn-blue'
              onClick={() => {
                onToConfirm()
              }}>
              {t('send')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmSendMessage;
