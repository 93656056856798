/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import { LocationData } from "../../../models/LocationData";
import TipsIcon from "../../TipsIcon";

export interface IPickupFieldsProps {
  locationData: LocationData;
}

const fieldsWithLodgingText = ["has_profile_description"];

const fieldsToCheckApplicability: Record<string, string> = {
  has_service_items: "can_modify_service_list",
  has_food_menus: "can_have_food_menus",
};

export const PickupFields: React.FunctionComponent<IPickupFieldsProps> = ({
  locationData,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityDetailsPage.pickupFields.${key}`);

  if (locationData.pickup_fields) {
    return (
      <div className="white-border-panel pickupFields-module padding32">
        <div className="top-bar">
          <div className="title">
            {t("section_title")}
            <div className="rights">{t("section_note")}</div>
          </div>
        </div>
        <div className="pickupFields-module__body">
          {Object.keys(locationData.pickup_fields).map((rawKey) => {
            const key =
              locationData.has_lodging && fieldsWithLodgingText.includes(rawKey)
                ? `${rawKey}_lodging`
                : rawKey;
            const hasPass = locationData.pickup_fields
              ? locationData.pickup_fields[rawKey]
              : false;
            const applicabilityField = fieldsToCheckApplicability[rawKey];

            return (
              <div className="groups" key={key}>
                <div className="gray-top">
                  <span className="txt">{t(key)}</span>
                  <TipsIcon tipsLabel={t(`popups.${key}`)} />
                </div>
                <div className="color-txt">
                  {applicabilityField &&
                  !locationData[applicabilityField as keyof LocationData] ? (
                    <span className="txt">{t("not_applicable")}</span>
                  ) : (
                    <i
                      className={`icons ${
                        hasPass ? "icon-done" : "icon-error"
                      }`}
                    ></i>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return <></>;
};

export default PickupFields;
