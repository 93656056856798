/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { DATE_FORMAT } from "../../../config";
import { AccountData } from "../../../models/AccountData";

import "./styles.scss";

export interface IMembersListProps {
  accountData?: AccountData;
  isAdminMode?: boolean;
  organizationName?: string;
  dataList: AccountData[];
  onClickInviteMember: () => void;
  onClickEditMember: (index: number) => void;
}

export const MembersList: React.FunctionComponent<IMembersListProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`accountManagementPage.membersList.${key}`);

  const {
    accountData,
    isAdminMode,
    organizationName,
    dataList,
    onClickInviteMember,
    onClickEditMember,
  } = props;

  return (
    <>
      <div className="white-border-panel members-list">
        <div className="top-bar flex-grid">
          <div className="left-title">
            {accountData?.organization?.name ?? organizationName}
          </div>
          {(isAdminMode || accountData?.role === "manager") && (
            <a
              className="btn btn-blue"
              onClick={() => {
                onClickInviteMember();
              }}
            >
              {t("invite_member")}
            </a>
          )}
        </div>

        <div className="table-data">
          <div className="row-th">
            <div className="col-th width21 name">
              <div className="spacing">{t("name")}</div>
            </div>
            <div className="col-th width21 role">
              <div className="spacing">{t("role")}</div>
            </div>
            <div className="col-th width21 email">
              <div className="spacing">{t("mail_address")}</div>
            </div>
            <div className="col-th width21 created-date">
              <div className="spacing">{t("created_date")}</div>
            </div>
            <div className="col-th w10">
              <div className="spacing"></div>
            </div>
          </div>
          {dataList.map((item: AccountData, index: number) => (
            <div className="row-td" key={index}>
              <div className="col-td width21 name">
                <div className="spacing">
                  <span className="strong-txt">{item.name}</span>
                </div>
              </div>
              <div className="col-td width21 role">
                <div className="spacing">{t(item.role)}</div>
              </div>
              <div className="col-td width21 email">
                <div className="spacing">{item.email}</div>
              </div>
              <div className="col-td width21 created-date">
                <div className="spacing">
                  <span className="sp-only">{t("created_date")}: </span>
                  {moment(item.created_at).format(DATE_FORMAT)}
                </div>
              </div>
              <div className="col-td center w10 manage">
                <div className="spacing">
                  {accountData?.id === item.id ? (
                    <span className="gray-link">{t("you")}</span>
                  ) : (
                    <>
                      {(isAdminMode || accountData?.role === "manager") && (
                        <a
                          className="blue-link"
                          onClick={() => {
                            onClickEditMember(index);
                          }}
                        >
                          {t("edit")}
                        </a>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MembersList;
