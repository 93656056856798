import React from "react";
import { useTranslation } from "react-i18next";

import { ToolsMetrics } from "../../types";

import "./styles.scss";

const ToolsChecklist: React.FunctionComponent<{ metrics: ToolsMetrics }> = ({
  metrics,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`internaltoolsPage.metricsNew.checklistTable.${key}`);
  const { checklist } = metrics;

  return (
    <div id="checklistTable">
      {Object.keys(checklist).map((field) => (
        <React.Fragment key={field}>
          <h4>{t(field)}</h4>
          <i
            className={`icons icon-${checklist[field] ? "done" : "no-data"}`}
          ></i>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ToolsChecklist;
