import React from "react";
import { useTranslation } from "react-i18next";

import { LocationData } from "../../../models/LocationData";
import { percentage } from "../../../utils/common";
import { formatNum } from "../../../utils/formatValidation";
import TipsIcon from "../../TipsIcon";

import "./styles.scss";
export interface IImportantMetricsProps {
  locationData: LocationData;
}

export const ImportantMetrics: React.FunctionComponent<
  IImportantMetricsProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityDetailsPage.importantMetrics.${key}`);

  const { locationData } = props;

  // get Profile Views Change ratio
  const getProfileViewsChangeRatio = (): number => {
    return (
      ((locationData.stat.profile_views -
        locationData.stat.prev_profile_views) /
        locationData.stat.prev_profile_views) *
      100
    );
  };

  // get Media Views Change ratio
  const getBookingFoodorderChangeRatio = () => {
    const count =
      locationData.stat.booking_count + locationData.stat.food_order_count;
    const prev_count =
      locationData.stat.prev_booking_count +
      locationData.stat.prev_food_order_count;
    return ((count - prev_count) / prev_count) * 100;
  };

  // get Website Clicks Change ratio
  const getWebsiteClicksChangeRatio = () => {
    return (
      ((locationData.stat.website_clicks -
        locationData.stat.prev_website_clicks) /
        locationData.stat.prev_website_clicks) *
      100
    );
  };

  // get Route Searches Change ratio
  const getRouteSearchesChangeRatio = () => {
    return (
      ((locationData.stat.direction_search_count -
        locationData.stat.prev_direction_search_count) /
        locationData.stat.prev_direction_search_count) *
      100
    );
  };

  // get Reviews Received Change ratio
  const getReviewsReceivedChangeRatio = () => {
    return (
      ((locationData.stat.comment_count -
        locationData.stat.prev_comment_count) /
        locationData.stat.prev_comment_count) *
      100
    );
  };

  // get Percentage Content
  const getPercentageContent = (value: number) => {
    const cls =
      Number.isFinite(value) && value > 0
        ? "green"
        : value < 0
        ? "red"
        : "gray";
    const indicate =
      Number.isFinite(value) && value > 0 ? "↑" : value < 0 ? "↓" : "";
    const suffix = Number.isFinite(value) && value ? "%" : "";

    return (
      <span className={`txt ${cls}`}>
        {indicate} {percentage(value, true)}
        {suffix}
      </span>
    );
  };

  return (
    <div className="white-border-panel important-metrics-module ">
      <div className="titles">{t("important_metrics")}</div>
      {locationData.is_archived && (
        <p className="data-note">
          {t("broken_data_link")}
          <br />
          {t("broken_at")}
          {new Date(locationData.is_archived_at).toLocaleDateString("ja-JP")}
        </p>
      )}
      <div className="table-data">
        <div className="row-th pc-only">
          <div className="col-th width425 title">
            <div className="spacing">&nbsp;</div>
          </div>
          <div className="col-th width20 numbers">
            <div className="spacing">{t("last_28_days")}</div>
          </div>
          <div className="col-th width20 numbers">
            <div className="spacing">{t("prev_28_days")}</div>
          </div>
          <div className="col-th width175 perc-change">
            <div className="spacing">{t("percentage_change")}</div>
          </div>
        </div>
        <div className="row-td">
          <div className="col-td width425 title">
            <div className="spacing">
              <span className="gray-txt">{t("profile_views")}</span>
              <TipsIcon tipsLabel={t("profile_views_comment")} />
            </div>
          </div>
          <div className="col-td width20 numbers">
            <div className="gray-txt sp-only">{t("last_28_days")}</div>
            <div className="spacing">
              {formatNum(locationData.stat.profile_views)}
            </div>
          </div>
          <div className="col-td width20 numbers">
            <div className="gray-txt sp-only">{t("prev_28_days")}</div>
            <div className="spacing">
              {formatNum(locationData.stat.prev_profile_views)}
            </div>
          </div>
          <div className="col-td width175 perc-change">
            <div className="gray-txt sp-only">{t("percentage_change")}</div>
            <div className="spacing">
              {getPercentageContent(getProfileViewsChangeRatio())}
            </div>
          </div>
        </div>
        {!locationData.has_lodging && (
          <div className="row-td">
            <div className="col-td width425 title">
              <div className="spacing">
                <span className="gray-txt">
                  {t("booking_food_order_count")}
                </span>

                <TipsIcon tipsLabel={t("booking_food_order_comment")} />
              </div>
            </div>
            <div className="col-td width20 numbers">
              <div className="gray-txt sp-only">{t("last_28_days")}</div>
              <div className="spacing">
                {formatNum(
                  locationData.stat.booking_count +
                    locationData.stat.food_order_count
                )}
              </div>
            </div>
            <div className="col-td width20 numbers">
              <div className="gray-txt sp-only">{t("prev_28_days")}</div>
              <div className="spacing">
                {formatNum(
                  locationData.stat.prev_booking_count +
                    locationData.stat.prev_food_order_count
                )}
              </div>
            </div>
            <div className="col-td width175 perc-change">
              <div className="gray-txt sp-only">{t("percentage_change")}</div>
              <div className="spacing">
                {getPercentageContent(getBookingFoodorderChangeRatio())}
              </div>
            </div>
          </div>
        )}
        <div className="row-td">
          <div className="col-td width425 title">
            <div className="spacing">
              <span className="gray-txt">{t("website_clicks")}</span>
              <TipsIcon tipsLabel={t("website_clicks_comment")} />
            </div>
          </div>
          <div className="col-td width20 numbers">
            <div className="gray-txt sp-only">{t("last_28_days")}</div>
            <div className="spacing">
              {formatNum(locationData.stat.website_clicks)}
            </div>
          </div>
          <div className="col-td width20 numbers">
            <div className="gray-txt sp-only">{t("prev_28_days")}</div>
            <div className="spacing">
              {formatNum(locationData.stat.prev_website_clicks)}
            </div>
          </div>
          <div className="col-td width175 perc-change">
            <div className="gray-txt sp-only">{t("percentage_change")}</div>
            <div className="spacing">
              {getPercentageContent(getWebsiteClicksChangeRatio())}
            </div>
          </div>
        </div>
        <div className="row-td">
          <div className="col-td width425 title">
            <div className="spacing">
              <span className="gray-txt">{t("route_searches")}</span>
              <TipsIcon tipsLabel={t("route_searches_comment")} />
            </div>
          </div>
          <div className="col-td width20 numbers">
            <div className="gray-txt sp-only">{t("last_28_days")}</div>
            <div className="spacing">
              {formatNum(locationData.stat.direction_search_count)}
            </div>
          </div>
          <div className="col-td width20 numbers">
            <div className="gray-txt sp-only">{t("prev_28_days")}</div>
            <div className="spacing">
              {formatNum(locationData.stat.prev_direction_search_count)}
            </div>
          </div>
          <div className="col-td width175 perc-change">
            <div className="gray-txt sp-only">{t("percentage_change")}</div>
            <div className="spacing">
              {getPercentageContent(getRouteSearchesChangeRatio())}
            </div>
          </div>
        </div>
        <div className="row-td">
          <div className="col-td width425 title">
            <div className="spacing">
              <span className="gray-txt">{t("reviews_received")}</span>
              <TipsIcon tipsLabel={t("reviews_received_comment")} />
            </div>
          </div>
          <div className="col-td width20 numbers">
            <div className="gray-txt sp-only">{t("last_28_days")}</div>
            <div className="spacing">
              {formatNum(locationData.stat.comment_count)}
            </div>
          </div>
          <div className="col-td width20 numbers">
            <div className="gray-txt sp-only">{t("prev_28_days")}</div>
            <div className="spacing">
              {formatNum(locationData.stat.prev_comment_count)}
            </div>
          </div>
          <div className="col-td width175 perc-change">
            <div className="gray-txt sp-only">{t("percentage_change")}</div>
            <div className="spacing">
              {getPercentageContent(getReviewsReceivedChangeRatio())}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportantMetrics;
