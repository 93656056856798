import { SummaryLocation } from "../containers/SendMessageScreens";

export interface OrgRecommendationData {
  title: string;
  body: string;
  email_sent: number;
  relevant_locations?: SummaryLocation[];
  organization_id: number;
  generated_date: string;
  section_title: string;
  is_main_section: number;
  id: number;
  created_at: string;
  created_by: number
  last_modified_at: string;
  last_modified_by: number;
}

export interface RawOrgRecommendationData extends Omit<OrgRecommendationData, "relevant_locations"> {
  relevant_locations?: string;
}

export interface OrgRecommendationHistoryData {
  organization_id: number;
  histories: {
    generated_date: string;
  }[];
}

export const sanitizeOrgRecommendationData = (rawData: OrgRecommendationData | RawOrgRecommendationData): OrgRecommendationData => {
  if(typeof rawData.relevant_locations === "string") { // returned JSON string
    return {
      ...rawData,
      relevant_locations: JSON.parse(rawData.relevant_locations)
    }
  } else {
    return rawData as OrgRecommendationData
  }
}