import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import DataSvc from "../../../services/dataSvc";
import { modalViewGAEvent } from "../../../utils/ga";
import { RecentPostsListSkeleton } from "../../DashboardComponents/LocalPostsComponent/RecentPostsListSkeleton";
import RecentPostList, {
  RecentMediaListContext,
} from "../../DashboardComponents/RecentPostsList";
import "./styles.scss";

interface IModalLocalPostIdeas {
  locationId: string;
  onClose: () => void;
}

export const ModalLocalPostIdeas: React.FunctionComponent<
  IModalLocalPostIdeas
> = ({ locationId, onClose }: IModalLocalPostIdeas) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(
      `analysisCollectionsPage.modalCreateLocalPost.modalLocalPostIdeas.${key}`
    );

  const { data, isFetching } = useQuery(
    ["sameCategoryLocalPosts", locationId],
    async () => {
      return await DataSvc.getLocalPostIdeas(locationId);
    }
  );

  useEffect(() => {
    modalViewGAEvent("localPostIdeas");
  }, []);

  return (
    <div className="modal modal-default modal-local-post-ideas">
      <div className="modal-mains">
        <button
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        ></button>
        <div className="modal-mains__body">
          <div className="top-title">
            <h2>{t("title")}</h2>
          </div>
          <div className="description-container">
            <span className="description">{t("description")}</span>
            <span className="cant-find-post">
              {_t("dashboardPage.localPostList.cant_find_post")}
            </span>
          </div>
          <div className="sub-section same-category">
            <div className="sub-section-title">
              <h3>{t("same_category")}</h3>
              <div className="line"></div>
            </div>
            {isFetching ? (
              <RecentPostsListSkeleton />
            ) : (
              <RecentPostList
                dataList={data?.sameCategory ?? null}
                context={RecentMediaListContext.IDEA_MODE}
              />
            )}
          </div>
          <div className="sub-section same-area">
            <div className="sub-section-title">
              <h3>{t("same_area")}</h3>
              <div className="line"></div>
            </div>
            {isFetching ? (
              <RecentPostsListSkeleton />
            ) : (
              <RecentPostList
                dataList={data?.sameArea ?? null}
                context={RecentMediaListContext.IDEA_MODE}
              />
            )}
          </div>
          <div className="bottom-buttons">
            <button className="btn btn-border" onClick={onClose}>
              {t("back")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
