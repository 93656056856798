import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { GoogleInformation } from "../../../models/GoogleInformation";
import { LocationData } from "../../../models/LocationData";
import {
  SelectedToolContext,
  ToolListContext,
} from "../InternalToolsContextProvider";
import {
  InternalToolEnum,
  InternalToolsCommonProps,
} from "../InternalToolsDictionaryAndTypes";

import "./styles.scss";

export interface IInternalToolsContainerProps {
  initialGoogleInformation: GoogleInformation | undefined;
  overlayTextKey?: string;
  locationData?: LocationData;
  orgId?: number;
  disableToolContainer?: boolean;
}

export const InternalToolContainer: React.FC<IInternalToolsContainerProps> = (
  props
) => {
  const { t } = useTranslation();
  const { initialGoogleInformation } = props;
  const toolList = useContext(ToolListContext);
  const { selectedTools } = useContext(SelectedToolContext);
  const [overlayText, setOverlayText] = useState("");
  const [googleInformation, setGoogleInformation] = useState<
    GoogleInformation | undefined
  >(initialGoogleInformation);
  const [errorFromChild, setErrorFromChild] = useState<string>("");

  const defaultOverlayTextKey =
    props.overlayTextKey || "internaltoolsPage.public.login_into_google_first";

  useEffect(() => {
    const hasError =
      // if disableToolContainer is true, put up the blocking overlay
      props.disableToolContainer ||
      // Internal tools needs either one of googleInformation or locationId to function
      (!googleInformation &&
        !props.locationData &&
        !props.orgId &&
        // If no tool is selected, no need to show overlay
        selectedTools.length !== 0 &&
        // If only getReviews is selected, don't show overlay even if both googleInformation and locationId are empty
        !(
          selectedTools.includes(InternalToolEnum.GetReviews) &&
          selectedTools.length === 1
        ));

    if (hasError) {
      setOverlayText(t(defaultOverlayTextKey));
    } else if (errorFromChild !== "") {
      setOverlayText(errorFromChild);
    } else {
      setOverlayText("");
    }
  }, [
    selectedTools,
    googleInformation,
    defaultOverlayTextKey,
    props.locationData,
    props.orgId,
    props.disableToolContainer,
    t,
    errorFromChild,
  ]);

  // if parent component passes in a new googleInformation, update the state
  useEffect(() => {
    setGoogleInformation(initialGoogleInformation);
  }, [initialGoogleInformation]);

  const currentComponentFunction = selectedTools.map(
    (tool) => toolList[tool].componentFunction
  );

  const onLoading = (loadingText: string) => {
    setOverlayText(loadingText || t("internaltoolsPage.public.loading"));
  };

  const onLoaded = () => {
    setOverlayText("");
  };

  /**
   * setErrorFromChild is used to pass error from child component to parent component to trigger blocking overlay
   * otherwise the error will be swallowed by the child component
   * @param err Error Object
   * @returns error for the child component to handle
   */
  const commonErrorHandler = (err: any): string | undefined => {
    console.log("err: ", err);
    console.log("response: ", err.response);
    if (
      err.response?.data ===
        "error: AxiosError: Request failed with status code 401" ||
      err.response.includes("403")
    ) {
      setGoogleInformation(undefined);
      setErrorFromChild(defaultOverlayTextKey);
      return;
    } else if (err === "error: Error: no_location_found") {
      return "場所取得失敗。名前を変えて再度検索してください。\n数回発生し続ける場合は、アカウントに施設が問題なくアカウント権限を持っているかをご確認ください。";
    } else {
      return err;
    }
  };

  const commonProps: InternalToolsCommonProps = {
    onLoading,
    onLoaded,
    googleInformation,
    commonErrorHandler,
    locationData: props.locationData,
    orgId: props.orgId,
  };

  return (
    <div className="internal-tool-tools">
      {currentComponentFunction.map((componentFunction, index) => {
        return (
          <div
            className="internal-tool"
            key={`${componentFunction.name}-${index}`}
          >
            {componentFunction(commonProps)}
          </div>
        );
      })}
      {overlayText && overlayText !== "" && (
        <div id="interactionArea">
          <div id="loading-text">{overlayText}</div>
        </div>
      )}
    </div>
  );
};
