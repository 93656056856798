import { LocationData } from "../../../../models/LocationData";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function canCreateStandardPost(locationData: LocationData | undefined): boolean {
  return true;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function canCreateEventPost(locationData: LocationData | undefined): boolean {
  return true;
}

export function canCreateOfferPost(locationData: LocationData | undefined): boolean {
  if (locationData?.has_lodging) {
    return false;
  }
  return true;
}