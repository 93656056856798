import React from "react";
import { useTranslation } from "react-i18next";

import {
  ActionType,
  CreateLocalPostState,
  LocalPostTopicType,
} from "../../../../CreateLocalPostForm/utils";

import "./styles.scss";

interface ILocalPostPreviewCallToAction {
  localPost: CreateLocalPostState;
}

export const LocalPostPreviewCallToAction: React.FunctionComponent<
  ILocalPostPreviewCallToAction
> = ({ localPost }) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.modalCreateLocalPost.${key}`);

  const generateInnerHtml = (): string | null => {
    if (localPost.topicType === LocalPostTopicType.OFFER) {
      return localPost.offer.redeemOnlineUrl.length > 0
        ? t("modalPreview.offer.use_online")
        : null;
    }
    if (
      localPost.callToAction.actionType ===
        ActionType.ACTION_TYPE_UNSPECIFIED ||
      localPost.callToAction.url.length < 1
    ) {
      return null;
    }
    return t(
      `createLocalPostForm.actionTypes.${localPost.callToAction.actionType}`
    );
  };

  const innerHtml = generateInnerHtml();

  if (innerHtml === null) {
    return <></>;
  }

  return (
    <div className="local-post-preview-call-to-action">
      {innerHtml}
    </div>
  );
};
