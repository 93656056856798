import _ from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  SelectedToolContext,
  ToolListContext,
} from "./InternalToolsContextProvider";
import {
  ToolDictionaryEntryType,
  InternalToolEnum,
} from "./InternalToolsDictionaryAndTypes";
import DropdownSelect from "../FormElement/DropdownSelect";


const InternalToolsSelect: React.FC = () => {
  const { t } = useTranslation();

  const toolList = useContext(ToolListContext);
  const { setSelectedTools } = useContext(SelectedToolContext);
  const [selectedKey, setSelectedKey] = useState("");

  const toolListWithTranslatedKeys: {
    [key: string]: ToolDictionaryEntryType & {
      name: keyof typeof InternalToolEnum;
    };
  } = _.transform(toolList, (result, value, key) => {
    result[t(`internaltoolsPage.${value.titleKey}`)] = {
      name: key as keyof typeof InternalToolEnum,
      ...value,
    };
  });

  const onChangeTool = (translatedToolTitle: string) => {
    setSelectedTools([
      InternalToolEnum[toolListWithTranslatedKeys[translatedToolTitle].name],
    ]);
    setSelectedKey(translatedToolTitle);
  };

  return (
    <div className="internal-tool-select">
      <DropdownSelect
        value={selectedKey ?? "選択してください"}
        items={_.keys(toolListWithTranslatedKeys)}
        onChange={onChangeTool}
      ></DropdownSelect>
    </div>
  );
};

export default InternalToolsSelect;
