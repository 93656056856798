import * as types from '../constants/actionTypes';

const defaultState = {
  db: {},
  loading: false
};

const exportReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case types.LOAD_ACCOUNTS_ME_DATA:
      return {
        ...state,
        accountsMe: action.data,
      };
    case types.LOAD_LABELS_DATA:
      return {
        ...state,
        labels: action.data,
      };
    case types.LOAD_CATEGORIES_DATA:
      return {
        ...state,
        categories: action.data,
      };
    case types.LOAD_MUNICIPALITIES_DATA:
      return {
        ...state,
        municipalities: action.data,
      };
    case types.LOAD_LOCATIONS_DATA:
      return {
        ...state,
        locations: action.data,
      };
    case types.LOAD_LOCATION_DATA:
      return {
        ...state,
        location: action.data,
      };
    case types.LOAD_LOCATION_RECOMMENDATIONS_DATA:
      return {
        ...state,
        locationRecommendations: action.data,
      };
    case types.LOADING_CHANGE:
      return {
        ...state,
        loading: action.data,
      };
    case types.LOAD_STATS_PRIME_DATA:
      return {
        ...state,
        statsPrime: action.data,
      };
    case types.LOAD_STATS_STATUS_DATA:
      return {
        ...state,
        statsStatus: action.data,
      };
    default:
      return state;
  }
};

export default exportReducer;
