import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import { LocalPost } from "../../../types";

const buttonLabel = (label: string) => {
  switch (label) {
    case 'BOOK':
      return '予約する';
    case 'ORDER':
      return '注文する';
    case 'SHOP':
      return '買う';
    case 'LEARN_MORE':
      return '詳細';
    case 'SIGN_UP':
      return '登録する';
    case 'CALL':
      return '電話する';
    default:
      return label;
  }
}

const ExamplePost: React.FunctionComponent<{ post: LocalPost }>  = ({ post }) => {
  const { t: _t, } = useTranslation();
  const t = (key: string) => _t(`internaltoolsPage.metricsNew.localPost.${key}`);

  const { callToAction, createTime, event, media, summary, updateTime } = post;

  const timeToDisplay = updateTime ? updateTime : createTime;

  return (<article className="examplePost">
    <div className="examplePost__head">
      {
        media && <img src={media[0].googleUrl} alt=""/>
      }
      <p>{`${updateTime ? t('updated_at') : t('posted_at') }: ${dayjs(timeToDisplay).format('YYYY年M月D日')}`}</p>
    </div>
    <div className="examplePost__body">
      {
        event && <div className="examplePost__body__header">
          <h3>{event.title}</h3>
          <p className='date'>{`${event.schedule.startDate.year}年${event.schedule.startDate.month}月${event.schedule.startDate.day}日〜${event.schedule.endDate.year}年${event.schedule.endDate.month}月${event.schedule.endDate.day}日`}</p>
        </div>
      }
      <p className="examplePost__body__summary">{summary}</p>
      {
        callToAction && <a target='_blank' href={callToAction.url} rel="noreferrer">{buttonLabel(callToAction.actionType)}</a>
      }
    </div>
  </article>)
}

export default ExamplePost