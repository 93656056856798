import React from "react";
import { useTranslation } from "react-i18next";

import ExamplePost from "./components/ExamplePost";
import { PostType, ToolsMetrics } from "../../types";

import "./styles.scss";

const LocalPosts: React.FunctionComponent<{ metrics: ToolsMetrics }> = ({
  metrics,
}) => {
  const { localPosts } = metrics;

  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`internaltoolsPage.metricsNew.localPost.${key}`);

  const postTypes: Record<PostType, number> = {
    STANDARD: 0,
    EVENT: 0,
    OFFER: 0,
  };

  localPosts.forEach((post) => {
    postTypes[post.topicType] = postTypes[post.topicType] + 1;
  });

  const total = localPosts.length;

  return (
    <>
      <h3>
        {_t("internaltoolsPage.metricsNew.localPost.post_number", { total })}
      </h3>
      <div className="data-table">
        {Object.keys(postTypes).map((key) => (
          <React.Fragment key={key}>
            <p>
              <strong>{_t(`internaltoolsPage.metricsNew.localPost.types.${key}`)}</strong>
            </p>
            <p>{postTypes[key as PostType]}</p>
          </React.Fragment>
        ))}
      </div>
      {localPosts.length > 0 && (
        <>
          <h3>{t("most_recent_post")}</h3>
          <ExamplePost post={localPosts[0]} />
          <p>{t("may_be_different_from_gbp")}</p>
        </>
      )}
    </>
  );
};

export default LocalPosts;
