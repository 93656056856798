import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import { ArrangedUseLimitsData } from "../../../models/FeatureUseLimitsData";
import DataSvc from "../../../services/dataSvc";
import EditUseLimitItem from "../EditUseLimitItem";

export interface IModalEditUseLimit {
  arrangeUseLimits: ArrangedUseLimitsData;
  onClose: () => void;
  onDelete: () => void;
  onRefresh: () => void;
  mode: string;
}

export const ModalEditUseLimit: React.FunctionComponent<IModalEditUseLimit> = ({
  arrangeUseLimits,
  onClose,
  onDelete,
  onRefresh,
  mode = "edit",
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityManagementPage.modalEditUseLimit.${key}`);

  const [updateUseLimits, setUpdateUseLimits] = useState(new Map());

  const updateUseLimit = useMutation((payload: any) => {
    if (arrangeUseLimits.target === "org") {
      return DataSvc.updateOrgUseLimit({
        target_id: Number(arrangeUseLimits.target_id),
        feature_id: Number(payload.feature.id),
        can_use: payload.can_use,
        can_use_times: payload.can_use_times,
      });
    } else {
      return DataSvc.updateUserUseLimit({
        target_id: Number(arrangeUseLimits.target_id),
        feature_id: Number(payload.feature.id),
        can_use: payload.can_use,
        can_use_times: payload.can_use_times,
      });
    }
  });

  const onClickSubmit = async () => {
    try {
      const updatePromises = Array.from(updateUseLimits.values()).map((item) =>
        updateUseLimit.mutateAsync(item)
      );

      await Promise.allSettled(updatePromises);
      onRefresh();
      onClose();
    } catch (error) {
      console.error("Error updating use limits:", error);
    }
  };

  return (
    <>
      <div className="modal modal-default modal-edit-feaetures">
        <div className="modal-mains">
          <button
            className="btn-close"
            onClick={() => {
              onClose();
            }}
          />

          <div className="top-title">
            {arrangeUseLimits?.email || arrangeUseLimits?.name || "-"}
          </div>
          {mode === "edit" && (
            <div className="icons icon-trash" onClick={onDelete}></div>
          )}
          {arrangeUseLimits.use_limits.map((useLimit) => (
            <EditUseLimitItem
              key={useLimit.feature.id}
              arrangedUseLimit={useLimit}
              onChange={(needUpdate, item) => {
                if (needUpdate) {
                  updateUseLimits.set(item.feature.id, item);
                } else {
                  updateUseLimits.delete(item.feature.id);
                }

                setUpdateUseLimits(new Map(updateUseLimits));
              }}
            />
          ))}
          <div className="bottom-btns">
            <button className="btn btn-border" onClick={onClose}>
              {t("cancel")}
            </button>
            <button
              className={`btn btn-blue`}
              onClick={() => {
                onClickSubmit();
              }}
            >
              {t("submit")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEditUseLimit;
