/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import formValidationSvc from "../../../services/formValidationSvc";
import { modalViewGAEvent } from "../../../utils/ga";
import { InputBox } from "../../FormElement/InputBox";
import "./styles.scss";

export interface IModalCreateNewLabelProps {
  onClose: () => void;
  onCreateLabel: (labelName: string) => void;
  errorTxt: string;
}

export const ModalCreateNewLabel: React.FunctionComponent<
  IModalCreateNewLabelProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityListPage.modalCreateNewLabel.${key}`);

  const [labelName, setLabelName] = useState<string>("");

  const { onClose, onCreateLabel, errorTxt } = props;

  // on Create
  const onCreate = () => {
    onCreateLabel(labelName);
  };

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent("create-new-label");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="modal-default ">
      <div className="modal-mains">
        <a
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        ></a>
        <div className="top-title">{t("create_new_label")}</div>
        <div className="title-bar">
          <div className="label-title flex">
            {t("title")}
            <span className="little-txt">({t("up_to_16_chars")})</span>
          </div>
          <InputBox
            value={labelName}
            placeholder={t("label_title_here")}
            errorLabel={errorTxt ? t(errorTxt) : ""}
            classNameContainer={"create-label-input"}
            pattern="[\W\w]{0,16}"
            onChange={() => {}}
            onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
              setLabelName(
                formValidationSvc.validateInputEnteringPattern(event, labelName)
              );
            }}
          />
        </div>
        <div className="bottom-btns">
          <a
            className="btn btn-border"
            onClick={() => {
              onClose();
            }}
          >
            {t("cancel")}
          </a>
          <a
            className="btn btn-blue"
            onClick={() => {
              onCreate();
            }}
          >
            {t("create")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalCreateNewLabel;
