import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { MetricsPieChartData } from "../../../models/MetricsPieChartData";
import { OrgStatusData } from "../../../models/OrgStatusData";
import { formatNum } from "../../../utils/formatValidation";
import PieChartItem from "../PieChartItem";
import "./styles.scss";

export interface IFacilityOverviewProps {
  organization_id?: string;
  dataList: OrgStatusData | undefined;
}

export const FacilityOverview: React.FunctionComponent<
  IFacilityOverviewProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`dashboardPage.facilityOverview.${key}`);

  const [pieChartData, setPieChartData] = useState<MetricsPieChartData[]>([]);

  const { organization_id, dataList } = props;

  useEffect(() => {
    if (dataList) {
      setPieChartData([
        {
          name: "Score A",
          unit: "",
          fill: "#7AC63F",
          data: dataList.status.location_count_score_a,
        },
        {
          name: "Score B",
          unit: "",
          fill: "#F6C30E",
          data: dataList.status.location_count_score_b,
        },
        {
          name: "Score C",
          unit: "",
          fill: "#DC5B3E",
          data: dataList.status.location_count_score_c,
        },
        {
          name: "Score D",
          unit: "",
          fill: "#96001B",
          data: dataList.status.location_count_score_d,
        },
      ]);
    }
  }, [dataList]);

  return (
    <div className="white-border-panel dashboard-facility-overview-module">
      {!dataList ? (
        <div className="row loading-row mh-355">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col col-md-4 col-12">
              <div className="top-area">
                <div className="title">{t("reply_perc_more_than_80")}</div>
                <div className="littles">{t("post_in_last_14_days")}</div>
              </div>
              <div className="center-num">
                <i className="icons icon-done"></i>
                <span className="num">
                  {formatNum(dataList.status.location_count_score_a)}
                </span>
              </div>
              <NavLink
                to={`/locations/organization${
                  organization_id ? `/${organization_id}` : ""
                }?score=A1`}
                className="btn btn-border"
              >
                {t("see_full_list")}
              </NavLink>
            </div>
            <div className="col col-md-4 col-12">
              <div className="top-area">
                <div className="title">{t("reply_perc_more_than_50")}</div>
                <div className="littles">{t("post_in_last_30_days")}</div>
              </div>
              <div className="center-num">
                <i className="icons icon-info"></i>
                <span className="num">
                  {formatNum(dataList.status.location_count_score_b)}
                </span>
              </div>
              <NavLink
                to={`/locations/organization${
                  organization_id ? `/${organization_id}` : ""
                }?score=B1`}
                className="btn btn-border"
              >
                {t("see_full_list")}
              </NavLink>
            </div>
            <div className="col col-md-4 col-12">
              <div className="chart-one">
                <PieChartItem
                  width={370}
                  height={360}
                  innerRadius={115}
                  outerRadius={130}
                  showLegend={false}
                  sizeType={"smaller"}
                  dataList={pieChartData.reverse()}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-md-4 col-12">
              <div className="top-area">
                <div className="title">{t("reply_perc_less_than_50")}</div>
                <div className="littles">{t("no_post_in_last_30_days")}</div>
              </div>
              <div className="center-num">
                <i className="icons icon-error"></i>
                <span className="num">
                  {formatNum(dataList.status.location_count_score_c)}
                </span>
              </div>
              <NavLink
                to={`/locations/organization${
                  organization_id ? `/${organization_id}` : ""
                }?score=C1&score=C2`}
                className="btn btn-border"
              >
                {t("see_full_list")}
              </NavLink>
            </div>
            <div className="col col-md-4 col-12">
              <div className="top-area">
                <div className="title">{t("pickup_fields_not_filled_out")}</div>
              </div>
              <div className="center-num">
                <i className="icons icon-no-data"></i>
                <span className="num">
                  {formatNum(dataList.status.location_count_score_d)}
                </span>
              </div>
              <NavLink
                to={`/locations/organization${
                  organization_id ? `/${organization_id}` : ""
                }?score=D1`}
                className="btn btn-border"
              >
                {t("see_full_list")}
              </NavLink>
            </div>
            <div className="col col-md-4 col-12"></div>
          </div>
        </>
      )}
    </div>
  );
};

export default FacilityOverview;
