import i18next from "i18next";
import _ from "lodash";
import QueryString from "qs";

import { PROFILE_BOOLEAN_OPTIONS } from "../config";
import { CategoryData } from "../models/CategoryData";
import {
  CustomDashboardConditions,
  CustomDashboardParams
} from "../models/CustomDashboardParams";
import {
  FacilityFilterConditionData,
  getScoreArrayString
} from "../models/FacilityFilterConditionData";
import { LabelData } from "../models/LabelData";
import { MunicipalityGroupData } from "../models/MunicipalityData";

// Search Query and Form Data Manipulation

export const convertSearchQueryToConditionFormData = (
  searchQuery: QueryString.ParsedQs,
  municipalitiesData?: MunicipalityGroupData[],
  categories?: CategoryData[],
  labels?: LabelData[]
): FacilityFilterConditionData => {
  const formData: FacilityFilterConditionData = {
    healthIndex: [],
    municipalities: [],
    categories: [],
    labels: [],
    profBooleans: []
  };
  if (searchQuery.municipality_id && municipalitiesData) {
    const municipality_id = _.concat([], searchQuery.municipality_id);
    const cloned = _.cloneDeep(municipalitiesData);
    cloned.forEach((group) => {
      group.muniList = _.intersectionWith(
        group.muniList,
        municipality_id,
        (list, id) => list.id.toString() === id
      );
    });
    formData.municipalities = cloned;
  }
  if (searchQuery.has_lodging) {
    formData.hasLodging = {
      key: "has_lodging",
      shouldInclude: searchQuery.has_lodging === "true"
    };
  }
  if (searchQuery.score) {
    const score = _.uniq(
      _.concat([], searchQuery.score).map((s: any) => s.substr(0, 1))
    );
    const h = (key: string) =>
      i18next.t(
        `facilityListPage.modalFilterCondition.healthIndexLabels.${key}`
      );
    formData.healthIndex = score.map((s: any) => ({
      key: s,
      name: h(s),
      checked: true
    }));
  }
  if (searchQuery.label_id && labels) {
    const label_id = _.concat([], searchQuery.label_id);
    formData.labels = labels.filter(
      (l) => label_id.indexOf(l.id.toString()) >= 0
    );
  }
  if (searchQuery.category_id && categories) {
    const category_id = _.concat([], searchQuery.category_id);
    formData.categories = categories.filter(
      (c) => category_id.indexOf(c.id.toString()) >= 0
    );
  }
  PROFILE_BOOLEAN_OPTIONS.forEach((option) => {
    const rawValue = searchQuery[option.key];
    let value: boolean | undefined;
    if (rawValue === "true") {
      value = true;
    } else if (rawValue === "false") {
      value = false;
    }
    if (!_.isUndefined(value)) {
      formData.profBooleans.push({
        key: option.key,
        shouldInclude: value
      });
    }
  });

  return formData;
};

export const convertCustomDashboardParamsToFormData = (
  dashboardParams: CustomDashboardConditions,
  municipalitiesData?: MunicipalityGroupData[],
  categories?: CategoryData[],
  labels?: LabelData[]
): FacilityFilterConditionData => {
  const formData: FacilityFilterConditionData = {
    healthIndex: [],
    municipalities: [],
    categories: [],
    labels: [],
    profBooleans: []
  };

  const { municipality_id, category_id, label_id, has_lodging } =
    dashboardParams;
  if (municipality_id && municipalitiesData) {
    const muniIds = _.concat([], municipality_id);
    const cloned = _.cloneDeep(municipalitiesData);
    cloned.forEach((group) => {
      group.muniList = _.intersectionWith(
        group.muniList,
        muniIds,
        (list, id) => list.id === id
      );
    });
    formData.municipalities = cloned;
  }
  if (has_lodging !== undefined) {
    formData.hasLodging = {
      key: "has_lodging",
      shouldInclude: has_lodging === true
    };
  }

  if (label_id && labels) {
    formData.labels = labels.filter((l) => label_id.indexOf(l.id) >= 0);
  }
  if (category_id && categories) {
    formData.categories = categories.filter(
      (c) => category_id.indexOf(c.id) >= 0
    );
  }

  return formData;
};

export const convertFormDataToQueryParams = (
  formData: FacilityFilterConditionData
): QueryString.ParsedQs => {
  const queryParams: any = {};
  if (formData.hasLodging?.shouldInclude !== undefined) {
    queryParams.has_lodging = formData.hasLodging?.shouldInclude;
  }

  if (formData.healthIndex) {
    queryParams.score = [];

    const scoreArrayString = getScoreArrayString(formData);
    queryParams.score.push(...scoreArrayString);
  }

  if (formData.municipalities) {
    queryParams.municipality_id = [];
    formData.municipalities.forEach((group) => {
      group.muniList.forEach((item) =>
        queryParams.municipality_id.push(item.id)
      );
    });
  }

  if (formData.categories) {
    queryParams.category_id = formData.categories.map((c) => c.id);
  }

  if (formData.labels) {
    queryParams.label_id = formData.labels.map((l) => l.id);
  }

  formData.profBooleans.forEach((item) => {
    queryParams[item.key] = item.shouldInclude;
  });
  return queryParams;
};

export const convertFormDataToCustomDashboardParams = (
  formData: FacilityFilterConditionData
): CustomDashboardConditions => {
  const conditions: CustomDashboardConditions = {};
  const { hasLodging, municipalities, categories, labels } = formData;
  if (hasLodging?.shouldInclude !== undefined) {
    conditions.has_lodging = hasLodging.shouldInclude;
  }

  if (municipalities) {
    conditions.municipality_id = municipalities.flatMap((muniGroup) => {
      return muniGroup.muniList
        .filter((muni) => muni.checked)
        .map((muni) => muni.id);
    });
  }

  if (categories) {
    conditions.category_id = categories
      .filter((cat) => cat.checked)
      .map((cat) => cat.id);
  }

  if (labels) {
    conditions.label_id = labels
      .filter((label) => label.checked)
      .map((label) => label.id);
  }

  return conditions;
};

export const convertDashboardConditionsToQueryParams = (
  customDashboardParams: CustomDashboardParams
): QueryString.ParsedQs => {
  const queryParams: any = {};

  const { category_id, municipality_id, label_id, has_lodging } =
    customDashboardParams.conditions;

  if (has_lodging !== undefined) {
    queryParams.has_lodging = has_lodging;
  }

  if (municipality_id) {
    queryParams.municipality_id = municipality_id;
  }

  if (category_id) {
    queryParams.category_id = category_id;
  }

  if (label_id) {
    queryParams.label_id = label_id;
  }

  return queryParams;
};
