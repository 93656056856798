/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler'

import { LabelData } from '../../../models/LabelData';
import PopupSelectLabels from '../PopupSelectLabels';
import './styles.scss';

export interface ILabelsListProps {
  organizationId: string
  dataList: LabelData[];
  onDeleteLabel: (indexLabel: number, hasConfirm: boolean) => void
  onAddLabel: (label: LabelData) => void
}

export const LabelsList: React.FunctionComponent<ILabelsListProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`facilityListPage.facilityList.${key}`)
  
  const { organizationId, dataList, onDeleteLabel, onAddLabel } = props;
  const [shownPopupSelectLabels, setShownPopupSelectLabels] = useState<boolean>(false); // false  

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setShownPopupSelectLabels(false)
        }}>
        <div className="some-labels">
          {
            dataList.filter((label) => { return Number(label.organization_id) === Number(organizationId); }) // show only the same org
                    .map((itemLabel: LabelData, indexLabel: number) => (
              <span className='items' key={indexLabel}>
                {itemLabel.name}
                {/*
                <a className='btn-close'
                  onClick={() => {
                    onDeleteLabel(indexLabel, true)
                  }}>
                </a>
                */}
              </span>
            ))
          }
          <div className="add-items-wrap">
            <a href="#javascript" className='add-items'
              onClick={(event) => {
                setShownPopupSelectLabels(!shownPopupSelectLabels);
                event.preventDefault();
              }}>
              <span className='btn-add'></span>
              <span className='txt'>{t('edit_labels')}</span>
            </a>
            {shownPopupSelectLabels && (
              <PopupSelectLabels
                organizationId={organizationId}
                dataList={dataList}
                onDeleteLabel={(indexLabel: number, hasConfirm: boolean) => {
                  onDeleteLabel(indexLabel, hasConfirm)
                }}
                onAddLabel={(label: LabelData) => {
                  onAddLabel(label)
                }}
              />
            )}
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default LabelsList;
