import _ from "lodash";
import React, { createContext, useState } from "react";

import {
  InternalToolEnum,
  ToolDictionaryEntryType,
  toolDictionary
} from "./InternalToolsDictionaryAndTypes";

export const ToolListContext = createContext<{
  [key in InternalToolEnum]: ToolDictionaryEntryType;
}>(toolDictionary);

export const SelectedToolContext = createContext<{
  selectedTools: InternalToolEnum[];
  setSelectedTools: React.Dispatch<React.SetStateAction<InternalToolEnum[]>>;
}>({
  // placeholder values to be replaced by InternalToolsContextProvider
  selectedTools: [],

  setSelectedTools: () => {}
});

export interface IInternalToolsContextProviderProps {
  toolsToExpose?: InternalToolEnum[];
  defaultTools?: InternalToolEnum[];
}

export const InternalToolsContextProvider = ({
  toolsToExpose = Object.values(InternalToolEnum),
  defaultTools = [],
  children
}: React.PropsWithChildren<IInternalToolsContextProviderProps>) => {
  const [selectedTools, setSelectedTools] =
    useState<InternalToolEnum[]>(defaultTools);
  return (
    <ToolListContext.Provider value={_.pick(toolDictionary, toolsToExpose)}>
      <SelectedToolContext.Provider value={{ selectedTools, setSelectedTools }}>
        {children}
      </SelectedToolContext.Provider>
    </ToolListContext.Provider>
  );
};
