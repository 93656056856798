import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {useTranslation} from 'react-i18next';

import { OrgStatData } from '../../../models/OrgStatData';
import { percentage } from '../../../utils/common';
import './styles.scss';

export interface IImportantMetricsProps {
  dataList: OrgStatData | undefined;
}

export const ImportantMetrics: React.FunctionComponent<IImportantMetricsProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`dashboardPage.importantMetrics.${key}`)

  const { dataList } = props;
  
  // get Profile Views Change ratio
  const getProfileViewsChangeRatio = (): number => {
    if (dataList?.stats) {
      return (((dataList.stats.profile_views - dataList.stats.prev_profile_views)
               / dataList.stats.prev_profile_views) * 100)
    } else {
      return 0;
    }
  }
    
  // get Media Views Change ratio
  const getBookingCountChangeRatio = () => {
    if (dataList?.stats) {
      const count = dataList.stats.booking_count + dataList.stats.food_order_count;
      const prev_count = dataList.stats.prev_booking_count + dataList.stats.prev_food_order_count;
      return (((count - prev_count) / prev_count) * 100)
    } else {
      return 0;
    }
  }
  
  // get Route Searches Change ratio
  const getRouteSearchesChangeRatio = () => {
    if (dataList?.stats) {
      return (((dataList.stats.direction_search_count - dataList.stats.prev_direction_search_count)
               / dataList.stats.prev_direction_search_count) * 100)
    } else {
      return 0;
    }
  }
  
  // get Arrow Style
  const getArrowStyle = (value: number) => {
    return (value === 0 ? 'even' : (value > 0 ? 'up' : 'down'))
  }

  return (    
    <div className="white-border-panel dashboard-important-metrics-module" id="important">
      <div className="panel-titles">
        {t('important_metrics')}
      </div>
      <div className="three-border-box">
        {!dataList ? (
          <div className="row loading-row">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
        <div className="row">
          <div className="col col-md-4 col-12">
            <div className="border-box">
              <div className="top-little">
                <div className="little">
                  {t('all_facilities')}
                </div>
                <div className="title">
                  {t('total_profile_views')}
                </div>
              </div>
              <div className="parent-area">
                <i className={`icons icon-up-down ${getArrowStyle(getProfileViewsChangeRatio())}`}>
                </i>
                <div className="parent-num">
                  {percentage(getProfileViewsChangeRatio(), true)} <span className='little'>%</span> 
                </div>
                <div className="gray-txt">
                  {t('vs_last_month')}
                </div>
              </div>
            </div>
          </div>
          <div className="col col-md-4 col-12">
            <div className="border-box">
              <div className="top-little">
                <div className="little">
                  {t('all_facilities')}
                </div>
                <div className="title">
                  {t('total_booking_count')}
                </div>
              </div>
              <div className="parent-area">
                <i className={`icons icon-up-down ${getArrowStyle(getBookingCountChangeRatio())}`}>
                </i>
                <div className="parent-num">
                  {percentage(getBookingCountChangeRatio(), true)} <span className='little'>%</span> 
                </div>
                <div className="gray-txt">
                  {t('vs_last_month')}
                </div>
              </div>
            </div>
          </div>
          <div className="col col-md-4 col-12">
            <div className="border-box">
              <div className="top-little">
                <div className="little">
                  {t('all_facilities')}
                </div>
                <div className="title">
                  {t('total_route_searches')}
                </div>
              </div>
              <div className="parent-area">
                <i className={`icons icon-up-down ${getArrowStyle(getRouteSearchesChangeRatio())}`}>
                </i>
                <div className="parent-num">
                  {percentage(getRouteSearchesChangeRatio(), true)} <span className='little'>%</span> 
                </div>
                <div className="gray-txt">
                  {t('vs_last_month')}
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default ImportantMetrics;
