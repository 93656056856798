import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./QuickNav.scss";

const QuickNav: React.FC = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`internaltoolsPage.metricsNew.${key}`);
  const [isOpen, setIsOpen] = React.useState(true);

  useEffect(() => {
    document.querySelectorAll('#quickNavs a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document
          .querySelector(anchor.getAttribute("href") ?? "")
          ?.scrollIntoView({
            behavior: "smooth",
          });
      });
    });
  }, []);

  return (
    <div id="quickNavs">
      <h3 onClick={() => setIsOpen(!isOpen)}>
        <img
          className={`menu-indicator ${isOpen ? "open" : ""}`}
          src="/assets/left-arrow.svg"
          alt="menu inducator"
          height="14px"
        />{" "}
        {t("quick_link")}
      </h3>
      {[
        "checklist",
        "chronoSections",
        "posts",
        "media",
        "actions",
        "searchKeywords",
      ].map((title) => (
        <a key={title} href={`#${title}`} className={isOpen ? "" : "hidden"}>
          {t(title)}
        </a>
      ))}
    </div>
  );
};

export default QuickNav;
