import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {useTranslation} from 'react-i18next';

import { MetricsPieChartFromAPIData, MetricsPieChartData } from '../../../models/MetricsPieChartData';
import PieChartItem from '../PieChartItem';
import './styles.scss';

export interface IMetricPieChartProps {
  title: string;
  dataList: MetricsPieChartFromAPIData[] | undefined;
}

export const MetricPieChart: React.FunctionComponent<IMetricPieChartProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`dashboardPage.metricPieChart.${key}`)
  
  const [pieChartData, setPieChartData] = useState<MetricsPieChartData[]>([]);

  const { title, dataList } = props;
  
  useEffect(() => {
    if (dataList) {
      setPieChartData(dataList.map((item: MetricsPieChartFromAPIData) => {
        return {
          ...item,
          name: item.label,
          fill: item.color
        }
      }))
    }
  }, [dataList]);

  return (    
    <div className="white-border-panel dashboard-metric-pie-chart-module padding32">
      <div className="panel-title">
        {t(`${title}`)}
      </div> 
      <div className="chart-area">
        {!dataList ? (
          <div className="row loading-row chart-row">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <PieChartItem
            width={500}
            height={400}
            innerRadius={120}
            outerRadius={130}
            showLegend={true}
            sizeType={'medium'}
            dataList={pieChartData.reverse()}/>
        )}
      </div>
    </div>
  );
};

export default MetricPieChart;
