import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { OrgRecommendationHistoryData } from "../../../models/OrgRecommendationData";
import DropdownSelect from "../../FormElement/DropdownSelect";
import "./styles.scss";

export interface ISuggestionsFilterBarProps {
  generatedDate: string;
  lastGeneratedDate: string;
  previousSuggestions: string;
  recommendationsHistoryData?: OrgRecommendationHistoryData;
  onChangePreviousSuggestionsDate: (newPreviousSuggestionsDate: string) => void;
}

export const SuggestionsFilterBar: React.FunctionComponent<
  ISuggestionsFilterBarProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`suggestionsListPage.suggestionsFilterBar.${key}`);

  const [nextUpdateDate, setNextUpdateDate] = useState<string>("");
  const [dropdownOptions, setDropdownOptions] = useState<string[]>([]);

  const {
    generatedDate,
    lastGeneratedDate,
    previousSuggestions,
    recommendationsHistoryData,
    onChangePreviousSuggestionsDate
  } = props;

  const DAYS_FOR_NEXT_GEN = 7;

  useEffect(() => {
    if (lastGeneratedDate) {
      let nextUpdateDateTemp = "";
      const dateStr = lastGeneratedDate.split("-");
      const date = new Date(
        parseInt(dateStr[0]),
        parseInt(dateStr[1]) - 1,
        parseInt(dateStr[2])
      );

      date.setDate(date.getDate() + DAYS_FOR_NEXT_GEN);

      nextUpdateDateTemp = `${date.getFullYear()}-${
        date.getMonth() + 1 < 10 ? "0" : ""
      }${date.getMonth() + 1}-${
        date.getDate() < 10 ? "0" : ""
      }${date.getDate()}`;

      setNextUpdateDate(nextUpdateDateTemp);
    }
  }, [lastGeneratedDate]);

  useEffect(() => {
    if (recommendationsHistoryData) {
      const dropdownOptionsTemp: string[] = [];

      recommendationsHistoryData.histories.forEach((item) => {
        dropdownOptionsTemp.push(item.generated_date);
      });

      setDropdownOptions(dropdownOptionsTemp);
    }
  }, [recommendationsHistoryData]);

  return (
    <div className="suggestions-filter-bar">
      {/* TODO:
      <div className="right-link">
        <a href="#javascript" className="blue-link">
          {t('list_of_sent_emails')}
          <i className="icons icon-arrow"></i>
        </a>
      </div>
      */}

      <div className="update-wrap">
        <div className="update-title-txt">
          {t("next_update")}: {nextUpdateDate}
        </div>
        <div className="line-bar">
          <div className="center-area">
            <div className="left-date">{generatedDate}</div>
            <div className="right-dropdown">
              <DropdownSelect
                fieldLabel={""}
                value={previousSuggestions}
                items={dropdownOptions}
                classNameContainer={"suggestions-filter-bar-dropdown"}
                onChange={(value: string) => {
                  onChangePreviousSuggestionsDate(value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionsFilterBar;
