import React from "react";

import { ScoreType, standardizeScoreType } from "../../utils/score";
import "./styles.scss";

export interface IScoreItemProps {
  score: string;
}

export const ScoreItem: React.FunctionComponent<IScoreItemProps> = (props) => {
  const { score } = props;

  const standardizedScore = standardizeScoreType(score);

  const getScoreContent = () => {
    switch (standardizedScore) {
      case ScoreType.A1:
        return <span className="letter-square green">A</span>;
      case ScoreType.B1:
        return <span className="letter-square yellow">B</span>;
      case ScoreType.C1:
        return <span className="letter-square red">C</span>;
      case ScoreType.D1:
        return <span className="letter-square brown">D</span>;
    }
  };

  return <>{getScoreContent()}</>;
};

export default ScoreItem;
