/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { DATE_FORMAT } from "../../config";
import {
  RecMessageData,
  RecMessageDataFromAPI,
} from "../../models/RecMessageData";
import { modalViewGAEvent } from "../../utils/ga";
import SummaryLocationList from "../SendMessageComponents/SummaryLocationList";

import "./styles.scss";

export interface IModalMessageDetailsProps {
  message: RecMessageData | RecMessageDataFromAPI;
  onClose: () => void;
}

export const ModalMessageDetails: React.FunctionComponent<
  IModalMessageDetailsProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`recMessageListPage.${key}`);

  const {
    message: {
      title,
      content,
      reply_to_address,
      relevant_locations,
      created_at,
      created_by,
    },
    onClose,
  } = props;

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent("open-modal-message-details");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="modal-default modal-message-details">
      <div className="modal-mains">
        <button className="btn-close" onClick={onClose}></button>
        <div className="modal-message-details-body">
          <h2 className="top-title">{title}</h2>
          <div className="message-meta">
            {t("created_at")}：{moment(created_at).format(DATE_FORMAT)}
            <br />
            {t("sent_by")}：
            {created_by
              ? `${created_by.name} (${created_by.email})`
              : `(${t("deleted_user")})`}
            <br />
            {t("ModalMessageDetails.reply_to")}：{reply_to_address}
          </div>
          <hr />
          <h3>{t("ModalMessageDetails.message_body")}</h3>
          <div className="message-body">{content}</div>
          <hr />
          <h3>{t("sent_to")}</h3>
          <SummaryLocationList locations={relevant_locations} />
        </div>
      </div>
    </div>
  );
};

export default ModalMessageDetails;
