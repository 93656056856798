/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs, { Dayjs } from "dayjs";
import { TOptions } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import { DateTimeInterval } from "../../../../../FormElement/DateTimeIntervalInput/utils";
import { LocalPostTopicType } from "../../../../CreateLocalPostForm/utils";

import "./styles.scss";

export interface ILocalPostPreviewEventScheduleProps {
  schedule: DateTimeInterval;
  topicType: LocalPostTopicType;
}

export const LocalPostPreviewEventSchedule: React.FunctionComponent<
  ILocalPostPreviewEventScheduleProps
> = ({ schedule, topicType }) => {
  const { t: _t } = useTranslation();
  const t = (key: string, options?: TOptions) =>
    _t(
      `analysisCollectionsPage.modalCreateLocalPost.modalPreview.event.schedule.${key}`,
      options
    );

  if (schedule.startDate === undefined || schedule.endDate === undefined) {
    return <></>;
  }
  const now = dayjs();
  const isSingleDay = schedule.startDate.isSame(schedule.endDate, "day");

  // OFFERs do not have start and end times, they are always treated as running from 0:00 ~ 23:59
  const { startTime, endTime } =
    topicType === LocalPostTopicType.OFFER
      ? {
          startTime: now.set("hour", 0).set("minute", 0),
          endTime: now.set("hour", 23).set("minute", 59),
        }
      : schedule;

  return (
    <div className="local-post-preview-event-schedule">
      {isSingleDay ? (
        <>
          <DateDisplay date={schedule.startDate} />{" "}
          <TimeDisplay
            time={startTime ?? now.set("hour", 0).set("minute", 0)}
          />
          {"~"}
          <TimeDisplay
            time={endTime ?? now.set("hour", 23).set("minute", 59)}
          />
          {topicType === LocalPostTopicType.OFFER && ` ${t("effective_until")}`}
        </>
      ) : (
        <>
          {topicType === LocalPostTopicType.OFFER && `${t("effective_date")}: `}
          <DateDisplay date={schedule.startDate} />
          {"~"}
          <DateDisplay date={schedule.endDate} />
        </>
      )}
    </div>
  );
};

const DateDisplay: React.FunctionComponent<{
  date: Dayjs;
}> = ({ date }) => {
  const { t: _t } = useTranslation();
  const t = (key: string, options?: TOptions) =>
    _t(
      `analysisCollectionsPage.modalCreateLocalPost.modalPreview.event.schedule.${key}`,
      options
    );

  const generateInnerHtml = (): string => {
    return t("date", {
      month: date.month() + 1,
      day: date.date(),
    });
  };

  const innerHtml = generateInnerHtml();

  return <span>{innerHtml}</span>;
};

const TimeDisplay: React.FunctionComponent<{
  time: Dayjs;
}> = ({ time }) => {
  const { t: _t } = useTranslation();
  const t = (key: string, options?: TOptions) =>
    _t(
      `analysisCollectionsPage.modalCreateLocalPost.modalPreview.event.schedule.${key}`,
      options
    );

  const generateInnerHtml = (): string => {
    let hour = time.hour();
    const ampm = hour < 12 ? t("am") : t("pm");
    if (hour === 0) {
      hour = 12;
    } else if (hour > 12) {
      hour -= 12;
    }

    return `${t("time", {
      hour: hour.toString(),
      minute: time.minute().toString().padEnd(2, "0"),
      ampm: ampm,
    })}`;
  };

  const innerHtml = generateInnerHtml();

  return <span>{innerHtml}</span>;
};
