import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

import { LocalPostData } from "../../../models/LocalPostData";
import { LocalPostItem } from "../LocalPostsComponent/LocalPostItem";

export enum RecentMediaListContext {
  OWN_LOCATION_HISTORY = "OWN_LOCATION_HISTORY",
  IDEA_MODE = "IDEA_MODE",
  FACILITY_UNDER_ORG = "FACILITY_UNDER_ORG",
}

export interface IRecentMediaListProps {
  dataList: LocalPostData[] | null;
  isShowLocationName?: boolean;
  context: RecentMediaListContext;
}

export const RecentPostList: React.FunctionComponent<IRecentMediaListProps> = (
  props
) => {
  const { dataList, isShowLocationName, context } = props;
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`dashboardPage.localPostList.${key}`);

  return (
    <>
      <div className="recent-local-post-list">
        <div className="local-post-list">
          {dataList !== null && dataList.length > 0 ? (
            dataList.map((data: LocalPostData, index: number) => (
              <LocalPostItem
                key={index}
                localPostItem={data}
                isShowLocationName={isShowLocationName}
                context={context}
              />
            ))
          ) : (
            <small className="no-data">{t("no_data")}</small>
          )}
        </div>
      </div>
      {context !== RecentMediaListContext.IDEA_MODE && (
        <p className="cant-find-post">{t("cant_find_post")}</p>
      )}
    </>
  );
};

export default RecentPostList;
