/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  ChartData,
  createDataGroups,
  createEmptyChartData,
  compileToDataSource,
} from "./utils";
import { OrgTimeSeriesMetricsData } from "../../../../models/ChartCollectionData";
import LineChartItem from "../LineChartItem";
import { calculateGraphSize } from "../utils";

import "./styles.scss";

export interface ILineChartProps {
  chartDetailsData: OrgTimeSeriesMetricsData[];
  prevYearChartDetailsData?: OrgTimeSeriesMetricsData[];
  timeUnit: "day" | "week" | "month";
  usePrevYear: boolean;
  setTimeUnit: (unit: "day" | "week" | "month") => void;
  setUsePrevYear: (shouldUse: boolean) => void;
  skipLabelTranslation?: boolean;
}

export const LineChart: React.FunctionComponent<ILineChartProps> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.collectionChartArea.${key}`);

  const {
    chartDetailsData,
    prevYearChartDetailsData,
    timeUnit,
    usePrevYear,
    setTimeUnit,
    setUsePrevYear,
    skipLabelTranslation,
  } = props;

  const [chartDataDays, setChartDataDays] = useState<ChartData>({
    ...createEmptyChartData(),
  });
  const [chartDataWeeks, setChartDataWeeks] = useState<ChartData>({
    ...createEmptyChartData(),
  });
  const [chartDataMonths, setChartDataMonths] = useState<ChartData>({
    ...createEmptyChartData(),
  });
  const [prevYearChartDataDays, setPrevYearChartDataDays] = useState<ChartData>(
    { ...createEmptyChartData() }
  );
  const [prevYearChartDataWeeks, setPrevYearChartDataWeeks] =
    useState<ChartData>({ ...createEmptyChartData() });
  const [prevYearChartDataMonths, setPrevYearChartDataMonths] =
    useState<ChartData>({ ...createEmptyChartData() });

  useEffect(() => {
    if (!!chartDetailsData) {
      const dataSourceConfig = compileToDataSource(
        chartDetailsData,
        skipLabelTranslation ?? false,
        false
      );

      const { chartDataDaysTemp, chartDataWeeksTemp, chartDataMonthsTemp } =
        createDataGroups(chartDetailsData, dataSourceConfig, false);

      setChartDataDays(_.cloneDeep(chartDataDaysTemp));
      setChartDataWeeks(_.cloneDeep(chartDataWeeksTemp));
      setChartDataMonths(_.cloneDeep(chartDataMonthsTemp));

      if (usePrevYear && !!prevYearChartDetailsData) {
        const prevYearConfig = compileToDataSource(
          prevYearChartDetailsData,
          skipLabelTranslation ?? false,
          true
        );

        const { chartDataDaysTemp, chartDataWeeksTemp, chartDataMonthsTemp } =
          createDataGroups(prevYearChartDetailsData, prevYearConfig, true);

        setPrevYearChartDataDays(_.cloneDeep(chartDataDaysTemp));
        setPrevYearChartDataWeeks(_.cloneDeep(chartDataWeeksTemp));
        setPrevYearChartDataMonths(_.cloneDeep(chartDataMonthsTemp));
      } else {
        setPrevYearChartDataDays({ ...createEmptyChartData() });
        setPrevYearChartDataWeeks({ ...createEmptyChartData() });
        setPrevYearChartDataMonths({ ...createEmptyChartData() });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDetailsData, prevYearChartDetailsData, usePrevYear]);

  const getDataByTimeUnit = (prevYear = false) => {
    if (prevYear) {
      switch (timeUnit) {
        case "day":
          return prevYearChartDataDays;
        case "week":
          return prevYearChartDataWeeks;
        case "month":
          return prevYearChartDataMonths;
      }
    } else {
      switch (timeUnit) {
        case "day":
          return chartDataDays;
        case "week":
          return chartDataWeeks;
        case "month":
          return chartDataMonths;
      }
    }
  };

  return (
    <div className="collection-chart-area-line-chart">
      <div className="right-pink-choose">
        <div className="chart-choose-module">
          <a
            className={`items current${timeUnit === "day" ? "current" : ""}`}
            onClick={() => {
              setTimeUnit("day");
            }}
          >
            {t("timeUnitOptions.day")}
          </a>
          <a
            className={`items current${timeUnit === "week" ? "current" : ""}`}
            onClick={() => {
              setTimeUnit("week");
            }}
          >
            {t("timeUnitOptions.week")}
          </a>
          <a
            className={`items current${timeUnit === "month" ? "current" : ""}`}
            onClick={() => {
              setTimeUnit("month");
            }}
          >
            {t("timeUnitOptions.month")}
          </a>
        </div>
      </div>
      <div className="chart-choose-last-year-module">
        <button
          onClick={() => {
            setUsePrevYear(!usePrevYear);
          }}
        >
          <input type="checkbox" checked={usePrevYear} readOnly={true} />
          <p>{t("usePrevYear")}</p>
        </button>
      </div>

      <div className="line-chart">
        <LineChartItem
          {...calculateGraphSize(
            "line",
            chartDetailsData.length + (prevYearChartDetailsData?.length ?? 0)
          )}
          dataList={getDataByTimeUnit()}
          previousYearDataList={getDataByTimeUnit(true)}
          usePrevYear={usePrevYear}
        />
      </div>
    </div>
  );
};

export default LineChart;
