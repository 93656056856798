import React from "react";
import "./styles.scss";

interface IInputTextAreaCharsDisplay {
  currentChars: number;
  maxChars?: number;
}

export const InputTextAreaCharsDisplay: React.FunctionComponent<
  IInputTextAreaCharsDisplay
> = (props) => {
  const { currentChars, maxChars } = props;
  return (
    <div
      className={`input-text-area-chars-display${
        maxChars && currentChars > maxChars ? " over" : ""
      }`}
    >
      <span>{currentChars}</span>
      {maxChars && (
        <>
          <span>/</span>
          <span>{maxChars}</span>
        </>
      )}
    </div>
  );
};
