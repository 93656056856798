import { ChangeEvent } from 'react';

// form Validation service
export default class FormValidationSvc {
  // validate the pattern input entering
  static validateInputEnteringPattern(event: ChangeEvent<HTMLInputElement>, fieldNameValueCurrent?: string) {    
    return event.target.validity.valid
      ? event.target.value.toString()
      : (fieldNameValueCurrent || '').toString()
  }
}