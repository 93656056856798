/* eslint-disable jsx-a11y/anchor-is-valid */
import ja from "date-fns/locale/ja";
import moment, { Moment } from "moment";
import React, { useState, useEffect } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";

import { CHART_PERIOD_OPTIONS } from "../../../config";
import {
  ChartData,
  PeriodOptionType,
  AnalysisChartFilterData,
} from "../../../models/ChartCollectionData";
import { getFromToDate } from "../../../utils/chartPeriodUtils";
import { isMobile } from "../../../utils/responsive";
import { BaseDateInput } from "../../FormElement/BaseDateInput";
import DropdownSelect from "../../FormElement/DropdownSelect";

import "./styles.scss";

// Register locale for date picker
registerLocale("ja", ja);

export interface IDateRangeAreaProps {
  chartData: ChartData;
  onClickApply: (formData: AnalysisChartFilterData) => void;
}

export interface RawAnalysisChartFilterData {
  periodDropdownValue: PeriodOptionType;
  fromDate: Date | undefined | null;
  toDate: Date | undefined | null;
}

export const DateRangeArea: React.FunctionComponent<IDateRangeAreaProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.collectionChartArea.dateRangeArea.${key}`);

  const [shownPopup, setShownPopup] = useState<boolean>(false); // false

  const [minus18Month, setMinus18Month] = useState<string>("");

  const [formSavedData, setFormSavedData] = useState<AnalysisChartFilterData>({
    periodDropdownValue: "",
    fromDate: "",
    toDate: "",
  });
  const [formData, setFormData] = useState<AnalysisChartFilterData>({
    periodDropdownValue: "",
    fromDate: "",
    toDate: "",
  });

  const { chartData, onClickApply } = props;

  const getInitFromToDate = () => {
    if (
      chartData.period_option === "custom" &&
      chartData.period_start &&
      chartData.period_end
    ) {
      return {
        fromDateStr: new Date(chartData.period_start),
        toDateStr: new Date(chartData.period_end),
      };
    }

    if (chartData.period_option && chartData.period_option !== "custom") {
      return getFromToDate(chartData.period_option);
    }

    return { fromDateStr: undefined, toDateStr: undefined };
  };

  useEffect(() => {
    if (chartData) {
      const { fromDateStr, toDateStr } = getInitFromToDate() as {
        fromDateStr: Date;
        toDateStr: Date;
      };

      const params = {
        periodDropdownValue: chartData.period_option,
        fromDate: fromDateStr,
        toDate: toDateStr,
        checkedCompareWith: chartData.compare_to_prev,
      };
      standardizedSetFormData(params);
      standardizedSetFormData(params, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  useEffect(() => {
    const baseDate = moment().endOf("day");

    if (formData.periodDropdownValue) {
      // last_week, last_month should be current time agnostic and therefore use utc()
      // last_day, last_7_days, last_28_days should be sensitive to current local time and should not use utc()
      const { fromDateStr, toDateStr } = getFromToDate(
        formData.periodDropdownValue
      ) as { fromDateStr: Moment; toDateStr: Moment };

      if (fromDateStr === undefined || toDateStr === undefined) {
        return;
      }

      standardizedSetFormData({
        ...formData,
        fromDate: fromDateStr.toDate(),
        toDate: toDateStr.toDate(),
      });
    }

    setMinus18Month(baseDate.subtract(18, "months").toString());
    // eslint-disable-next-line
  }, [formData.periodDropdownValue]);

  const standardizedSetFormData = (
    formData: RawAnalysisChartFilterData,
    setSaved = false
  ) => {
    const { fromDate, toDate } = formData;
    const formattedFormData = {
      ...formData,
      fromDate: fromDate
        ? moment.utc(formData.fromDate).startOf("day").toISOString()
        : "",
      toDate: toDate
        ? moment.utc(formData.toDate).startOf("day").toISOString()
        : "",
    };
    if (setSaved) {
      setFormSavedData(formattedFormData);
    } else {
      setFormData(formattedFormData);
    }
  };

  // get Day String
  const getDayString = (date: Date) => {
    const todayDateString = `${date.getFullYear()}-${
      date.getMonth() + 1 < 10 ? "0" : ""
    }${date.getMonth() + 1}-${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;

    return todayDateString;
  };

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={() => {
          setShownPopup(false);
        }}
      >
        <div className={`right-date-wrap ${shownPopup ? "open" : ""}`}>
          <div className="date-drop">
            <a
              className="btn-gray-border"
              onClick={() => {
                setShownPopup(!shownPopup);
              }}
            >
              <span className="bold">
                {_t(`common.dropdown.${formSavedData.periodDropdownValue}`)}
              </span>
              <div className="date-txt">
                {formSavedData.fromDate
                  ? getDayString(new Date(formSavedData.fromDate))
                  : ""}
                &nbsp;&nbsp;-&nbsp;&nbsp;
                {formSavedData.toDate
                  ? getDayString(new Date(formSavedData.toDate))
                  : ""}
              </div>
              <i className="icons icon-drop">&nbsp;</i>
            </a>
          </div>
          <div className="date-panel">
            <div className="date-range-area">
              <div
                className={`left-calendar ${
                  formData.periodDropdownValue !== "custom" ? "disabled" : ""
                }`}
              >
                <ReactDatePicker
                  locale="ja"
                  selected={
                    formData.fromDate ? new Date(formData.fromDate) : null
                  }
                  onChange={(dates) => {
                    const [start, end] = dates;

                    standardizedSetFormData({
                      ...formData,
                      fromDate: start,
                      toDate: end,
                    });
                  }}
                  minDate={new Date(minus18Month)}
                  startDate={
                    formData.fromDate ? new Date(formData.fromDate) : null
                  }
                  endDate={formData.toDate ? new Date(formData.toDate) : null}
                  monthsShown={!isMobile() ? 2 : 1}
                  selectsRange
                  inline
                />
              </div>
              <div className="line"></div>
              <div className="from-area">
                <div className="period-select flex">
                  <div className="label-txt">{t("period")}</div>
                  <DropdownSelect
                    fieldLabel={""}
                    value={formData.periodDropdownValue}
                    isTranslation={true}
                    items={CHART_PERIOD_OPTIONS}
                    classNameContainer={"sort-dropdown"}
                    onChange={(value: string) => {
                      setFormData({
                        ...formData,
                        periodDropdownValue: value as PeriodOptionType,
                      });
                    }}
                  />
                </div>
                <div className="date-to">
                  <BaseDateInput
                    fieldLabel={""}
                    value={
                      formData.fromDate ? new Date(formData.fromDate) : null
                    }
                    placeholder={""}
                    disabled={formData.periodDropdownValue !== "custom"}
                    minDate={new Date(minus18Month)}
                    maxDate={new Date(formData.toDate)}
                    onChange={(newDate) => {
                      setFormData({
                        ...formData,
                        fromDate: newDate ? newDate.toString() : "",
                      });
                    }}
                  />
                  <span className="to">~</span>
                  <BaseDateInput
                    fieldLabel={""}
                    value={formData.toDate ? new Date(formData.toDate) : null}
                    placeholder={""}
                    disabled={formData.periodDropdownValue !== "custom"}
                    minDate={new Date(formData.fromDate)}
                    onChange={(newDate) => {
                      setFormData({
                        ...formData,
                        toDate: newDate ? newDate.toString() : "",
                      });
                    }}
                  />
                </div>
                {/* TODO:
                <div className="check-wrap">
                  <Checkbox
                    checked={formData.checkedCompareWith}
                    label={t('compare_with_the_prev_period')}
                    id={`checkbox-compare_with_the_prev_period_${chartData.id}`}
                    onChange={(checked: boolean) => {
                      setFormData({
                        ...formData,
                        checkedCompareWith: checked
                      })
                    }}
                  />
                </div>
                */}
                <div className="bottom-btn">
                  <a
                    className="btn btn-border"
                    onClick={() => {
                      setShownPopup(false);
                    }}
                  >
                    {t("cancel")}
                  </a>
                  <a
                    className="btn btn-blue"
                    onClick={() => {
                      setFormSavedData({
                        ...formData,
                      });
                      onClickApply(formData);
                      setShownPopup(false);
                    }}
                  >
                    {t("apply")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default DateRangeArea;
