import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { useMe } from "../../hooks/queries";

import "./styles.scss";

export interface IBottomMenuProps {
  onOpenMenu: () => void;
}

export const BottomMenu: React.FunctionComponent<IBottomMenuProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`leftMenu.${key}`);

  const { onOpenMenu } = props;
  const { data: me } = useMe();

  return (
    <div className="bottom-nav">
      <ul>
        <li>
          <NavLink
            to={
              me?.organization?.id
                ? `/locations/organization/${me.organization.id}`
                : "/locations"
            }
            className={`nav-items`}
          >
            <i className="icons icon-build"></i>
            <span className="txt">{t("facility_list")}</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`${me ? `/account/${me?.id}` : "/accounts"}`}
            className={`nav-items`}
          >
            <i className="icons icon-user-circle"></i>
            <span className="txt">{t("account")}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/management/locations" className={`nav-items`}>
            <i className="icons icon-list"></i>
            <span className="txt">{t("integration")}</span>
          </NavLink>
        </li>
        <li>
          <button onClick={onOpenMenu} className={`nav-items`}>
            <i className="icons icon-build"></i>
            <span className="txt">{t("menu")}</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default BottomMenu;
