import {
  OrgGroupAggregationMetricsData,
  OrgGroupAggregationChartItemData,
} from "../../../../models/ChartCollectionData";

export const compileToDataSource = (
  chartDetailsData: OrgGroupAggregationMetricsData
) => {
  const columnArrayTemp: string[] = [];
  const chartRowDataTemp: any[] = [];

  chartDetailsData.data.forEach((item: OrgGroupAggregationChartItemData) => {
    if (columnArrayTemp.indexOf(item.groups[0]) === -1) {
      columnArrayTemp.push(item.groups[0]);
    }

    let matched = false;
    chartRowDataTemp.forEach((rowItem: any) => {
      if (rowItem.name === item.groups[1]) {
        matched = true;
      }
    });
    if (!matched) {
      chartRowDataTemp.push({
        name: item.groups[1],
        values: [],
      });
    }
  });

  chartDetailsData.data.forEach((item: OrgGroupAggregationChartItemData) => {
    chartRowDataTemp.forEach((rowItem: any) => {
      if (rowItem.values.length === 0) {
        rowItem.values = new Array(columnArrayTemp.length).fill(0);
      }
      if (rowItem.name === item.groups[1]) {
        columnArrayTemp.forEach((columnItem: any, index: number) => {
          if (columnItem === item.groups[0]) {
            rowItem.values[index] = item.value;
          }
        });
      }
    });
  });

  return [chartRowDataTemp, columnArrayTemp];
};
