import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { InternalToolContainer } from "../../components/InternalTools/InternalToolsContainer";
import { InternalToolsContextProvider } from "../../components/InternalTools/InternalToolsContextProvider";
import { InternalToolEnum } from "../../components/InternalTools/InternalToolsDictionaryAndTypes";
import { useMe } from "../../hooks/queries";

import "./styles.scss";

type IOrgToolsPageProps = RouteComponentProps<any>;

const OrgToolsPage: React.FunctionComponent<IOrgToolsPageProps> = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`orgToolsPage.${key}`);

  const { data: me } = useMe();

  const internalToolsInUse = [InternalToolEnum.MetricsAreaNew];

  return (
    <InternalToolsContextProvider
      defaultTools={internalToolsInUse}
      toolsToExpose={internalToolsInUse}
    >
      <div className="right-content org-tool">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("OrgTools")}</div>
          <div className="line"></div>
        </div>
        <InternalToolContainer
          initialGoogleInformation={undefined}
          orgId={me?.organization_id ? Number(me.organization_id) : undefined}
          overlayTextKey=""
        />
      </div>
    </InternalToolsContextProvider>
  );
};

export default withRouter(OrgToolsPage);
