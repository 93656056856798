/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ScoreData } from "../../models/ScoreData";
import DataSvc from "../../services/dataSvc";
import DemoModeSvc from "../../services/demoModeSvc";
import { getScoreType } from "../../utils/score";
import { ProgressBar } from "../ProgressBar";
import { ScoreItem } from "../ScoreItem";

import "./styles.scss";
export interface IModalProfileCompletionLeaderboardProps {
  organizationName: string;
  organizationId: string;
  onClose: () => void;
}

export const ModalProfileCompletionLeaderboard: React.FunctionComponent<
  IModalProfileCompletionLeaderboardProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`modalProfileCompletionLeaderboard.${key}`);

  const { organizationName, organizationId, onClose } = props;

  const [leaderboardList, setLeaderboardList] = useState<ScoreData[]>([]);

  const { data: profileCompletionLeaderboard } = useQuery(
    ["profileCompletionLeaderboard"],
    () => {
      return DataSvc.getProfileCompletionLeaderboard();
    }
  );

  useEffect(() => {
    if (profileCompletionLeaderboard) {
      profileCompletionLeaderboard.sort(function (a: ScoreData, b: ScoreData) {
        return b.score - a.score;
      });
      let rank = 0;
      let previousValue = 101;
      const leaderboardListTemp: ScoreData[] = [];
      profileCompletionLeaderboard.forEach((item: ScoreData) => {
        if (previousValue !== item.score) {
          rank++;
          previousValue = item.score;
        }

        leaderboardListTemp.push({
          ...item,
          rank: rank,
          isActive: organizationId === item.organization_id,
        });
      });

      setLeaderboardList(leaderboardListTemp);
    }
    // eslint-disable-next-line
  }, [profileCompletionLeaderboard]);

  const calculateOrgName = (isActive: boolean, organizationName: string) => {
    if (!isActive) {
      return "-";
    }

    return DemoModeSvc.current.isDemoModeOn ? "デモ団体" : organizationName;
  };

  return (
    <div className="modal modal-default modal-profile-completion-leaderboard">
      <div className="modal-mains">
        <a
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        ></a>
        <div className="top-title">{t(`title`)}</div>
        <div className="content">
          <div className="leader-list-table-title">
            {t(`profile_completion`)}
          </div>
          <div className="leader-list-table">
            {leaderboardList.map((item: ScoreData, index: number) => (
              <div
                className={`row-td ${item.isActive ? "active" : ""}`}
                key={index}
              >
                <div className="col-td width-54 flex">
                  <div className="spacing">{item.rank}</div>
                  <div className="spacing name">
                    {calculateOrgName(item.isActive, organizationName)}
                  </div>
                </div>
                <div className="col-td width-8">
                  <div className="spacing">
                    <ScoreItem score={getScoreType(item.score)} />
                  </div>
                </div>
                <div className="col-td width-24">
                  <div className="spacing">
                    <ProgressBar value={item.score} />
                  </div>
                </div>
                <div className="col-td width-18">
                  <div className="spacing">{item.score}%</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bottom-btns">
          <a
            className="btn btn-blue"
            onClick={() => {
              onClose();
            }}
          >
            {t("ok")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalProfileCompletionLeaderboard;
