/* eslint-disable jsx-a11y/anchor-is-valid */
import { find } from "lodash";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import {
  MetricsRankingItemData,
  MetricsRankingListData,
} from "../../../models/MetricsRankingListData";
import { percentage } from "../../../utils/common";
import { formatNum } from "../../../utils/formatValidation";
import { modalViewGAEvent } from "../../../utils/ga";
import "./styles.scss";

export interface IMetricRankingListProps {
  title: string;
  isShownNumberOfFacilities?: boolean;
  dataList: MetricsRankingListData;
  dataListPrev: MetricsRankingListData;
  link?: "municipality" | "category" | "location";
}

export const MetricRankingList: React.FunctionComponent<
  IMetricRankingListProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`dashboardPage.metricRankingList.${key}`);

  const [shownAll, setShownAll] = useState<boolean>(false); // false

  const [dataListShown, setDataListShown] = useState<MetricsRankingItemData[]>(
    []
  );

  const { title, isShownNumberOfFacilities, dataList, dataListPrev, link } =
    props;

  useEffect(() => {
    modalViewGAEvent("organizationRanking");
  }, []);

  useEffect(() => {
    if (dataList && dataListPrev) {
      const dataListShownTemp = dataList?.ranking || [];
      dataListShownTemp.forEach((item: MetricsRankingItemData) => {
        const found = find(dataListPrev.ranking, ["id", item.id]);
        if (found) {
          item.prev_score = found.score;
        }
      });

      setDataListShown(dataListShownTemp);
    }
  }, [dataList, dataListPrev]);

  // get Change ratio
  const getChangeRatio = (value: number, valuePrev: number): number => {
    return ((value - valuePrev) / valuePrev) * 100;
  };

  const getLink = (item: MetricsRankingItemData) => {
    if (link === "category") {
      return `/locations/organization/${dataList.organization_id}?category_id=${item.id}`;
    } else if (link === "municipality") {
      return `/locations/organization/${dataList.organization_id}?municipality_id=${item.id}`;
    } else if (link === "location") {
      return `/location/${item.id}`;
    } else {
      return `/locations/organization/${dataList.organization_id}`;
    }
  };

  return (
    <div className="white-border-panel dashboard-metric-ranking-list-module padding32">
      <div className="panel-title">{t(`${title}`)}</div>
      {!dataList ? (
        <div className="row loading-row mh-300">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className={`table-wrap ${shownAll ? "open" : ""}`}>
          <div className="rank-list-table">
            <div className="row-th">
              <div className="col-th">
                <div className="spacing">&nbsp;</div>
              </div>
              {isShownNumberOfFacilities && (
                <div className="col-th">
                  <div className="spacing">{t("number_of_facilities")}</div>
                </div>
              )}
              <div className="col-th">
                <div className="spacing">{t("last_28_days")}</div>
              </div>
              <div className="col-th">
                <div className="spacing">{t("vs_prev_period")}</div>
              </div>
            </div>
            {dataListShown
              .slice(0, shownAll ? dataListShown.length : 4)
              .map((item: MetricsRankingItemData, index: number) => (
                <div className="row-td" key={index}>
                  <div className="col-td">
                    <div className="spacing flex">
                      <span className="num">{item.rank}</span>
                      <NavLink to={getLink(item)} className="links">
                        {item.name}
                      </NavLink>
                    </div>
                  </div>
                  {isShownNumberOfFacilities && (
                    <div className="col-td">
                      <div className="spacing">
                        {formatNum(item.location_count)}
                      </div>
                    </div>
                  )}
                  <div className="col-td">
                    <div className="spacing">{formatNum(item.score)}</div>
                  </div>
                  <div className="col-td">
                    <div
                      className={`spacing
                                     ${
                                       getChangeRatio(
                                         item.score,
                                         item.prev_score || 0
                                       ) > 0
                                         ? "green"
                                         : ""
                                     }
                                     ${
                                       getChangeRatio(
                                         item.score,
                                         item.prev_score || 0
                                       ) === 0
                                         ? "yellow"
                                         : ""
                                     }
                                     ${
                                       getChangeRatio(
                                         item.score,
                                         item.prev_score || 0
                                       ) < 0
                                         ? "red"
                                         : ""
                                     }`}
                    >
                      {item.prev_score && (
                        <>
                          {getChangeRatio(item.score, item.prev_score) > 0
                            ? "↑"
                            : ""}
                          {getChangeRatio(item.score, item.prev_score) < 0
                            ? "↓"
                            : ""}
                          {percentage(
                            Math.ceil(
                              Math.abs(
                                getChangeRatio(item.score, item.prev_score)
                              )
                            )
                          )}
                          %
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {dataListShown.length > 4 && (
            <div className="show-more">
              <a
                href="#javascript"
                className="more-link"
                onClick={(event) => {
                  ReactGA.event({
                    category: "Dashboard metrics",
                    action: shownAll ? "collapse" : "expand",
                    label: t(`${title}`),
                  });
                  setShownAll(!shownAll);
                  event.preventDefault();
                }}
              >
                <span className="txt show">{t("see_more")}</span>
                <span className="txt close">{t("close")}</span>
                <i className="icons icon-drop show"></i>
                <i className="icons icon-drop close"></i>
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MetricRankingList;
