import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GoogleInformation } from '../../../../models/GoogleInformation';
import DataSvc from '../../../../services/dataSvc';
import Checkbox from '../../../FormElement/Checkbox';

interface ICheckOwnerAreaProps extends RouteComponentProps<any> {
  onLoading(loadingText: string | void): void
  onLoaded(): void
  googleInformation: GoogleInformation | undefined
}
const CheckOwnerArea: React.FunctionComponent<ICheckOwnerAreaProps> = ({
  onLoading,
  onLoaded,
  googleInformation
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`internaltoolsPage.checkOwnerArea.${key}`);
  const [prefecture, setPrefecture] = useState<string>('');
  const [locations, setLocations] = useState<string>('');
  const [extraKeywords, setExtraKeywords] = useState<string>('');
  const [useMainList, setUseMainList] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onClickExecute = async () => {
    if(!prefecture) {
      setErrorMessage(t('set_prefecture'));
      return;
    }
    if(!locations) {
      setErrorMessage(t('set_municipalities'));
      return;
    }
    if(!useMainList && !extraKeywords) {
      setErrorMessage(t('set_keywords'));
      return;
    }
  
    onLoading()
    DataSvc.checkOwnerArea({
      accessToken: googleInformation?.accessToken,
      prefecture,
      locations,
      extraKeywords,
      useMainList
    }).then( data => {
      const { csvString } = data

      if(!csvString) {
        return Promise.reject('不明なエラー発生');
      }

      const blob = new Blob([csvString], { type: 'text/csv' });
      const file = window.URL.createObjectURL(blob);
      window.location.assign(file);
    }).catch(error => {
      setErrorMessage(_t('internaltoolsPage.public.error', { errorMessage: error }))
    }).finally(onLoaded);
  }

  return (<React.Fragment>
    <h1 className='main-title'>{t('main_title')}</h1>
    <section className='card'>
      <p>{t('check_gbp_register')}</p>
      <h3>{t('section_1_title')}</h3>
      <input
        placeholder={t('section_1_prefecture')}
        onChange={(e) => setPrefecture(e.target.value)}
      />
      <br/>
      <textarea
        id='locations'
        cols={40}
        rows={10}
        placeholder={t('section_1_location_name')}
        onChange={(e) => setLocations(e.target.value)}
      />
      <br/>
      <h3>{t('section_2_title')}</h3>
      <Checkbox
        checked={useMainList}
        label={t('section_2_check_box_placeholder')}
        id={"f"}
        onChange={setUseMainList}
      />
      <br/>
      <textarea
        id='extraextraKeywordss'
        cols={40}
        rows={10}
        placeholder={t('section_2_extraKeywords_placeholder')}
        onChange={(e) => setExtraKeywords(e.target.value)}
      />
      <br/>
      <p><button onClick={onClickExecute}>{t('execute')}</button></p>
      {errorMessage && <h3 id='error'>{errorMessage}</h3>}
    </section>
    </React.Fragment>)
}

export default withRouter(CheckOwnerArea);