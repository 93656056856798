import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { compileToDataSource } from "./utils";
import { OrgGroupAggregationMetricsData } from "../../../../models/ChartCollectionData";

import "./styles.scss";

export interface ITableChartProps {
  chartDetailsData: OrgGroupAggregationMetricsData;
}

export type ColumnArray = Array<{
  name: string;
  values: (number | string)[];
}>;

export const TableChart: React.FunctionComponent<ITableChartProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const t = (key: string) =>
    _t(`analysisCollectionsPage.modalCreateEditChart.chartCategoryItem.${key}`);

  const { chartDetailsData } = props;

  const [columnArray, setColumnArray] = useState<ColumnArray>([]);
  const [chartRowData, setChartRowData] = useState<any[]>([]);

  useEffect(() => {
    if (!!chartDetailsData) {
      const [columnArrayTemp, chartRowDataTemp] =
        compileToDataSource(chartDetailsData);

      setColumnArray(columnArrayTemp);
      setChartRowData(chartRowDataTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDetailsData]);

  return (
    <div className="collection-chart-area-table-chart">
      <div className="table-chart">
        <div className="blue-table-chart">
          <div className="row-th">
            <div className="col-th">&nbsp;</div>
            {columnArray.map((item, index: number) => (
              <div className="col-th" key={index}>
                {item.name}
              </div>
            ))}
            <div className="col-th">Total</div>
          </div>
          {chartRowData.map((item, index: number) => (
            <div className="row-td" key={index}>
              <div className="col-td">{item}</div>
              {columnArray.map((valueItem, valueIndex: number) => (
                <div className="col-td" key={valueIndex}>
                  {valueItem.values[index] === 0
                    ? 0
                    : Number(valueItem.values[index])}
                </div>
              ))}
              <div className="col-td">
                {columnArray
                  .map((valueItem) => Number(valueItem.values[index]))
                  .reduce((a, b) => a + b)}
              </div>
            </div>
          ))}
          <div className="row-td total-row">
            <div className="col-td">Total</div>
            {columnArray.map((item, index: number) => (
              <div className="col-td" key={index}>
                {item.values
                  .map((value) => Number(value))
                  .reduce((a, b) => a + b, 0)}
              </div>
            ))}
            <div className="col-td">
              {columnArray.length > 0
                ? columnArray
                    .map((item) =>
                      item.values
                        .map((value) => Number(value))
                        .reduce((a, b) => a + b, 0)
                    )
                    .reduce((a, b) => a + b)
                : 0}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableChart;
