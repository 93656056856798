import React, { useEffect, useState } from "react";
import { PieChart, Pie, Legend } from "recharts";

import { MetricsPieChartData } from "../../../models/MetricsPieChartData";
import { percentage } from "../../../utils/common";
import { formatNum } from "../../../utils/formatValidation";

import "./styles.scss";

export interface IPieChartItemProps {
  width: number;
  height: number;
  innerRadius: number;
  outerRadius: number;
  showLegend: boolean;
  sizeType?: string;
  dataList: MetricsPieChartData[];
}

export const PieChartItem: React.FunctionComponent<IPieChartItemProps> = (
  props
) => {
  const {
    width,
    height,
    innerRadius,
    outerRadius,
    showLegend,
    sizeType,
    dataList
  } = props;

  const [totalNumber, setTotalNumber] = useState<number>(100);

  useEffect(() => {
    if (dataList) {
      let total = 0;
      dataList.forEach((item: MetricsPieChartData) => {
        total += item.data;
      });

      setTotalNumber(total);
    }
  }, [dataList]);

  const renderCustomizedLabel = ({
    x,
    y,
    index,
    midAngle
  }: {
    x: number;
    y: number;
    index: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
  }) => {
    const RADIAN = Math.PI / 180;
    const xNew = x + 24 * Math.cos(-midAngle * RADIAN);
    const yNew = y + 24 * Math.sin(-midAngle * RADIAN);

    if (dataList[index].unit) {
      return (
        <>
          <text
            x={xNew}
            y={yNew}
            fill="#121863"
            textAnchor="middle"
            dominantBaseline="central"
          >
            <tspan x={xNew} y={yNew} fontSize="17px" fontWeight={700}>
              {percentage((dataList[index].data * 100) / totalNumber)}%
            </tspan>
            <tspan x={xNew} y={yNew + 18} fontSize="13px">
              {formatNum(dataList[index].data)} {dataList[index].unit}
            </tspan>
          </text>
        </>
      );
    } else {
      return (
        <>
          <text
            x={x + 10}
            y={y}
            fontSize="13px"
            fill="#121863"
            textAnchor="end"
            dominantBaseline="central"
          >
            {percentage((dataList[index].data * 100) / totalNumber)}%
          </text>
        </>
      );
    }
  };

  return (
    <div className="pie-chart-container">
      <PieChart width={width} height={height}>
        {showLegend && (
          <Legend
            layout="horizontal"
            verticalAlign="bottom"
            align="center"
            wrapperStyle={{
              fontSize: "12px",
              fontWeight: 700,
              bottom: "-40px"
            }}
          />
        )}
        <Pie
          startAngle={0}
          paddingAngle={2}
          data={dataList}
          dataKey="data"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill="#fff"
          label={renderCustomizedLabel}
          labelLine={false}
          legendType="plainline"
        />
      </PieChart>
      {sizeType === "bigger" && (
        <span className="chart-center-num bigger">
          {formatNum(totalNumber)}
        </span>
      )}
      {sizeType === "medium" && (
        <span className="chart-center-num medium">
          {formatNum(totalNumber)}
        </span>
      )}
      {sizeType === "smaller" && (
        <span className="chart-center-num smaller">
          {formatNum(totalNumber)}
        </span>
      )}
      {!sizeType && (
        <span className="chart-center-num">{formatNum(totalNumber)}</span>
      )}
    </div>
  );
};

export default PieChartItem;
