import React from "react";
import { useTranslation } from "react-i18next";

const RatioSection: React.FunctionComponent<{ 
  title: string,
  numbers: number[]
}> = ({
  title,
  numbers,
}) => {
  const { t: _t, } = useTranslation();
  const t = (key: string) => _t(`internaltoolsPage.metricsNew.chrono.${key}`);

  const firstNumber = numbers[0];
  const ratio = numbers.map(number => Math.round(number * 100 / firstNumber) / 100).join(' : ');

  return <>
    <h4>{title}</h4>
    <p>{ isNaN(firstNumber) ? t('cannot_make_ratio') : ratio }</p>
  </>
}

export default RatioSection