import QRCode from "qrcode";
import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import "./styles.scss";

interface IQRCodeDisplayProps {
  content: string;
  downloadFileName?: string;
}

export const QRCodeDisplay: React.FunctionComponent<IQRCodeDisplayProps> = ({
  content,
  downloadFileName = "qr-code",
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityDetailsPage.basicProfile.qrCodeDisplay.${key}`);

  const qrcodeCanvasRef = React.useRef<HTMLCanvasElement>(null);

  // Scale is set to 16 rather than the default of 4 so that the image
  // appears larger when downloaded, and can be scaled down as needed by
  // the user. CSS is used to force the image to shrink down while on the page.
  if (qrcodeCanvasRef.current !== null) {
    QRCode.toCanvas(qrcodeCanvasRef.current, content, { scale: 16 }, () => {});
  }

  return (
    <div className="qr-code-display-wrapper">
      <a
        className="qr-url"
        href={content}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          ReactGA.event({
            category: "facility-details",
            action: "Open QR Code Link",
          });
        }}
      >
        {content}
      </a>
      <div className="qr-code-display-inner-wrapper">
        <canvas ref={qrcodeCanvasRef}></canvas>
        <a
          className="btn btn-border qr-download-link"
          download={downloadFileName}
          href={qrcodeCanvasRef.current?.toDataURL("image/png")}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            ReactGA.event({
              category: "facility-details",
              action: "Download QR code",
            });
          }}
        >
          <i className="icon icon-saveload icon-saveload-blue"></i>
          {t("download")}
        </a>
      </div>
    </div>
  );
};
