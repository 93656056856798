import React from "react";
import { useTranslation } from "react-i18next";

import { MediaData } from "../../../models/MediaData";
import { MediaItem } from "../../MediaComponents/MediaItem";

import "./styles.scss";
export interface IRecentMediaListProps {
  dataList: MediaData[] | null;
}

export const RecentMediaList: React.FunctionComponent<IRecentMediaListProps> = (
  props
) => {
  const { dataList } = props;
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`dashboardPage.mediaList.${key}`);

  return (
    <div className="recent-media-list">
      <div className="media-list">
        {dataList !== null && dataList.length > 0 ? (
          dataList.map((mediaItem: MediaData, index: number) => (
            <MediaItem key={index} mediaItem={mediaItem} />
          ))
        ) : (
          <small className="no-data">{t("no_data")}</small>
        )}
      </div>
    </div>
  );
};

export default RecentMediaList;
