import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import allReducers from './reducers';

const middlewares = [thunk];

// Only use the redux-logger middleware in development
if (process.env.NODE_ENV === 'development') {
  // middlewares.push(createLogger());
}

const store = createStore(allReducers, applyMiddleware(...middlewares));

export default store;