/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useMutation } from "@tanstack/react-query";
import _ from "lodash";
import pickBy from "lodash/pickBy";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { FacilityManagementList } from "../../components/FacilityManagementComponents/FacilityManagementList";
import { ModalAssignOrg } from "../../components/FacilityManagementComponents/ModalAssignOrg";
import { ModalFilterCondition } from "../../components/FacilityManagementComponents/ModalFilterCondition";
import { useMe } from "../../hooks/queries";
import { FacilityManagementFilterConditionData } from "../../models/FacilityFilterConditionData";
import { LocationData } from "../../models/LocationData";
import { OrganizationData } from "../../models/OrganizationData";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

const defaultFilterFormData = {
  searchName: "",
  sort: "count.organizations",
  order: undefined,
  numberPerPage: 20,
  pageIndex: 1,
};
const defaultConditionFormData = {
  organizations: [],
  isArchived: undefined,
};

type IFacilityManagementPageProps = RouteComponentProps<any>;

const FacilityManagementPage: React.FunctionComponent<
  IFacilityManagementPageProps
> = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityManagementPage.${key}`);

  const [locationsData, setLocationsData] = useState<LocationData[]>([]);
  const [totalLocations, setTotalLocations] = useState(0);
  const [shownModalFilterCondition, setShownModalFilterCondition] =
    useState<boolean>(false); // false
  const [shownModalAssignOrg, setShownModalAssignOrg] =
    useState<boolean>(false); // false
  const [assignedFacilityItem, setAssignedFacilityItem] =
    useState<LocationData>();
  const [organizationId, setOrganizationId] = useState<string>("");

  const [conditionFormData, setConditionFormData] =
    useState<FacilityManagementFilterConditionData>(defaultConditionFormData);
  const [filterFormData, setFilterFormData] = useState<any>(
    defaultFilterFormData
  );

  const { data: me } = useMe();

  const { data: locations, refetch: refetchLocations } = useQuery(
    ["locations", organizationId, conditionFormData, filterFormData],
    async () => {
      return await DataSvc.getLocations(
        pickBy({
          sort: filterFormData.sort,
          order: filterFormData.order,
          limit: filterFormData.numberPerPage,
          offset: (filterFormData.pageIndex - 1) * filterFormData.numberPerPage,
          organization_id:
            conditionFormData.organizations.length > 0
              ? conditionFormData.organizations.map((c) => c.id)
              : null,
          label_id: null,
          category_id: null,
          municipality_id: null,
          score: null,
          query: filterFormData.searchName.trim(),
          status: ["Registered", "Confirming"],
          seek_total_count: true,
          ...(conditionFormData?.isArchived?.shouldInclude !== undefined
            ? {
                is_archived: String(conditionFormData.isArchived.shouldInclude),
              }
            : {}),
        })
      );
    }
  );

  const deleteLocationMutation = useMutation((locationId: number) => {
    return DataSvc.deleteLocation(locationId);
  });

  const registerMutation = useMutation((payload: any) => {
    return DataSvc.registerLocationWithOrg(payload.locationId, payload.orgId);
  });

  const updateLocationsMutation = useMutation((payload: any) => {
    return DataSvc.updateLocations(payload.locationId, {
      independent_use: payload.isIndependentUse,
    });
  });

  const deregisterMutation = useMutation((payload: any) => {
    return DataSvc.deregisterLocationWithOrg(payload.locationId, payload.orgId);
  });

  useEffect(() => {
    if (locations) {
      setLocationsData(locations.list);
      setTotalLocations(locations.total);
    }
  }, [locations]);

  useEffect(() => {
    if (!me) {
      return;
    }

    setOrganizationId(me.organization?.id?.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  // on Apply Filter Condition
  const onApplyFilterCondition = (
    conditionFormDataApply: FacilityManagementFilterConditionData
  ) => {
    setConditionFormData(_.cloneDeep(conditionFormDataApply));
    // reset page number
    filterFormData.pageIndex = 1;

    setFilterFormData(_.cloneDeep({ ...filterFormData }));
    setShownModalFilterCondition(false);
  };

  // on Click Clear Filter Condition
  const onClickClearFilterCondition = () => {
    setConditionFormData({
      organizations: [],
      isArchived: undefined,
    });
    // reset page number
    filterFormData.pageIndex = 1;
    setFilterFormData({ ...filterFormData });
  };

  // on Click Assign Org
  const onClickAssignOrg = (facilityItem: LocationData) => {
    setShownModalAssignOrg(true);
    setAssignedFacilityItem(facilityItem);
  };

  // on Delete Facility Item
  const onDeleteFacilityItem = () => {
    if (assignedFacilityItem) {
      deleteLocationMutation
        .mutateAsync(assignedFacilityItem.id)
        .then(refetchLocations);
    }
  };

  const onToggleIndependentUse = (
    locationId: number,
    isIndependentUse: boolean
  ) => {
    updateLocationsMutation
      .mutateAsync({
        locationId,
        isIndependentUse,
      })
      .finally(() => {
        setTimeout(() => {
          refetchLocations();
        }, 500);
      });
  };

  // on Update Assign Selection
  const onUpdateAssignSelection = (
    registeredList: OrganizationData[],
    unregisteredList: OrganizationData[]
  ) => {
    if (assignedFacilityItem) {
      Promise.all(
        registeredList
          .map((o) =>
            registerMutation.mutateAsync({
              locationId: assignedFacilityItem?.id,
              orgId: o.id,
            })
          )
          .concat(
            unregisteredList.map((o) =>
              deregisterMutation.mutateAsync({
                locationId: assignedFacilityItem.id,
                orgId: o.id,
              })
            )
          )
      ).finally(() => {
        setTimeout(() => {
          refetchLocations();
        }, 500);
      });
    }
  };

  return (
    <>
      <div className="right-content facility-management">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("facility_management")}</div>
          <div className="line"></div>
        </div>

        {!!locationsData && (
          <FacilityManagementList
            dataList={locationsData}
            totalCount={totalLocations}
            conditionFormData={conditionFormData}
            filterFormData={filterFormData}
            organizationId={organizationId || ""}
            onClickFilter={() => {
              setShownModalFilterCondition(true);
            }}
            onClickClearFilterCondition={() => {
              onClickClearFilterCondition();
            }}
            onChangeFilterFormData={(filterFormData: any) => {
              setFilterFormData(filterFormData);
            }}
            onClickAssignOrg={(facilityItem: LocationData) => {
              onClickAssignOrg(facilityItem);
            }}
          />
        )}
      </div>
      {shownModalFilterCondition && (
        <ModalFilterCondition
          onClose={() => {
            setShownModalFilterCondition(false);
          }}
          conditionFormData={conditionFormData}
          onApply={(
            conditionFormDataApply: FacilityManagementFilterConditionData
          ) => {
            onApplyFilterCondition(conditionFormDataApply);
          }}
        />
      )}

      {shownModalAssignOrg && !!assignedFacilityItem && (
        <ModalAssignOrg
          locationData={assignedFacilityItem}
          onClose={() => {
            setShownModalAssignOrg(false);
          }}
          onDelete={() => {
            onDeleteFacilityItem();
            setShownModalAssignOrg(false);
          }}
          onUpdateAssignSelection={(
            registeredList: OrganizationData[],
            unregisteredList: OrganizationData[],
            isIndependentUse: boolean
          ) => {
            if (isIndependentUse !== assignedFacilityItem.independent_use) {
              onToggleIndependentUse(assignedFacilityItem.id, isIndependentUse);
            }
            onUpdateAssignSelection(registeredList, unregisteredList);
            setShownModalAssignOrg(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(FacilityManagementPage);
