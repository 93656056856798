import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";

import NavigationContainer from "./containers/NavigationContainer";
import { useMe } from "./hooks/queries";
import DemoModeSvc from "./services/demoModeSvc";
import { setUserDimensions } from "./utils/ga";

import "react-toastify/dist/ReactToastify.css";
interface IAppProps {
  loading: boolean;
}

const App: React.FunctionComponent<IAppProps> = ({ loading, children }) => {
  const { data: me } = useMe();
  useEffect(() => {
    setUserDimensions(me);
  }, [me]);

  useEffect(() => {
    DemoModeSvc.current.addDemoListeners();
  }, []);

  return (
    <div className={loading ? "loading" : ""}>
      <NavigationContainer>{children}</NavigationContainer>
      <ToastContainer hideProgressBar={true} limit={5} />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.dataReducer.loading,
});

const matchDispatchToProps = () => ({});

export default connect(mapStateToProps, matchDispatchToProps)(App);
