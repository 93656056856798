/* eslint-disable jsx-a11y/anchor-is-valid */
import { isArray } from 'lodash';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import { SendMessageLocationData, SummaryLocation } from '../../../containers/SendMessageScreens';
import { EmailState } from '../../../containers/SendMessageScreens/utils';
import { FacilityFilterConditionData } from '../../../models/FacilityFilterConditionData';
import { LocationData } from '../../../models/LocationData';
import { modalViewGAEvent } from '../../../utils/ga';
import EmailForm from '../EmailForm';
import './styles.scss';
import LocationsDisplay from '../LocationsDisplay';

export interface IModalSendMessageProps extends EmailState {
  locationData: SendMessageLocationData
  userEmail: string
  onChangeTextVariable: (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
  onClose: () => void
  onToConfirm: (selectedLocations: LocationData | Array<SummaryLocation>) => void

  // Custom filter mode
  isCustomFilterMode: boolean,
  conditionFormData?: FacilityFilterConditionData
  onOpenFilters?: () => void
}

export const ModalSendMessage: React.FunctionComponent<IModalSendMessageProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`modalSendMessage.${key}`)

  const { isCustomFilterMode, locationData, conditionFormData, onClose, onToConfirm, onOpenFilters, ...formProps } = props;

  const [selectedLocations, setSelectedLocations] = useState<LocationData | Array<SummaryLocation>>(locationData)
  const isWithoutLocationData = (isArray(selectedLocations) && selectedLocations.length === 0) || (selectedLocations === null) ;

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent('input-message-indiv-fac');
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onSelectedLocations = (selectedLocationds: Array<SummaryLocation>) => {
    setSelectedLocations(selectedLocationds)
  }

  useEffect(() => {
    setSelectedLocations(locationData)
  }, [locationData])

  return (    
    <div className="modal-default modal-send-message">
      <div className="modal-mains">
        <a className='btn-close'
          onClick={() => {
            onClose()
          }}>
        </a>
        <div className="modal-send-message-body">
          <LocationsDisplay 
            isCustomFilterMode={isCustomFilterMode}
            isSelectableLocations={true}
            conditionFormData={conditionFormData}
            locationData={locationData}
            onOpenFilters={onOpenFilters}
            onSelectedLocations={onSelectedLocations}
          />
          <hr />
          <EmailForm
            {...formProps}
            adminMode={false}
          />
          <div className="bottom-btns">
            <a className='btn btn-border'
              onClick={() => {
                onClose()
              }}>
              {t('cancel')}
            </a>
            <a className={`btn btn-blue ${isWithoutLocationData ? 'btn-blue--fade' : ''}`}
              onClick={() => {
                if(!isWithoutLocationData) {
                  onToConfirm(selectedLocations)
                }
              }}>
              {t('confirm')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSendMessage;
