/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import {
  AccountData,
  AccountInformationData,
} from "../../../models/AccountData";
import formValidationSvc from "../../../services/formValidationSvc";
import { InputBox } from "../../FormElement/InputBox";
import { ModalConfirm } from "../../ModalConfirm";
import "./styles.scss";

export interface IModalEditAccountInformationProps {
  onClose: () => void;
  onDelete: () => void;
  onSave: (formData: AccountInformationData) => void;
  dataList: AccountData;
}

export const ModalEditAccountInformation: React.FunctionComponent<
  IModalEditAccountInformationProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`accountManagementPage.modalEditAccountInformation.${key}`);

  const [confirmType, setConfirmType] = useState<string>(""); // 'save', 'delete'
  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false); // false

  const [formData, setFormData] = useState<AccountInformationData>({
    familyName: "",
    firstName: "",
    email: "",
    role: "",
  });

  const { onClose, onDelete, onSave, dataList } = props;

  useEffect(() => {
    if (dataList) {
      const str = dataList.name;
      const first = str.substring(0, str.indexOf(" "));
      const second = str.substring(str.indexOf(" ") + 1);

      setFormData({
        ...formData,
        familyName: first,
        firstName: second,
      });
    }
    // eslint-disable-next-line
  }, [dataList]);

  // is Enabled Btn
  const isEnabledBtn = () => {
    return formData.familyName !== "" && formData.firstName !== "";
  };

  return (
    <div className="modal modal-default modal-edit-account-information">
      <div className="modal-mains">
        <button
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        />
        <div className="top-title flex-grid">
          {t("edit_account_information")}
          {/* TODO:
          <div className="rights">
            <a className='icons icon-del'
              onClick={() => {
                setShownModalConfirm(true);
                setConfirmType('delete')
              }}>
              &nbsp;
            </a>
          </div>
          */}
        </div>

        <div className="row row-form">
          <div className="col col-md-6">
            <div className="title-bar">
              <div className="label-title flex">{t("family_name")}</div>
              <InputBox
                value={formData.familyName}
                placeholder={t("family_name")}
                classNameContainer={"family_name-input"}
                pattern="[\W\w]{0,20}"
                onChange={() => {}}
                onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                  setFormData({
                    ...formData,
                    familyName: formValidationSvc.validateInputEnteringPattern(
                      event,
                      formData.familyName
                    ),
                  });
                }}
              />
            </div>
          </div>
          <div className="col col-md-6">
            <div className="title-bar">
              <div className="label-title flex">{t("first_name")}</div>
              <InputBox
                value={formData.firstName}
                placeholder={t("first_name")}
                classNameContainer={"first_name-input"}
                pattern="[\W\w]{0,20}"
                onChange={() => {}}
                onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                  setFormData({
                    ...formData,
                    firstName: formValidationSvc.validateInputEnteringPattern(
                      event,
                      formData.firstName
                    ),
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="bottom-btns">
          <a
            className="btn btn-border"
            onClick={() => {
              onClose();
            }}
          >
            {t("cancel")}
          </a>
          <a
            className={`btn btn-blue ${!isEnabledBtn() ? "disabled" : ""}`}
            onClick={() => {
              setShownModalConfirm(true);
              setConfirmType("save");
            }}
          >
            {t("save")}
          </a>
        </div>
      </div>

      {shownModalConfirm && (
        <ModalConfirm
          title={
            confirmType === "save"
              ? "please_confirm_to_save_the_account_info"
              : "please_confirm_to_delete_the_account"
          }
          cancelLabel={"cancel"}
          confirmLabel={"confirm"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            if (confirmType === "save") {
              onSave(formData);
            } else if (confirmType === "delete") {
              onDelete();
            }

            setShownModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default ModalEditAccountInformation;
