/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './styles.scss';

export interface IToggleBtnsProps {
  selectedValue: number;
  options: number[];
  onClickBtn: (option: number) => void
}

export const ToggleBtns: React.FunctionComponent<IToggleBtnsProps> = (props) => {
  const { selectedValue, options, onClickBtn } = props;

  return (    
    <div className="choose-bar">
      {
        options.map((item: number, index: number) => (
          <a key={index}
            className={`items ${selectedValue === item ? 'current' : ''}`}
            onClick={() => {
              onClickBtn(item)
            }}>
            {item}
          </a>
        ))
      }
    </div>
  );
};

export default ToggleBtns;
