import { useGoogleLogin, CodeResponse } from '@react-oauth/google';
import React from 'react';
import {useTranslation} from 'react-i18next';

import { GOOGLE_REDIRECT_URI, GBP_SCOPE } from '../../config';
import './styles.scss';

export interface ISignInWithGoogleButtonProps {
    onSuccess: (codeResponse: CodeResponse) => void
}

export const SignInWithGoogleButton: React.FunctionComponent<ISignInWithGoogleButtonProps> = (props) => {
    const { onSuccess } = props
    const { t: _t } = useTranslation()
    const t = (key: string) => _t(`signInWithGoogle.${key}`)
    const login = useGoogleLogin({
        onSuccess,
        redirect_uri: GOOGLE_REDIRECT_URI,
        scope: GBP_SCOPE,
        flow: 'auth-code',
    });
    return (
        <button className='sign-in-with-google' onClick={login}>
            <i className='icons icon-google'></i>
            <span>
                {t('sign_in')}
            </span>
        </button>
    );
};

export default SignInWithGoogleButton;
