// actions types

export const LOAD_ACCOUNTS_ME_DATA = 'LOAD_ACCOUNTS_ME_DATA';
export const LOAD_LABELS_DATA = 'LOAD_LABELS_DATA';
export const LOAD_CATEGORIES_DATA = 'LOAD_CATEGORIES_DATA';
export const LOAD_MUNICIPALITIES_DATA = 'LOAD_MUNICIPALITIES_DATA';
export const LOAD_LOCATIONS_DATA = 'LOAD_LOCATIONS_DATA';
export const LOAD_LOCATION_DATA = 'LOAD_LOCATION_DATA';
export const LOAD_LOCATION_RECOMMENDATIONS_DATA = 'LOAD_LOCATION_RECOMMENDATIONS_DATA';
export const LOADING_CHANGE = 'LOADING_CHANGE';
export const LOAD_STATS_PRIME_DATA = 'LOAD_STATS_PRIME_DATA';
export const LOAD_STATS_STATUS_DATA = 'LOAD_STATS_STATUS_DATA';