/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMutation } from "@tanstack/react-query";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { useLabels } from "../../../hooks/queries";
import { LabelData } from "../../../models/LabelData";
import DataSvc from "../../../services/dataSvc";
import { Checkbox } from "../../FormElement/Checkbox";
import ModalCreateNewLabel from "../ModalCreateNewLabel";
import "./styles.scss";

export interface IPopupSelectLabelsProps {
  organizationId: string;
  dataList: LabelData[];
  onDeleteLabel: (indexLabel: number, hasConfirm: boolean) => void;
  onAddLabel: (label: LabelData) => void;
}

export const PopupSelectLabels: React.FunctionComponent<
  IPopupSelectLabelsProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityListPage.facilityList.popupSelectLabels.${key}`);

  const [labelsData, setLabelsData] = useState<LabelData[]>([]);
  const [shownModalCreateNewLabel, setShownModalCreateNewLabel] =
    useState<boolean>(false); // false

  const { organizationId, dataList, onDeleteLabel, onAddLabel } = props;

  const { data: labels, refetch } = useLabels(
    _.pickBy({
      organization_id: organizationId
    })
  );

  const createLabelMutation = useMutation((label: any) => {
    return DataSvc.createLabel(label);
  });

  useEffect(() => {
    if (labels) {
      setLabelsData(labels);
    }
  }, [labels]);

  // on Create Label
  const onCreateLabel = (labelName: string) => {
    createLabelMutation
      .mutateAsync({
        name: labelName,
        organization_id: parseInt(organizationId)
      })
      .then(() => {
        refetch();
        setShownModalCreateNewLabel(false);
      });
  };

  // get Checked State
  const getCheckedState = (label: LabelData) => {
    let checked = false;
    dataList.forEach((item: LabelData) => {
      if (item.id === label.id) {
        checked = true;
      }
    });

    return checked;
  };

  // get Checked Index
  const getCheckedIndex = (label: LabelData) => {
    let checkedIndex = -1;
    dataList.forEach((item: LabelData, index) => {
      if (item.id === label.id) {
        checkedIndex = index;
      }
    });

    return checkedIndex;
  };

  // Sned GA event on select/deselect label
  const sendLabelEventToGA = (label: LabelData, action: string) => {
    ReactGA.event({
      category: "Label Selection",
      action: action,
      label: label.name
    });
  };

  return (
    <div className="popup-select-labels">
      <div className="popup-title-bar">
        <div className="title">{t("select_labels")}</div>
      </div>
      <div className="check-list">
        <ul>
          {!!labelsData &&
            labelsData.map((item, index) => (
              <li key={index}>
                <Checkbox
                  checked={getCheckedState(item)}
                  label={item.name}
                  id={`checkbox-select-label-${index}`}
                  onChange={(checked: boolean) => {
                    if (checked) {
                      if (!getCheckedState(item)) {
                        onAddLabel(item);
                        sendLabelEventToGA(item, "Label selected");
                      }
                    } else {
                      if (getCheckedState(item)) {
                        onDeleteLabel(getCheckedIndex(item), false);
                        sendLabelEventToGA(item, "Label deselected");
                      }
                    }
                  }}
                />
              </li>
            ))}
        </ul>
      </div>
      <div className="bottom-btn">
        <a
          className="btn btn-blue"
          onClick={() => {
            setShownModalCreateNewLabel(true);
          }}
        >
          <i className="icons icon-add"></i>
          {t("create_new_label")}
        </a>
      </div>

      {shownModalCreateNewLabel && (
        <ModalCreateNewLabel
          errorTxt={createLabelMutation.error as string}
          onClose={() => {
            setShownModalCreateNewLabel(false);
          }}
          onCreateLabel={(labelName: string) => {
            onCreateLabel(labelName);
          }}
        />
      )}
    </div>
  );
};

export default PopupSelectLabels;
