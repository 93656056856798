import "babel-polyfill";
import "whatwg-fetch";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { Suspense } from "react";
import { render } from "react-dom";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { BrowserRouter, Switch } from "react-router-dom";

import App from "./app";
import Auth0ProviderWithHistory from "./components/Auth0ProviderWithHistory";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { AUTH0, GOOGLE_CLIENT_ID, GA_ID } from "./config";
import routes from "./routes";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/styles.scss";
import "accessible-nprogress/dist/accessible-nprogress.min.css";

// import i18n (needs to be bundled ;))
import "./i18n";

// Helper function that renders single route
const renderRoute = (route: any, props: any) => {
  // window.scrollTo(0, 0); // Reset scroll to top
  return <route.component routeParams={props.match.params} />;
};

// Helper function that create all routes
const createRoutes = () => (
  <Switch>
    {
      routes.map((route: any) => (
        <ProtectedRoute
          exact={true}
          key={route.path}
          path={route.path}
          component={(props: any) => renderRoute(route, props)}
        />
      )) //.concat(<Redirect key="fallback" from="/" to="/dashboard" />) //TODO
    }
  </Switch>
);

const providerConfig = {
  domain: AUTH0.DOMAIN,
  clientId: AUTH0.CLIENT_ID,
  redirectUri: window.location.origin
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
    }
  }
});

ReactGA.initialize(GA_ID);

render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <Auth0ProviderWithHistory {...providerConfig}>
              <App>{createRoutes()}</App>
            </Auth0ProviderWithHistory>
          </GoogleOAuthProvider>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);
