import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { NavLink } from "react-router-dom";

import { useMe } from "../../../hooks/queries";
import { OrgRecommendationData } from "../../../models/OrgRecommendationData";

import "./styles.scss";

const SuggestionBody: React.FunctionComponent<{
  suggestion: OrgRecommendationData;
}> = ({ suggestion }) => {
  const { data: me } = useMe();

  const { body, relevant_locations } = suggestion;

  // API returns string in form of "〇〇個の施設{The rest of the message}"
  // Following regex decomposes this to [0] => "", [1] => "〇〇個の施設", [2] => the rest of the message
  const components = body.match(/([0-9]*施設)([\S\s]*)/);

  const [displayFacs, setDisplayFacs] = useState(false);

  if (!components || (components?.length && components.length < 3)) {
    return <div className="malformed-suggestion"></div>;
  }

  return (
    <div className="suggestion-body">
      <OutsideClickHandler onOutsideClick={() => setDisplayFacs(false)}>
        <button
          className="facNumber"
          onClick={() => setDisplayFacs(!displayFacs)}
        >
          {components[1]}
          {
            <div className={`facList ${displayFacs ? "show" : ""}`}>
              {relevant_locations?.map((loc) => (
                <NavLink
                  key={loc.id}
                  to={`/location/${loc.id}/${
                    me ? `organization/${me.organization_id}` : ""
                  }`}
                >
                  {loc.name}
                </NavLink>
              ))}
            </div>
          }
        </button>
        {components[2]}
      </OutsideClickHandler>
    </div>
  );
};

export default SuggestionBody;
