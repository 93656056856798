import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  BarChart,
  Legend,
  CartesianGrid,
  Tooltip,
  Bar,
  XAxis,
  YAxis
} from "recharts";

import {
  OrgDailyMetricsSettingData,
  OrgDailyMetricsData
} from "../../../../models/MetricsLineChartData";
import "./styles.scss";

export interface IBarChartItemProps {
  width: number;
  height: number;
  dataList: OrgDailyMetricsData;
}

export const BarChartItem: React.FunctionComponent<IBarChartItemProps> = (
  props
) => {
  const { width, height, dataList } = props;

  const [dataListShown, setDataListShown] = useState<OrgDailyMetricsData>();

  useEffect(() => {
    if (dataList) {
      const dataListTemp = dataList;

      dataListTemp.dataForLine.forEach((item) => {
        const keys = Object.keys(item);
        keys.forEach((itemKey) => {
          if (itemKey !== "name" && item[itemKey]) {
            item[itemKey] = parseFloat(item[itemKey]);
          }
        });
      });

      setDataListShown(_.cloneDeep(dataListTemp));
    }
  }, [dataList]);

  return (
    <>
      {!!dataListShown && (
        <BarChart
          width={width}
          height={height}
          data={dataListShown.dataForLine}
        >
          <Legend
            iconType="plainline"
            wrapperStyle={{ fontSize: "12px", fontWeight: 700 }}
          />
          <CartesianGrid strokeDasharray="3 2" />
          <XAxis
            dataKey="name"
            tick={{ fontSize: 13 }}
            style={{ fontFamily: "Inter" }}
            interval={0}
          />
          <YAxis
            tick={{ fontSize: 10 }}
            tickCount={6}
            style={{ fontFamily: "Inter" }}
            tickFormatter={(value: number) =>
              new Intl.NumberFormat("en").format(value)
            }
          />
          <Tooltip
            formatter={(value) =>
              new Intl.NumberFormat("en").format(value as number)
            }
            labelFormatter={(value) => {
              return `${value}`;
            }}
          />
          {dataListShown.dataSources.map(
            (item: OrgDailyMetricsSettingData, index: number) => (
              <React.Fragment key={index}>
                <Bar
                  name={item.label}
                  dataKey={item.fieldName}
                  fill={item.color}
                  barSize={40}
                />
              </React.Fragment>
            )
          )}
        </BarChart>
      )}
    </>
  );
};

export default BarChartItem;
