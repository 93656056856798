import React, { useState, useEffect } from "react";

import { compileToDataSource } from "./utils";
import { OrgGroupAggregationMetricsData } from "../../../../models/ChartCollectionData";
import PieChartItem from "../../../DashboardComponents/PieChartItem";

import "./styles.scss";

export interface ICircleChartProps {
  chartDetailsData: OrgGroupAggregationMetricsData;
}

export const CircleChart: React.FunctionComponent<ICircleChartProps> = (
  props
) => {
  const { chartDetailsData } = props;

  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    if (!!chartDetailsData) {
      const chartDataTemp = compileToDataSource(chartDetailsData);
      setChartData(chartDataTemp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDetailsData]);

  return (
    <div className="collection-chart-area-circle-chart">
      <PieChartItem
        width={750}
        height={460}
        innerRadius={108}
        outerRadius={125}
        showLegend={true}
        sizeType={"bigger"}
        dataList={chartData.reverse()}
      />
    </div>
  );
};

export default CircleChart;
