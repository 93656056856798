import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import DropdownSelect from "../DropdownSelect";

import "./styles.scss";

interface ITimeInput {
  value: dayjs.Dayjs | undefined;
  onChange: (value: dayjs.Dayjs | undefined) => void;
  fieldLabel?: string;
}

export const TimeInput: React.FunctionComponent<ITimeInput> = ({
  fieldLabel,
  value,
  onChange,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`time_input.${key}`);

  const handleDropdownChange = (newValue: string) => {
    if (newValue === t("none")) {
      onChange(undefined);
      return;
    }
    const [hours, minutes] = newValue.split(":");
    const newDate = dayjs()
      .set("hours", parseInt(hours))
      .set("minutes", parseInt(minutes));
    onChange(newDate);
  };

  return (
    <div className="time-input">
      {fieldLabel && <label>{fieldLabel}</label>}
      <DropdownSelect
        translationKey="time_input"
        placeholder="none"
        value={
          value
            ? `${value.hour()}:${value.minute().toString().padEnd(2, "0")}`
            : ""
        }
        items={[
          t("none"),
          "0:00",
          "0:30",
          "1:00",
          "1:30",
          "2:00",
          "2:30",
          "3:00",
          "3:30",
          "4:00",
          "4:30",
          "5:00",
          "5:30",
          "6:00",
          "6:30",
          "7:00",
          "7:30",
          "8:00",
          "8:30",
          "9:00",
          "9:30",
          "10:00",
          "10:30",
          "11:00",
          "11:30",
          "12:00",
          "12:30",
          "13:00",
          "13:30",
          "14:00",
          "14:30",
          "15:00",
          "15:30",
          "16:00",
          "16:30",
          "17:00",
          "17:30",
          "18:00",
          "18:30",
          "19:00",
          "19:30",
          "20:00",
          "20:30",
          "21:00",
          "21:30",
          "22:00",
          "22:30",
          "23:00",
          "23:30",
        ]}
        onChange={handleDropdownChange}
      />
    </div>
  );
};
