import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { OrgRecommendationData } from "../../../models/OrgRecommendationData";
import SuggestionBody from "../SuggestionBody";
import "./styles.scss";

export interface ISuggestionsTableProps {
  title: string;
  main_section: boolean;
  dataList: OrgRecommendationData[];
  openRecommendation: (recommendation: OrgRecommendationData) => void;
}

export const SuggestionsTable: React.FunctionComponent<
  ISuggestionsTableProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`suggestionsListPage.suggestionsTable.${key}`);

  const [suggestionsExpanded, setSuggestionsExpanded] = useState(false);

  const { title, main_section, dataList, openRecommendation } = props;

  let sortedDataList;
  if (main_section) {
    sortedDataList = dataList
      .filter((item) => item.is_main_section === 1)
      .sort((it1, it2) => {
        if (it1.section_title !== it2.section_title) {
          return it1.section_title < it2.section_title ? -1 : 1;
        }

        const relLocLength1 = it1.relevant_locations?.length ?? 0;
        const relLocLength2 = it2.relevant_locations?.length ?? 0;

        if (relLocLength1 === relLocLength2) {
          return 0;
        }
        return relLocLength1 > relLocLength2 ? -1 : 1;
      })
      .slice(0, suggestionsExpanded ? dataList.length : 3);
  } else {
    const data = dataList.filter((item) => item.is_main_section === 0);
    const sectionTitles = new Set(data.map((item) => item.section_title));
    const tempArray: OrgRecommendationData[][] = [];

    Array.from(sectionTitles).forEach((sectionTitle) => {
      const items = data
        .filter((item) => item.section_title === sectionTitle)
        .sort((it1, it2) => {
          const relLocLength1 = it1.relevant_locations?.length ?? 0;
          const relLocLength2 = it2.relevant_locations?.length ?? 0;

          if (relLocLength1 === relLocLength2) {
            return 0;
          }
          return relLocLength1 > relLocLength2 ? -1 : 1;
        });
      tempArray.push(items);
    });

    sortedDataList = tempArray
      .sort((a, b) => {
        const itemAMaxLength = a
          .map((item) => item.relevant_locations?.length ?? 0)
          .reduce((prev, curr) => Math.max(prev, curr), 0);
        const itemBMaxLength = b
          .map((item) => item.relevant_locations?.length ?? 0)
          .reduce((prev, curr) => Math.max(prev, curr), 0);

        if (itemAMaxLength === itemBMaxLength) {
          return 0;
        }
        return itemAMaxLength > itemBMaxLength ? -1 : 1;
      })
      .flatMap((items) => items)
      .slice(0, suggestionsExpanded ? dataList.length : 3);
  }

  return (
    <div className="white-border-panel suggestions-table-module padding32">
      <div className="titles">{t(title)}</div>
      {sortedDataList.map((item, index) => (
        <div className="group-wrap" key={index}>
          <div className="group-title flex-grid">
            <div className="lefts flex">
              <div className="blue-block">
                {!!main_section && index + 1}
                {!main_section && item.section_title}
              </div>
              <div className="title">{item.title}</div>
            </div>
            {item.email_sent === 0 &&
              item.relevant_locations &&
              item.relevant_locations.length > 0 && (
                <button
                  onClick={() => openRecommendation(item)}
                  className="btn btn-border"
                >
                  {t("email")}
                </button>
              )}
            {item.email_sent === 1 && (
              <div className="email-send flex">
                <i className="icons orange-done"></i>
                <span className="txt">{t("email_sent")}</span>
              </div>
            )}
          </div>
          <div className="txt-area">
            <SuggestionBody suggestion={item} />
          </div>
        </div>
      ))}
      <div className="button-container">
        <button
          onClick={() => setSuggestionsExpanded(!suggestionsExpanded)}
          className="btn btn-border"
        >
          {suggestionsExpanded ? t("close") : t("see_more")}
        </button>
      </div>
    </div>
  );
};

export default SuggestionsTable;
