import { TOptions } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import { DateTimeInterval } from "../../../../../FormElement/DateTimeIntervalInput/utils";
import {
  LocalPostTopicType,
} from "../../../../CreateLocalPostForm/utils";
import { LocalPostPreviewEventSchedule } from "../LocalPostPreviewEventSchedule";

import "./styles.scss";

export interface ILocalPostPreviewCouponCodeProps {
  topicType: LocalPostTopicType;
  couponCode: string;
  schedule: DateTimeInterval;
}

export const LocalPostPreviewCouponCode: React.FunctionComponent<
  ILocalPostPreviewCouponCodeProps
> = ({ couponCode, topicType, schedule }) => {
  const { t: _t } = useTranslation();
  const t = (key: string, options?: TOptions) =>
    _t(
      `analysisCollectionsPage.modalCreateLocalPost.modalPreview.offer.couponCode.${key}`,
      options
    );

  if (topicType !== LocalPostTopicType.OFFER || couponCode.length < 1) {
    return <></>;
  }
  return (
    <div className="local-post-preview-offer-coupon">
      <span className="please-show">{t("please_show")}</span>
      <span className="coupon-code">{couponCode}</span>
      <LocalPostPreviewEventSchedule
        schedule={schedule}
        topicType={topicType}
      />
    </div>
  );
};
