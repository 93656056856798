/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery, useQueries, UseQueryResult } from "@tanstack/react-query";
import _ from "lodash";
import React, { useState, useEffect, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { EMPTY_ORGANIZATION } from "../../../config";
import { MunicipalityData } from "../../../models/MunicipalityData";
import {
  RepresentativeUserData,
  OrganizationRegisterMunicipalitiesData,
  OrganizationData,
} from "../../../models/OrganizationData";
import DataSvc from "../../../services/dataSvc";
import formValidationSvc from "../../../services/formValidationSvc";
import {
  checkFormatEmail,
  checkFormatPassword,
} from "../../../utils/formatValidation";
import { modalViewGAEvent } from "../../../utils/ga";
import { BaseDateInput } from "../../FormElement/BaseDateInput";
import DropdownSelect from "../../FormElement/DropdownSelect";
import { InputBox } from "../../FormElement/InputBox";
import "./styles.scss";

export interface IModalRegisterEditOrganizationProps {
  isModalRegister: boolean;
  registerEditOrganization: OrganizationData;
  onClose: () => void;
  onRemove: () => void;
  onSubmit: (
    formData: OrganizationData,
    municipalityIdList: number[],
    representativeUserData: RepresentativeUserData
  ) => void;
}

interface ParentSelection {
  index: number;
  municipality: MunicipalityData;
}

export const ModalRegisterEditOrganization: React.FunctionComponent<
  IModalRegisterEditOrganizationProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`organizationManagementPage.modalRegisterEditOrganization.${key}`, {
      number: accounts?.length,
    });

  const [dropdownOptionsParent, setDropdownOptionsParent] = useState<string[]>(
    []
  );

  const [shownDeleteConfirm, setShownDeleteConfirm] = useState<boolean>(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [retypedPasswordError, setRetypedPasswordError] = useState("");

  const [formData, setFormData] = useState<OrganizationData>(
    _.cloneDeep(EMPTY_ORGANIZATION)
  );

  const [formMunicipalityData, setFormMunicipalityData] = useState<
    OrganizationRegisterMunicipalitiesData[]
  >(
    _.cloneDeep([
      {
        parent: "",
        child: "",
        municipalitiesChild: [],
        dropdownOptionsChild: ["All Areas"],
      },
    ])
  );

  const [representativeUserData, setRepresentativeUserData] =
    useState<RepresentativeUserData>({
      family_name: "",
      first_name: "",
      email: "",
      password: "",
    });

  const [isPasswordRequired, setIsPasswordRequired] = useState<boolean>(true);

  const [emailToValidate, setEmailToValidate] = useState("");

  // use additional email variable to trigger the account email service calling
  const [emailText, setEmailText] = useState<string>("");

  const [retypedPassword, setRetypedPassword] = useState<string>("");

  const [selectedParent, setSelectedParent] = useState<ParentSelection>();

  const {
    isModalRegister,
    registerEditOrganization,
    onClose,
    onRemove,
    onSubmit,
  } = props;

  const { data: validateResult } = useQuery(["email", emailToValidate], () => {
    if (emailToValidate) {
      try {
        setEmailError("");
        return DataSvc.getAccountByEmail(emailToValidate);
      } catch (e) {
        return null;
      }
    }
    return null;
  });

  useEffect(() => {
    if (validateResult) {
      // If an account exists and belongs to an org, show the error message.
      if (validateResult.organization_id) {
        setEmailError(t("email_already_registered"));
        return;
      }
      // If the existing account does not belongs to any org, it's able to assign it to the new org.
      // In this case, password is not required.
      const names = validateResult.name.split(" ");
      setRepresentativeUserData({
        ...representativeUserData,
        id: validateResult.id,
        family_name: names[0],
        first_name: names[1],
      });
      setIsPasswordRequired(false);
    } else {
      // If the email is availabel, clear ID and show password input.
      setRepresentativeUserData({
        ...representativeUserData,
        id: undefined,
      });
      setIsPasswordRequired(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateResult]);

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent(`${isModalRegister ? "create" : "edit"}-organization`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: municipalitiesParent } = useQuery(
    ["municipalitiesParent"],
    () => {
      return DataSvc.getMunicipalitiesParent();
    }
  );

  const { refetch: fetchChildren, data: fetchedChildren } = useQuery(
    ["municipalitiesChild", selectedParent?.municipality.id],
    () => {
      if (selectedParent) {
        return DataSvc.getMunicipalitiesChild(
          selectedParent?.municipality.external_id?.toString()
        );
      }
    },
    { enabled: false }
  );

  useEffect(() => {
    if (selectedParent) {
      fetchChildren();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParent]);

  useEffect(() => {
    if (selectedParent && fetchedChildren) {
      const index = selectedParent.index;
      const formMunicipalityDataTemp = formMunicipalityData;
      formMunicipalityDataTemp[index].parent = selectedParent.municipality.name;
      formMunicipalityDataTemp[index].child = "";
      formMunicipalityDataTemp[index].municipalitiesChild = fetchedChildren;
      formMunicipalityDataTemp[index].dropdownOptionsChild = [
        "All Areas",
      ].concat(fetchedChildren.map((c: MunicipalityData) => c.name));
      setFormMunicipalityData(_.cloneDeep(formMunicipalityDataTemp));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParent, fetchedChildren]);

  const childQuries = useQueries({
    queries: registerEditOrganization.municipalities.map((m) => {
      if (m.type === "pref") {
        return {
          queryKey: ["municipalitiesChild", m.external_id],
          queryFn: () =>
            DataSvc.getMunicipalitiesChild(m.external_id?.toString()),
        };
      } else {
        return {
          queryKey: ["municipalitiesChild", m.parent_external_id],
          queryFn: () =>
            DataSvc.getMunicipalitiesChild(m.parent_external_id?.toString()),
        };
      }
    }),
  });

  const isSuccess = childQuries.every((c: UseQueryResult) => c.isSuccess);

  useEffect(() => {
    if (!isModalRegister && isSuccess && municipalitiesParent) {
      const children = childQuries.map(
        (c: UseQueryResult<MunicipalityData[]>) => c.data
      );
      const formMunicipalityDataTemp: OrganizationRegisterMunicipalitiesData[] =
        [];
      registerEditOrganization.municipalities.forEach((item, index) => {
        if (item.type === "pref") {
          formMunicipalityDataTemp.push({
            parent: item.name,
            child: "All Areas",
            municipalitiesChild: children[index] || [],
            dropdownOptionsChild: ["All Areas"].concat(
              (children[index] || []).map((c: MunicipalityData) => c.name)
            ),
          });
        } else {
          // get parent item name
          let parentItemName = "";
          municipalitiesParent.forEach((itemParent: MunicipalityData) => {
            if (
              itemParent.external_id?.toString() ===
              item.parent_external_id?.toString()
            ) {
              parentItemName = itemParent.name;
            }
          });

          formMunicipalityDataTemp.push({
            parent: parentItemName,
            child: item.name,
            municipalitiesChild: children[index] || [],
            dropdownOptionsChild: ["All Areas"].concat(
              (children[index] || []).map((c: MunicipalityData) => c.name)
            ),
          });
        }
      });

      setFormMunicipalityData(_.cloneDeep(formMunicipalityDataTemp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, municipalitiesParent, isModalRegister]);

  useEffect(() => {
    if (municipalitiesParent) {
      // load parent municipality list and dropdown options
      const dropdownOptionsParentTemp: string[] = [];
      municipalitiesParent.forEach((item: MunicipalityData) => {
        dropdownOptionsParentTemp.push(item.name);
      });

      setDropdownOptionsParent(dropdownOptionsParentTemp);
    }

    if (registerEditOrganization) {
      setFormData(registerEditOrganization);
    }
  }, [municipalitiesParent, registerEditOrganization, isModalRegister]);

  // change Form Input Field
  const changeFormInputField = (
    value: string,
    fieldName: "name" | "plan_expiry_date"
  ) => {
    const formDataTemp = formData;
    formDataTemp[fieldName] = value;

    setFormData(_.cloneDeep(formDataTemp));
  };

  // change Representative User Input Field
  const changeRepresentativeUserInputField = (
    value: string,
    fieldName: "family_name" | "first_name" | "email" | "password"
  ) => {
    const representativeUserDataTemp = representativeUserData;
    representativeUserDataTemp[fieldName] = value;

    setRepresentativeUserData(_.cloneDeep(representativeUserDataTemp));
  };

  // change Municipality Dropdown Field
  const changeMunicipalityDropdownField = (
    value: string,
    index: number,
    fieldName: "parent" | "child"
  ) => {
    const formMunicipalityDataTemp = formMunicipalityData;

    if (fieldName === "parent") {
      // change municipality parent dropdown
      municipalitiesParent.forEach((itemParent: MunicipalityData) => {
        if (itemParent.name === value) {
          setSelectedParent({ index, municipality: itemParent });
        }
      });
    }

    if (fieldName === "child") {
      // change municipality child dropdown
      formMunicipalityDataTemp[index].child = value;

      setFormMunicipalityData(_.cloneDeep(formMunicipalityDataTemp));
    }
  };

  // delete Municipality
  const deleteMunicipality = (index: number) => {
    const formMunicipalityDataTemp = formMunicipalityData;
    formMunicipalityDataTemp.splice(index, 1);

    setFormMunicipalityData(_.cloneDeep(formMunicipalityDataTemp));
  };

  // add Municipality
  const addMunicipality = () => {
    const formMunicipalityDataTemp = formMunicipalityData;
    formMunicipalityDataTemp.push({
      parent: "",
      child: "",
      municipalitiesChild: [],
      dropdownOptionsChild: [],
    });

    setFormMunicipalityData(_.cloneDeep(formMunicipalityDataTemp));
  };

  // get Municipality Id
  const getMunicipalityId = () => {
    const municipalityIdList: number[] = [];
    const formMunicipalityDataTemp = formMunicipalityData;

    // get municipality id of each rows which used for api calling
    formMunicipalityDataTemp.forEach((item, index) => {
      if (item.child === "All Areas") {
        municipalitiesParent.forEach((itemParent: MunicipalityData) => {
          if (itemParent.name === item.parent) {
            municipalityIdList.push(itemParent.id);
          }
        });
      } else {
        formMunicipalityDataTemp[index].municipalitiesChild.forEach(
          (itemChild: MunicipalityData) => {
            if (itemChild.name === item.child) {
              municipalityIdList.push(itemChild.id);
            }
          }
        );
      }
    });

    return municipalityIdList;
  };

  // is Enable Submit
  const isEnableSubmit = () => {
    let enabled = true;
    if (formData.name.trim() === "" || formData.plan_expiry_date === "") {
      enabled = false;
    }

    let passFormMunicipality = true;
    formMunicipalityData.forEach((item) => {
      if (item.parent !== "" && item.child === "") {
        passFormMunicipality = false;
      }
    });

    if (isModalRegister) {
      if (
        representativeUserData.family_name.trim() === "" ||
        representativeUserData.first_name.trim() === "" ||
        (isPasswordRequired &&
          (representativeUserData.password.trim() === "" ||
            retypedPassword.trim() === "" ||
            emailText.trim() === "" ||
            !checkFormatEmail(emailText) ||
            !checkFormatPassword(representativeUserData.password) ||
            retypedPassword !== representativeUserData.password))
      ) {
        enabled = false;
      }
    }

    return enabled && passFormMunicipality && !emailError;
  };

  const { data: accounts } = useQuery(
    ["accounts", registerEditOrganization.id, shownDeleteConfirm],
    () => {
      if (shownDeleteConfirm) {
        return DataSvc.getAccounts({
          organization_id: registerEditOrganization.id,
          sort: "name",
        });
      }

      return [];
    }
  );

  return (
    <div className="modal modal-default modal-register-edit-organization">
      <div className="modal-mains">
        <a
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        ></a>
        <div className="top-title flex-grid">
          {t(isModalRegister ? "register_organization" : "edit_Organization")}
          <div
            className={`trash-question-box ${isModalRegister ? "hide" : ""} ${
              shownDeleteConfirm ? "open" : ""
            }`}
          >
            <a
              className="icons btn-trash"
              onClick={() => {
                setShownDeleteConfirm(true);
              }}
            ></a>
            <div className="tip-box">
              <a
                className="btn-close"
                onClick={() => {
                  setShownDeleteConfirm(false);
                }}
              ></a>
              <p className="txt">{t("are_you_sure_to_delete_organization")}</p>
              {accounts?.length ? (
                <>
                  <p>{t("accounts_below_will_be_deleted")}</p>
                  <ul>
                    {accounts.map((account, i) => (
                      <li key={i}>
                        <span>{account.name}</span>
                        <span>{account.email}</span>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <p>{t("no_account_associated")}</p>
              )}

              <div className="bottom-btns">
                <a
                  className="btn btn-border"
                  onClick={() => {
                    setShownDeleteConfirm(false);
                  }}
                >
                  {t("no")}
                </a>
                <a
                  className="btn btn-blue"
                  onClick={() => {
                    onRemove();
                    setShownDeleteConfirm(false);
                  }}
                >
                  {t("yes")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="p-txt">
          <div className="title-bar">
            <div className="label-title flex">{t("organization_name")}*</div>
            <InputBox
              value={formData.name}
              placeholder={t("organization_name")}
              classNameContainer={"register-organization-input"}
              pattern="[\s\S]{0,50}"
              onChange={() => {}}
              onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                changeFormInputField(
                  formValidationSvc.validateInputEnteringPattern(
                    event,
                    registerEditOrganization.name
                  ),
                  "name"
                );
              }}
            />
          </div>
          <div className="title-bar">
            <div className="label-title flex">{t("expiration_date")}*</div>
            <div className="date-inputs">
              <BaseDateInput
                fieldLabel={""}
                value={
                  formData.plan_expiry_date
                    ? new Date(formData.plan_expiry_date)
                    : null
                }
                placeholder={t("Select")}
                minDate={new Date()}
                onChange={(newDate) => {
                  changeFormInputField(
                    newDate ? newDate.toString() : "",
                    "plan_expiry_date"
                  );
                }}
              />
            </div>
          </div>

          <div className="title-bar">
            <div className="label-title flex">{t("municipalities")}</div>
            {formMunicipalityData.map(
              (item: OrganizationRegisterMunicipalitiesData, index: number) => (
                <div className="drop-group-row flex" key={index}>
                  <div className="group first-group">
                    <DropdownSelect
                      fieldLabel={""}
                      placeholder={"prefecture"}
                      value={item.parent}
                      items={dropdownOptionsParent}
                      classNameContainer={"register-organization-dropdown"}
                      onChange={(value: string) => {
                        changeMunicipalityDropdownField(value, index, "parent");
                      }}
                    />
                  </div>
                  <div className="group last-group">
                    <DropdownSelect
                      fieldLabel={""}
                      placeholder={"please_select_a_prefecture_first"}
                      isDisabled={item.parent === ""}
                      value={item.child}
                      items={item.dropdownOptionsChild}
                      classNameContainer={"register-organization-dropdown"}
                      onChange={(value: string) => {
                        changeMunicipalityDropdownField(value, index, "child");
                      }}
                    />
                  </div>
                  <a
                    className="icons btn-trash"
                    onClick={() => {
                      deleteMunicipality(index);
                    }}
                  >
                    &nbsp;
                  </a>
                </div>
              )
            )}
            <div className="bottom-add">
              <a
                className="btn btn-blue"
                onClick={() => {
                  addMunicipality();
                }}
              >
                {t("add")}
              </a>
            </div>
          </div>

          {isModalRegister && (
            <div className="register-area title-bar">
              <div className="label-title flex">
                {t("representative_user")}*
              </div>
              <div className="input-groups flex">
                <div className="items">
                  <InputBox
                    value={representativeUserData.family_name}
                    placeholder={t("family_name")}
                    classNameContainer={"register-organization-input"}
                    pattern="[\s\S]{0,20}"
                    onChange={() => {}}
                    onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                      changeRepresentativeUserInputField(
                        formValidationSvc.validateInputEnteringPattern(
                          event,
                          representativeUserData.family_name
                        ),
                        "family_name"
                      );
                    }}
                  />
                </div>
                <div className="items">
                  <InputBox
                    value={representativeUserData.first_name}
                    placeholder={t("first_name")}
                    classNameContainer={"register-organization-input"}
                    pattern="[\s\S]{0,20}"
                    onChange={() => {}}
                    onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                      changeRepresentativeUserInputField(
                        formValidationSvc.validateInputEnteringPattern(
                          event,
                          representativeUserData.first_name
                        ),
                        "first_name"
                      );
                    }}
                  />
                </div>
              </div>
              <InputBox
                value={emailText}
                placeholder={t("email")}
                classNameContainer={"register-organization-input"}
                pattern="[\s\S]{0,100}"
                errorLabel={emailError}
                onChange={() => {}}
                onBlur={() => {
                  if (checkFormatEmail(emailText)) {
                    setEmailToValidate(emailText);
                  } else {
                    setEmailError(t("email_format_wrong"));
                  }
                }}
                onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                  setEmailText(
                    formValidationSvc.validateInputEnteringPattern(
                      event,
                      emailText
                    )
                  );
                }}
              />
              {isPasswordRequired && (
                <InputBox
                  value={representativeUserData.password}
                  type="password"
                  placeholder={t("password")}
                  classNameContainer="register-organization-input"
                  pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,20}$"
                  errorLabel={passwordError}
                  onChange={() => {}}
                  onBlur={() => {
                    if (!checkFormatPassword(representativeUserData.password)) {
                      setPasswordError(t("password_format_wrong"));
                    } else {
                      setPasswordError("");
                    }
                  }}
                  onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                    if (
                      !!passwordError &&
                      checkFormatPassword(event.target.value)
                    ) {
                      setPasswordError("");
                    }
                    changeRepresentativeUserInputField(
                      event.target.value,
                      "password"
                    );
                  }}
                />
              )}
              {isPasswordRequired && (
                <InputBox
                  value={retypedPassword}
                  type="password"
                  placeholder={t("retype_password")}
                  classNameContainer="register-organization-input"
                  pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,20}$"
                  errorLabel={retypedPasswordError}
                  onChange={() => {}}
                  onBlur={() => {
                    if (retypedPassword !== representativeUserData.password) {
                      setRetypedPasswordError(t("passwords_should_match"));
                    } else {
                      setRetypedPasswordError("");
                    }
                  }}
                  onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                    if (
                      retypedPasswordError &&
                      event.target.value === representativeUserData.password
                    ) {
                      setRetypedPasswordError("");
                    }
                    if (
                      retypedPassword.length >= 8 &&
                      event.target.value !== representativeUserData.password
                    ) {
                      setRetypedPasswordError(t("passwords_should_match"));
                    }
                    setRetypedPassword(event.target.value);
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div className="bottom-btns">
          <a
            className="btn btn-border"
            onClick={() => {
              onClose();
            }}
          >
            {t("cancel")}
          </a>
          <a
            className={`btn btn-blue ${isEnableSubmit() ? "" : "disabled"}`}
            onClick={() => {
              onSubmit(formData, getMunicipalityId(), {
                ...representativeUserData,
                email: emailText,
              });
            }}
          >
            {t("submit")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalRegisterEditOrganization;
