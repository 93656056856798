import ja from "date-fns/locale/ja";
import React from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

// Register locale for date picker
registerLocale("ja", ja);

export const DATE_FORMAT = "yyyy/MM/dd";

export type IBaseDateInputProps = {
  fieldLabel?: string;
  isShownError?: boolean;
  tipsLabel?: string;
  isShownTips?: boolean;
  isRequired?: boolean;
  placeholder: string;
  disabled?: boolean;
  value: Date | null;
  maxDate?: Date;
  minDate?: Date;
  onChange?: (newDate: Date | null | string) => void;
  className?: string;
};

export type IWrapperInputProps = {
  value?: Date | null;
  onClick?: () => void;
};

export const BaseDateInput: React.FunctionComponent<IBaseDateInputProps> = (
  props
) => {
  const {
    fieldLabel,
    isShownError,
    tipsLabel,
    isShownTips,
    isRequired,
    value,
    placeholder,
    disabled,
    onChange,
    maxDate,
    minDate,
    className,
  } = props;

  /* const WrapperInput: React.FunctionComponent<IWrapperInputProps> = forwardRef<
    HTMLDivElement,
    IWrapperInputProps
  >(({ onClick }, ref) => (
    <div className="base-date-input-wrapper" onClick={onClick} ref={ref}>
      <FormControl
        type="text"
        placeholder={placeholder}
        value={value ? moment(value).format(DATE_FORMAT) : ''}
        onChange={(event: ChangeEvent<FormControlElement>) => {         
          // onChange && onChange(event.target?.value || null)
        }}
      >
      </FormControl>
      <span className="right-icon"></span>
    </div>
  )) */

  return (
    <div className={`base-date-input ${className ?? ""}`}>
      <div className="label-txt">
        {fieldLabel} {isRequired && <em>*</em>}
        {isShownTips && (
          <span
            className="icons icon-info"
            title={tipsLabel}
            onClick={(event) => {
              event.preventDefault();
            }}
          ></span>
        )}
      </div>
      <div className="inputs-wrap">
        <div
          className={`inputs ${disabled ? "disabled" : ""}${
            isShownError ? "error" : ""
          }`}
        >
          <ReactDatePicker
            locale={"ja"}
            dateFormat={DATE_FORMAT}
            disabled={disabled}
            selected={value}
            onChange={(date) => onChange && onChange(date as Date | null)}
            maxDate={maxDate}
            minDate={minDate}
            placeholderText={placeholder}
          />
        </div>
      </div>
    </div>
  );
};
