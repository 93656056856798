import dayjs, { Dayjs } from "dayjs";

import { LocationData } from "../../../models/LocationData";
import { LocationsAccountsData } from "../../../models/LocationsAccountsData";

export const calculateSignupDate = (location: LocationData): Dayjs => {
  let earliest: Dayjs = dayjs(location.created_at);
  if (
    location.locations_accounts === null ||
    location.locations_accounts === undefined ||
    location.locations_accounts.length < 1
  )
    return earliest;
  location.locations_accounts.forEach((account: LocationsAccountsData) => {
    if (!account.verified) return;

    const date = dayjs(account.last_modified_at);
    if (date.isBefore(earliest)) {
      earliest = date;
    }
  });
  return earliest;
};
