import React from "react";

import CheckOwner from "./IndividualTools/CheckOwner";
import CheckOwnerArea from "./IndividualTools/CheckOwnerArea";
import CheckOwnerList from "./IndividualTools/CheckOwnerList";
import GetReviews from "./IndividualTools/GetReviews";
import MetricsAreaNew from "./IndividualTools/MetricsAreaNew";
import MetricsNew from "./IndividualTools/MetricsNew";
import ReviewScores from "./IndividualTools/ReviewScores";
import { GoogleInformation } from "../../models/GoogleInformation";
import { LocationData } from "../../models/LocationData";

export interface InternalToolsCommonProps {
  onLoading: (loadingText: string) => void;
  onLoaded: () => void;
  locationData?: LocationData;
  orgId?: number;
  googleInformation: GoogleInformation | undefined;
  commonErrorHandler: (err: string) => string | undefined;
}

export type ToolDictionaryEntryType = {
  titleKey: string;
  componentFunction: (commonProps: InternalToolsCommonProps) => JSX.Element;
};

export enum InternalToolEnum {
  GetReviews = "GetReviews",
  CheckOwner = "CheckOwner",
  CheckOwnerArea = "CheckOwnerArea",
  CheckOwnerList = "CheckOwnerList",
  MetricsNew = "MetricsNew",
  MetricsAreaNew = "MetricsAreaNew",
  ReviewScores = "ReviewScores",
}

export const toolDictionary: {
  [key in InternalToolEnum]: ToolDictionaryEntryType;
} = {
  [InternalToolEnum.GetReviews]: {
    titleKey: "getReviews.main_title",
    componentFunction: (commonProps: InternalToolsCommonProps) => (
      <GetReviews key={InternalToolEnum.GetReviews} {...commonProps} />
    ),
  },
  [InternalToolEnum.CheckOwner]: {
    titleKey: "checkOwner.main_title",
    componentFunction: (commonProps: InternalToolsCommonProps) => (
      <CheckOwner key={InternalToolEnum.CheckOwner} {...commonProps} />
    ),
  },
  [InternalToolEnum.CheckOwnerArea]: {
    titleKey: "checkOwnerArea.main_title",
    componentFunction: (commonProps: InternalToolsCommonProps) => (
      <CheckOwnerArea key={InternalToolEnum.CheckOwnerArea} {...commonProps} />
    ),
  },
  [InternalToolEnum.CheckOwnerList]: {
    titleKey: "checkOwnerList.main_title",
    componentFunction: (commonProps: InternalToolsCommonProps) => (
      <CheckOwnerList key={InternalToolEnum.CheckOwnerList} {...commonProps} />
    ),
  },
  [InternalToolEnum.MetricsNew]: {
    titleKey: "metricsNew.main_title",
    componentFunction: (commonProps: InternalToolsCommonProps) => (
      <MetricsNew key={InternalToolEnum.MetricsNew} {...commonProps} />
    ),
  },
  [InternalToolEnum.MetricsAreaNew]: {
    titleKey: "areaNew.main_title",
    componentFunction: (commonProps: InternalToolsCommonProps) => (
      <MetricsAreaNew key={InternalToolEnum.MetricsAreaNew} {...commonProps} />
    ),
  },
  [InternalToolEnum.ReviewScores]: {
    titleKey: "reviewScores.main_title",
    componentFunction: (commonProps: InternalToolsCommonProps) => (
      <ReviewScores key={InternalToolEnum.ReviewScores} {...commonProps} />
    ),
  },
};
