/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery, useMutation } from "@tanstack/react-query";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { MembersList } from "../../components/AccountManagementComponents/MembersList";
import { ModalEditMember } from "../../components/AccountManagementComponents/ModalEditMember";
import { ModalInviteNewMember } from "../../components/AccountManagementComponents/ModalInviteNewMember";
import { AccountData, AccountInformationData } from "../../models/AccountData";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

type IAdminAccountManagementPageProps = RouteComponentProps<any>;

const AdminAccountManagementPage: React.FunctionComponent<
  IAdminAccountManagementPageProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`accountManagementPage.${key}`);

  const [shownModalEditMember, setShownModalEditMember] =
    useState<boolean>(false); // false
  const [shownModalInviteNewMember, setShownModalInviteNewMember] =
    useState<boolean>(false); // false

  const [organizationId, setOrganizationId] = useState<string>("");

  const [accountsData, setAccountsData] = useState<AccountData[]>([]);

  const [editAccountData, setEditAccountData] = useState<AccountData>();

  const [emailToValidate, setEmailToValidate] = useState<string>();

  const [inviteExists, setInviteExists] = useState<AccountData>();

  const { data: emailTaken } = useQuery(["accounts", emailToValidate], () => {
    if (emailToValidate) {
      return DataSvc.getAccounts({
        email: emailToValidate,
      }).then((accounts) => {
        if (accounts.length > 0) {
          if (accounts[0].organization_id) {
            return true;
          } else {
            setInviteExists(accounts[0]);
            return false;
          }
        } else {
          setInviteExists(undefined);
          return false;
        }
      });
    }
    return false;
  });

  const { data: org } = useQuery(["organizations", organizationId], () => {
    if (!!organizationId) {
      return DataSvc.getOrganization({ organizationId });
    }
  });

  const { data: accounts, refetch: refetchAccounts } = useQuery(
    ["accounts", organizationId],
    () => {
      if (!!organizationId) {
        return DataSvc.getAccounts({
          organization_id: organizationId,
          limit: 200,
        });
      }

      return [];
    }
  );

  useEffect(() => {
    if (accounts) {
      setAccountsData(accounts);
    }
  }, [accounts]);

  useEffect(() => {
    if (props.match.params.organizationId) {
      setOrganizationId(props.match.params.organizationId);
    }
  }, [props.match.params.organizationId]);

  const updateAccountMutation = useMutation<
    AccountData,
    unknown,
    [string, any],
    unknown
  >(([id, data]) => {
    return DataSvc.updateAccount(id, data);
  });

  const registerMutation = useMutation((data: any) => {
    return DataSvc.registerAccount(data);
  });

  // on Save Invite New Member
  const onSaveInviteNewMember = (formData: AccountInformationData) => {
    if (inviteExists) {
      if (organizationId) {
        updateAccountMutation
          .mutateAsync([
            inviteExists.id.toString(),
            {
              organization_id: parseInt(organizationId),
              type: "org_admin",
              role: formData.role,
            },
          ])
          .then(() => {
            setShownModalInviteNewMember(false);
            refetchAccounts();
          });
      }
    } else {
      registerMutation
        .mutateAsync({
          email: formData.email,
          role: formData.role,
          first_name: formData.firstName,
          last_name: formData.familyName,
          organization_id: parseInt(organizationId),
          type: "org_admin",
        })
        .then(() => {
          setShownModalInviteNewMember(false);
          refetchAccounts();
        });
    }
  };

  // on Save Edit Member
  const onSaveEditMember = (formData: AccountInformationData) => {
    if (editAccountData) {
      updateAccountMutation
        .mutateAsync([
          editAccountData.id.toString(),
          {
            first_name: formData.firstName,
            last_name: formData.familyName,
            role: formData.role,
          },
        ])
        .then((data) => {
          const index = _.findIndex(accountsData, ["id", editAccountData.id]);
          accountsData.splice(index, 1, data);
          setAccountsData([...accountsData]);
        });
    }
  };

  // on Delete Member
  const onDeleteMember = (id: number) => {
    updateAccountMutation
      .mutateAsync([id.toString(), { organization_id: null }])
      .then(() => {
        const index = _.findIndex(accountsData, ["id", id]);
        accountsData.splice(index, 1);
        setAccountsData([...accountsData]);
      });
  };

  return (
    <>
      <div className="right-content account-management">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("account_management")}</div>
          <div className="line"></div>
        </div>

        <div className="detail-group">
          <div className="group-title">
            {t("members")}
            <div className="line"></div>
          </div>

          <MembersList
            isAdminMode={true}
            organizationName={org?.name ?? ""}
            dataList={accountsData}
            onClickInviteMember={() => {
              setShownModalInviteNewMember(true);
              setEmailToValidate("");
              setInviteExists(undefined);
            }}
            onClickEditMember={(index: number) => {
              setEditAccountData(accountsData[index]);
              setShownModalEditMember(true);
            }}
          />
        </div>
      </div>
      {shownModalInviteNewMember && (
        <ModalInviteNewMember
          dataList={{
            name: "",
            email: "",
          }}
          emailTaken={emailTaken || false}
          exists={!!inviteExists}
          checkEmailAvailability={setEmailToValidate}
          onClose={() => {
            setShownModalInviteNewMember(false);
          }}
          onSave={(formData: AccountInformationData) => {
            onSaveInviteNewMember(formData);
          }}
        />
      )}

      {shownModalEditMember && !!editAccountData && (
        <ModalEditMember
          isAdminMode={true}
          dataList={editAccountData}
          onClose={() => {
            setShownModalEditMember(false);
          }}
          onDelete={(id: number) => {
            onDeleteMember(id);
            setShownModalEditMember(false);
          }}
          onSave={(formData: AccountInformationData) => {
            onSaveEditMember(formData);
            setShownModalEditMember(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(AdminAccountManagementPage);
