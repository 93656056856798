import { ArrangedUseLimitsData } from "../../models/FeatureUseLimitsData";

export enum FeatureNames {
  MASS_SEND_EMAIL = "mass_send_email",
  GENERATE_LOCAL_POST_WITH_AI = "generate_local_post_with_ai",
}

export const getArrangedUselimits = (
  list: any[],
  target: string
): ArrangedUseLimitsData[] => {
  if(list.length === 0) return [];
  const arrangedUseLimits = list.reduce((acc, current) => {
    const target_id = current?.target_user?.id || current.target_org?.id;
    const name = current?.target_user?.name || current.target_org?.name;
    const email = current?.target_user?.email ?? "";

    const use_limit = {
      feature: current?.user_feature || current.org_feature,
      can_use: current.can_use,
      can_use_times: current.can_use_times,
      specific_rule_expire_at: current.specific_rule_expire_at,
      times_triggered_in_cycle: current.times_triggered_in_cycle,
    };

    if (!acc[target_id]) {
      acc[target_id] = {
        target: target,
        target_id: target_id,
        name: name,
        email: email,
        use_limits: [],
      };
    }

    acc[target_id].use_limits.push(use_limit);

    return acc;
  }, {});

  const mergedUseLimits: ArrangedUseLimitsData[] =
    Object.values(arrangedUseLimits);

  return mergedUseLimits;
};
