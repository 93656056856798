import _ from "lodash";
import React, { useState, useEffect } from "react";

import { compileToDataSource } from "./utils";
import { OrgGroupAggregationMetricsData } from "../../../../models/ChartCollectionData";
import BarChartItem from "../BarChartItem";
import { calculateGraphSize } from "../utils";

import "./styles.scss";

export interface IBarChartProps {
  chartDetailsData: OrgGroupAggregationMetricsData;
}

export const BarChart: React.FunctionComponent<IBarChartProps> = (props) => {
  const { chartDetailsData } = props;

  const [chartData, setChartData] = useState<any>({
    title: "",
    dataSources: [
      {
        label: "number",
        fieldName: "value",
        style: "",
        color: "#4753EF",
      },
    ],
    dataForLine: [],
  });

  useEffect(() => {
    if (!!chartDetailsData) {
      const chartDetailTmp = compileToDataSource(chartDetailsData);
      setChartData(_.cloneDeep(chartDetailTmp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartDetailsData]);

  return (
    <div className="collection-chart-area-bar-chart">
      {/* TODO: Calculate label length for responsive bar height */}
      <BarChartItem {...calculateGraphSize("bar", 0)} dataList={chartData} />
    </div>
  );
};

export default BarChart;
