import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

export interface IFunctionListProps {
  targetType: string;
}

export const IndividualSettingListHeader: FunctionComponent<
  IFunctionListProps
> = ({ targetType }) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`functionManagementPage.functionList.header.${key}`);

  return (
    <>
      <div className="row-th function-individual-settings-list-header">
        <div className="col-th width10">
          {targetType === "org" ? t("org") : t("user")}
        </div>
        <div className="col-th width10">{t("function")}</div>
        <div className="col-th width10">{t("limit")}</div>
        <div className="col-th width10"></div>
      </div>
    </>
  );
};
