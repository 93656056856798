import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { DATE_FORMAT } from "../../config";
import { RecMessageDataFromAPI } from "../../models/RecMessageData";

import "./styles.scss";

export interface IRecMessageListProps {
  dataList: RecMessageDataFromAPI[];
  openRecMessage: (recommendation: RecMessageDataFromAPI) => void;
}

export const RecMessageList: React.FunctionComponent<IRecMessageListProps> = (
  props
) => {
  const { dataList, openRecMessage } = props;
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`recMessageListPage.${key}`);

  return (
    <div className="white-border-panel rec-message-table-module padding32">
      <div className="table-data">
        <div className="row-th">
          <div className="col-th">
            <div className="spacing">{t("created_at")}</div>
          </div>
          <div className="col-th">
            <div className="spacing">{t("sent_by")}</div>
          </div>
          <div className="col-th">
            <div className="spacing">{t("title")}</div>
          </div>
          <div className="col-th">
            <div className="spacing">{t("sent_to")}</div>
          </div>
          <div className="col-th">
            <div className="spacing"></div>
          </div>
        </div>
        {(!dataList || dataList.length === 0) && (
          <div className="row-td text-center sub-txt">
            {t("no_messages")}
            <br />
            <NavLink to="/recommendations">
              {t("try_sending_a_message")}
            </NavLink>
          </div>
        )}
        {dataList.map((item: RecMessageDataFromAPI) => {
          const { created_at, created_by, title, relevant_locations } = item;

          let relLocationsString = relevant_locations[0].name;
          if (relevant_locations.length > 1) {
            relLocationsString += `、${relevant_locations[1].name}`;
          }
          if (relevant_locations.length > 2) {
            relLocationsString += `、 ${_t("recMessageListPage.and_others", {
              othersNumber: relevant_locations.length - 2,
            })}`;
          }

          return (
            <div className="row-td" key={`${created_at}-${title}`}>
              <div className="col-td font14">
                <div className="spacing">
                  {moment(created_at).format(DATE_FORMAT)}
                </div>
              </div>
              <div className="col-td font14">
                <div className="spacing">
                  {created_by ? (
                    <>
                      {created_by.name}
                      <br />
                      <small>({created_by.email})</small>
                    </>
                  ) : (
                    `(${t("deleted_user")})`
                  )}
                </div>
              </div>
              <div className="col-td title">
                <div className="spacing">
                  <strong>{title}</strong>
                </div>
              </div>
              <div className="col-td text-center font14">
                <div className="spacing">{relLocationsString}</div>
              </div>
              <div className="col-td center">
                <div className="spacing">
                  <button
                    onClick={() => openRecMessage(item)}
                    className="btn btn-blue"
                  >
                    {t("open_details")}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecMessageList;
