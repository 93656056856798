/* eslint-disable jsx-a11y/anchor-is-valid */
import { CodeResponse } from "@react-oauth/google";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import {
  LocationStatusType,
  ImportLocationsLocationData,
} from "../../../models/LocationData";
import { getMainCategory } from "../../../utils/getMainCategory";
import "./styles.scss";
import { SignInWithGoogleButton } from "../../SignInWithGoogleButton";

export interface IFacilityRegistrationListProps {
  dataList: ImportLocationsLocationData[];
  isUnauthorizedInGoogle: () => boolean;
  onClickEdit: () => void;
  onGoogleSignInSuccess: (codeResponse: CodeResponse) => void;
}

export const FacilityRegistrationList: React.FunctionComponent<
  IFacilityRegistrationListProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityRegistrationPage.list.${key}`);

  const {
    dataList,
    isUnauthorizedInGoogle,
    onClickEdit,
    onGoogleSignInSuccess,
  } = props;

  const getStatusColorAndLabel = (status: LocationStatusType) => {
    switch (status) {
      case "Registered":
        return {
          color: "green",
          label: "Registerd",
        };
      case "Confirming":
        return {
          color: "yellow",
          label: "Confiming",
        };
      case "Unregistered":
      default:
        return {
          color: "red",
          label: "Not Registered",
        };
    }
  };

  // Send GA event if Google login is shown on first load
  useEffect(() => {
    if (isUnauthorizedInGoogle()) {
      ReactGA.event({
        category: "Facility Register List",
        action: "Prompt Google login",
        nonInteraction: true,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`facility-registration-list-module ${
        isUnauthorizedInGoogle() ? "" : "have-list"
      }`}
    >
      <div className="empty-list">
        <div className="txt">
          {t("please_use_google_login_to_use_this_feature")}
        </div>
        <SignInWithGoogleButton onSuccess={onGoogleSignInSuccess} />
      </div>
      <div className="table-area">
        <div className="top-edit">
          <a
            className="btn btn-blue"
            onClick={() => {
              onClickEdit();
            }}
          >
            {t("edit")}
          </a>
        </div>
        <div className="list-wrap ">
          <div className="table-header">
            <div className="row-th">
              <div className="col-th w30 loc-name">
                <div className="spacing pl18">{t("facility")}</div>
              </div>
              <div className="col-th w20 status">
                <div className="spacing">{t("status")}</div>
              </div>
              <div className="col-th w20 muni">
                <div className="spacing pl22">{t("municipality")}</div>
              </div>
              <div className="col-th w30 category">
                <div className="spacing pl10">{t("category")}</div>
              </div>
            </div>
          </div>
          <div className="table-body">
            {dataList.map(
              (item: ImportLocationsLocationData, index: number) => (
                <div className="row-td" key={index}>
                  <div className="col-td w30 loc-name">
                    <div className="spacing bold">{item.name}</div>
                  </div>
                  <div className="col-td w20 status">
                    <div className="spacing">
                      <div
                        className={`color-txt ${
                          getStatusColorAndLabel(
                            item.front_end_status ?? "Unregistered"
                          )?.color
                        }`}
                      >
                        {t(
                          getStatusColorAndLabel(
                            item.front_end_status ?? "Unregistered"
                          )?.label
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-td w20 muni">
                    <div className="spacing">
                      {item.prefecture} {item.city}
                    </div>
                  </div>
                  <div className="col-td w30 category">
                    <div className="spacing">{getMainCategory(item)}</div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilityRegistrationList;
