/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import {
  AccountData,
  AccountInformationData,
} from "../../../models/AccountData";
import formValidationSvc from "../../../services/formValidationSvc";
import { checkFormatEmail } from "../../../utils/formatValidation";
import { InputBox } from "../../FormElement/InputBox";
import { RadioBox } from "../../FormElement/RadioBox";
import { ModalConfirm } from "../../ModalConfirm";
import "./styles.scss";

export interface IModalInviteNewMemberProps {
  onClose: () => void;
  onSave: (formData: AccountInformationData) => void;
  dataList: Partial<AccountData>;
  emailTaken: boolean;
  checkEmailAvailability: (email: string) => void;
  exists: boolean;
}

export const ModalInviteNewMember: React.FunctionComponent<
  IModalInviteNewMemberProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`accountManagementPage.modalInviteNewMember.${key}`);

  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false); // false

  const [emailError, setEmailError] = useState("");

  const [formData, setFormData] = useState<AccountInformationData>({
    familyName: "",
    firstName: "",
    email: "",
    role: "viewer",
  });

  const {
    onClose,
    onSave,
    dataList,
    emailTaken,
    checkEmailAvailability,
    exists,
  } = props;

  useEffect(() => {
    if (dataList) {
      const str = dataList.name || "";
      const first = str.substring(0, str.indexOf(" "));
      const second = str.substring(str.indexOf(" ") + 1);

      setFormData({
        familyName: first || formData.familyName,
        firstName: second || formData.firstName,
        email: dataList?.email || formData.email,
        role: formData.role,
      });
    }
    // eslint-disable-next-line
  }, [dataList]);

  // is Enabled Btn
  const isEnabledBtn = () => {
    return (
      exists ||
      (formData.familyName !== "" &&
        formData.firstName !== "" &&
        formData.email !== "" &&
        formData.role !== "" &&
        formData.email.trim() !== "" &&
        checkFormatEmail(formData.email))
    );
  };

  return (
    <div className="modal modal-default modal-invite-new-member">
      <div className="modal-mains">
        <a
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        ></a>
        <div className="top-title flex-grid">{t("invite_new_member")}</div>

        <div className="row row-form">
          <div className="col col-md-6">
            <div className="title-bar">
              <div className="label-title flex">{t("family_name")}</div>
              <InputBox
                value={formData.familyName}
                placeholder={t("family_name")}
                classNameContainer={"family_name-input"}
                pattern="[\W\w]{0,20}"
                onChange={() => {}}
                onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                  setFormData({
                    ...formData,
                    familyName: formValidationSvc.validateInputEnteringPattern(
                      event,
                      formData.familyName
                    ),
                  });
                }}
              />
            </div>
          </div>
          <div className="col col-md-6">
            <div className="title-bar">
              <div className="label-title flex">{t("first_name")}</div>
              <InputBox
                value={formData.firstName}
                placeholder={t("first_name")}
                classNameContainer={"first_name-input"}
                pattern="[\W\w]{0,20}"
                onChange={() => {}}
                onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                  setFormData({
                    ...formData,
                    firstName: formValidationSvc.validateInputEnteringPattern(
                      event,
                      formData.firstName
                    ),
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="title-bar row-form mb60">
          <div className="label-title flex">{t("mail_address")}</div>
          <InputBox
            value={formData.email}
            placeholder={t("mail_address")}
            classNameContainer={"mail_address-input"}
            pattern="[\s\S]{0,100}"
            errorLabel={
              emailTaken
                ? t("this_email_address_has_already_been_taken")
                : emailError
            }
            onChange={() => {}}
            onBlur={() => {
              if (checkFormatEmail(formData.email)) {
                setEmailError("");
                checkEmailAvailability(formData.email);
              } else {
                setEmailError(t("email_format_wrong"));
              }
            }}
            onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
              setFormData({
                ...formData,
                email: formValidationSvc.validateInputEnteringPattern(
                  event,
                  formData.email
                ),
              });
            }}
          />
        </div>

        <div className="radio-groups">
          <div className="label-txt">{t("role")}</div>
          <div className="two-radio">
            <div className="radio-wrapper">
              <RadioBox
                checked={formData.role === "viewer"}
                label={t("viewer")}
                subLabel={t("member_can_view_reports")}
                id={"role-type-viewer"}
                name={"role-type"}
                onChange={() => {
                  setFormData({
                    ...formData,
                    role: "viewer",
                  });
                }}
              />
            </div>
            {dataList?.organization?.type !== "location" && (
              <div className="radio-wrapper">
                <RadioBox
                  checked={formData.role === "editor"}
                  label={t("editor")}
                  subLabel={t("member_can_create_charts_and_send_emails")}
                  id={"role-type-editor"}
                  name={"role-type"}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      role: "editor",
                    });
                  }}
                />
              </div>
            )}
            <div className="radio-wrapper">
              <RadioBox
                checked={formData.role === "manager"}
                label={t("manager")}
                subLabel={t(
                  "member_can_create_charts_send_emails_and_manage_members"
                )}
                id={"role-type-manager"}
                name={"role-type"}
                onChange={() => {
                  setFormData({
                    ...formData,
                    role: "manager",
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="bottom-btns">
          <a
            className="btn btn-border"
            onClick={() => {
              onClose();
            }}
          >
            {t("cancel")}
          </a>
          <a
            className={`btn btn-blue ${!isEnabledBtn() ? "disabled" : ""}`}
            onClick={() => {
              setShownModalConfirm(true);
            }}
          >
            {t("invite")}
          </a>
        </div>
      </div>

      {shownModalConfirm && (
        <ModalConfirm
          title={"please_confirm_to_invite_the_member"}
          cancelLabel={"cancel"}
          confirmLabel={"confirm"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            onSave(formData);
            setShownModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default ModalInviteNewMember;
