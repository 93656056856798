import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import utc from "dayjs/plugin/utc";
import i18next from "i18next";

import { OrgTimeSeriesMetricsData } from "../../../../models/ChartCollectionData";

dayjs.extend(utc);
dayjs.extend(isoWeek);

type TimeGrouping = "week" | "month";

export type DataSource = {
  label: string;
  fieldName: string;
  style: string;
  color: string;
};

export type DataForLine = {
  [key: string]: number | string;
  name: string;
  date: string;
};

export type ChartData = {
  title: string;
  dataSources: DataSource[];
  dataForLine: DataForLine[];
};

export const createEmptyChartData = (
  dataSourceConfig: DataSource[] = []
): ChartData => ({
  title: "",
  dataSources: dataSourceConfig,
  dataForLine: [],
});

export const fieldNameArray = [
  "aa",
  "bb",
  "cc",
  "dd",
  "ee",
  "ff",
  "gg",
  "hh",
  "ii",
  "jj",
  "kk",
  "ll",
  "mm",
  "nn",
  "oo",
  "pp",
  "qq",
  "rr",
  "ss",
  "tt",
  "uu",
  "vv",
  "ww",
  "xx",
  "yy",
  "zz",
];
export const prevYearFieldNameArray = fieldNameArray.map(
  (name) => `${name}-last`
);

// Day handling

export const createDateList = (
  chartDetailsData: OrgTimeSeriesMetricsData[]
) => {
  const startDate = dayjs.utc(chartDetailsData[0].start_date).startOf("day");
  const endDate = dayjs
    .utc(chartDetailsData[0].end_date)
    .startOf("day")
    .add(1, "day");

  const fullDates: string[] = [];
  let currentDate = startDate;
  while (currentDate.isBefore(endDate)) {
    fullDates.push(currentDate.toISOString());
    currentDate = currentDate.add(1, "day");
  }
  return fullDates;
};

export const populateDayData = (
  chartDetailsData: OrgTimeSeriesMetricsData[],
  fullDates: string[],
  isLastYear: boolean
) => {
  const lineData: DataForLine[] = [];

  for (let i = 0; i < fullDates.length; i++) {
    let dotData: any = {
      name: "",
    };

    // eslint-disable-next-line
    chartDetailsData.forEach(
      (item: OrgTimeSeriesMetricsData, index: number) => {
        const metricDate = fullDates[i];
        const date = new Date(metricDate);
        const dateLabel = `${date.getMonth() + 1}/${date.getDate()}`;

        const itemValue = item.data.find(({ metric_date }) => {
          if (isLastYear) {
            return (
              dayjs(metricDate).subtract(1, "year").toISOString() ===
              metric_date
            );
          } else {
            return metric_date === metricDate;
          }
        });
        const value = itemValue ? itemValue.value : 0;

        const key = isLastYear
          ? prevYearFieldNameArray[index]
          : fieldNameArray[index];

        dotData = {
          ...dotData,
          name: dateLabel,
          date: metricDate,
          [key]: value,
        };
      }
    );

    lineData.push(dotData);
  }

  return lineData;
};

// Group handling

export const groupData = (dayData: ChartData, grouping: TimeGrouping) => {
  const results: Record<string, DataForLine[]> = {};

  dayData.dataForLine.forEach((lineItem, index, array) => {
    let groupTitle = "";
    if (grouping === "week") {
      const remainder = index % 7;
      const baseItemIndex = index - remainder;

      groupTitle = array[baseItemIndex].name;
    } else if (grouping === "month") {
      groupTitle = `${dayjs(lineItem.date).month() + 1}/${dayjs(
        lineItem.date
      ).year()}`;
    }

    // add this key as a property to the result object
    if (!results[groupTitle]) {
      results[groupTitle] = [];
    }

    // push the current date that belongs to the year-week calculated befor
    results[groupTitle].push(lineItem);
  });

  return results;
};

export const populateGroupData = (
  dataByGroup: Record<string, DataForLine[]>,
  chartDetailsData: OrgTimeSeriesMetricsData[],
  grouping: TimeGrouping,
  isLastYear: boolean
): DataForLine[] => {
  const resultArray: any[] = [];
  let index = 0;
  for (const fieldName in dataByGroup) {
    chartDetailsData.forEach(
      // eslint-disable-next-line no-loop-func
      (item: OrgTimeSeriesMetricsData, indexLine: number) => {
        let count = 0;
        const key = isLastYear
          ? prevYearFieldNameArray[indexLine]
          : fieldNameArray[indexLine];
        dataByGroup[fieldName].forEach((itemDay: any) => {
          count += parseInt(itemDay[key]);
        });

        const name = fieldName;

        resultArray[index] = {
          ...resultArray[index],
          name,
          [key]: count,
        };
      }
    );
    index++;
  }

  return resultArray;
};

export const createDataGroups = (
  chartDetailsData: OrgTimeSeriesMetricsData[],
  dataSourceConfig: DataSource[],
  isLastYear: boolean
) => {
  const chartDataDaysTemp = { ...createEmptyChartData(dataSourceConfig) };
  const chartDataWeeksTemp = { ...createEmptyChartData(dataSourceConfig) };
  const chartDataMonthsTemp = { ...createEmptyChartData(dataSourceConfig) };

  // chartDataTemp.dataForLine
  if (chartDetailsData.length > 0) {
    const fullDates = createDateList(chartDetailsData);
    chartDataDaysTemp.dataForLine = populateDayData(
      chartDetailsData,
      fullDates,
      isLastYear
    );

    // sort by weeks
    const groupByWeeks = groupData(chartDataDaysTemp, "week");
    chartDataWeeksTemp.dataForLine = populateGroupData(
      groupByWeeks,
      chartDetailsData,
      "week",
      isLastYear
    );

    // sort by months
    const groupByMonths = groupData(chartDataDaysTemp, "month");
    chartDataMonthsTemp.dataForLine = populateGroupData(
      groupByMonths,
      chartDetailsData,
      "month",
      isLastYear
    );
  }

  return {
    chartDataDaysTemp,
    chartDataWeeksTemp,
    chartDataMonthsTemp,
  };
};

const colorArray = ["#4753EF", "#9BA2FC", "#E9907D", "#101CB9"];
export const compileToDataSource = (
  chartDetailsData: OrgTimeSeriesMetricsData[],
  skipLabelTranslation: boolean,
  isLastYear: boolean
) => {
  const t = (key: string) =>
    i18next.t(`analysisCollectionsPage.collectionChartArea.${key}`);
  return chartDetailsData.map(
    (item: OrgTimeSeriesMetricsData, index: number) => {
      const labels = item.title.map((titleText) =>
        titleText === "others" ? t("metricItems.avg_of_others") : titleText
      );
      return {
        // TODO: support more labels and aggregation(sum/avg,category/municipality)
        label: `${
          skipLabelTranslation
            ? labels.join("+")
            : t(`metricItems.${labels.join("+")}`)
        }${isLastYear ? t("prevYear") : ""}`,
        fieldName: isLastYear
          ? prevYearFieldNameArray[index]
          : fieldNameArray[index],
        style: isLastYear ? "dashed" : "solid",
        color: colorArray[index],
      };
    }
  );
};
