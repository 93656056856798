import moment from 'moment';

import { PeriodOptionType } from '../models/ChartCollectionData';

export const getFromToDate = (period_option: PeriodOptionType) => {
  const baseDate = moment().endOf('day');
  let fromDateStr;
  let toDateStr;
  switch (period_option) {
    case 'today':
      fromDateStr = baseDate;
      toDateStr = baseDate;
      break;
    case 'yesterday':
      fromDateStr = baseDate.clone().subtract(1, 'days');
      toDateStr = baseDate.clone().subtract(1, 'days');
      break;
    case 'last_week':
      fromDateStr = getWeekStartDate().subtract(7, 'days');
      toDateStr = getWeekEndDate().subtract(7, 'days');
      break;
    case 'last_month':
      fromDateStr = (getLastMonthStartDate());
      toDateStr = (getLastMonthEndDate());
      break;
    case 'last_day':
      fromDateStr = baseDate.clone().subtract(7, 'days');
      toDateStr = baseDate.subtract(7, 'days');
      break;
    case 'last_7_days':
      fromDateStr = baseDate.clone().subtract(13, 'days');
      toDateStr = baseDate.subtract(7, 'days');
      break;
    case 'last_28_days':
      fromDateStr = baseDate.clone().subtract(34, 'days');
      toDateStr = baseDate.subtract(7, 'days');
      break;
    case 'last_18_months':
      fromDateStr = baseDate.clone().subtract(18, 'months');
      toDateStr = baseDate.subtract(7, 'days');
      break;
    default:
      return { fromDateStr: undefined, toDateStr: undefined }
  }

    return { fromDateStr, toDateStr }
}

// get Start Day of this week
const getWeekStartDate = () => {
  return moment().utc().day(0).startOf('day');
};

// get End Day of this week
const getWeekEndDate = () => {
  return moment().utc().day(6).endOf('day');
};

// get Start Day of Last month
const getLastMonthStartDate = () => {
  return moment().utc().subtract(1, 'month').startOf('month');
};

// get End Day of Last month
const getLastMonthEndDate = () => {
  return moment().utc().subtract(1, 'month').endOf('month');
};