import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { initialEmailState, validateEmailForm } from "./utils";
import { ModalFilterCondition } from "../../components/FacilityListComponents/ModalFilterCondition";
import { LocationsDisplay } from "../../components/SendMessageComponents/LocationsDisplay";
import { ModalConfirmSendMessage } from "../../components/SendMessageComponents/ModalConfirmSendMessage";
import { ModalSendMessage } from "../../components/SendMessageComponents/ModalSendMessage";
import { useMe } from "../../hooks/queries";
import {
  FacilityFilterConditionData,
  createDefaultConditionFormData,
  getScoreArrayString,
  getProfBoolQueryObject,
  hasConditionFilter,
} from "../../models/FacilityFilterConditionData";
import { LocationData } from "../../models/LocationData";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

export type SendMessageLocationData = LocationData | Array<SummaryLocation>;

export interface SummaryLocation {
  id: number;
  name: string;
  has_lodging: boolean;
}

export interface ISelectableSummaryLocation {
  summaryLocation: SummaryLocation;
  isSelected: boolean;
}

interface ISendMessageScreensProps {
  locationData: SendMessageLocationData;
  setShowSendMessageScreens: (value: boolean) => void;
  orgRecommendationId?: number; // For sending by recommendation

  // For sending using custom filters
  isCustomFilterMode?: boolean;
  organizationId?: string;
}

const defaultConditionFormData = createDefaultConditionFormData();

const SendMessageScreens: React.FunctionComponent<ISendMessageScreensProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`modalSendMessage.${key}`);
  const { data: me } = useMe();

  const {
    isCustomFilterMode,
    locationData,
    orgRecommendationId,
    organizationId,
    setShowSendMessageScreens,
  } = props;

  const [
    shownSendMessageConfirmationModal,
    setShownSendMessageConfirmationModal,
  ] = useState<boolean>(false);
  const [sendMessageVariables, setSendMessageVariables] = useState<any>({
    ...initialEmailState,
  });
  const [selectedLocations, setSelectedLocations] = useState<
    LocationData | Array<SummaryLocation>
  >(Array.isArray(locationData) ? locationData : [locationData]);
  const [locations, setLocations] =
    useState<SendMessageLocationData>(locationData);

  const [shownModalFilterCondition, setShownModalFilterCondition] =
    useState(false);
  const [conditionFormData, setConditionFormData] =
    useState<FacilityFilterConditionData>(defaultConditionFormData);

  const { messageTitle, messageText, replyEmail, bccTo } = sendMessageVariables;

  const updateVariables = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSendMessageVariables({
      ...sendMessageVariables,
      [event.target.name]: event.target.value,
    });
  };

  const onToConfirm = (data: LocationData | Array<SummaryLocation>) => {
    validateEmailForm(
      sendMessageVariables,
      setSendMessageVariables,
      () => {
        setShownSendMessageConfirmationModal(true);
      },
      () => {
        setSelectedLocations(data);
      }
    );
  };

  const extractLocationIds = (): number | Array<number> => {
    return _.isArray(locations)
      ? (selectedLocations as Array<SummaryLocation>).map((item) =>
          Number(item.id)
        )
      : [(locations as LocationData).id];
  };

  // on Send Message
  const onSendMessage = async () => {
    await DataSvc.sendEmailToLocation({
      data: [
        {
          location_ids: extractLocationIds(),
          subject: messageTitle,
          text: messageText,
          replyToEmail: !!replyEmail ? replyEmail : me?.email,
          ...(orgRecommendationId
            ? { org_recommendation_id: orgRecommendationId }
            : {}),
        },
      ],
    });

    toast(t("email_sent"));
    setSendMessageVariables({ ...initialEmailState });
    setShowSendMessageScreens(false);
    setShownSendMessageConfirmationModal(false);
  };

  // Only used for custom filters
  const onOpenFilters = () => {
    setShownModalFilterCondition(true);
  };

  const hasConditions = hasConditionFilter(conditionFormData);

  const { data: filteredLocations } = useQuery(
    ["custom-message-locations", organizationId, conditionFormData],
    () => {
      const { labels, categories, municipalities } = conditionFormData;
      return DataSvc.getLocations(
        _.pickBy(
          {
            organization_id: organizationId ? [organizationId] : organizationId,
            seek_total_count: true,
            label_id: labels ? labels.map((label) => label.id) : null,
            category_id: categories
              ? categories.map((category) => category.id)
              : null,
            municipality_id: municipalities
              ? municipalities.flatMap((muniGroup) =>
                  muniGroup.muniList.map((muni) => muni.id)
                )
              : null,
            score: getScoreArrayString(conditionFormData),
            ...getProfBoolQueryObject(conditionFormData),
            ...(conditionFormData.hasLodging
              ? { has_lodging: conditionFormData.hasLodging.shouldInclude }
              : {}),
            summary_mode: true,
          },
          (value: any) => {
            return !_.isUndefined(value) && !_.isNull(value);
          }
        )
      );
    },
    { enabled: isCustomFilterMode && hasConditions }
  );

  useEffect(() => {
    if (!isCustomFilterMode) {
      // indiv facility and sending through recommendations
      setLocations(locationData);
      return;
    }

    // Stateful handling needed by custom filter mode
    if (!hasConditions) {
      setLocations([]);
    } else if (filteredLocations) {
      setLocations(filteredLocations.list);
    }
  }, [isCustomFilterMode, locationData, filteredLocations, hasConditions]);

  const onApplyFilterCondition = (
    conditionFormData: FacilityFilterConditionData
  ) => {
    setConditionFormData(conditionFormData);
    setShownModalFilterCondition(false);
  };

  return (
    <React.Fragment>
      <ModalSendMessage
        {...sendMessageVariables}
        isCustomFilterMode={isCustomFilterMode ?? false}
        conditionFormData={conditionFormData}
        locationData={locations}
        userEmail={me?.email ?? ""}
        onChangeTextVariable={updateVariables}
        onClose={() => {
          setShowSendMessageScreens(false);
        }}
        onToConfirm={onToConfirm}
        onOpenFilters={onOpenFilters}
      />

      {shownSendMessageConfirmationModal && (
        <ModalConfirmSendMessage
          messageTitle={messageTitle}
          messageText={messageText}
          replyEmail={replyEmail}
          userEmail={me?.email ?? ""}
          bccTo={bccTo}
          onClose={() => {
            setShownSendMessageConfirmationModal(false);
          }}
          onToConfirm={() => {
            onSendMessage();
          }}
          conditions={
            selectedLocations && (
              <LocationsDisplay
                isSelectableLocations={false}
                isCustomFilterMode={false}
                locationData={selectedLocations}
              />
            )
          }
        />
      )}

      {isCustomFilterMode && shownModalFilterCondition && (
        <ModalFilterCondition
          organizationId={organizationId || ""}
          userId={me?.id}
          onClose={() => {
            setShownModalFilterCondition(false);
          }}
          conditionFormData={conditionFormData}
          onApply={onApplyFilterCondition}
        />
      )}
    </React.Fragment>
  );
};

export default SendMessageScreens;
