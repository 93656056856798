/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {useTranslation} from 'react-i18next';

import { AccountData } from '../../../models/AccountData';
import './styles.scss';

export interface IAccountInformationProps {
  dataList: AccountData;
  onClickEditAccountInformation: () => void
}

export const AccountInformation: React.FunctionComponent<IAccountInformationProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`accountManagementPage.accountInformation.${key}`)
  
  const { dataList, onClickEditAccountInformation } = props;

  return (    
    <div className="white-border-panel account-information-module">
      <div className="row">
        <div className="col col-md-3 col-12">
          <div className="top-area">
            <div className="title">
              {t('name')}
            </div>
          </div>
          <div className="center-num">
            {dataList.name}
          </div>
        </div>
        <div className="col col-md-3 col-12">
          <div className="top-area">
            <div className="title">
              {t('mail_address')}
            </div>
          </div>
          <div className="center-num">
            {dataList.email}
          </div>
        </div>
        <div className="col col-md-3 col-12">
          <div className="top-area">
            <div className="title">
              {t('organization_role')}
            </div>
          </div>
          <div className="center-num">
            {dataList.organization?.name || t('no_organization')} / {t(dataList.role)}
          </div>
        </div>
        <div className="col col-md-3 col-12">
          {dataList.role === 'manager' && (
            <a className="btn btn-border"
              onClick={() => {
                onClickEditAccountInformation()
              }}>
              <i className="icons icon-edit"></i>
              {t('edit')}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountInformation;
