/*
 * App configurations
 */

import { ProfBooleanData } from "../models/ProfBooleanData";

export const GA_ID = process.env.REACT_APP_GA_ID || 'G-Y33XDG49PS';

export const META = {
  PAGE_TITLE_SUFFIX: 'Area Compass',
  PAGE_DESCRIPTION: 'Area Compass',
  PAGE_KEYWORDS: 'Area Compass',
};

export const DATE_FORMAT = "YYYY/MM/DD";

export const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export const FACILITY_SORT = {
  NAME: 'name',
  STATS_SCORE: 'stats.score',
  STATS_PROFILE_VIEWS: 'stats.profile_views',
  STATS_PROFILE_VIEWS_CHANGE_PERCENTAGE: 'stats.profile_views_change_percentage',
  STATS_DIRECTION_SEARCH_COUNT: 'stats.direction_search_count',
  STATS_DIRECTION_SEARCH_COUNT_CHANGE_PERCENTAGE: 'stats.direction_search_count_change_percentage',
  LOCATION: 'prefecture+city',
  CATEGORY: 'category',
};

export const FACILITY_SORT_BY = {
  FACILITY_NAME_ASC: '施設名 ↑',
  FACILITY_NAME_DESC: '施設名 ↓',
  HEALTH_INDEX_ASC: '健康指標 ↑',
  HEALTH_INDEX_DESC: '健康指標 ↓',
  PROFILE_VIEWS_ASC: '閲覧数 ↑',
  PROFILE_VIEWS_DESC: '閲覧数 ↓',
  PROFILE_VIEWS_CHANGE_PERCENTAGE_ASC: '閲覧数増減率 ↑',
  PROFILE_VIEWS_CHANGE_PERCENTAGE_DESC: '閲覧数増減率 ↓',
  DIRECTION_SEARCH_COUNT_ASC: 'ルート検索数 ↑',
  DIRECTION_SEARCH_COUNT_DESC: 'ルート検索数 ↓',
  DIRECTION_SEARCH_COUNT_CHANGE_PERCENTAGE_ASC: 'ルート検索数増減率 ↑',
  DIRECTION_SEARCH_COUNT_CHANGE_PERCENTAGE_DESC: 'ルート検索数増減率 ↓',
  LOCATION_ASC: '所在地 ↑',
  LOCATION_DESC: '所在地 ↓',
  CATEGORY_ASC: '業種 ↑',
  CATEGORY_DESC: '業種 ↓',
};

export const SORT_FACILITY_MANAGEMENT_OPTIONS = [
  '振り分け状況 ↑',
  '振り分け状況 ↓',
  '施設名 ↑',
  '施設名 ↓',
  '所在地 ↑',
  '所在地 ↓',
  '施設ユーザー狀況 ↑',
  '施設ユーザー狀況 ↓',
];

export const SORT_ORGANIZATION_MANAGEMENT_OPTIONS = [
  '団体名 ↑',
  '団体名 ↓',
  '種別 ↑',
  '種別 ↓',
  '利用期限 ↑',
  '利用期限 ↓',
  '施設の\'A\'健康施設率 ↑',
  '施設の\'A\'健康施設率 ↓',
  '施設のログイン率 ↑',
  '施設のログイン率 ↓',
  'サインアップ率 ↑',
  'サインアップ率 ↓',
];

export const CHART_PERIOD_OPTIONS = [
  'custom',
  /*
  'today',
  'yesterday',
  'last_week',
  */
  'last_month',
  'last_day',
  'last_7_days',
  'last_28_days',
  'last_18_months',
];

export const PAGE_NUMBER_OPTIONS = [20, 50, 100];

export const HEALTH_INDEX_OPTIONS = [
  {
    key: 'A',
    name: 'Profile Completed, with some posts in last 28 days',
    checked: false,
  },
  {
    key: 'B',
    name: 'Profile Completed, no post in last 28 days',
    checked: false,
  },
  {
    key: 'C',
    name: 'No Profile or Photo',
    checked: false,
  },
  {
    key: 'D',
    name: 'No Profile and Photo',
    checked: false,
  },
];

export const PROFILE_BOOLEAN_OPTIONS: ProfBooleanData[] = [
  {
    key: 'has_website_url',
    shouldInclude: undefined,
  },
  {
    key: 'has_profile_description',
    shouldInclude: undefined,
  },
  {
    key: 'has_primary_phone',
    shouldInclude: undefined,
  },
  {
    key: 'has_primary_category_service_types',
    shouldInclude: undefined,
  },
  {
    key: 'has_min_photo_count',
    shouldInclude: undefined,
  },
  {
    key: 'has_min_photo_categories',
    shouldInclude: undefined,
  },
  {
    key: 'has_recent_media',
    shouldInclude: undefined,
  },
  {
    key: 'has_min_local_categories',
    shouldInclude: undefined,
  },
  {
    key: 'has_recent_review_reply',
    shouldInclude: undefined,
  },

];

export const EMPTY_ORGANIZATION = {
  name: '',
  plan_expiry_date: '',
  type: '',
  refresh_token: '',
  municipalities: [],
  id: '0',
  created_at: '',
  created_by: '0',
  last_modified_at: '',
  last_modified_by: '0',
  locations: [],
  accounts: [],
  checked: false,
  login_rate: 0,
  sign_up_rate: 0,
  health_index: 0,
};

export const AUTH0 = {
  DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || 'liigo-ac-dev.jp.auth0.com',
  CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || 'DZ5G0MAynaQ1ZS5Rk4t4aEl3IvtSaYVr',
};

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '746122142928-jmq4smk85nhdgsa3a7l2e0la6o6e5uqv.apps.googleusercontent.com'
export const GOOGLE_REDIRECT_URI = process.env.REACT_APP_GOOGLE_REDIRECT_URI || window?.location?.origin || 'http://localhost:3001'

export const API_BASE = process.env.REACT_APP_API_BASE || 'http://localhost:3000'; // Staging: 'https://api.www.area-compass-dev.com';
export const GOOGLE_ID = process.env.GOOGLE_ID || "746122142928-jbc8tbfb0hfhbfi4scaiumds015u9apo.apps.googleusercontent.com";

// export const API_BASE = process.env.REACT_APP_API_BASE || 'https://api.www.area-compass-dev.com';

export const GBP_SCOPE = 'https://www.googleapis.com/auth/business.manage';
export const INTERNAL_TOOLS_AUTH_REDIRECT_URL = `${process.env.REACT_APP_API_BASE}/tools/authorize`;
export const INTERNAL_TOOLS_GOOGLE_LOGIN_URL = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${GOOGLE_CLIENT_ID}&redirect_uri=${INTERNAL_TOOLS_AUTH_REDIRECT_URL}&scope=${GBP_SCOPE}`;