/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import React, { useEffect, useState, ChangeEvent } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import {
  ChartCollectionData,
  ChartData,
  CreateChartCollectionData,
} from "../../../models/ChartCollectionData";
import DataSvc from "../../../services/dataSvc";
import formValidationSvc from "../../../services/formValidationSvc";
import { modalViewGAEvent } from "../../../utils/ga";
import { InputBox } from "../../FormElement/InputBox";
import { ModalConfirm } from "../../ModalConfirm";
import ChartCategoryItem from "../ChartCategoryItem";

import "./styles.scss";

export interface IModalCreateEditChartProps {
  onClose: () => void;
  onDelete: () => void;
  onCreateSaveChart: (
    labelName: string,
    selectedTemplateChart: ChartData,
    selectedCollectionTemplateId: string
  ) => void;
  isEdit: boolean;
  editingChartData: ChartData;
  indivFacilityMode?: boolean;
}

export const ModalCreateEditChart: React.FunctionComponent<
  IModalCreateEditChartProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.modalCreateEditChart.${key}`);

  const {
    onClose,
    onDelete,
    onCreateSaveChart,
    isEdit,
    editingChartData,
    indivFacilityMode,
  } = props;

  const [labelName, setLabelName] = useState<string>(
    isEdit ? (editingChartData ? editingChartData.name : "") : ""
  );
  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false); // false

  const [chartCollectionsTemplateData, setChartCollectionsTemplateData] =
    useState<CreateChartCollectionData[]>([]);

  const [selectedCollectionTemplateId, setSelectedCollectionTemplateId] =
    useState<string>("");
  const [selectedTemplateChart, setSelectedTemplateChart] = useState<ChartData>(
    {
      name: "",
      collection_id: 0,
      template_id: 0,
      template_name: "",
      period_start: "",
      period_end: "",
      period_option: "custom",
      type: "bar",
      compare_to_prev: false,
      time_unit: "day",
      metrics: "",
      aggregation: "sum",
      grouping1: "",
      grouping2: "",
      prefecture_filter: "",
      municipality_filter: "",
      category_filter: "",
      label_filter: "",
      display_order: 0,
      is_template: true,
      id: 0,
      created_at: "",
      created_by: 1,
      last_modified_at: "",
      last_modified_by: 1,
    }
  );

  const { data: chartCollectionsTemplate } = useQuery(
    ["chartCollectionsTemplate"],
    () => {
      return DataSvc.getChartCollections({
        sort: "name",
        limit: 50,
        is_template: true,
      });
    }
  );

  useEffect(() => {
    if (chartCollectionsTemplate) {
      const chartCollectionsTemplateDataTemp = chartCollectionsTemplate.map(
        (item: ChartCollectionData) => {
          return {
            collectionTemplate: item,
            chartList: [],
          };
        }
      );

      const fetchChartsData = async () => {
        await Promise.all(
          chartCollectionsTemplate.map(
            async (item: ChartCollectionData, index: number) => {
              const rawCharts = await DataSvc.getChartCollectionsByCollectionId(
                item.id.toString()
              );
              const sortedCharts = rawCharts.sort((chartOne, chartTwo) => {
                if (
                  (chartOne.grouping1 === null &&
                    chartTwo.grouping1 !== null) ||
                  (chartOne.grouping1 === "metric" &&
                    chartTwo.grouping1 !== "metric") ||
                  (chartOne.grouping1 === "municipality" &&
                    chartTwo.grouping1 === "category") ||
                  (chartOne.grouping2 === null && chartTwo.grouping2 !== null)
                ) {
                  return -1;
                }

                if (
                  chartOne.grouping1 === chartTwo.grouping1 &&
                  chartOne.grouping2 === chartTwo.grouping2
                ) {
                  return 0;
                }

                return 1;
              });
              const charts = indivFacilityMode
                ? // If indiv facility mode, only select line (time based) graphs without groupings
                  sortedCharts
                    .filter(
                      ({
                        type,
                        aggregation,
                        grouping1,
                        grouping2,
                      }: ChartData) => {
                        return (
                          type === "line" &&
                          aggregation === "sum" &&
                          grouping1 === null &&
                          grouping2 === null
                        );
                      }
                    )
                    .map((chart) => ({
                      ...chart,
                      name: chart.name.replace("全施設 ", ""),
                    }))
                : rawCharts;
              chartCollectionsTemplateDataTemp[index].chartList = charts;
            }
          )
        );
      };

      fetchChartsData().then(() => {
        setChartCollectionsTemplateData(
          _.cloneDeep(chartCollectionsTemplateDataTemp)
        );
      });
    }
    // eslint-disable-next-line
  }, [chartCollectionsTemplate]);

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent(`${isEdit ? "edit" : "create"}-chart`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Send GA event on create/delete chart
  const sendChartEventToGA = (chartName: string, action: string) => {
    ReactGA.event({
      category: "Chart Actions",
      action: action,
      label: chartName,
    });
  };

  // on Create Save
  const onCreateSave = () => {
    if (selectedTemplateChart) {
      onCreateSaveChart(
        labelName,
        selectedTemplateChart,
        selectedCollectionTemplateId
      );
      sendChartEventToGA(labelName, "Chart created");
    }
  };

  // is Enabled Btn
  const isEnabledBtn = () => {
    if (isEdit) {
      return !!labelName && labelName !== editingChartData.name;
    } else {
      return selectedCollectionTemplateId && selectedTemplateChart?.id;
    }
  };

  const generateTitle = () => {
    if (indivFacilityMode) {
      return t("set_indiv_fac_chart");
    }
    return t(isEdit ? "edit_chart" : "create_new_chart");
  };

  return (
    <div className="modal modal-default modal-create-edit-chart">
      <div className="modal-mains">
        <button
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        />
        <div className="modal-mains__body">
          <div className="top-title flex-grid">
            {generateTitle()}
            <div className="rights">
              {/* TODO: 
              <a className='btn btn-border'>
                {t(isEdit ? 'change_to_custom_chart' : 'create_custom_chart')}
              </a>
              */}
              {isEdit && (
                <a
                  className="icons icon-del"
                  onClick={() => {
                    setShownModalConfirm(true);
                  }}
                >
                  &nbsp;
                </a>
              )}
            </div>
          </div>
          {!indivFacilityMode && (
            <>
              <div className="blue-status-bar">
                <span className="blue-block">{t("title")}</span>
                <span className={`txt ${isEdit ? "red" : "gray"}`}>
                  {isEdit ? t("required") : t("optional")}
                </span>
              </div>
              <div className="title-bar">
                <div className="label-title flex">
                  {isEdit
                    ? t("up_to_40_chars")
                    : t("up_to_40_chars_use_default")}
                </div>
                <div className="input-area">
                  <InputBox
                    value={labelName}
                    placeholder={t("you_can_set_your_own_title")}
                    classNameContainer={"create-label-input"}
                    pattern="[\W\w]{0,40}"
                    onChange={() => {}}
                    onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                      setLabelName(
                        formValidationSvc.validateInputEnteringPattern(
                          event,
                          labelName
                        )
                      );
                    }}
                  />
                </div>
              </div>
            </>
          )}

          {!isEdit && (
            <>
              <div className="blue-status-bar">
                <span className="blue-block">{t("type")}</span>
                <span className="txt red">{t("required")}</span>
              </div>
              <div className="items-group">
                {!isEdit && (
                  <div className="choose-txt">
                    {t("choose_a_chart_for_analysis")}
                  </div>
                )}
                {chartCollectionsTemplateData.map((item, index) => (
                  <React.Fragment key={index}>
                    <ChartCategoryItem
                      selectedCollectionTemplateId={
                        selectedCollectionTemplateId
                      }
                      selectedTemplateChart={selectedTemplateChart}
                      itemData={item}
                      onSelectChart={(
                        collectionTemplateId: string,
                        chartItem: ChartData
                      ) => {
                        setSelectedCollectionTemplateId(collectionTemplateId);
                        setSelectedTemplateChart(chartItem);
                      }}
                    />
                  </React.Fragment>
                ))}
              </div>
            </>
          )}

          <div className="bottom-btns">
            <a
              className="btn btn-border"
              onClick={() => {
                onClose();
              }}
            >
              {t("cancel")}
            </a>
            <a
              className={`btn btn-blue ${!isEnabledBtn() ? "disabled" : ""}`}
              onClick={() => {
                onCreateSave();
              }}
            >
              {t(isEdit ? "save" : "create")}
            </a>
          </div>
        </div>
      </div>

      {shownModalConfirm && (
        <ModalConfirm
          title={"please_confirm"}
          cancelLabel={"cancel"}
          confirmLabel={"confirm"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            onDelete();
            sendChartEventToGA(labelName, "Chart deleted");
            setShownModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default ModalCreateEditChart;
