import { Dayjs } from "dayjs";

// compare Date
export const compareDate = (firstValue: string, secondValue: string) => {
  const firstStr = firstValue.split('-');
  const firstTime = new Date(parseInt(firstStr[0]), parseInt(firstStr[1]) - 1, parseInt(firstStr[2]));
  const firstTimes = firstTime.getTime();

  const secondStr = secondValue.split('-');
  const secondTime = new Date(parseInt(secondStr[0]), parseInt(secondStr[1]) - 1, parseInt(secondStr[2]));
  const secondTimes = secondTime.getTime();

  if (firstTimes >= secondTimes) {
    return false;
  } else {
    return true;
  }
};

export const percentage = (value: number, abs = false) => {
  if (Number.isNaN(value) || !Number.isFinite(value)) {
    return '-';
  }
  const percentage = abs ? Math.abs(value) : value;
  return percentage.toFixed(0);
}

export const combineDateAndTime = (date: Dayjs, time: Dayjs): Dayjs => {
  return date.set("hour", time.hour()).set("minute", time.minute()).set("second", time.second());
}