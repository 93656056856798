/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import React, { useEffect, useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";

import { LocationData } from "../../../models/LocationData";
import { MunicipalityData } from "../../../models/MunicipalityData";
import { OrganizationData } from "../../../models/OrganizationData";
import DataSvc from "../../../services/dataSvc";
import { modalViewGAEvent } from "../../../utils/ga";
import { Checkbox } from "../../FormElement/Checkbox";
import { InputBox } from "../../FormElement/InputBox";
import ModalAssignSelectionConfirm from "../ModalAssignSelectionConfirm";
import "./styles.scss";

export interface IModalAssignOrgProps {
  locationData: LocationData;
  onClose: () => void;
  onDelete: () => void;
  onUpdateAssignSelection: (
    registeredList: OrganizationData[],
    unregisteredList: OrganizationData[],
    isIndependentUse: boolean
  ) => void;
}

export const ModalAssignOrg: React.FunctionComponent<IModalAssignOrgProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityManagementPage.modalAssignOrg.${key}`);

  const [searchName, setSearchName] = useState<string>("");
  const [searchedName, setSearchedName] = useState<string>("");

  const [dataListColumnLikeStored, setDataListColumnLikeStored] = useState<
    OrganizationData[]
  >([]);
  const [dataListColumnOtherStored, setDataListColumnOtherStored] = useState<
    OrganizationData[]
  >([]);
  const [dataListColumnLikeShown, setDataListColumnLikeShown] = useState<
    OrganizationData[]
  >([]);
  const [dataListColumnOtherShown, setDataListColumnOtherShown] = useState<
    OrganizationData[]
  >([]);
  const [isIndependentUse, setIsIndependentUse] = useState<boolean>(false);
  const [actionType, setActionType] = useState<"delete" | "submit" | "">("");
  const [
    shownModalAssignSelectionConfirm,
    setShownModalAssignSelectionConfirm,
  ] = useState<boolean>(false); // false

  const { locationData, onClose, onDelete, onUpdateAssignSelection } = props;

  const { data: organizations } = useQuery(["organizations"], () => {
    return DataSvc.getOrganizations(
      _.pickBy({
        sort: "name",
        limit: 1000,
      })
    );
  });

  useEffect(() => {
    if (locationData) {
      setIsIndependentUse(locationData.independent_use);
    }
  }, [locationData]);

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent("assign-org");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (organizations) {
      const dataListColumnLikeShownTemp: OrganizationData[] = [];
      const dataListColumnOtherShownTemp: OrganizationData[] = [];

      organizations.list.forEach((item: OrganizationData) => {
        if (isMatchedSearch(item)) {
          let isLike = false;
          item.municipalities.forEach((municipalityItem: MunicipalityData) => {
            if (municipalityItem.name === locationData.prefecture) {
              isLike = true;
            }
          });

          if (isLike) {
            dataListColumnLikeShownTemp.push(item);
          } else {
            dataListColumnOtherShownTemp.push(item);
          }
        }
      });

      dataListColumnLikeShownTemp.forEach((item: OrganizationData) => {
        let matched = false;
        locationData.organizations?.forEach(
          (locationOrganizationItem: OrganizationData) => {
            if (item.id === locationOrganizationItem.id) {
              matched = true;
            }
          }
        );
        if (matched) {
          item.checked = true;
        }
      });

      dataListColumnOtherShownTemp.forEach((item: OrganizationData) => {
        let matched = false;
        locationData.organizations?.forEach(
          (locationOrganizationItem: OrganizationData) => {
            if (item.id === locationOrganizationItem.id) {
              matched = true;
            }
          }
        );
        if (matched) {
          item.checked = true;
        }
      });

      setDataListColumnLikeStored(_.cloneDeep(dataListColumnLikeShownTemp));
      setDataListColumnOtherStored(_.cloneDeep(dataListColumnOtherShownTemp));
      setDataListColumnLikeShown(_.cloneDeep(dataListColumnLikeShownTemp));
      setDataListColumnOtherShown(_.cloneDeep(dataListColumnOtherShownTemp));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData, organizations, searchedName]);

  // change Check Box
  const changeCheckBox = (
    checked: boolean,
    index: number,
    type: "Like" | "Other"
  ) => {
    if (type === "Like") {
      const dataListColumnLikeShownTemp = dataListColumnLikeShown;

      dataListColumnLikeShownTemp[index].checked = checked;

      setDataListColumnLikeShown(_.cloneDeep(dataListColumnLikeShownTemp));
    } else {
      const dataListColumnOtherShownTemp = dataListColumnOtherShown;

      dataListColumnOtherShownTemp[index].checked = checked;

      setDataListColumnOtherShown(_.cloneDeep(dataListColumnOtherShownTemp));
    }
  };

  // get Registered list
  const getRegisteredList = () => {
    const dataListTemp: OrganizationData[] = [];

    dataListColumnLikeShown.forEach((item: OrganizationData, index) => {
      if (
        (item.checked || false) !==
        (dataListColumnLikeStored[index].checked || false)
      ) {
        if (item.checked) {
          dataListTemp.push(item);
        }
      }
    });

    dataListColumnOtherShown.forEach((item: OrganizationData, index) => {
      if (
        (item.checked || false) !==
        (dataListColumnOtherStored[index].checked || false)
      ) {
        if (item.checked) {
          dataListTemp.push(item);
        }
      }
    });

    return dataListTemp;
  };

  // is Matched Search
  const isMatchedSearch = (organization: OrganizationData) => {
    return (
      organization.name.toLowerCase().indexOf(searchedName.toLowerCase()) > -1
    );
  };

  // get Unregistered list
  const getUnregisteredList = () => {
    const dataListTemp: OrganizationData[] = [];
    const orgIdSet = new Set();

    dataListColumnLikeShown.forEach((item: OrganizationData, index) => {
      if (
        (item.checked || false) !==
        (dataListColumnLikeStored[index].checked || false)
      ) {
        if (!item.checked) {
          dataListTemp.push(item);
          orgIdSet.add(item.id);
        }
      }
    });

    dataListColumnOtherShown.forEach((item: OrganizationData, index) => {
      if (
        (item.checked || false) !==
        (dataListColumnOtherStored[index].checked || false)
      ) {
        if (!item.checked) {
          dataListTemp.push(item);
          orgIdSet.add(item.id);
        }
      }
    });

    if (isIndependentUse && locationData.organizations.length > 0) {
      locationData.organizations.forEach((item: OrganizationData) => {
        if (!orgIdSet.has(item.id)) {
          dataListTemp.push(item);
        }
      });
    }

    return dataListTemp;
  };

  // get Municipalities Labels
  const getMunicipalitiesLabels = (organization: OrganizationData): string => {
    let str = "";

    organization.municipalities?.forEach((item: MunicipalityData, index) => {
      str += `${index === 0 ? "" : ", "}${item.name}`;
    });

    return str;
  };

  // get Location List Labels
  const getLocationListLabels = (locationList: OrganizationData[]) => {
    let str = "";

    locationList.forEach((item: OrganizationData, index) => {
      str += `${index === 0 ? "" : ", "}${item.name}`;
    });

    return str;
  };

  // on Click Submit
  const onClickSubmit = () => {
    setActionType("submit");
    setShownModalAssignSelectionConfirm(true);
  };

  // on Click Delete
  const onClickDelete = () => {
    setActionType("delete");
    setShownModalAssignSelectionConfirm(true);
  };

  // on Selection Confirm
  const onSelectionConfirm = () => {
    if (actionType === "delete") {
      onDelete();
    } else if (actionType === "submit") {
      onUpdateAssignSelection(
        getRegisteredList(),
        getUnregisteredList(),
        isIndependentUse
      );
    }
  };

  const uncheckAllItems = () => {
    const updatedLikeShown = dataListColumnLikeShown.map((item) => {
      if (item.checked) {
        return { ...item, checked: false };
      }
      return item;
    });

    const updatedOtherShown = dataListColumnOtherShown.map((item) => {
      if (item.checked) {
        return { ...item, checked: false };
      }
      return item;
    });

    setDataListColumnLikeShown(updatedLikeShown);
    setDataListColumnOtherShown(updatedOtherShown);
  };

  const onSetIsIndependentUse = (checked: boolean) => {
    if (checked) {
      uncheckAllItems();
    }
    setIsIndependentUse(checked);
  };

  const isEnableSubmit = () => {
    if (
      isIndependentUse !== locationData.independent_use ||
      (!isIndependentUse &&
        (getRegisteredList().length !== 0 ||
          getUnregisteredList().length !== 0))
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="modal modal-default modal-assign-org">
        <div className="modal-mains">
          <a
            aria-label="Close"
            className="btn-close"
            onClick={() => {
              onClose();
            }}
          ></a>
          <div className="top-title">{locationData.name}</div>
          <div className="top-sub-title">
            {t("locations")}: {locationData.prefecture}, {locationData.city}
          </div>
          <Checkbox
            checked={isIndependentUse}
            label={t("independent_use")}
            id={"checkbox-independent-use"}
            onChange={(checked: boolean) => {
              onSetIsIndependentUse(checked);
            }}
          />
          <div className="filter-bar flex-grid">
            <div className="lefts">
              <div className="title">{t("select_organizations")}</div>
            </div>
            <div className="rights">
              <InputBox
                value={searchName}
                placeholder={t("search_by_name")}
                isSearch={true}
                classNameContainer={"search-input"}
                onChange={(value: string) => {
                  setSearchName(value);
                }}
                onKeyPress={(event: KeyboardEvent<HTMLImageElement>) => {
                  if (event.key === "Enter") {
                    setSearchedName(searchName);
                  }
                }}
              />
            </div>
          </div>

          <div className={`overlay ${isIndependentUse ? "cover" : ""}`}>
            <div className={"content-selection-wrap"}>
              <div className="selection-wrap">
                {dataListColumnLikeShown.length > 0 && (
                  <div className="group-title">{t("likely_candidates")}</div>
                )}
                {dataListColumnLikeShown.map(
                  (item: OrganizationData, index) => (
                    <div className="items" key={index}>
                      <div className="checkbox-wrap have-txt">
                        <Checkbox
                          checked={item.checked || false}
                          label={item.name}
                          subLabel={`${getMunicipalitiesLabels(item)}`}
                          id={`checkbox-location-like-${index}`}
                          onChange={(checked: boolean) => {
                            changeCheckBox(checked, index, "Like");
                          }}
                        />
                      </div>
                    </div>
                  )
                )}
                {dataListColumnOtherShown.length > 0 && (
                  <div className="group-title">{t("others")}</div>
                )}
                {dataListColumnOtherShown.map(
                  (item: OrganizationData, index) => (
                    <div className="items" key={index}>
                      <div className="checkbox-wrap have-txt">
                        <Checkbox
                          checked={item.checked || false}
                          label={item.name}
                          disabled={isIndependentUse}
                          subLabel={`${getMunicipalitiesLabels(item)}`}
                          id={`checkbox-location-other-${index}`}
                          onChange={(checked: boolean) => {
                            changeCheckBox(checked, index, "Other");
                          }}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="registered-list">
            <div className="groups">
              <div className="color-block blue ">
                {t("tenants_to_be_registered")}
                <span className="number">{getRegisteredList().length}</span>
              </div>
              <p className="p-txt">
                {getLocationListLabels(getRegisteredList())}
              </p>
            </div>
            <div className="groups">
              <div className="color-block pink ">
                {t("tenants_to_be_unregistered")}
                <span className="number">{getUnregisteredList().length}</span>
              </div>
              <p className="p-txt">
                {getLocationListLabels(getUnregisteredList())}
              </p>
            </div>
          </div>
          <div className="bottom-btns">
            <a
              className="btn btn-red"
              onClick={() => {
                onClickDelete();
              }}
            >
              {t("delete")}
            </a>
            <a
              className="btn btn-border"
              onClick={() => {
                onClose();
              }}
            >
              {t("cancel")}
            </a>
            <a
              className={`btn btn-blue ${isEnableSubmit() ? "" : "disabled"}`}
              onClick={() => {
                onClickSubmit();
              }}
            >
              {t("submit")}
            </a>
          </div>
        </div>
      </div>
      {shownModalAssignSelectionConfirm && (
        <ModalAssignSelectionConfirm
          onClose={() => {
            setShownModalAssignSelectionConfirm(false);
          }}
          onSelectionConfirm={() => {
            onSelectionConfirm();
            setShownModalAssignSelectionConfirm(false);
          }}
        />
      )}
    </>
  );
};

export default ModalAssignOrg;
