/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import {useTranslation} from 'react-i18next';

import { modalViewGAEvent } from '../../utils/ga';
import './styles.scss';

export interface IModalPrivacyPolicyAndTermsServiceProps {
  type: string;
  onClose: () => void
}

export const ModalPrivacyPolicyAndTermsService: React.FunctionComponent<IModalPrivacyPolicyAndTermsServiceProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`modalPrivacyPolicyAndTermsService.${key}`)

  const { type, onClose } = props;

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent(`t-and-c-${type}`);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps  

  return (    
    <div className="modal modal-default modal-privacy-policy-and-terms-service">
      <div className="modal-mains">
        <a className='btn-close'
          onClick={() => {
            onClose()
          }}>
        </a>
        <div className="top-title">
          {t(`${type}.title`)}
        </div>
        <div className="content"
          dangerouslySetInnerHTML={{ __html: t(`${type}.content`) }}>
          
        </div>
        <div className="bottom-btns">
          <a className='btn btn-blue'
            onClick={() => {
              onClose()
            }}>
            {t('close')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalPrivacyPolicyAndTermsService;
