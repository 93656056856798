import dayjs from "dayjs";
import React from "react";
import { NavLink } from "react-router-dom";
import "./styles.scss";

import { useMe } from "../../../hooks/queries";
import { MediaData } from "../../../models/MediaData";

export interface IMediaItemProps {
  mediaItem: MediaData | null;
}

export const MediaItem: React.FunctionComponent<IMediaItemProps> = (props) => {
  const { mediaItem } = props;
  const { data: me } = useMe();

  if (!mediaItem) return null;

  return (
    <NavLink
      to={
        me?.organization_id
          ? `/location/${mediaItem.location_id}/organization/${me?.organization_id}`
          : `/location/${mediaItem.location_id}`
      }
      className="media-item"
      key={mediaItem.id}
    >
      <div className="media-top-info">
        <div className="left-title">{mediaItem.location_name}</div>
        <div className="right-title">
          {dayjs(mediaItem.post_date).format("YYYY-MM-DD")}
        </div>
      </div>
      <div className="media-img-container">
        <img className="media-img" alt="media" src={mediaItem.url} />
      </div>
    </NavLink>
  );
};

export default MediaItem;
