import _ from "lodash";
import React, { useEffect, useState, ChangeEvent, KeyboardEvent } from "react";

import formValidationSvc from "../../services/formValidationSvc";
import { InputBox } from "../FormElement/InputBox";

import "./styles.scss";

export interface IPaginationProps {
  filterFormData: any;
  totalRowsNumber: number;
  onChangeFilterFormData: (filterFormData: any) => void;
}

export const Pagination: React.FunctionComponent<IPaginationProps> = (
  props
) => {
  const { filterFormData, totalRowsNumber, onChangeFilterFormData } = props;

  const [pageIndex, setPageIndex] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const changeFilterFormDataWithScroll = (filterFormData: any) => {
    const table = document.querySelector(".table-data");
    if (table) {
      table.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
    onChangeFilterFormData(filterFormData);
  };

  useEffect(() => {
    if (filterFormData) {
      setPageIndex(filterFormData.pageIndex.toString());
    }
  }, [filterFormData]);

  // get Total Pages
  const getTotalPages = () => {
    const total = Math.ceil(totalRowsNumber / filterFormData.numberPerPage);
    return _.isNaN(total) ? 1 : total;
  };

  return (
    <div className="pagenation">
      <div className="center-page">
        <button
          className={`icons btn-prev ${
            filterFormData.pageIndex <= 1 ? "disabled" : ""
          }`}
          onClick={() => {
            changeFilterFormDataWithScroll({
              ...filterFormData,
              pageIndex: filterFormData.pageIndex - 1,
            });
          }}
        ></button>
        <div className="page-area flex">
          <InputBox
            value={pageIndex}
            pattern="[1-9][0-9]{0,9}"
            placeholder={"1"}
            classNameContainer={"number-input"}
            isFocused={isFocused}
            onChange={() => {}}
            onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
              setPageIndex(
                formValidationSvc.validateInputEnteringPattern(event, pageIndex)
              );
            }}
            onKeyPress={(event: KeyboardEvent<HTMLImageElement>) => {
              if (event.key === "Enter") {
                if (!pageIndex || Number(pageIndex) > getTotalPages()) {
                  return;
                }
                changeFilterFormDataWithScroll({
                  ...filterFormData,
                  pageIndex: parseInt(pageIndex),
                });
              }
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
          />
          <div className="total-num">
            / {getTotalPages() <= 0 ? 1 : getTotalPages()}
          </div>
        </div>
        <button
          className={`icons btn-next ${
            filterFormData.pageIndex >= getTotalPages() ? "disabled" : ""
          }`}
          onClick={() => {
            changeFilterFormDataWithScroll({
              ...filterFormData,
              pageIndex: filterFormData.pageIndex + 1,
            });
          }}
        ></button>
      </div>
    </div>
  );
};

export default Pagination;
