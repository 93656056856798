/* eslint-disable jsx-a11y/anchor-is-valid */
import { isArray } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  SendMessageLocationData,
  SummaryLocation
} from "../../../containers/SendMessageScreens";
import {
  FacilityFilterConditionData,
  hasConditionFilter
} from "../../../models/FacilityFilterConditionData";
import { LocationData } from "../../../models/LocationData";
import { CurrentFilters } from "../../FacilityListComponents/CurrentFilters/CurrentFilters";
import SummaryLocationList from "../SummaryLocationList";

import "./styles.scss";

export interface ILocationsModuleProps {
  isCustomFilterMode: boolean;
  isSelectableLocations: boolean;
  conditionFormData?: FacilityFilterConditionData;
  locationData: SendMessageLocationData;
  onOpenFilters?: () => void;
  onSelectedLocations?: (selectedLocationds: Array<SummaryLocation>) => void;
}

export const LocationsDisplay: React.FunctionComponent<
  ILocationsModuleProps
> = ({
  isCustomFilterMode,
  isSelectableLocations,
  conditionFormData,
  locationData,
  onOpenFilters,
  onSelectedLocations
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`modalSendMessage.${key}`);

  const filterApplied =
    conditionFormData && hasConditionFilter(conditionFormData);

  if (!isArray(locationData)) {
    // Send message to individual location
    return (
      <div className="top-title">
        {_t("modalSendMessage.send_message_to", {
          locationTitle: (locationData as LocationData).name
        })}
      </div>
    );
  }

  return (
    <>
      {isCustomFilterMode && (
        <>
          <div className="top-title">{t("send_custom_message")}</div>
          {filterApplied && (
            <CurrentFilters
              conditionFormData={
                conditionFormData as FacilityFilterConditionData
              }
            />
          )}
          <button className="btn btn-blue" onClick={onOpenFilters}>
            {t("choose_filters")}
          </button>
          {filterApplied && <hr />}
        </>
      )}
      {!(isCustomFilterMode && !filterApplied) && (
        <>
          <div className="top-title">{t("locations_to_send_to")}</div>
          <SummaryLocationList
            isSelectable={isSelectableLocations}
            locations={locationData as Array<SummaryLocation>}
            onSelectedLocations={(data) => {
              if (onSelectedLocations) {
                onSelectedLocations(data);
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default LocationsDisplay;
