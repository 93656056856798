import React from 'react';
import './styles.scss';

export interface IProgressBarProps {
  value: number;
}

export const ProgressBar: React.FunctionComponent<IProgressBarProps> = (props) => {  
  const { value } = props;

  return (
    <div className='progress-bg'>
      <div className='bar-blue'
        style={{ width: `${value}%` }}>
      </div>
    </div>
  );
};

export default ProgressBar;
