import React from 'react';
import './styles.scss';

export interface ICheckboxProps {
  checked: boolean;
  label: string;
  isSelectAll?: boolean;
  disabled?: boolean;
  subLabel?: string;
  id: string;
  onChange: (checked: boolean) => void;
}

export const Checkbox: React.FunctionComponent<ICheckboxProps> = (props) => {
  const { checked, label, isSelectAll, subLabel, id, disabled = false } = props;
  return (
    <div className={`checkbox-wrap ${label !== '' ? 'have-txt' : ''} ${isSelectAll ? 'checkbox-select-all' : ''}`}>
      <input type="checkbox"
        checked={checked}
        disabled={disabled}
        id={id}
        onChange={(evt) => props.onChange(evt.target.checked)}/>
      <label htmlFor={id}>
        <span className='main-label'>{label}</span>
        {!!subLabel && (
          <span className='little'>
            {subLabel}
          </span>
        )}
      </label>
    </div>
  );
};

export default Checkbox;
