/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FacilityManagementFilterConditionData } from "../../../models/FacilityFilterConditionData";
import { OrganizationData } from "../../../models/OrganizationData";
import { ProfBooleanData } from "../../../models/ProfBooleanData";
import DataSvc from "../../../services/dataSvc";
import { modalViewGAEvent } from "../../../utils/ga";
import IfOrCondition from "../../FacilityListComponents/IfOrCondition";
import ItemCondition from "../../FacilityListComponents/ItemCondition";
import { ModalConfirm } from "../../ModalConfirm";
import "../../FacilityListComponents/ModalFilterCondition/styles.scss";

export interface IModalFilterConditionProps {
  conditionFormData: FacilityManagementFilterConditionData;
  onClose: () => void;
  onApply: (conditionFormData: FacilityManagementFilterConditionData) => void;
}

export const ModalFilterCondition: React.FunctionComponent<
  IModalFilterConditionProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityManagementPage.modalFilterCondition.${key}`);

  const { onClose, onApply } = props;

  const [conditionFormData, setConditionFormData] =
    useState<FacilityManagementFilterConditionData>({
      organizations: [],
      isArchived: undefined
    });

  const [selectAll, setSelectAll] = useState<any>({
    organizations: false
  });

  const [organizationsData, setOrganizationsData] = useState<
    OrganizationData[]
  >([]);

  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false); // false

  const [isArchived, setIsArchived] = useState<ProfBooleanData>({
    key: "is_archived",
    shouldInclude: props.conditionFormData.isArchived?.shouldInclude
  });

  const { data: organizations } = useQuery(["organizations"], () => {
    return DataSvc.getOrganizations(
      _.pickBy({
        sort: "name",
        limit: 1000
      })
    );
  });

  useEffect(() => {
    if (organizations) {
      if (props.conditionFormData.organizations) {
        _.intersectionBy(
          organizations.list,
          props.conditionFormData.organizations,
          "id"
        ).forEach((item) => (item.checked = true));
      }
      setOrganizationsData(organizations.list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]);

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent("facility-manage");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // change Checkbox
  const changeCheckbox = (value: boolean, index: number, name: string) => {
    switch (name) {
      case "organizations":
        const organizationsDataTemp = organizationsData;
        organizationsDataTemp[index].checked = value;
        setOrganizationsData(_.cloneDeep(organizationsDataTemp));
        break;
    }
  };

  // change Select All
  const changeSelectAll = (value: boolean, name: string) => {
    const selectAllTemp = selectAll;

    switch (name) {
      case "organizations":
        selectAllTemp.organizations = value;
        const organizationsDataTemp = organizationsData;
        organizationsDataTemp.forEach((item) => {
          item.checked = value;
        });
        setOrganizationsData(_.cloneDeep(organizationsDataTemp));
        break;
    }

    setSelectAll(_.cloneDeep(selectAllTemp));
  };

  // on Clear
  const onClear = () => {
    changeSelectAll(false, "organizations");
  };

  const onClickApply = () => {
    const conditionFormDataTemp = conditionFormData;

    if (isArchived.shouldInclude !== undefined) {
      conditionFormDataTemp.isArchived = isArchived;
    }

    conditionFormDataTemp.organizations = organizationsData.filter(function (
      item: OrganizationData
    ) {
      return item.checked;
    });

    setConditionFormData(_.cloneDeep(conditionFormDataTemp));

    onApply(_.cloneDeep(conditionFormDataTemp));
  };

  // Change archived
  const changeArchived = (key: string, nextValue: boolean | undefined) => {
    setIsArchived({
      key,
      shouldInclude: nextValue
    });
  };

  return (
    <div className="filter-panel">
      <div className="filter-mains">
        <a
          className="icons btn-close"
          onClick={() => {
            onClose();
          }}
        ></a>
        <div className="top-title flex-grid">
          <div className="titles">{t("filter_condition")}</div>
          <div className="rights">
            <a
              href="#javascript"
              className="btn btn-border"
              onClick={(event) => {
                setShownModalConfirm(true);
                event.preventDefault();
              }}
            >
              {t("clear")}
            </a>
          </div>
        </div>
        <div className="check-full-wrap">
          {isArchived && (
            <div className="check-groups">
              <div className="blue-block-row flex">
                <div className="blue-block">{t("is_archived")}</div>
              </div>
              <div className="check-groups-horizontal">
                <IfOrCondition
                  isBlueTitle={false}
                  item={isArchived}
                  changeCheckbox={changeArchived}
                />
              </div>
            </div>
          )}
          <ItemCondition
            title={t("organizations")}
            checkedAll={selectAll.organizations}
            sectionName={"organizations"}
            isBlueTitle={true}
            dataList={organizationsData}
            changeSelectAll={(checked: boolean, sectionName: string) => {
              changeSelectAll(checked, sectionName);
            }}
            changeCheckbox={(
              checked: boolean,
              index: number,
              sectionName: string
            ) => {
              changeCheckbox(checked, index, sectionName);
            }}
          />
        </div>
        <div className="bottom-btns">
          <a
            className="btn btn-border"
            onClick={() => {
              onClose();
            }}
          >
            {t("cancel")}
          </a>
          <a
            className="btn btn-blue"
            onClick={(event) => {
              onClickApply();
              event.preventDefault();
            }}
          >
            {t("apply")}
          </a>
        </div>
      </div>

      {shownModalConfirm && (
        <ModalConfirm
          title={"please_confirm_to_reset"}
          cancelLabel={"no-go"}
          confirmLabel={"go"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            onClear();
            setShownModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default ModalFilterCondition;
