import * as types from '../constants/actionTypes';
import { AccountData } from '../models/AccountData';
import { CategoryData } from '../models/CategoryData';
import { LabelData } from '../models/LabelData';
import { LocationData } from '../models/LocationData';
import { MunicipalityData } from '../models/MunicipalityData';
import { OrgRecommendationData } from '../models/OrgRecommendationData';
import { OrgStatData } from '../models/OrgStatData';
import { OrgStatusData } from '../models/OrgStatusData';

// loads Accounts Me Data
export function loadAccountsMeData(data: AccountData) {
  return {type: types.LOAD_ACCOUNTS_ME_DATA, data};
}

// loads Labels Data
export function loadLabelsData(data: LabelData[]) {
  return {type: types.LOAD_LABELS_DATA, data};
}

// loads Categories Data
export function loadCategoriesData(data: CategoryData[]) {
  return {type: types.LOAD_CATEGORIES_DATA, data};
}

// loads Municipalities Data
export function loadMunicipalitiesData(data: MunicipalityData[]) {
  return {type: types.LOAD_MUNICIPALITIES_DATA, data};
}

// loads Locations Data
export function loadLocationsData(data: LocationData[]) {
  return {type: types.LOAD_LOCATIONS_DATA, data};
}

// loads Location Data
export function loadLocationData(data: LocationData) {
  return {type: types.LOAD_LOCATION_DATA, data};
}

// loads Location Recommendations Data
export function loadLocationRecommendationsData(data: OrgRecommendationData[]) {
  return {type: types.LOAD_LOCATION_RECOMMENDATIONS_DATA, data};
}

export function loadingChange(data: boolean) {
  return {type: types.LOADING_CHANGE, data};
}

// loads Stats Prime Data
export function loadStatsPrimeData(data: OrgStatData) {
  return {type: types.LOAD_STATS_PRIME_DATA, data};
}

// loads Stats Status Data
export function loadStatsStatusData(data: OrgStatusData) {
  return {type: types.LOAD_STATS_STATUS_DATA, data};
}

const exportItem = {
};

export default exportItem;
