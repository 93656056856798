import dayjs from "dayjs";
import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import {
  ChartData,
  OrgGroupAggregationMetricsData,
  OrgTimeSeriesMetricsData,
} from "../../../../models/ChartCollectionData";
import { getFromToDate } from "../../../../utils/chartPeriodUtils";
import { compileToDataSource as compileToDatasourceBar } from "../BarChart/utils";
import { compileToDataSource as compileToDataSourcePie } from "../CircleChart/utils";
import {
  DataSource,
  compileToDataSource,
  createDataGroups,
} from "../LineChart/utils";
import { compileToDataSource as compileToDataSourceTable } from "../TableChart/utils";

export interface IDownloadCsvButtonProps {
  chartData: ChartData;
  chartDetailsData: OrgGroupAggregationMetricsData;
  chartDetailsDataForLines: OrgTimeSeriesMetricsData[];
  prevYearChartDetailsDataForLines: OrgTimeSeriesMetricsData[];
}

const chooseLineDataSet = (
  chartDetailsData: OrgTimeSeriesMetricsData[],
  dataSourceConfig: DataSource[],
  isLastYear: boolean,
  chartData: ChartData
) => {
  const { chartDataDaysTemp, chartDataWeeksTemp, chartDataMonthsTemp } =
    createDataGroups(chartDetailsData, dataSourceConfig, isLastYear);
  if (chartData.time_unit === "day") {
    return chartDataDaysTemp;
  } else if (chartData.time_unit === "week") {
    return chartDataWeeksTemp;
  } else {
    return chartDataMonthsTemp;
  }
};

const downloadTableToCsv = (
  chartDetailsData: OrgGroupAggregationMetricsData
) => {
  const [chartRowDataTemp, columnArrayTemp] =
    compileToDataSourceTable(chartDetailsData);

  let csvContent = `,${columnArrayTemp.join(",")},Total\n`;
  chartRowDataTemp.forEach((rowItem) => {
    const rowValues = rowItem.values
      .map((value: string) => parseInt(value))
      .join(",");
    const rowTotal = rowItem.values.reduce(
      (acc: number, value: string) => acc + parseInt(value),
      0
    );
    csvContent += `${rowItem.name},${rowValues},${rowTotal}\n`;
  });

  const columnTotals = columnArrayTemp
    .map((_, colIndex: number) => {
      return chartRowDataTemp.reduce(
        (acc: number, rowItem) => acc + parseInt(rowItem.values[colIndex]),
        0
      );
    })
    .join(",");

  const grandTotal = chartRowDataTemp.reduce((acc: number, rowItem) => {
    return (
      acc +
      rowItem.values.reduce(
        (acc: number, value: string) => acc + parseInt(value),
        0
      )
    );
  }, 0);

  csvContent += `Total,${columnTotals},${grandTotal}\n`;

  return csvContent;
};

const downloadBarGraphToCsv = (
  chartDetailsData: OrgGroupAggregationMetricsData
) => {
  let csvContent = "";
  const dataSourceTmp = compileToDatasourceBar(chartDetailsData);

  const unit = dataSourceTmp.dataSources[0].label;
  const csvRows = [["Name", `${unit}`]].concat(
    dataSourceTmp.dataForLine.map((d: any) => [d.name])
  );

  dataSourceTmp.dataForLine.forEach(
    (item: { value: any; name: string }, index: number) => {
      csvRows[index + 1].push(`${item.value}`);
    }
  );

  csvContent += csvRows.join("\n");
  return csvContent;
};

const downloadPieGraphToCsv = (
  charDetailData: OrgGroupAggregationMetricsData
) => {
  let csvContent = "";

  const dataSourceTmp = compileToDataSourcePie(charDetailData);

  const csvRows = [["Type", `${dataSourceTmp[0]?.unit || ""}`, "%"]].concat(
    dataSourceTmp.map((d) => [d.name])
  );

  const total = dataSourceTmp.reduce((acc, item) => acc + item.data, 0);
  csvRows.push(["total", `${total}`, "100%"]);

  dataSourceTmp.forEach((item, index) => {
    const { data } = item;
    csvRows[index + 1].push(`${data}`);
    csvRows[index + 1].push(`${((data / total) * 100).toFixed(2)}%`);
  });
  csvContent += csvRows.join("\n");
  return csvContent;
};

const downloadLineGraphToCsv = (
  chartData: ChartData,
  chartDetailsDataForLines: OrgTimeSeriesMetricsData[],
  prevYearChartDetailsDataForLines: OrgTimeSeriesMetricsData[]
) => {
  const skipLabelTranslation = !!(chartData.grouping1 || chartData.grouping2);
  const dataSourceConfig = compileToDataSource(
    chartDetailsDataForLines,
    skipLabelTranslation,
    false
  );

  const dataSet = chooseLineDataSet(
    chartDetailsDataForLines,
    dataSourceConfig,
    false,
    chartData
  );

  let csvContent = "";
  const csvRows = [["Date"]].concat(dataSet.dataSources.map((d) => [d.label]));

  dataSet.dataForLine.forEach((item) => {
    const { date, name, ...rest } = item;
    csvRows[0].push(`${name}`);

    Object.keys(rest).forEach((key, index) => {
      csvRows[index + 1].push(`${item[key]}`);
    });
  });
  csvContent += csvRows.join("\n");

  if (chartData.compare_to_prev && !!prevYearChartDetailsDataForLines) {
    csvContent += "\n";
    const prevYearDataConfig = compileToDataSource(
      prevYearChartDetailsDataForLines,
      skipLabelTranslation,
      true
    );

    const prevYearRows = prevYearDataConfig.map((d) => [d.label]);
    const prevYearDataSet = chooseLineDataSet(
      prevYearChartDetailsDataForLines,
      prevYearDataConfig,
      true,
      chartData
    );

    prevYearDataSet.dataForLine.forEach((item) => {
      const { date, name, ...rest } = item;
      Object.keys(rest).forEach((key, index) => {
        prevYearRows[index].push(`${item[key]}`);
      });
    });

    csvContent += prevYearRows.join("\n");
  }

  return csvContent;
};

export const DownloadCsvButton: React.FunctionComponent<
  IDownloadCsvButtonProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.collectionChartArea.${key}`);

  const {
    chartData,
    chartDetailsDataForLines,
    prevYearChartDetailsDataForLines,
    chartDetailsData,
  } = props;

  const downloadToCsv = () => {
    let csv = "data:text/csv;charset=utf-8,";
    let chartTitle = chartData.name;

    let periodStart;
    let periodEnd;

    const { fromDateStr, toDateStr } = getFromToDate(chartData.period_option);

    if (chartData.period_option === "custom") {
      periodStart = chartData.period_start;
      periodEnd = chartData.period_end;
    } else {
      periodStart = fromDateStr
        ? fromDateStr.format("YYYY-MM-DD")
        : dayjs().endOf("day").subtract(20, "days").format("YYYY-MM-DD");

      periodEnd = toDateStr
        ? toDateStr.format("YYYY-MM-DD")
        : dayjs().endOf("day").format("YYYY-MM-DD");
    }

    if (chartData.type === "line") {
      csv += downloadLineGraphToCsv(
        chartData,
        chartDetailsDataForLines,
        prevYearChartDetailsDataForLines
      );
      chartTitle += ` ${periodStart} - ${periodEnd}`;
    } else if (chartData.type === "pie") {
      csv += downloadPieGraphToCsv(chartDetailsData);
      chartTitle += ` ${periodStart} - ${periodEnd}`;
    } else if (chartData.type === "bar") {
      csv += downloadBarGraphToCsv(chartDetailsData);
      chartTitle += ` ${periodStart} - ${periodEnd}`;
    } else if (chartData.type === "table") {
      csv += downloadTableToCsv(chartDetailsData);
    }

    const encodedUri = encodeURI(csv);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${chartTitle}.csv`);
    document.body.appendChild(link);
    link.click();

    ReactGA.event({
      category: "analysis-chart",
      action: "Download chart data",
    });
  };

  return (
    <button className="btn btn-border" onClick={downloadToCsv}>
      <i className="icons icon-saveload icon-saveload-blue"></i>
      {t("to_csv")}
    </button>
  );
};
