/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { ImportLocationsLocationData } from "../../../models/LocationData";
import { modalViewGAEvent } from "../../../utils/ga";
import { getMainCategory } from "../../../utils/getMainCategory";
import { Checkbox } from "../../FormElement/Checkbox";
import ModalFacilitySelectionConfirm from "../ModalFacilitySelectionConfirm";
import "./styles.scss";

export interface IModalFacilitySelectionProps {
  dataList: ImportLocationsLocationData[];
  onClose: () => void;
  onUpdateFacilitySelection: (
    registeredList: ImportLocationsLocationData[],
    unregisteredList: ImportLocationsLocationData[]
  ) => void;
}

export const ModalFacilitySelection: React.FunctionComponent<
  IModalFacilitySelectionProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityRegistrationPage.modalFacilitySelection.${key}`);

  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);

  const [dataListColumnStored, setDataListColumnStored] = useState<
    ImportLocationsLocationData[][]
  >([]);
  const [dataListColumnShown, setDataListColumnShown] = useState<
    ImportLocationsLocationData[][]
  >([]);

  const [
    shownModalFacilitySelectionConfirm,
    setShownModalFacilitySelectionConfirm,
  ] = useState<boolean>(false); // false

  const { dataList, onClose, onUpdateFacilitySelection } = props;

  useEffect(() => {
    if (dataList) {
      const dataListColumnShownTemp = [];

      let index = 0;
      while (index < dataList.length) {
        dataListColumnShownTemp.push(dataList.slice(index, (index += 7)));
      }

      dataListColumnShownTemp.forEach((item: ImportLocationsLocationData[]) => {
        item.forEach((subItem) => {
          if (
            subItem.front_end_status === "Registered" ||
            subItem.front_end_status === "Confirming"
          ) {
            subItem.checked = true;
          }
        });
      });

      setDataListColumnStored(_.cloneDeep(dataListColumnShownTemp));
      setDataListColumnShown(_.cloneDeep(dataListColumnShownTemp));
    }
  }, [dataList]);

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent("facility-manage");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // change Check Box
  const changeCheckBox = (
    checked: boolean,
    index: number,
    subIndex: number
  ) => {
    const dataListColumnShownTemp = dataListColumnShown;

    dataListColumnShownTemp[index][subIndex].checked = checked;

    setDataListColumnShown(_.cloneDeep(dataListColumnShownTemp));
  };

  // change All Check Box
  const changeAllCheckBox = (checked: boolean) => {
    const dataListColumnShownTemp = dataListColumnShown;

    dataListColumnShownTemp.forEach((item: ImportLocationsLocationData[]) => {
      item.forEach((subItem) => {
        subItem.checked = checked;
      });
    });

    setDataListColumnShown(_.cloneDeep(dataListColumnShownTemp));
  };

  // get Registered list
  const getRegisteredList = () => {
    const dataListTemp: ImportLocationsLocationData[] = [];

    dataListColumnShown.forEach(
      (item: ImportLocationsLocationData[], index) => {
        item.forEach((subItem, subIndex) => {
          if (
            (subItem.checked || false) !==
            (dataListColumnStored[index][subIndex].checked || false)
          ) {
            if (subItem.checked) {
              dataListTemp.push(subItem);
            }
          }
        });
      }
    );

    return dataListTemp;
  };

  // get Unregistered list
  const getUnregisteredList = () => {
    const dataListTemp: ImportLocationsLocationData[] = [];

    dataListColumnShown.forEach(
      (item: ImportLocationsLocationData[], index) => {
        item.forEach((subItem, subIndex) => {
          if (
            (subItem.checked || false) !==
            (dataListColumnStored[index][subIndex].checked || false)
          ) {
            if (!subItem.checked) {
              dataListTemp.push(subItem);
            }
          }
        });
      }
    );

    return dataListTemp;
  };

  // get Location List Labels
  const getLocationListLabels = (
    locationList: ImportLocationsLocationData[]
  ) => {
    let str = "";

    locationList.forEach((item: ImportLocationsLocationData, index) => {
      str += `${index === 0 ? "" : ", "}${item.name}`;
    });

    return str;
  };

  // on Submit
  const onSubmit = () => {
    if (getUnregisteredList().length > 0) {
      setShownModalFacilitySelectionConfirm(true);
    } else {
      onUpdate();
    }
  };

  // on Selection Confirm
  const onSelectionConfirm = () => {
    onUpdate();
  };

  // on Update
  const onUpdate = () => {
    onUpdateFacilitySelection(getRegisteredList(), getUnregisteredList());
  };

  return (
    <>
      <div className="modal modal-default modal-facility-selection">
        <div className="modal-mains">
          <button className="btn-close" onClick={onClose}></button>
          <div className="top-title">
            {t("select_facilities_to_register_in_area_compass")}
          </div>
          <div className="filter-bar flex-grid">
            <div className="lefts">
              {/* TODO:
              <div className={`filter-wrap`}>
                <div className="filter-some-block">
                  <a className='btn btn-filter'>
                    <i className='icons icon-filter'></i>
                    <span className='txt'>
                      {t('filter')}
                    </span>
                  </a>
                  <div className="after-info">
                    
                  </div>
                </div>
              </div>
              */}
            </div>
            <div className="rights">
              <Checkbox
                checked={isCheckedAll}
                label={t("select_all")}
                id={`checkbox-${"check box"}`}
                onChange={(checked: boolean) => {
                  ReactGA.event({
                    category: "Register facility modal",
                    action: "Toggle facilities",
                    label: "all",
                    value: checked ? 0 : 1, // value === true is removing checkbox
                  });
                  setIsCheckedAll(checked);
                  changeAllCheckBox(checked);
                }}
              />
            </div>
          </div>
          <div className="content-selection-wrap ">
            <div className="row">
              {dataListColumnShown.map(
                (item: ImportLocationsLocationData[], index) => (
                  <div className="col col-md-3" key={index}>
                    <ul className="lists">
                      {item.map(
                        (subItem: ImportLocationsLocationData, subIndex) => (
                          <li key={subIndex}>
                            <Checkbox
                              checked={subItem.checked || false}
                              label={subItem.name}
                              subLabel={`${subItem.city}, ${getMainCategory(
                                subItem
                              )}`}
                              id={`checkbox-location-${index}-${subIndex}`}
                              onChange={(checked: boolean) => {
                                changeCheckBox(checked, index, subIndex);
                              }}
                            />
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="registered-list">
            <div className="groups">
              <div className="color-block blue ">
                {t("facilities_to_be_registered")}
                <span className="number">{getRegisteredList().length}</span>
              </div>
              <p className="p-txt">
                {getLocationListLabels(getRegisteredList())}
              </p>
            </div>
            <div className="groups">
              <div className="color-block pink ">
                {t("facilities_to_be_unregistered")}
                <span className="number">{getUnregisteredList().length}</span>
              </div>
              <p className="p-txt">
                {getLocationListLabels(getUnregisteredList())}
              </p>
            </div>
          </div>
          <div className="bottom-btns">
            <a
              className="btn btn-border"
              onClick={() => {
                onClose();
              }}
            >
              {t("cancel")}
            </a>
            <a
              className="btn btn-blue"
              onClick={() => {
                onSubmit();
              }}
            >
              {t("submit")}
            </a>
          </div>
        </div>
      </div>
      {shownModalFacilitySelectionConfirm && (
        <ModalFacilitySelectionConfirm
          dataList={getUnregisteredList()}
          onClose={() => {
            setShownModalFacilitySelectionConfirm(false);
          }}
          onSelectionConfirm={() => {
            onSelectionConfirm();
            setShownModalFacilitySelectionConfirm(false);
          }}
        />
      )}
    </>
  );
};

export default ModalFacilitySelection;
