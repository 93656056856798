import React from "react";

import {
  canCreateEventPost,
  canCreateOfferPost,
  canCreateStandardPost,
} from "./utils";
import { LocationData } from "../../../../models/LocationData";
import DropdownSelect from "../../../FormElement/DropdownSelect";
import { LocalPostTopicType } from "../utils";

export interface ITopicTypeSelectProps {
  topicType: LocalPostTopicType;
  changeTopicType: (value: LocalPostTopicType) => void;
  locationData: LocationData | undefined;
}

export const TopicTypeSelect: React.FunctionComponent<
  ITopicTypeSelectProps
> = ({ topicType, changeTopicType, locationData }) => {
  const conditions = [
    { check: canCreateStandardPost, type: LocalPostTopicType.STANDARD },
    { check: canCreateEventPost, type: LocalPostTopicType.EVENT },
    { check: canCreateOfferPost, type: LocalPostTopicType.OFFER },
  ];

  const items: LocalPostTopicType[] = conditions
    .filter(({ check }) => check(locationData))
    .map(({ type }) => type);

  return (
    <DropdownSelect
      isTranslation={true}
      translationKey="analysisCollectionsPage.modalCreateLocalPost.createLocalPostForm.topicTypes"
      value={topicType}
      items={items}
      onChange={changeTopicType}
    />
  );
};
