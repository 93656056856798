import _ from "lodash";

import i18n from "../../i18n";
import { checkFormatEmail } from "../../utils/formatValidation";

export interface EmailState {
  messageTitle: string;
  messageText: string;
  replyEmail: string;
  messageTitleError?: string;
  messageTextError?: string;
  replyEmailError?: string;

  // Currently only used for massSendEmails for admins
  // Hidden when used in SendMessageScreens
  bccTo: string;
  bccToError?: string;
}

export const initialEmailState: EmailState = {
  messageTitle: "",
  messageText: "",
  replyEmail: "",
  bccTo: "",
  messageTitleError: undefined,
  messageTextError: undefined,
  replyEmailError: undefined,
  bccToError: undefined
};

export const validateEmailForm = (
  emailState: EmailState,
  setSendMessageVariablesCallback: (emailState: EmailState) => void,
  successCallback: () => void,
  setStateCallback?: () => void
) => {
  const { messageTitle, messageText, replyEmail, bccTo } = emailState;
  const variableHolder = {
    ...emailState
  };

  variableHolder.messageTitleError =
    messageTitle.length === 0
      ? i18n.t("modalSendMessage.please_set_title")
      : undefined;

  variableHolder.messageTextError =
    messageText.length <= 10
      ? i18n.t("modalSendMessage.please_set_message")
      : undefined;

  variableHolder.replyEmailError =
    !!replyEmail && !checkFormatEmail(replyEmail)
      ? i18n.t("modalSendMessage.email_format_wrong")
      : undefined;

  variableHolder.bccToError =
    !!bccTo && !checkFormatEmail(bccTo)
      ? i18n.t("modalSendMessage.email_format_wrong")
      : undefined;

  if (setStateCallback) {
    setStateCallback();
  }
  setSendMessageVariablesCallback(variableHolder);

  if (
    [
      variableHolder.messageTextError,
      variableHolder.messageTextError,
      variableHolder.replyEmailError,
      variableHolder.bccToError
    ].every((item) => _.isUndefined(item))
  ) {
    successCallback();
  }
};
