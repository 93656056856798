import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import { DateTimeInterval } from "./utils";
import { combineDateAndTime } from "../../../utils/common";
import { EventScheduleError } from "../../FacilityDetailsComponents/CreateLocalPostForm/utils";
import { BaseDateInput } from "../BaseDateInput";
import { TimeInput } from "../TimeInput";

import "./styles.scss";

interface IDateTimeIntervalInput {
  value: DateTimeInterval;
  onChange: (value: DateTimeInterval) => void;
  error: EventScheduleError;
  onChangeError: (error: EventScheduleError) => void;
  hideTimeOfDay?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

export const DateTimeIntervalInput: React.FunctionComponent<
  IDateTimeIntervalInput
> = ({
  value,
  onChange,
  error,
  onChangeError,
  hideTimeOfDay = false,
  minDate,
  maxDate,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`date_interval_input.${key}`);

  const changeDate = (
    field: "startDate" | "startTime" | "endDate" | "endTime",
    newDate: Dayjs | undefined
  ) => {
    const _newDate = newDate?.isValid() ? newDate : undefined;
    const newValue = { ...value };

    newValue[field] = _newDate;

    validate(newValue);
    onChange(newValue);
  };

  const validate = (value: DateTimeInterval) => {
    const error: EventScheduleError = {
      dateError: undefined,
      timeError: undefined,
    };
    if (value.startDate === undefined || value.endDate === undefined) {
      error.dateError = t("errors.dates_required");
    } else {
      const now = dayjs();
      const combinedStartDate = combineDateAndTime(
        value.startDate,
        value.startTime ?? now.set("hour", 0).set("minute", 0)
      );
      const combinedEndDate = combineDateAndTime(
        value.endDate,
        value.endTime ?? now.set("hour", 23).set("minute", 59)
      );

      if (
        combinedEndDate.isBefore(now) ||
        combinedEndDate.diff(now, "minute") === 0
      ) {
        error.dateError = t("errors.event_already_ended");
      }
      if (
        combinedEndDate.isBefore(combinedStartDate) ||
        combinedEndDate.diff(combinedStartDate, "minute") === 0
      ) {
        error.timeError = t("errors.start_date_after_end_date");
      }
    }

    onChangeError(error);
  };

  return (
    <div className="date-interval-wrapper">
      <div className="date-interval-row">
        <BaseDateInput
          className="date-interval-base-date-input"
          fieldLabel={t("start_date")}
          placeholder={""}
          value={value.startDate ? value.startDate.toDate() : null}
          onChange={(newDate) => changeDate("startDate", dayjs(newDate))}
          minDate={minDate?.toDate()}
          maxDate={value.endDate?.toDate()}
        />
        {!hideTimeOfDay && (
          <TimeInput
            fieldLabel={t("start_time")}
            value={value.startTime}
            onChange={(value) => changeDate("startTime", value)}
          />
        )}
      </div>
      <div className="date-interval-row">
        <BaseDateInput
          className="date-interval-base-date-input"
          fieldLabel={t("end_date")}
          placeholder={""}
          value={value.endDate ? value.endDate.toDate() : null}
          onChange={(newDate) => changeDate("endDate", dayjs(newDate))}
          minDate={value.startDate?.toDate()}
          maxDate={maxDate?.toDate()}
        />
        {!hideTimeOfDay && (
          <TimeInput
            fieldLabel={t("end_time")}
            value={value.endTime}
            onChange={(value) => changeDate("endTime", value)}
          />
        )}
      </div>
      {(error.dateError ?? (!hideTimeOfDay && error.timeError)) && (
        <div className="error">{error.dateError ?? error.timeError}</div>
      )}
    </div>
  );
};
