import React from "react";
import "./styles.scss";

export interface IRadioBoxProps {
  checked: boolean;
  label: string;
  subLabel?: string;
  id: string;
  name: string;
  onChange: () => void;
}

export const RadioBox: React.FunctionComponent<IRadioBoxProps> = (props) => {
  const { checked, label, subLabel, id, name } = props;

  return (
    <div className="radio-wrap">
      <input
        type="radio"
        checked={checked}
        id={id}
        name={name}
        onChange={() => props.onChange()}
      />
      <label htmlFor={id}>{label}</label>
      <span className="gray-text">{subLabel}</span>
    </div>
  );
};

export default RadioBox;
