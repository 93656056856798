import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ReviewData } from "../../../models/ReviewData";
import DataSvc from "../../../services/dataSvc";
import UnRepliedReviewItem from "../UnRepliedReviewItem";

import "./styles.scss";

export interface IModalUnRepliedReviewsProps {
  locationId: string | number;
  onClose: () => void;
}

export const ModalUnRepliedReviews: React.FunctionComponent<
  IModalUnRepliedReviewsProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityDetailsPage.modalUnRepliedReviews.${key}`);
  const { onClose, locationId } = props;

  const { data: unRepliedReviews, status } = useQuery(
    ["unRepliedReviews", locationId],
    () => {
      const now = dayjs();
      if (!locationId) return [];
      return DataSvc.getFacilityReviews({
        locationId: locationId,
        periodFromDate: dayjs(now).subtract(60, "days").format("YYYY-MM-DD"),
        periodToDate: dayjs(now).format("YYYY-MM-DD"),
        isReplied: 0,
        sort: "review_date",
        order: "desc",
      });
    }
  );

  const [unRepliedReviewList, setUnRepliedReviews] =
    React.useState<ReviewData[]>(unRepliedReviews);

  useEffect(() => {
    if (unRepliedReviews?.length > 0) {
      setUnRepliedReviews(unRepliedReviews);
    } else {
      setUnRepliedReviews([]);
    }
  }, [unRepliedReviews]);

  const onDeleteReviewItem = (reviewId: string) => {
    setUnRepliedReviews(
      unRepliedReviewList.filter((r) => r.review_id !== reviewId)
    );
  };

  const renderUnRepliedReviews = () => {
    if (status === "loading") {
      return <div className="loading">{t("loading")}</div>;
    }

    return unRepliedReviewList?.length > 0 ? (
      unRepliedReviewList.map((review: ReviewData) => (
        <UnRepliedReviewItem
          key={review.id}
          review={review}
          onDelete={onDeleteReviewItem}
        />
      ))
    ) : (
      <div className="no-comment">{t("noCommentNeedReply")}</div>
    );
  };

  return (
    <div className="modal modal-default modal-unreplied-reviews">
      <div className="modal-mains">
        <div className="btn-close" onClick={onClose}></div>
        <b className="title">{t("listOfNotReplied")}</b>
        <div className="review-container">{renderUnRepliedReviews()}</div>
        <div className="bottom-btns">
          <div className="btn btn-border" onClick={onClose}>
            {t("back")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUnRepliedReviews;
