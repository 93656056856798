import React, { ChangeEvent, KeyboardEvent, FocusEvent } from "react";

import { InputTextAreaCharsDisplay } from "./InputTextAreaCharsDisplay";

import "./styles.scss";

export interface ITextAreaProps {
  value: string;
  classNameContainer?: string;
  placeholder?: string;
  errorLabel?: string;
  isFocused?: boolean;
  name?: string;
  onChange: (value: string) => void;
  onChangeEvent?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  maxChars?: number;
  showChars?: boolean;
}

export const InputTextArea: React.FunctionComponent<ITextAreaProps> = (
  props
) => {
  const {
    value,
    classNameContainer,
    placeholder,
    errorLabel,
    isFocused,
    name,
    onChange,
    onChangeEvent,
    onKeyPress,
    onFocus,
    onBlur,
    maxChars,
    showChars,
  } = props;

  return (
    <div
      className={`textarea
                     ${classNameContainer ? classNameContainer : ""}
                     ${errorLabel ? "error-txt" : ""}
                     ${isFocused ? "focused" : ""}`}
    >
      <textarea
        cols={65}
        placeholder={placeholder || ""}
        value={value}
        {...(name ? { name } : {})}
        onChange={(evt: ChangeEvent<HTMLTextAreaElement>) => {
          onChange(evt.target.value);
          if (onChangeEvent) {
            onChangeEvent(evt);
          }
        }}
        onKeyPress={(evt: KeyboardEvent<HTMLTextAreaElement>) => {
          if (onKeyPress) {
            onKeyPress(evt);
          }
        }}
        onFocus={(evt: FocusEvent<HTMLTextAreaElement>) => {
          if (onFocus) {
            onFocus(evt);
          }
        }}
        onBlur={(evt: FocusEvent<HTMLTextAreaElement>) => {
          if (onBlur) {
            onBlur(evt);
          }
        }}
      />
      {errorLabel && <div className="error">{errorLabel}</div>}
      {showChars && (
        <InputTextAreaCharsDisplay
          currentChars={value.length}
          maxChars={maxChars}
        />
      )}
    </div>
  );
};

export default InputTextArea;
