import dayjs from 'dayjs';

import i18n from '../../../../../../i18n';
import { CombinedMonthlyMetric, ComparisonData, MonthlyMetric } from '../../types';

export const createPalette = (index: number): string => {
  switch (index) {
    case 0: return '#4753EF'
    case 1: return '#A9AEED'
    case 2: return '#685044'
    case 3: return '#1036B9'
  }
  return '#000'
}

export const convertMetricLabel = (text: string, toggleFactor?: string) => {
  switch (text) {
    case "BUSINESS_IMPRESSIONS_DESKTOP_MAPS" :
    case "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH" :
    case "BUSINESS_IMPRESSIONS_MOBILE_MAPS" :
    case "BUSINESS_IMPRESSIONS_MOBILE_SEARCH" :
      return toggleFactor === 'header' ? `${i18n.t(`internaltoolsPage.metricsNew.metricLabels.businessProfileViews`)}(${i18n.t(`internaltoolsPage.metricsNew.metricLabels.${text}`)})`:  i18n.t(`internaltoolsPage.metricsNew.metricLabels.${text}`);
    default:
      return i18n.t(`internaltoolsPage.metricsNew.metricLabels.${text}`);
  }
}

export const convertDateToLabel = (date: string, index: number, setLength: number) => {
  const useWeekly = (document.querySelector('#useWeekly') as HTMLInputElement)?.checked ?? false;
  if(index === 0) {
    return dayjs(date).format('YY/M/D~')
  } else if (index === setLength - 1) {
    const lastDate = dayjs(date);
    if(lastDate.date() === 1) {
      return dayjs(lastDate).format('YY/M/D~')
    }

    const customEndDate = (document.querySelector('#dataEndDate') as HTMLInputElement)?.value ?? null;
    if (customEndDate) {
      return `${dayjs(date).format('YY/M/D')}~${dayjs(customEndDate).format('YY/M/D')}`;
    } else {
      return dayjs(new Date()).format('~YY/M/D');
    }
  } else {
    return useWeekly ? dayjs(date).format('YY/M/D~') : dayjs(date).format('YY/M~');
  }
}

const getCombinedYoy = (keys: string[], yoyComparison: ComparisonData) => ({
  lastYearData: yoyComparison
    .lastYearData
    .filter(item => keys.includes(item.metric))
    .reduce((prev, current) => prev + Number(current.totalValue.value), 0),
  thisYearData: yoyComparison
    .thisYearData
    .filter(item => keys.includes(item.metric))
    .reduce((prev, current) => prev + Number(current.totalValue.value), 0),
})

export const createCombinedMetric = (metrics: MonthlyMetric[], sectionKey: string, keys: string[], yoyComparison?: ComparisonData): CombinedMonthlyMetric => {
  const selectSection = (key: string) => metrics.find(section => section.key === key);

  const sections = keys.map(key => selectSection(key)) as MonthlyMetric[];
  const dataSets = sections;

  dataSets.push({
    key: 'TOTAL',
    data: sections[0].data.map((item, index) => ({
      start: item.start,
      value: sections.reduce((prev, current) => prev + current.data[index].value, 0),
    })),
    total: dataSets.reduce((prev, next) => { return prev + (next.total ?? 0)}, 0)
  })

  return {
    key: sectionKey,
    dataSets,
    ...(yoyComparison ? {yoy: getCombinedYoy(keys, yoyComparison)} : {})
  };
}