/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import { OrgRecommendationData } from '../../../models/OrgRecommendationData';
import SuggestionBody from '../../SuggestionsListComponents/SuggestionBody';
import './styles.scss';

export interface ISuggestionsProps {
  buttonType: 'link' | 'button';
  shownAll: boolean;
  minimumSize?: number;
  isDashboard?: boolean;
  organization_id?: string;
  recommendations: OrgRecommendationData[] | undefined;
}

export const Suggestions: React.FunctionComponent<ISuggestionsProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`facilityDetailsPage.suggestions.${key}`)
  
  const [shownAll, setShownAll] = useState<boolean>(props.shownAll); // false

  const { buttonType, minimumSize, isDashboard, organization_id, recommendations } = props;

  const minSize = minimumSize !== undefined ? minimumSize : 2;

  return (    
    <div className="white-border-panel suggestions-module padding32">
      <div className="titles">
        {t(isDashboard ? 'dashboard_suggestions': 'suggestions')}
      </div>
      {!recommendations ? (
          <div className="row loading-row">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
        <>
          <ul className='suggestion-list'>
            {recommendations && recommendations.length > 0 && (
              recommendations.slice(0, !shownAll ? minSize : recommendations.length).map((item: OrgRecommendationData, index: number) =>
              <li key={index}>
                <div className="txt">
                <span className='points'></span>
                  <SuggestionBody suggestion={item} />
                </div>
              </li>
              )
            )}
            {(!recommendations || !recommendations.length) && (
              <li className="no-suggestion">
                <div className="txt">
                  <div>{t('no_suggestion')}</div>
                </div>
              </li>
          )}
          </ul>
          <div className="show-all">
            {buttonType === 'link' && 
              recommendations && recommendations.length > minSize && (
              <a className={shownAll ? 'btn-hide' : `btn-show`}
                onClick={() => {
                  setShownAll(!shownAll)
                }}>
                {t(shownAll ? 'show_less' : 'show_all')}
                <i className='icons icon-drop'></i>
              </a>
            )}
            {((!shownAll && buttonType === 'button' &&
              recommendations && recommendations.length > minSize) || isDashboard) && (
              <NavLink to={`/recommendations/organization/${organization_id}`} className='btn btn-blue'
                onClick={() => {
                  setShownAll(true)
                }}>
                {t('see_all_suggestions')}
              </NavLink>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Suggestions;