import { SummaryLocation } from "../containers/SendMessageScreens";
import { AccountData } from "../models/AccountData";
import { LocationData } from "../models/LocationData";
import { OrganizationData } from "../models/OrganizationData";

export default class DemoModeSvc {
  isDemoModeOn: boolean;
  activeKeys: Record<string, boolean>;

  static current = new DemoModeSvc();

  constructor() {
    this.isDemoModeOn = sessionStorage.getItem('isDemoModeOn') === "true";
    this.activeKeys = {};

    this.addDemoListeners = this.addDemoListeners.bind(this)
    this.checkDemo = this.checkDemo.bind(this)
    this.toggleDemo = this.toggleDemo.bind(this)
    this.processSummaryLocation = this.processSummaryLocation.bind(this)
    this.processLocation = this.processLocation.bind(this)
    this.processOrganization = this.processOrganization.bind(this)
    this.processAccount = this.processAccount.bind(this)
  }

  addDemoListeners() {
    document.addEventListener('keydown', (event) => {
      this.activeKeys[event.key] = true;

      this.checkDemo();
    });

    document.addEventListener('keyup', (event) => {
      delete this.activeKeys[event.key];
   });
  }

  checkDemo() {
    if(['d','e','m'].every(key => {
      return this.activeKeys[key] === true
    })) {
      this.toggleDemo();
    }
  }

  toggleDemo() {
    this.isDemoModeOn = !this.isDemoModeOn;
    window.alert(`デモモード${this.isDemoModeOn ? 'ON' : 'OFF'}`);
    sessionStorage.setItem('isDemoModeOn', this.isDemoModeOn ? "true" : "")

    window.location.reload()
  }

  processSummaryLocation(item: SummaryLocation, index?: number): SummaryLocation {
    return {
      ...item,
      name: `デモ施設${index !== undefined ? index + 1 : ''}`,
    }
  }

  processLocation(item: LocationData, index?: number): LocationData {
    return {
      ...item,
      name: `デモ施設${index !== undefined ? index + 1 : ''}`,
      ...(item.organizations ? { organizations: item.organizations.map(this.processOrganization)} : {}) 
    }
  }

  processOrganization(item: OrganizationData, index?: number): OrganizationData {
    return {
      ...item,
      name: `デモ団体${index !== undefined ? index + 1 : ''}`
    }
  }

  processAccount(item: AccountData, index?: number):AccountData {
    return {
      ...item,
      name: `デモユーザー${index !== undefined ? index + 1 : ''}`,
      email: `demo@demo.com`,
      organization: this.processOrganization(item.organization)
    }
  }
}