import { isMobile } from "../../../utils/responsive"

export const calculateGraphSize = (type: string, labelLength: number) => {
  if(isMobile()) {
    return {
      width: 300,
      height: 240 + (labelLength * 18),
    }
  }

  return {
    width: 710,
    height: 420,
  }
}