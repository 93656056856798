/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import {useTranslation} from 'react-i18next';

import { LocationData } from '../../../models/LocationData';
import { modalViewGAEvent } from '../../../utils/ga';
import './styles.scss';

export interface IModalFacilitySelectionConfirmProps {
  dataList: LocationData[]
  onClose: () => void
  onSelectionConfirm: () => void
}

export const ModalFacilitySelectionConfirm: React.FunctionComponent<IModalFacilitySelectionConfirmProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`facilityRegistrationPage.modalFacilitySelectionConfirm.${key}`)

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent('confirm-facility-assign');
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { dataList, onClose, onSelectionConfirm} = props;

  return (    
    <div className="modal modal-default modal-facility-selection-confirm">
      <div className="modal-mains">
        <a className='btn-close'
          onClick={() => {
            onClose()
          }}>
        </a>
        <div className="top-title">
          {t('some_facilities_will_no_longer_be_available')}
        </div>
        <p className='p-txt'>
          {t('the_following_facility_will_not_be_seen')}
        </p>
        <div className="list-border-box">
          <ul>
            {
              dataList.map((item: LocationData, index) => (
                <li key={index}>
                  {item.name}
                </li>
              ))
            }
          </ul>
        </div>
        <div className="bottom-btns">
          <a className='btn btn-border'
            onClick={() => {
              onClose()
            }}>
            {t('cancel')}
          </a>
          <a className='btn btn-blue'
            onClick={() => {
              onSelectionConfirm()
            }}>
            {t('submit')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalFacilitySelectionConfirm;
