import React from "react";

import "./styles.scss";

interface IMediaPreview {
  file: File;
  remove: () => void;
}

export const MediaPreview: React.FunctionComponent<IMediaPreview> = (props) => {
  const { file, remove } = props;

  const src = React.useMemo<string>(() => {
    return URL.createObjectURL(file);
  }, [file]);

  return (
    <div className="media-preview">
      <button className="media-preview-delete-button btn" onClick={remove}>
        <span className="icons icon-trash"></span>
      </button>
      <img alt={`Preview ${file.name}`} src={src}></img>
    </div>
  );
};
