/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

// import { useTranslation } from 'react-i18next';
import { TypeType, ChartData, CreateChartCollectionData } from '../../../models/ChartCollectionData';
import './styles.scss';

export interface IChartCategoryItemProps {
  selectedCollectionTemplateId: string;
  selectedTemplateChart: ChartData;
  itemData: CreateChartCollectionData
  onSelectChart: (collectionTemplateId: string, chartItem: ChartData) => void
}

export const ChartCategoryItem: React.FunctionComponent<
  IChartCategoryItemProps
> = (props) => {
  const { selectedCollectionTemplateId, selectedTemplateChart, itemData, onSelectChart } = props;
  
  const getChartIconContent = (type: TypeType) => {
    switch (type) {
      case 'line':
        return (
          <i className='icons icon-line-chart'>&nbsp;</i>
        )
      case 'bar':
        return (
          <i className='icons icon-bar-chart'>&nbsp;</i>
        )
      case 'pie':
        return (
          <i className='icons icon-circle-chart'>&nbsp;</i>
        )
      case 'table':
        return (
          <i className='icons icon-grid-chart'>&nbsp;</i>
        )
    }
  }

  return (
    <div className="chart-category-item">
      <div className="titles">
        {itemData.collectionTemplate.name}
      </div>
      <div className="border-list flex">
        {
          itemData.chartList.map((chartItem, index) => (
            <a key={index}
              className={`border-items ${(itemData.collectionTemplate.id.toString() === selectedCollectionTemplateId && chartItem.id.toString() === selectedTemplateChart?.id.toString()) ? 'active' : ''}`}
              onClick={() => {
                onSelectChart(itemData.collectionTemplate.id.toString(), chartItem)
              }}>
              <div className="left-txt">
                {chartItem.name}
              </div>
              {getChartIconContent(chartItem.type)}
            </a>
          ))
        }
      </div>
    </div>
  );
};

export default ChartCategoryItem;
