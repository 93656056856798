import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import RatioSection from "./components/RatioSection";
import YoYSection from "./components/YoYSection";
import {
  convertMetricLabel,
  convertDateToLabel,
  createCombinedMetric,
  createPalette,
} from "./utils";
import { CombinedMonthlyMetric, MetricKey, ToolsMetrics } from "../../types";

import "./styles.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const lineGraphOptions = {
  options: {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  },
};

const getMetricsCsv = (metrics: ToolsMetrics, showToCustomer?: boolean) => {
  const { title, monthlyMetrics } = metrics;
  let csvString = `data:text/csv;charset=utf-8,\uFEFF${title}\n`;

  const months = monthlyMetrics[0].data
    .map((item, index, array) =>
      convertDateToLabel(item.start, index, array.length)
    )
    .join(",");
  csvString += `種類,${months},合計\n`;

  monthlyMetrics.forEach((metric) => {
    const { key, data } = metric;
    if (showToCustomer && key === MetricKey.BUSINESS_FOOD_ORDERS) return; // skip if food order
    const total = data.reduce((prev, next) => prev + next.value, 0);
    const monthlyData = data.map((item) => item.value).join(",");
    csvString += `${convertMetricLabel(key)},${monthlyData},${total}\n`;
  });

  const encodedUri = encodeURI(csvString);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${title}-metrics.csv`);
  document.body.appendChild(link);
  link.click();
};

const ChronoData: React.FunctionComponent<{
  metrics: ToolsMetrics;
  useWeekly: boolean;
  showToCustomer?: boolean;
}> = ({ metrics, showToCustomer }) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`internaltoolsPage.metricsNew.chrono.${key}`);

  const { monthlyMetrics, yoyComparison } = metrics;

  const businessImpressionsKeys: MetricKey[] = [
    MetricKey.BUSINESS_IMPRESSIONS_DESKTOP_MAPS,
    MetricKey.BUSINESS_IMPRESSIONS_DESKTOP_SEARCH,
    MetricKey.BUSINESS_IMPRESSIONS_MOBILE_MAPS,
    MetricKey.BUSINESS_IMPRESSIONS_MOBILE_SEARCH,
  ];

  const keysToExclude = [
    ...businessImpressionsKeys,
    MetricKey.BUSINESS_FOOD_ORDERS,
  ];

  const combinedMetrics: CombinedMonthlyMetric[] = [
    createCombinedMetric(
      monthlyMetrics,
      MetricKey.TOTAL_BUSINESS_IMPRESSIONS,
      businessImpressionsKeys,
      yoyComparison
    ),
  ];

  const fixedDisplayOrder = [
    MetricKey.BUSINESS_DIRECTION_REQUESTS,
    MetricKey.WEBSITE_CLICKS,
    MetricKey.CALL_CLICKS,
    MetricKey.BUSINESS_BOOKINGS,
    ...(!showToCustomer ? keysToExclude : []),
  ];

  const individualMetrics = showToCustomer
    ? monthlyMetrics.filter(
        ({ key }) => !keysToExclude.includes(key as MetricKey)
      )
    : monthlyMetrics;

  return (
    <>
      <button
        className="dlCsvButton"
        onClick={() => getMetricsCsv(metrics, showToCustomer)}
      >
        {t("csv_button")}
      </button>
      {combinedMetrics.map(({ key, dataSets, yoy }) => (
        <div className="monthSection" key={key}>
          <h3>{convertMetricLabel(key, "header")}</h3>
          <div className="line-container">
            <Line
              {...lineGraphOptions}
              data={{
                labels: dataSets[0].data.map((item, index) =>
                  convertDateToLabel(item.start, index, dataSets[0].data.length)
                ),
                datasets: dataSets.map(({ key, data }, index) => ({
                  label: convertMetricLabel(key),
                  data: data.map((item) => item.value),
                  borderColor: createPalette(index),
                })),
              }}
            />
          </div>
          {!showToCustomer && key === "TOTAL_BUSINESS_IMPRESSIONS" && (
            <RatioSection
              title={t("map_ratio_title")}
              numbers={[
                "BUSINESS_IMPRESSIONS_DESKTOP_MAPS",
                "BUSINESS_IMPRESSIONS_DESKTOP_SEARCH",
                "BUSINESS_IMPRESSIONS_MOBILE_MAPS",
                "BUSINESS_IMPRESSIONS_MOBILE_SEARCH",
              ].map(
                (key) =>
                  monthlyMetrics.find((set) => set.key === key)?.total as number
              )}
            />
          )}
          {yoyComparison && (
            <YoYSection metric={key} yoyComparison={yoyComparison} yoy={yoy} />
          )}
        </div>
      ))}
      {fixedDisplayOrder.map((metricKey) => {
        const key = metricKey as string;
        const data =
          individualMetrics.find((set) => set.key === key)?.data || [];
        return (
          <div className="monthSection" key={key}>
            <h3>{convertMetricLabel(key as string, "header")}</h3>
            <div className="line-container">
              <Line
                {...lineGraphOptions}
                data={{
                  labels: data.map((item, index) =>
                    convertDateToLabel(item.start, index, data.length)
                  ),
                  datasets: [
                    {
                      label: convertMetricLabel(key),
                      data: data.map((item) => item.value),
                      borderColor: "#4753EF",
                    },
                  ],
                }}
              />
            </div>
            {yoyComparison && (
              <YoYSection metric={key} yoyComparison={yoyComparison} />
            )}
          </div>
        );
      })}
    </>
  );
};

export default ChronoData;
