import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { GoogleInformation } from "../../../../models/GoogleInformation";
import DataSvc from "../../../../services/dataSvc";

interface ICheckOwnerListProps extends RouteComponentProps<any> {
  onLoading(loadingText: string | void): void;
  onLoaded(): void;
  googleInformation: GoogleInformation | undefined;
  commonErrorHandler: (err: string) => string | undefined;
}
const CheckOwnerList: React.FunctionComponent<ICheckOwnerListProps> = ({
  onLoading,
  onLoaded,
  googleInformation,
  commonErrorHandler,
}) => {
  const { t: _t } = useTranslation();
  const t = useCallback(
    (key: string) => _t(`internaltoolsPage.checkOwnerList.${key}`),
    [_t]
  );
  const [selectedFile, setSelectedFile] = useState<Blob | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isPerformingCheck, setIsPerformingCheck] = useState<boolean>(false);
  const [resultMessage, setResultMessage] = useState("");

  const fetchOwnerList = async () => {
    if (!selectedFile) {
      setErrorMessage(t("please_attach_file"));
      return;
    }

    if (!googleInformation?.accessToken) {
      setErrorMessage(t("re_login"));
      return;
    }

    setResultMessage("");
    setIsPerformingCheck(true);
    onLoading(t("do_not_close_window"));

    const formData = new FormData();
    formData.append("file", selectedFile);

    await DataSvc.checkOwnerList(formData, googleInformation.accessToken)
      .then((data) => {
        const { csvString } = data;

        if (!csvString) {
          setErrorMessage(t("can_not_get_scores"));
          return;
        }
        const blob = new Blob([csvString], { type: "text/csv" });
        const file = window.URL.createObjectURL(blob);
        window.location.assign(file);

        setResultMessage(t("fetch_complete"));
      })
      .catch((err) => {
        const filteredError = commonErrorHandler(err) ?? "";
        setErrorMessage(filteredError ?? "");
      })
      .finally(() => {
        onLoaded();
        setIsPerformingCheck(false);
      });
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      if (isPerformingCheck) {
        event.returnValue = t("still_fetching");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isPerformingCheck, t]);

  const onSelectedFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files: FileList | null = e.currentTarget.files;
    setSelectedFile(files !== null ? files[0] : null);
  };

  return (
    <React.Fragment>
      <h1 className="main-title">{t("main_title")}</h1>
      <h2 className="sub-title">{t("input")}</h2>
      <section className="card">
        <p>{t("section_1_title_1")}</p>
        <p>
          {t("section_1_title_2")}
          <a href="/samples/owner_check_sample_upload.csv">
            {t("section_1_content_1")}
          </a>
          {t("section_1_content_2")}
          <strong>{t("section_1_content_3")}</strong>
        </p>
        <p>
          <input
            onChange={onSelectedFile}
            className="input"
            type="file"
            id="file"
            name="file"
            placeholder="CSV"
            accept=".csv"
          />
        </p>
        <p>
          <button type="button" onClick={fetchOwnerList}>
            {t("execute")}
          </button>
        </p>
        {errorMessage && <h3 id="error">{errorMessage.toString()}</h3>}
      </section>
      <h2 className="sub-title">{t("result_message")}</h2>
      {resultMessage && (
        <section className="card">
          <p>{resultMessage}</p>
        </section>
      )}
    </React.Fragment>
  );
};

export default withRouter(CheckOwnerList);
