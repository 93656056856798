/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import { EmailForm } from "../../components/SendMessageComponents/EmailForm";
import { ModalConfirmSendMessage } from "../../components/SendMessageComponents/ModalConfirmSendMessage";
import DataSvc from "../../services/dataSvc";
import {
  initialEmailState,
  validateEmailForm,
} from "../SendMessageScreens/utils";

import "./styles.scss";

type IMassSendEmailsPageProps = RouteComponentProps<any>;

const DEFAULT_LIIGO_ADMIN_REPLY_TO = "cs@liigo.world";

const MassSendEmailsPage: React.FunctionComponent<
  IMassSendEmailsPageProps
> = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`sendMassEmailsPage.${key}`);

  const [sendToOrgAdmin, setSendToOrgAdmin] = useState<boolean>(false);
  const [sendToLocAdmin, setSendToLocAdmin] = useState<boolean>(false);
  const [sendToError, setSendToError] = useState<string | undefined>(undefined);
  const [
    shownSendMessageConfirmationModal,
    setShownSendMessageConfirmationModal,
  ] = useState<boolean>(false);
  const [sendMessageVariables, setSendMessageVariables] = useState<any>({
    ...initialEmailState,
    userEmail: DEFAULT_LIIGO_ADMIN_REPLY_TO,
  });

  const { messageTitle, messageText, replyEmail, userEmail, bccTo } =
    sendMessageVariables;

  const updateVariables = (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSendMessageVariables({
      ...sendMessageVariables,
      [event.target.name]: event.target.value,
    });
  };

  const hasSendToError = () => {
    if (!sendToOrgAdmin && !sendToLocAdmin) {
      setSendToError(t("please_select_send_to"));
      return true;
    } else {
      setSendToError(undefined);
      return false;
    }
  };

  const onToConfirm = () => {
    validateEmailForm(sendMessageVariables, setSendMessageVariables, () => {
      const sendToError = hasSendToError();
      if (!sendToError) {
        setShownSendMessageConfirmationModal(true);
      }
    });
  };

  // on Send Message
  const onSendMessage = async () => {
    await DataSvc.sendMassEmail({
      subject: messageTitle,
      text: messageText,
      replyToEmail: !!replyEmail ? replyEmail : userEmail,
      bccToEmail: !!bccTo ? bccTo : "",
      sendToOrgAdmin,
      sendToLocAdmin,
    });

    toast(t("email_sent"));
    setSendMessageVariables({
      ...initialEmailState,
      userEmail: DEFAULT_LIIGO_ADMIN_REPLY_TO,
    });
    setShownSendMessageConfirmationModal(false);
  };

  const produceEmailRecepients = () =>
    [
      sendToOrgAdmin ? t("org_admin") : null,
      sendToLocAdmin ? t("loc_admin") : null,
    ]
      .filter((item) => !!item)
      .join(` ${t("and")} `);

  return (
    <>
      <div className="right-content mass-send">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("mass_send_emails")}</div>
          <div className="rights"></div>
          <div className="line"></div>
        </div>

        <div className="white-border-panel padding32">
          <div className="mass-send__main">
            <h3>{t("send_to")}</h3>
            <div className="mass-send__main__form">
              <div className="mass-send__main__form__checkbox">
                <input
                  type="checkbox"
                  id="sendToOrgAdmin"
                  name="sendToOrgAdmin"
                  checked={sendToOrgAdmin}
                  onChange={() => setSendToOrgAdmin(!sendToOrgAdmin)}
                />
                <label htmlFor="sendToOrgAdmin">{t("org_admin")}</label>
              </div>
              <div className="mass-send__main__form__checkbox">
                <input
                  type="checkbox"
                  id="sendToLocAdmin"
                  name="sendToLocAdmin"
                  checked={sendToLocAdmin}
                  onChange={() => setSendToLocAdmin(!sendToLocAdmin)}
                />
                <label htmlFor="sendToLocAdmin">{t("loc_admin")}</label>
              </div>
              {sendToError && <div className="error">{sendToError}</div>}
            </div>
            <hr />
            <EmailForm
              {...sendMessageVariables}
              onChangeTextVariable={updateVariables}
              adminMode={true}
            />
            <div className="mass-send__main__form__btns">
              <a
                className="btn btn-blue"
                onClick={() => {
                  onToConfirm();
                }}
              >
                {t("confirm")}
              </a>
            </div>
          </div>
        </div>
      </div>
      {shownSendMessageConfirmationModal && (
        <ModalConfirmSendMessage
          messageTitle={messageTitle}
          messageText={messageText}
          replyEmail={replyEmail}
          userEmail={DEFAULT_LIIGO_ADMIN_REPLY_TO}
          bccTo={bccTo}
          onClose={() => {
            setShownSendMessageConfirmationModal(false);
          }}
          onToConfirm={() => {
            onSendMessage();
          }}
          conditions={
            <div className="mass-send__conditions">
              <h2 className="top-title">{t("send_to")}</h2>
              <p>{produceEmailRecepients()}</p>
            </div>
          }
        ></ModalConfirmSendMessage>
      )}
    </>
  );
};

export default withRouter(MassSendEmailsPage);
