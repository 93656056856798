import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, {useEffect, useState} from "react";
import { Route } from "react-router-dom";

import DataSvc from '../../services/dataSvc';

export function ProtectedRoute({ component, ...args }: any) {
  const [tokenFetched, setTokenFetched] = useState(false);
  const {isAuthenticated, getIdTokenClaims} = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      DataSvc.setToken(getIdTokenClaims().then(claims => {
        setTokenFetched(true);
        return claims?.__raw || '';
      }));
    }
  }, [isAuthenticated, getIdTokenClaims])

  if (!isAuthenticated || tokenFetched) {
    return (
      <Route component={withAuthenticationRequired(component, {})} {...args} />
    );
  } else {
    return <div>loading</div>
  }

}