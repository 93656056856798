import { LocationData } from '../models/LocationData';

// get Main Category from Location Data
export const getMainCategory = (item: LocationData): string => {
  let categoryValue = '';
  item.categories.forEach((itemCategory) => {
    if (itemCategory.is_main) {
      categoryValue = itemCategory.name
    }
  });
  return categoryValue
};

// get First Category from Location Data
export const getFirstCategory = (item: LocationData): string => {
  let categoryValue = '';
  item.categories.forEach((itemCategory, index) => {
    if (index === 0) {
      categoryValue = itemCategory.name
    }
  });
  return categoryValue + (item.categories.length > 1 ? `,and ${item.categories.length - 1} others` : '')
};

