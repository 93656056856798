/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery, useMutation } from "@tanstack/react-query";
import _, { without } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { ModalRegisterEditOrganization } from "../../components/OrganizationManagementComponents/ModalRegisterEditOrganization";
import { OrganizationManagementList } from "../../components/OrganizationManagementComponents/OrganizationManagementList";
import { EMPTY_ORGANIZATION } from "../../config";
import { useMe } from "../../hooks/queries";
import {
  RepresentativeUserData,
  OrganizationData,
} from "../../models/OrganizationData";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

const defaultFilterFormData = {
  searchName: "",
  sort: "plan_expiry_date",
  sortBy: "利用期限 ↑",
  order: "asc",
  numberPerPage: 20,
  pageIndex: 1,
};

type IOrganizationManagementPageProps = RouteComponentProps<any>;

const OrganizationManagementPage: React.FunctionComponent<
  IOrganizationManagementPageProps
> = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`organizationManagementPage.${key}`);

  const [organizationsData, setOrganizationsData] = useState<
    OrganizationData[]
  >([]);
  const [totalOrganizations, setTotalOrganizations] = useState(0);

  const [registerEditOrganization, setRegisterEditOrganization] =
    useState<OrganizationData>(_.cloneDeep(EMPTY_ORGANIZATION));
  const [editingOrg, setEditingOrg] = useState<OrganizationData>();

  const [isModalRegister, setIsModalRegister] = useState<boolean>(true); // true
  const [
    shownModalRegisterEditOrganization,
    setShownModalRegisterEditOrganization,
  ] = useState<boolean>(false); // false

  const [organizationId, setOrganizationId] = useState<string>("");

  const [filterFormData, setFilterFormData] = useState<any>(
    defaultFilterFormData
  );

  const { data: me } = useMe();

  const { data: orgsLoginRate } = useQuery([], async () => {
    return await DataSvc.getOrgsLoginRate();
  });

  const { data: organizations, refetch: refetchOrgs } = useQuery(
    ["organizations", organizationId, filterFormData],
    () => {
      return DataSvc.getOrganizations(
        _.pickBy({
          sort: filterFormData.sort,
          order: filterFormData.order,
          limit: filterFormData.numberPerPage,
          offset: (filterFormData.pageIndex - 1) * filterFormData.numberPerPage,
          query: filterFormData.searchName.trim(),
          seek_total_count: true,
        })
      );
    }
  );

  useEffect(() => {
    if (organizations) {
      setOrganizationsData(organizations.list);
      setTotalOrganizations(organizations.total);
    }
  }, [organizations]);

  useEffect(() => {
    if (!me) {
      return;
    }

    setOrganizationId(me.organization?.id?.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  // on Click Register
  const onClickRegister = () => {
    setRegisterEditOrganization(_.cloneDeep(EMPTY_ORGANIZATION));

    setShownModalRegisterEditOrganization(true);
    setIsModalRegister(true);
  };

  // on Click Edit
  const onClickEdit = (organizationItem: OrganizationData) => {
    setRegisterEditOrganization(_.cloneDeep(organizationItem));
    setEditingOrg(organizationItem);
    setShownModalRegisterEditOrganization(true);
    setIsModalRegister(false);
  };

  const createOrg = useMutation((payload: any) => {
    return DataSvc.createOrganization(payload);
  });

  const addMunicipality = useMutation((payload: any) => {
    return DataSvc.addMunicipalityToOrganization(
      payload.organization_id,
      payload.municipality_id
    );
  });

  const removeMunicipality = useMutation((payload: any) => {
    return DataSvc.removeMunicipalityFromOrganization(
      payload.organization_id,
      payload.municipality_id
    );
  });

  const updateOrg = useMutation((payload: any) => {
    return DataSvc.updateOrganization(payload.id, {
      name: payload.name,
      plan_expiry_date: payload.plan_expiry_date,
    });
  });

  const removeOrg = useMutation(() => {
    return DataSvc.removeOrganization(registerEditOrganization.id);
  });

  const registerAccount = useMutation((payload: any) => {
    return DataSvc.registerAccount(payload);
  });

  const updateAccount = useMutation((payload: any) => {
    return DataSvc.updateAccount(payload.id, payload);
  });

  // on Remove
  const onRemove = () => {
    removeOrg.mutateAsync().then(() => {
      setShownModalRegisterEditOrganization(false);
      refetchOrgs();
    });
  };

  // on Submit
  const onSubmit = (
    formData: OrganizationData,
    municipalityIdList: number[],
    representativeUserData: RepresentativeUserData
  ) => {
    const payload = {
      name: formData.name,
      plan_expiry_date: moment(formData.plan_expiry_date).toISOString(),
    };
    let promise: any = Promise.resolve();
    if (isModalRegister) {
      promise = createOrg.mutateAsync(payload).then(async (org) => {
        await Promise.all(
          municipalityIdList.map(async (id) => {
            return addMunicipality.mutateAsync({
              organization_id: org.id,
              municipality_id: id,
            });
          })
        );
        const accountPayload = {
          first_name: representativeUserData.first_name,
          last_name: representativeUserData.family_name,
          organization_id: Number(org.id),
          type: "org_admin",
          role: "manager",
        };
        if (representativeUserData.id) {
          await updateAccount.mutateAsync({
            ...accountPayload,
            id: representativeUserData.id,
          });
        } else {
          await registerAccount.mutateAsync({
            ...accountPayload,
            email: representativeUserData.email,
            password: representativeUserData.password,
          });
        }
      });
    } else {
      const originIds = editingOrg?.municipalities.map((m) => m.id) || [];
      const toRemove = without(originIds, ...municipalityIdList);
      const toAdd = without(municipalityIdList, ...originIds);
      promise = updateOrg
        .mutateAsync({ ...payload, id: formData.id })
        .then(() => {
          return Promise.all(
            toAdd
              .map((id) => {
                return addMunicipality.mutateAsync({
                  organization_id: formData.id,
                  municipality_id: id,
                });
              })
              .concat(
                toRemove.map((id) => {
                  return removeMunicipality.mutateAsync({
                    organization_id: formData.id,
                    municipality_id: id,
                  });
                })
              )
          );
        });
    }
    return promise?.then(() => {
      setShownModalRegisterEditOrganization(false);
      refetchOrgs();
    });
  };

  return (
    <>
      <div className="right-content organization-management">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("organization_management")}</div>
          <div className="rights">
            <div className="right-title">
              {_t(
                "organizationManagementPage.organizationManagementList.all_orgs_login_rate",
                { percentage: `${(orgsLoginRate * 100 || 0).toFixed(2)} %` }
              )}
            </div>
            <a
              className="btn btn-blue"
              onClick={() => {
                onClickRegister();
              }}
            >
              {t("register")}
            </a>
          </div>
          <div className="line"></div>
        </div>

        {!!organizationsData && (
          <OrganizationManagementList
            dataList={organizationsData}
            totalCount={totalOrganizations}
            filterFormData={filterFormData}
            organizationId={organizationId || ""}
            onChangeFilterFormData={(filterFormData: any) => {
              setFilterFormData(filterFormData);
            }}
            onClickEdit={(organizationItem: OrganizationData) => {
              onClickEdit(organizationItem);
            }}
          />
        )}
      </div>
      {shownModalRegisterEditOrganization && (
        <ModalRegisterEditOrganization
          isModalRegister={isModalRegister}
          registerEditOrganization={registerEditOrganization}
          onClose={() => {
            setShownModalRegisterEditOrganization(false);
          }}
          onRemove={() => {
            onRemove();
          }}
          onSubmit={(
            formData: OrganizationData,
            municipalityIdList: number[],
            representativeUserData: RepresentativeUserData
          ) => {
            onSubmit(formData, municipalityIdList, representativeUserData);
          }}
        />
      )}
    </>
  );
};

export default withRouter(OrganizationManagementPage);
