import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import {useTranslation} from 'react-i18next';

import { OrgDailyMetricsData } from '../../../models/MetricsLineChartData';
import LineChartItem from '../LineChartItem';
import './styles.scss';

export interface IMetricLineChartProps {
  title: string;
  dataList: OrgDailyMetricsData;
}

export const MetricLineChart: React.FunctionComponent<IMetricLineChartProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`dashboardPage.metricLineChart.${key}`)

  const { title, dataList } = props;
  
  return (    
    <div className="white-border-panel dashboard-metric-line-chart-module">
      <div className="panel-title">
        {t(`${title}`)}
      </div>
      <div className="chart-area">
        {dataList.dataForLine.length === 0 && (
          <div className="row loading-row chart-row">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <LineChartItem
          width={480}
          height={280}
          dataList={dataList}
          />
      </div>
    </div>
  );
};

export default MetricLineChart;
