import { DateTimeInterval } from "../../FormElement/DateTimeIntervalInput/utils";

export interface CreateLocalPostState {
  media: File[];
  mediaError?: string;
  text: string;
  messageTextError?: string;
  languageCode: string;
  callToAction: CallToAction;
  topicType: LocalPostTopicType;
  event: LocalPostEvent;
  offer: {
    couponCode: string;
    couponCodeError?: string;
    redeemOnlineUrl: string;
    redeemOnlineUrlError?: string;
    termsConditions: string;
    termsAndConditionsError?: string;
  };
}

export type LocalPostEvent = {
  title: string;
  titleError?: string;
  schedule: DateTimeInterval;
  scheduleError: EventScheduleError;
};

export type EventScheduleError = {
  dateError?: string;
  timeError?: string;
};

export type CallToAction = {
  actionType: ActionType;
  url: string;
  urlError?: string;
};

export enum ActionType {
  ACTION_TYPE_UNSPECIFIED = "ACTION_TYPE_UNSPECIFIED",
  BOOK = "BOOK",
  ORDER = "ORDER",
  SHOP = "SHOP",
  LEARN_MORE = "LEARN_MORE",
  SIGN_UP = "SIGN_UP",
  CALL = "CALL",
}

export enum LocalPostTopicType {
  LOCAL_POST_TOPIC_TYPE_UNSPECIFIED = "LOCAL_POST_TOPIC_TYPE_UNSPECIFIED",
  STANDARD = "STANDARD",
  EVENT = "EVENT",
  OFFER = "OFFER",
  ALERT = "ALERT",
}
