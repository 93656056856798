/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, KeyboardEvent } from 'react';
import {useTranslation} from 'react-i18next';

import { SORT_ORGANIZATION_MANAGEMENT_OPTIONS, PAGE_NUMBER_OPTIONS } from '../../../config';
import DropdownSelect from '../../FormElement/DropdownSelect';
import { InputBox } from '../../FormElement/InputBox';
import ToggleBtns from '../../ToggleBtns';
import '../../FacilityListComponents/ListTopBar/styles.scss';

export interface IListTopBarProps {
  filterFormData: any;
  onChangeFilterFormData: (filterFormData: any) => void
}

export const ListTopBar: React.FunctionComponent<IListTopBarProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`organizationManagementPage.organizationManagementList.listTopBar.${key}`)
  
  const [searchName, setSearchName] = useState<string>('');

  const { filterFormData, onChangeFilterFormData } = props;

  const getSortCriteria = (option: string) => {
    const criteria:any = {
      sortBy: option
    };
    switch (option) {
      case '団体名 ↑':
        criteria.sort = 'name';
        criteria.order = 'asc';
        break;
      case '団体名 ↓':
        criteria.sort = 'name';
        criteria.order = 'desc';
        break;
      case '種別 ↑':
        criteria.sort = 'type';
        criteria.order = 'asc';
        break;
      case '種別 ↓':
        criteria.sort = 'type';
        criteria.order = 'desc';
        break;
      case '利用期限 ↑':
        criteria.sort = 'plan_expiry_date';
        criteria.order = 'asc';
        break;
      case '利用期限 ↓':
        criteria.sort = 'plan_expiry_date';
        criteria.order = 'desc';
        break;
      case '施設の\'A\'健康施設率 ↑':
        criteria.sort = 'health_index';
        criteria.order = 'asc';
        break;
      case '施設の\'A\'健康施設率 ↓':
        criteria.sort = 'health_index';
        criteria.order = 'desc';
        break;
      case '施設のログイン率 ↑':
        criteria.sort = 'login_rate';
        criteria.order = 'asc';
        break;
      case '施設のログイン率 ↓':
        criteria.sort = 'login_rate';
        criteria.order = 'desc';
        break;
      case 'サインアップ率 ↑':
        criteria.sort = 'sign_up_rate';
        criteria.order = 'asc';
        break;
      case 'サインアップ率 ↓':
        criteria.sort = 'sign_up_rate';
        criteria.order = 'desc';
        break;
      default:
        break;
    }
    return criteria;
  }

  return (    
    <div className="list-top-bar flex-grid">
      <div className="lefts">
      </div>
      <div className="rights flex">
        <div className="search-module">
          <div className="top-txt">
            &nbsp;
          </div>
          <InputBox
            value={searchName}
            placeholder={t('search_by_name')}
            isSearch={true}
            classNameContainer={'search-input'}
            onChange = {(value: string) => {
              setSearchName(value)
            }}
            onKeyPress={(event: KeyboardEvent<HTMLImageElement>) => {
              if(event.key === 'Enter'){
                onChangeFilterFormData({
                  ...filterFormData,
                  searchName: searchName,
                  pageIndex: 1,
                })
              }
            }}
            />
        </div>
        <div className="sort-drop">
          <div className="top-txt">
            {t('sort')}
          </div>
          <DropdownSelect
            fieldLabel={''}
            value={filterFormData.sortBy}
            items={SORT_ORGANIZATION_MANAGEMENT_OPTIONS}
            classNameContainer={'sort-dropdown'}
            onChange={(
              value: string
            ) => {
              onChangeFilterFormData({
                ...filterFormData,
                ...getSortCriteria(value)
              })
            }}
          />
        </div>
        <div className="choose-area">
          <div className="top-txt">
            {t('number_of_facilities_per_page')}
          </div>
          <ToggleBtns
            selectedValue={filterFormData.numberPerPage}
            options={PAGE_NUMBER_OPTIONS}
            onClickBtn={(option: number) => {
              onChangeFilterFormData({
                ...filterFormData,
                numberPerPage: option,
                pageIndex: 1
              })
            }}
            />
        </div>
      </div>
    </div>
  );
};

export default ListTopBar;
