export enum ScoreType {
  A1 = 'A1',
  B1 = 'B1',
  C1 = 'C1',
  D1 = 'D1',
}

export const standardizeScoreType = (score: string): ScoreType => {
  switch (score) {
    case 'A1':
      return ScoreType.A1;
    case 'B1':
      return ScoreType.B1;
    case 'C1': case 'C2':
      return ScoreType.C1;
    case 'D1': case 'E1':
      return ScoreType.D1;
    default:
      return ScoreType.A1;
  }
}

// get Score Type
export const getScoreType = (score: number): ScoreType => {
  if (score > 75) {
    return ScoreType.A1;
  }
  if (75 >= score && score > 50) {
    return ScoreType.B1;
  }
  if (50 >= score && score > 25) {
    return ScoreType.C1;
  }
  if (25 >= score) {
    return ScoreType.D1;
  }
  return ScoreType.A1;
};