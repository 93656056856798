import i18next from "i18next";

import { OrgGroupAggregationMetricsData } from "../../../../models/ChartCollectionData";

const colorArray = ["#4753EF", "#9BA2FC", "#E9907D", "#101CB9"];

export const compileToDataSource = (
  chartDetailsData: OrgGroupAggregationMetricsData
) => {
  const chartDataTemp = chartDetailsData.data.map((item, index: number) => {
    return {
      unit: i18next.t(
        `analysisCollectionsPage.collectionChartArea.metricItems.unit_view`
      ),
      color: colorArray[index],
      data: parseInt(item.value),
      name: i18next.t(
        `analysisCollectionsPage.collectionChartArea.metricItems.${item.groups[0]}`
      ),
      fill: colorArray[index],
    };
  });

  return chartDataTemp;
};
