import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";

import { MediaType, ToolsMetrics } from "../../types";

import "./styles.scss";

const mediaTypeToTitle = (text?: string) => {
  switch (text) {
    case "COVER":
      return "カバー";
    case "PROFILE":
      return "プロフィール";
    case "LOGO":
      return "ロゴ";
    case "EXTERIOR":
      return "外観";
    case "INTERIOR":
      return "店内";
    case "PRODUCT":
      return "商品";
    case "AT_WORK":
      return "作業中";
    case "FOOD_AND_DRINK":
      return "食品と飲料";
    case "MENU":
      return "メニュー";
    case "COMMON_AREA":
      return "共有エリア";
    case "ROOMS":
      return "部屋";
    case "TEAMS":
      return "チーム";
    case "ADDITIONAL":
      return "その他";
    default:
      return text;
  }
};

const Media: React.FunctionComponent<{ metrics: ToolsMetrics }> = ({
  metrics,
}) => {
  const { mediaData, customerMedia } = metrics;

  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`internaltoolsPage.metricsNew.mediaSection.${key}`);

  const mediaTypes: Record<MediaType, number> = {
    COVER: 0,
    PROFILE: 0,
    LOGO: 0,
    EXTERIOR: 0,
    INTERIOR: 0,
    PRODUCT: 0,
    AT_WORK: 0,
    FOOD_AND_DRINK: 0,
    MENU: 0,
    COMMON_AREA: 0,
    ROOMS: 0,
    TEAMS: 0,
    ADDITIONAL: 0,
  };

  mediaData.mediaItems.forEach((media) => {
    const category = media.locationAssociation?.category;
    if (category) {
      mediaTypes[category] = mediaTypes[category] + 1;
    }
  });

  const mediaSample = [...mediaData.mediaItems].splice(0, 9);

  return (
    <>
      <h3>
        {t("media_posted_by_owner")}：{mediaData.totalMediaItemCount ?? 0}
        <br />
        {t("media_posted_by_customers")}：
        {customerMedia.totalMediaItemCount ?? 0}
      </h3>
      <div className="data-table">
        {Object.keys(mediaTypes).map((key) => (
          <React.Fragment key={key}>
            <p>
              <strong>{mediaTypeToTitle(key)}</strong>
            </p>
            <p>{mediaTypes[key as MediaType]}</p>
          </React.Fragment>
        ))}
      </div>
      <div>
        <h3>{t("example_media")}</h3>
        <div className="mediaExamples">
          {mediaSample.map((item) => (
            <div className="mediaExamples__item" key={item.name}>
              <img src={item.googleUrl} alt="" />
              <p className="mediaExamples__item__cat">
                {mediaTypeToTitle(item.locationAssociation?.category)}
              </p>
              <p className="mediaExamples__item__viewCount">
                {t("views")}：{item.insights?.viewCount ?? t("views")}
              </p>
              <p className="mediaExamples__item__date">
                {dayjs(item.createTime).format("YYYY年M月D日")}
              </p>
            </div>
          ))}
        </div>
        <p>{t("may_be_different_from_gbp")}</p>
      </div>
    </>
  );
};

export default Media;
