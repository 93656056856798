import React from "react";
import { useTranslation } from "react-i18next";

import { MediaPreview } from "./MediaPreview";

import "./styles.scss";

interface IMediaUpload {
  maxFiles?: number;
  maxSizeMiB?: number;
  minWidth?: number;
  minHeight?: number;
  files: File[];
  changeFiles: (files: File[]) => void;
  error?: string | undefined;
  changeError: (error: string | undefined) => void;
}

export const MediaUpload: React.FunctionComponent<IMediaUpload> = ({
  maxFiles = 1,
  maxSizeMiB = 1,
  minWidth = 400,
  minHeight = 300,
  files,
  changeFiles,
  error,
  changeError,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(
      `analysisCollectionsPage.modalCreateLocalPost.createLocalPostForm.${key}`
    );

  const [isDraggingFile, setDraggingFile] = React.useState<boolean>(false);

  const handleBadFileType = () => {
    changeError(t("wrong_file_type_error"));
  };

  const handleTooManyFiles = () => {
    changeError(t("too_many_files_error"));
  };

  const handleFileTooLarge = () => {
    changeError(t("file_too_large_error"));
  };

  const handleFileTooSmall = () => {
    changeError(t("file_too_small_error"));
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    if (
      event.dataTransfer.types.includes("application/x-moz-file") ||
      event.dataTransfer.types.includes("Files")
    ) {
      setDraggingFile(true);
    }
  };

  const handleDragLeave = () => {
    setDraggingFile(false);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) return;
    const _files: File[] = [];

    for await (const file of Array.from(event.target.files)) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        handleBadFileType();
        continue;
      }
      const sizeMiB = file.size / 1024 / 1024;
      if (sizeMiB > maxSizeMiB) {
        handleFileTooLarge();
        continue;
      }
      await new Promise<void>((res, rej) => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width < minWidth || img.height < minHeight) {
            handleFileTooSmall();
            rej();
          } else {
            changeError(undefined);
            _files.push(file);
            res();
          }
        };
      });
    }

    // If maxFiles is 1 and user has selected 1 new file, simply replace the current file with the selected file
    if (maxFiles === 1 && _files.length === 1) {
      changeFiles(_files);
      event.target.value = "";
      return;
    }

    const newFiles = [...files, ..._files];
    if (newFiles.length > maxFiles) {
      handleTooManyFiles();
      event.target.value = "";
      return;
    }
    changeFiles(newFiles);

    event.target.value = "";
  };

  const handleRemove = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    changeFiles(newFiles);
    changeError(undefined);
  };

  return (
    <div
      className={`media-upload ${maxFiles === 1 ? "single" : "multiple"} ${
        isDraggingFile ? "dragging-file" : "not-dragging-file"
      } ${files.length > 0 ? "selected" : "not-selected"}`}
    >
      <div
        className={`media-upload-body ${
          maxFiles === 1 ? "single" : "multiple"
        }`}
      >
        {files.map((file, index) => (
          <MediaPreview
            key={index}
            file={file}
            remove={() => handleRemove(index)}
          />
        ))}
        <div className="drag-drop-file-upload">
          <div className="drag-drop-file-upload-prompt">
            <span>{t("select_files_top")}</span>
            <span>{t("select_files_center")}</span>
            <span className="select-files-link">
              <span className="icons icon-plus"></span>
              {t("select_files_bottom")}
            </span>
          </div>
          <input
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDragLeave}
            onChange={handleChange}
            type="file"
            multiple
            accept="image/jpeg,image/png"
          ></input>
        </div>
      </div>
      {error && <span className="error">{error}</span>}
    </div>
  );
};

const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png"];
