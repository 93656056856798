/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import {useTranslation} from 'react-i18next';

import { modalViewGAEvent } from '../../../utils/ga';
import './styles.scss';

export interface IModalAssignSelectionConfirmProps {
  onClose: () => void
  onSelectionConfirm: () => void
}

export const ModalAssignSelectionConfirm: React.FunctionComponent<IModalAssignSelectionConfirmProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`facilityManagementPage.modalAssignSelectionConfirm.${key}`)

  const { onClose, onSelectionConfirm} = props;

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent('confirm-assign');
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  return (    
    <div className="modal modal-default modal-assign-selection-confirm">
      <div className="modal-mains">
        <a className='btn-close'
          onClick={() => {
            onClose()
          }}>
        </a>
        <div className="top-title">
          {t('confirm')}
        </div>
        <p className='p-txt'>
          {t('are_you_sure_to_proceed')}
        </p>
        <div className="bottom-btns">
          <a className='btn btn-border'
            onClick={() => {
              onClose()
            }}>
            {t('cancel')}
          </a>
          <a className='btn btn-blue'
            onClick={() => {
              onSelectionConfirm()
            }}>
            {t('submit')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalAssignSelectionConfirm;
