import React from 'react';
import {useTranslation} from 'react-i18next';

import { Checkbox } from '../../FormElement/Checkbox';
import { IConditionItem } from '../ModalFilterCondition';
import './styles.scss';

export interface IItemConditionProps {  
  title: string;
  checkedAll: boolean;
  sectionName: string;
  isBlueTitle: boolean;
  dataList: IConditionItem[]
  changeSelectAll: (checked: boolean, sectionName: string) => void;
  changeCheckbox: (checked: boolean, index: number, sectionName: string) => void;
}

export const ItemCondition: React.FunctionComponent<IItemConditionProps> = (props) => {
  const { t: _t } = useTranslation()
  const t = (key: string) => _t(`facilityListPage.modalFilterCondition.${key}`)
  
  const { title, checkedAll, sectionName, isBlueTitle,
          dataList, changeSelectAll, changeCheckbox  } = props;

  return (    
    <div className={`check-groups ${isBlueTitle ? '' : 'mb-54'}`}>
      <div className={`blue-block-row flex ${isBlueTitle ? '' : 'mb-14'}`}>
        <div className={`${isBlueTitle ? 'blue-block' : 'bold-title'}`}>
          {title}
        </div>
        <Checkbox
           checked={checkedAll}
           label={t('select_all')}
           isSelectAll={true}
           id={`checkbox-select-all-${sectionName}`}
           onChange={(checked: boolean) => {
             changeSelectAll(checked, sectionName)
           }}
          />
      </div>
      <div className="check-list">
        <ul>
          {
            dataList.map((item, index) => (
              <li key={index}>
                <Checkbox
                   checked={item?.checked || false}
                   label={item.name}
                   id={`checkbox-${sectionName}-${index}`}
                   onChange={(checked: boolean) => {
                     changeCheckbox(checked, index, sectionName)
                   }}
                  />
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  );
};

export default ItemCondition;
