import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { InternalToolContainer } from "../../components/InternalTools/InternalToolsContainer";
import { InternalToolsContextProvider } from "../../components/InternalTools/InternalToolsContextProvider";
import InternalToolsSelect from "../../components/InternalTools/InternalToolsSelect";
import { INTERNAL_TOOLS_GOOGLE_LOGIN_URL } from "../../config";
import { useSearchQuery } from "../../hooks/queries";
import { GoogleInformation } from "../../models/GoogleInformation";

import "./styles.scss";

type IInternalToolsPageProps = RouteComponentProps<any>;

const InternalToolsPage: React.FunctionComponent<
  IInternalToolsPageProps
> = () => {
  const { t: _t } = useTranslation();
  const t = useCallback((key: string) => _t(`internaltoolsPage.${key}`), [_t]);

  const [googleInformation, setGoogleInformation] = useState<
    GoogleInformation | undefined
  >(undefined);

  const searchQuery = useSearchQuery();

  useEffect(() => {
    if (searchQuery) {
      const { accountName, accessToken, displayName } = searchQuery;
      if (accountName && accessToken && displayName) {
        setGoogleInformation({
          accountName: accountName as string,
          accessToken: accessToken as string,
          displayName: displayName as string,
        });
      }
    }
  }, [searchQuery]);

  return (
    <InternalToolsContextProvider>
      <div className="right-content internal-tool">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{"Internal Tools"}</div>
          <div className="line"></div>
        </div>

        <div className="internal-tools-header">
          <div>
            <div className="internal-tools-login">
              <a
                className="btn btn-blue"
                href={INTERNAL_TOOLS_GOOGLE_LOGIN_URL}
              >
                Googleにログイン
              </a>
            </div>
            {googleInformation && (
              <>
                <h3>{t("public.currently_logged_in_as")}</h3>
                <p>{googleInformation.displayName}</p>
              </>
            )}
            <hr />
          </div>
          <InternalToolsSelect />
        </div>
        <InternalToolContainer initialGoogleInformation={googleInformation} />
      </div>
    </InternalToolsContextProvider>
  );
};

export default withRouter(InternalToolsPage);
