import React from "react";

import { LocalPostSkeleton } from "../LocalPostSkeleton";

interface IRecentPostsListSkeleton {
  numPosts?: number;
}

export const RecentPostsListSkeleton: React.FunctionComponent<
  IRecentPostsListSkeleton
> = (props: IRecentPostsListSkeleton) => {
  const { numPosts = 3 } = props;

  return (
    <div className="recent-local-post-list">
      <div className="local-post-list">
        {Array.from({ length: numPosts }).map((_value, index) => (
          <LocalPostSkeleton key={index} />
        ))}
      </div>
    </div>
  );
};
