import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { GoogleInformation } from "../../../../models/GoogleInformation";
import DataSvc from "../../../../services/dataSvc";

interface ICheckOwnerProps extends RouteComponentProps<any> {
  onLoading(loadingText: string | void): void;
  onLoaded(): void;
  googleInformation?: GoogleInformation;
  locationName?: string;
}

interface CheckOwnerData {
  query: string;
  locations?: {
    website?: string;
    address?: string;
    title?: string;
    hasOwner?: boolean;
  }[];
  error?: string;
}

const CheckOwner: React.FunctionComponent<ICheckOwnerProps> = ({
  onLoading,
  onLoaded,
  googleInformation,
  locationName: locationNameFromProps,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string, replace?: object) =>
    _t(`internaltoolsPage.checkOwner.${key}`, replace);
  const [placeName, setPlaceName] = useState<string>("");
  const [places, setPlaces] = useState<CheckOwnerData[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (locationNameFromProps) {
      setPlaceName(locationNameFromProps);
    }
  }, [locationNameFromProps]);

  const checkOwnerStatus = async () => {
    onLoading();
    setErrorMessage("");

    try {
      const data = await DataSvc.checkOwner({
        accessToken: googleInformation?.accessToken,
        query: placeName,
      });

      setPlaces(data);
    } catch (err) {
      setErrorMessage(
        _t("internaltoolsPage.public.error", { errorMessage: err })
      );
    }

    onLoaded();
  };

  return (
    <React.Fragment>
      <h1>{t("main_title")}</h1>
      <section className="card input">
        <p>{t("title_1")}</p>
        <p>{t("title_2")}</p>
        {!locationNameFromProps && (
          <input
            type="text"
            placeholder={t("place_name")}
            className="locationName"
            onChange={(e) => setPlaceName(e.target.value)}
          />
        )}
        <button type="button" onClick={checkOwnerStatus}>
          {t("execute")}
        </button>
        {errorMessage && <h3 id="error">{errorMessage}</h3>}
      </section>
      <h2>{t("facility_information")}</h2>
      <section className="card">
        {places.map(({ query, locations, error }, index) => (
          <>
            <h2>
              {t("action_title", { index: index + 1, actionTitle: query })}
            </h2>
            {error ? (
              <p>{error}</p>
            ) : (
              locations?.map(({ title, website, address, hasOwner }) => (
                <>
                  <h3>{title}</h3>
                  <p>
                    {t("has_owner", {
                      hasOwner: hasOwner
                        ? t("has_owner_already")
                        : t("no_owner"),
                    })}
                  </p>
                  <p>{t("address", { address })}</p>
                  <p>
                    <a href={website}>{t("website")}</a>
                  </p>
                </>
              ))
            )}
          </>
        ))}
      </section>
    </React.Fragment>
  );
};

export default withRouter(CheckOwner);
