/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { EmailState } from "../../../containers/SendMessageScreens/utils";
import { InputBox } from "../../FormElement/InputBox";
import { InputTextArea } from "../../FormElement/InputTextarea";

export interface IEmailFormProps extends EmailState {
  onChangeTextVariable: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => void;
  userEmail: string;
  adminMode: boolean;
}

export const EmailForm: React.FunctionComponent<IEmailFormProps> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`emailForm.${key}`);

  const {
    messageTitle,
    messageText,
    replyEmail,
    userEmail,
    bccTo,
    messageTitleError,
    messageTextError,
    replyEmailError,
    bccToError,
    onChangeTextVariable,
    adminMode
  } = props;

  return (
    <div className="message-form">
      <h2 className="top-title">{t("message_details")}</h2>
      <h3>{t("message_title")}</h3>
      <InputBox
        name="messageTitle"
        value={messageTitle}
        placeholder={t("message_title_placeholder")}
        classNameContainer={"message-title-input"}
        onChange={() => {}}
        onChangeEvent={onChangeTextVariable}
        errorLabel={messageTitleError}
      />
      <h3>{t("message_body")}</h3>
      <InputTextArea
        name="messageText"
        value={messageText}
        placeholder={t("message_body_placeholder")}
        classNameContainer={"message-body-input"}
        onChange={() => {}}
        onChangeEvent={onChangeTextVariable}
        errorLabel={messageTextError}
      />
      <h3>{t("reply_to")}</h3>
      <p>
        {adminMode
          ? _t("emailForm.if_reply_to_not_set_admin", { userEmail })
          : _t("emailForm.if_reply_to_not_set", { userEmail })}
      </p>
      <InputBox
        name="replyEmail"
        value={replyEmail}
        placeholder={t("reply_to_placeholder")}
        classNameContainer={"reply-to-input"}
        onChange={() => {}}
        onChangeEvent={onChangeTextVariable}
        errorLabel={replyEmailError}
      />
      {adminMode && (
        <>
          <h3>{t("bcc_to")}</h3>
          <p>{t("use_as_copy_etc")}</p>
          <InputBox
            name="bccTo"
            value={bccTo}
            placeholder={t("reply_to_placeholder")}
            classNameContainer={"bcc-to-input"}
            onChange={() => {}}
            onChangeEvent={onChangeTextVariable}
            errorLabel={bccToError}
          />
        </>
      )}
    </div>
  );
};

export default EmailForm;
