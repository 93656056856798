import { CodeResponse } from "@react-oauth/google";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { FacilityRegistrationList } from "../../components/FacilityRegistrationComponents/FacilityRegistrationList";
import { FacilityRegistrationTopInfo } from "../../components/FacilityRegistrationComponents/FacilityRegistrationTopInfo";
import { ModalFacilitySelection } from "../../components/FacilityRegistrationComponents/ModalFacilitySelection";
import { useMe } from "../../hooks/queries";
import {
  LocationData,
  convertToImportLocationsLocationData,
} from "../../models/LocationData";
import DataSvc from "../../services/dataSvc";

import "./styles.scss";

type IFacilityRegistrationPageProps = RouteComponentProps<any>;

const FacilityRegistrationPage: React.FunctionComponent<
  IFacilityRegistrationPageProps
> = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityRegistrationPage.${key}`);
  const { data: me } = useMe();

  const [managementLocations, setManagementLocations] = useState<
    LocationData[]
  >([]);
  const [managementLocationsData, setManagementLocationsData] = useState<
    LocationData[]
  >([]);
  const [isUnauthorizedInGoogle, setIsUnauthorizedInGoogle] = useState(false);
  const [shownModalFacilitySelection, setShownModalFacilitySelection] =
    useState<boolean>(false); // false

  const { mutateAsync: getLocations } = useMutation(() => {
    return DataSvc.getManagementLocations();
  });

  const registerMutation = useMutation((id: number) => {
    return DataSvc.registerLocation(id);
  });

  const deregisterMutation = useMutation((id: number) => {
    return DataSvc.deregisterLocation(id);
  });

  const updateRefreshTokenMutation = useMutation((code: string) => {
    return DataSvc.updateRefreshToken(code);
  });

  const loadLocations = () => {
    getLocations()
      .then((data) => {
        setManagementLocations(data);
        setIsUnauthorizedInGoogle(false);
      })
      .catch((e) => {
        if (e === "Unauthorized in Google") {
          setManagementLocations([]);
          setIsUnauthorizedInGoogle(true);
        }
      });
  };

  useEffect(loadLocations, [getLocations]);

  useEffect(() => {
    if (managementLocations) {
      const managementLocationsData = convertToImportLocationsLocationData(
        managementLocations,
        me?.type === "org_admin"
      );
      setManagementLocationsData(managementLocationsData);
    }
    // eslint-disable-next-line
  }, [managementLocations]);

  // on Update Facility Selection
  const onUpdateFacilitySelection = (
    registeredList: LocationData[],
    unregisteredList: LocationData[]
  ) => {
    Promise.all(
      registeredList
        .map((l) => registerMutation.mutateAsync(l.id))
        .concat(
          unregisteredList.map((l) => deregisterMutation.mutateAsync(l.id))
        )
    ).finally(loadLocations);
  };

  const onGoogleSignInSuccess = (codeResponse: CodeResponse) => {
    updateRefreshTokenMutation
      .mutateAsync(codeResponse.code)
      .then(loadLocations);
  };

  return (
    <>
      <div className="right-content facility-registration">
        <div className="top-title-bar flex-grid">
          <div className="left-title">{t("registerd_facilities")}</div>
        </div>

        <FacilityRegistrationTopInfo />

        <FacilityRegistrationList
          dataList={managementLocationsData}
          isUnauthorizedInGoogle={() => {
            return isUnauthorizedInGoogle;
          }}
          onClickEdit={() => {
            setShownModalFacilitySelection(true);
          }}
          onGoogleSignInSuccess={onGoogleSignInSuccess}
        />
      </div>
      {shownModalFacilitySelection && (
        <ModalFacilitySelection
          dataList={managementLocationsData}
          onClose={() => {
            setShownModalFacilitySelection(false);
          }}
          onUpdateFacilitySelection={(
            registeredList: LocationData[],
            unregisteredList: LocationData[]
          ) => {
            onUpdateFacilitySelection(registeredList, unregisteredList);
            setShownModalFacilitySelection(false);
          }}
        />
      )}
    </>
  );
};

export default withRouter(FacilityRegistrationPage);
