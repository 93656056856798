/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import _ from "lodash";
import moment from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import {
  useCategories,
  useLabels,
  useLocationMunis
} from "../../../hooks/queries";
import { CategoryData } from "../../../models/CategoryData";
import { CustomDashboardParams } from "../../../models/CustomDashboardParams";
import {
  FacilityFilterConditionData,
  createDefaultConditionFormData,
  hasConditionFilter
} from "../../../models/FacilityFilterConditionData";
import { LabelData } from "../../../models/LabelData";
import {
  MunicipalityData,
  MunicipalityGroupData
} from "../../../models/MunicipalityData";
import { ProfBooleanData } from "../../../models/ProfBooleanData";
import FormValidationSvc from "../../../services/formValidationSvc";
import { modalViewGAEvent } from "../../../utils/ga";
import IfOrCondition from "../../FacilityListComponents/IfOrCondition";
import ItemCondition from "../../FacilityListComponents/ItemCondition";
import { sortItems } from "../../FacilityListComponents/ModalFilterCondition";
import InputBox from "../../FormElement/InputBox";
import { ModalConfirm } from "../../ModalConfirm";

import "./styles.scss";

export interface IModalCustomDashboardConditionsProps {
  organizationId: string;
  userId?: number;
  customDashboards?: CustomDashboardParams[] | null;
  dashboardToEdit?: CustomDashboardParams;
  onClose: () => void;
  onApply: (
    title: string,
    conditionFormData: FacilityFilterConditionData
  ) => void;
  onDelete: (dashboardId: number) => void;
}

export const ModalCustomDashboardConditions: React.FunctionComponent<
  IModalCustomDashboardConditionsProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityListPage.modalFilterCondition.${key}`);
  const tCustomArchive = (key: string, params = {}) =>
    _t(`dashboardPage.modalCustomArchive.${key}`, { ...params });
  const nextSuggestionUpdateDate = moment()
    .clone()
    .add(1, "week")
    .startOf("week")
    .add(1, "day")
    .format("YYYY-MM-DD");

  const {
    userId,
    organizationId,
    customDashboards,
    dashboardToEdit,
    onClose,
    onApply,
    onDelete
  } = props;

  const [conditionFormData, setConditionFormData] =
    useState<FacilityFilterConditionData>(createDefaultConditionFormData());

  const [title, setTitle] = useState<string>(dashboardToEdit?.title ?? "");

  const [selectAll, setSelectAll] = useState<any>({
    healthIndex: false,
    municipalities: [false, false],
    categories: false,
    labels: false
  });

  const [mode, setMode] = useState("delete");

  const [hasLodging, setHasLodging] = useState<ProfBooleanData>({
    key: "has_lodging",
    shouldInclude: dashboardToEdit?.conditions.has_lodging
  });

  const [municipalitiesData, setMunicipalitiesData] = useState<
    MunicipalityGroupData[]
  >([]);
  const [categoriesData, setCategoriesData] = useState<CategoryData[]>([]);
  const [labelsData, setLabelsData] = useState<LabelData[]>([]);

  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false);
  const [titleError, setTitleError] = useState<string>("");

  const { data: labels } = useLabels(
    _.pickBy({
      organization_id: organizationId
    })
  );
  const { data: categories } = useCategories();
  const { data: municipalities } = useLocationMunis(userId, organizationId);

  useEffect(() => {
    if (municipalities) {
      if (dashboardToEdit?.conditions.municipality_id) {
        dashboardToEdit.conditions.municipality_id.forEach((muniId) => {
          const relevantMuni = municipalities.find(
            (muni) => muni.id.toString() === muniId.toString()
          );

          if (relevantMuni) {
            relevantMuni.checked = true;
          }
        });
      }

      const prefArray = municipalities.filter((item) => {
        return item.type === "pref";
      });

      const selectAllTemp = selectAll;
      selectAllTemp.municipalities = [];
      const municipalitiesArray: MunicipalityGroupData[] = [];

      prefArray.forEach((itemPref) => {
        const muniArray = municipalities
          .filter((itemMuni) => {
            return (
              itemMuni.type === "muni" &&
              itemMuni.parent_external_id === itemPref.external_id
            );
          })
          .map((muni) => {
            if (itemPref.checked) {
              muni.checked = true;
            }
            return muni;
          });

        municipalitiesArray.push({
          name: itemPref.name,
          muniList: muniArray
        });
        selectAllTemp.municipalities.push(false);
      });

      setMunicipalitiesData(_.cloneDeep(municipalitiesArray));
      setSelectAll(_.cloneDeep(selectAllTemp));
    }
    // eslint-disable-next-line
  }, [municipalities]);

  useEffect(() => {
    if (categories) {
      if (dashboardToEdit?.conditions.category_id) {
        dashboardToEdit.conditions.category_id.forEach((categoryId) => {
          const relevantCat = categories.find(
            (cat) => cat.id.toString() === categoryId.toString()
          );

          if (relevantCat) {
            relevantCat.checked = true;
          }
        });
      }
      const sortedCategories = sortItems("categories", categories);
      setCategoriesData(sortedCategories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    if (labels) {
      if (dashboardToEdit?.conditions.label_id) {
        dashboardToEdit.conditions.label_id.forEach((labelId) => {
          const relevantLabel = labels.find(
            (label) => label.id.toString() === labelId.toString()
          );

          if (relevantLabel) {
            relevantLabel.checked = true;
          }
        });
      }
      setLabelsData(labels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent("create-edit-custom-dashboard");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Change lodging
  const changeLodging = (key: string, nextValue: boolean | undefined) => {
    setHasLodging({
      key,
      shouldInclude: nextValue
    });
  };

  // change Checkbox
  const changeCheckbox = (value: boolean, index: number, name: string) => {
    let checkboxLabel = "";
    switch (name) {
      case "categories":
        const categoriesDataTemp = categoriesData;
        categoriesDataTemp[index].checked = value;
        checkboxLabel = categoriesDataTemp[index].name;
        setCategoriesData(_.cloneDeep(categoriesDataTemp));
        break;
      case "labels":
        const labelsDataTemp = labelsData;
        labelsDataTemp[index].checked = value;
        checkboxLabel = labelsDataTemp[index].name;
        setLabelsData(_.cloneDeep(labelsDataTemp));
        break;
    }

    ReactGA.event({
      category: `${
        dashboardToEdit ? "Edit" : "Create"
      } Custom Dashboard Filter`,
      action: `Toggle ${name}`,
      label: checkboxLabel,
      value: value ? 0 : 1 // value === true is removing checkbox
    });
  };

  // change Checkbox Municipality
  const changeCheckboxMunicipality = (
    value: boolean,
    index: number,
    indexMunicipalityGroup: number
  ) => {
    const municipalitiesDataTemp = municipalitiesData;
    municipalitiesDataTemp[indexMunicipalityGroup].muniList[index].checked =
      value;
    setMunicipalitiesData(_.cloneDeep(municipalitiesDataTemp));

    ReactGA.event({
      category: `${
        dashboardToEdit ? "Edit" : "Create"
      } Custom Dashboard Filter`,
      action: `Toggle municipality`,
      label:
        municipalitiesDataTemp[indexMunicipalityGroup].muniList[index].name,
      value: value ? 0 : 1 // value === true is removing checkbox
    });
  };

  // change Select All
  const changeSelectAll = (value: boolean, name: string) => {
    const selectAllTemp = selectAll;

    switch (name) {
      case "categories":
        selectAllTemp.categories = value;
        const categoriesDataTemp = categoriesData;
        categoriesDataTemp.forEach((item) => {
          item.checked = value;
        });
        setCategoriesData(_.cloneDeep(categoriesDataTemp));
        break;
      case "labels":
        selectAllTemp.labels = value;
        const labelsDataTemp = labelsData;
        labelsDataTemp.forEach((item) => {
          item.checked = value;
        });
        setLabelsData(_.cloneDeep(labelsDataTemp));
        break;
    }

    setSelectAll(_.cloneDeep(selectAllTemp));

    ReactGA.event({
      category: `${
        dashboardToEdit ? "Edit" : "Create"
      } Custom Dashboard Filter`,
      action: `Toggle ${name}`,
      label: "all",
      value: value ? 0 : 1 // value === true is removing checkbox
    });
  };

  // change Select All Municipality
  const changeSelectAllMunicipality = (
    value: boolean,
    indexMunicipalityGroup: number
  ) => {
    const selectAllTemp = selectAll;

    selectAllTemp.municipalities[indexMunicipalityGroup] = value;
    const municipalitiesDataTemp = municipalitiesData;
    municipalitiesDataTemp[indexMunicipalityGroup].muniList.forEach(
      (item: MunicipalityData) => {
        item.checked = value;
      }
    );
    setMunicipalitiesData(_.cloneDeep(municipalitiesDataTemp));

    setSelectAll(_.cloneDeep(selectAllTemp));

    ReactGA.event({
      category: `${
        dashboardToEdit ? "Edit" : "Create"
      } Custom Dashboard Filter`,
      action: `Toggle municipality`,
      label: "all",
      value: value ? 0 : 1 // value === true is removing checkbox
    });
  };

  // on Clear
  const onClear = () => {
    changeLodging("has_lodging", undefined);
    municipalitiesData.forEach((item, index) => {
      changeSelectAllMunicipality(false, index);
    });
    changeSelectAll(false, "categories");
    changeSelectAll(false, "labels");

    ReactGA.event({
      category: `${
        dashboardToEdit ? "Edit" : "Create"
      } Custom Dashboard Filter`,
      action: `Clear all`
    });
  };

  const validateParams = (conditionData: FacilityFilterConditionData) => {
    let validated = true;

    if (!title) {
      setTitleError(tCustomArchive("please_input_title"));
      validated = false;
    }

    const sameNameCustomDashboard = customDashboards?.find(
      (dashboard) => dashboard.title === title
    );
    if (
      sameNameCustomDashboard && // Dashboard with same name exists
      (!dashboardToEdit || // On create
        dashboardToEdit.id !== sameNameCustomDashboard.id) // Allow if is edit mode and name is the same
    ) {
      setTitleError(tCustomArchive("please_put_different_title"));
      validated = false;
    }

    if (!hasConditionFilter(conditionData)) {
      setTitleError(tCustomArchive("please_set_at_least_one_condition"));
      validated = false;
    }

    return validated;
  };

  const onClickApply = () => {
    const conditionFormDataTemp = conditionFormData;

    if (hasLodging.shouldInclude !== undefined) {
      conditionFormDataTemp.hasLodging = hasLodging;
    }

    const municipalitiesArray: MunicipalityGroupData[] = [];
    municipalitiesData.forEach((item) => {
      item.muniList = item.muniList.filter(function (item: MunicipalityData) {
        return item.checked;
      });

      municipalitiesArray.push(item);
    });

    conditionFormDataTemp.municipalities = municipalitiesArray;

    conditionFormDataTemp.categories = categoriesData.filter(function (
      item: CategoryData
    ) {
      return item.checked;
    });
    conditionFormDataTemp.labels = labelsData.filter(function (
      item: LabelData
    ) {
      return item.checked;
    });

    if (!validateParams(conditionFormDataTemp)) {
      return;
    }

    setConditionFormData(_.cloneDeep(conditionFormDataTemp));

    onApply(title, _.cloneDeep(conditionFormDataTemp));

    ReactGA.event({
      category: "Facility Filter",
      action: `Apply conditions`
    });
  };

  return (
    <div className="filter-panel custom-archive">
      <div className="filter-mains">
        <a
          className="icons btn-close"
          onClick={() => {
            onClose();
          }}
        ></a>
        <div className="top-title">
          <div className="rights">
            {dashboardToEdit && (
              <a
                className="icons icon-del"
                onClick={() => {
                  setMode("delete");
                  setShownModalConfirm(true);
                }}
              >
                &nbsp;
              </a>
            )}
          </div>
          <div className="titles">{tCustomArchive("title")}</div>
          <InputBox
            value={title}
            errorLabel={titleError}
            placeholder={tCustomArchive("title_placeholder")}
            pattern="[\W\w]{0,40}"
            onChange={() => {}}
            onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
              setTitle(
                FormValidationSvc.validateInputEnteringPattern(event, title)
              );
            }}
          />
        </div>
        <div className="top-title flex-grid">
          <div className="titles">{t("filter_condition")}</div>
          <div className="rights">
            <a
              href="#javascript"
              className="btn btn-border"
              onClick={(event) => {
                onClear();
                event.preventDefault();
              }}
            >
              {t("clear")}
            </a>
          </div>
        </div>
        <div className="check-full-wrap">
          <div className="check-groups">
            <div className="blue-block-row flex">
              <div className="blue-block">{t("fac_type")}</div>
            </div>
            <div className="check-groups-horizontal">
              <IfOrCondition
                isBlueTitle={false}
                item={hasLodging}
                changeCheckbox={changeLodging}
              />
            </div>
          </div>

          <div className="check-groups">
            <div className="blue-block-row flex">
              <div className="blue-block">{t("municipalities")}</div>
            </div>
            {municipalitiesData.map(
              (item: MunicipalityGroupData, indexMunicipalityGroup: number) => (
                <React.Fragment key={indexMunicipalityGroup}>
                  <ItemCondition
                    title={item.name}
                    checkedAll={
                      selectAll.municipalities[indexMunicipalityGroup]
                    }
                    sectionName={item.name}
                    isBlueTitle={false}
                    dataList={item.muniList}
                    changeSelectAll={(checked: boolean) => {
                      changeSelectAllMunicipality(
                        checked,
                        indexMunicipalityGroup
                      );
                    }}
                    changeCheckbox={(checked: boolean, index: number) => {
                      changeCheckboxMunicipality(
                        checked,
                        index,
                        indexMunicipalityGroup
                      );
                    }}
                  />
                </React.Fragment>
              )
            )}
          </div>
          <ItemCondition
            title={t("categories")}
            checkedAll={selectAll.categories}
            sectionName={"categories"}
            isBlueTitle={true}
            dataList={categoriesData}
            changeSelectAll={(checked: boolean, sectionName: string) => {
              changeSelectAll(checked, sectionName);
            }}
            changeCheckbox={(
              checked: boolean,
              index: number,
              sectionName: string
            ) => {
              changeCheckbox(checked, index, sectionName);
            }}
          />
          <ItemCondition
            title={t("labels")}
            checkedAll={selectAll.labels}
            sectionName={"labels"}
            isBlueTitle={true}
            dataList={labelsData}
            changeSelectAll={(checked: boolean, sectionName: string) => {
              changeSelectAll(checked, sectionName);
            }}
            changeCheckbox={(
              checked: boolean,
              index: number,
              sectionName: string
            ) => {
              changeCheckbox(checked, index, sectionName);
            }}
          />
        </div>
        <div className="bottom-btns">
          <a
            className="btn btn-border"
            onClick={() => {
              onClose();
            }}
          >
            {t("cancel")}
          </a>
          <a
            className="btn btn-blue"
            onClick={(event) => {
              if (dashboardToEdit) {
                setMode("edit");
                setShownModalConfirm(true);
              } else {
                onClickApply();
              }

              event.preventDefault();
            }}
          >
            {t("apply")}
          </a>
        </div>
      </div>

      {shownModalConfirm && dashboardToEdit && (
        <ModalConfirm
          title={"please_confirm_change_conditions"}
          secondaryDescription={
            mode === "delete"
              ? `${tCustomArchive("will_delete_this_dashboard")}${
                  dashboardToEdit.title
                }`
              : `${tCustomArchive("will_update_this_dashboard", {
                  title: dashboardToEdit.title,
                  suggestion_date: nextSuggestionUpdateDate
                })}`
          }
          cancelLabel={"cancel"}
          confirmLabel={"ok"}
          onClose={() => {
            setMode("delete");
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            if (mode === "edit") {
              onClickApply();
            } else if (mode === "delete") {
              onDelete(dashboardToEdit.id);
            }
            setMode("delete");
            setShownModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default ModalCustomDashboardConditions;
