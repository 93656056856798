import * as React from "react";
import ReactGA from "react-ga4";

import { useMe } from "../../../hooks/queries";

interface ICreateLocalPostButtonProps {
  onClickCreatePostToGoogleBusinessProfile: () => void;
  innerText: string;
  gbpLink: string;
}

export const CreateLocalPostButton: React.FunctionComponent<
  ICreateLocalPostButtonProps
> = (props) => {
  const { onClickCreatePostToGoogleBusinessProfile, innerText, gbpLink } =
    props;

  const { data: me } = useMe();

  return me?.type === "loc_admin" ? (
    <button
      className="btn btn-border"
      onClick={onClickCreatePostToGoogleBusinessProfile}
    >
      {innerText}
    </button>
  ) : (
    <a
      href={gbpLink}
      className="btn btn-border"
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        ReactGA.event({
          category: "GBP Link",
          action: "Click to GBP",
          label: "localPosts",
        });
      }}
    >
      {innerText}
    </a>
  );
};
