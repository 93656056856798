/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import {
  AccountData,
  AccountInformationData,
} from "../../../models/AccountData";
import formValidationSvc from "../../../services/formValidationSvc";
import { InputBox } from "../../FormElement/InputBox";
import { RadioBox } from "../../FormElement/RadioBox";
import { ModalConfirm } from "../../ModalConfirm";
import "./styles.scss";

export interface IModalEditMemberProps {
  onClose: () => void;
  onDelete: (id: number) => void;
  onSave: (formData: AccountInformationData) => void;
  isAdminMode?: boolean;
  accountData?: AccountData;
  dataList: AccountData;
}

export const ModalEditMember: React.FunctionComponent<IModalEditMemberProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`accountManagementPage.modalEditMember.${key}`);

  const [confirmType, setConfirmType] = useState<string>(""); // 'save', 'delete'
  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false); // false

  const [formData, setFormData] = useState<AccountInformationData>({
    familyName: "",
    firstName: "",
    email: "",
    role: "",
  });

  const { onClose, onDelete, onSave, isAdminMode, accountData, dataList } =
    props;

  useEffect(() => {
    if (dataList) {
      const str = dataList.name;
      const first = str.substring(0, str.indexOf(" "));
      const second = str.substring(str.indexOf(" ") + 1);

      setFormData({
        ...formData,
        familyName: first,
        firstName: second,
        email: dataList.email,
        role: dataList.role,
      });
    }
    // eslint-disable-next-line
  }, [dataList]);

  // is Enabled Btn
  const isEnabledBtn = () => {
    return (
      formData.familyName !== "" &&
      formData.firstName !== "" &&
      formData.email !== "" &&
      formData.role !== ""
    );
  };

  return (
    <div className="modal modal-default modal-edit-member">
      <div className="modal-mains">
        <a
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        ></a>
        <div className="top-title flex-grid">
          {t("edit_member")}
          <div className="rights">
            <a
              className="icons icon-del"
              onClick={() => {
                setShownModalConfirm(true);
                setConfirmType("delete");
              }}
            >
              &nbsp;
            </a>
          </div>
        </div>

        <div className="row row-form">
          <div className="col col-md-6">
            <div className="title-bar">
              <div className="label-title flex">{t("family_name")}</div>
              <InputBox
                value={formData.familyName}
                placeholder={t("family_name")}
                classNameContainer={"family_name-input"}
                pattern="[\W\w]{0,20}"
                onChange={() => {}}
                onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                  setFormData({
                    ...formData,
                    familyName: formValidationSvc.validateInputEnteringPattern(
                      event,
                      formData.familyName
                    ),
                  });
                }}
              />
            </div>
          </div>
          <div className="col col-md-6">
            <div className="title-bar">
              <div className="label-title flex">{t("first_name")}</div>
              <InputBox
                value={formData.firstName}
                placeholder={t("first_name")}
                classNameContainer={"first_name-input"}
                pattern="[\W\w]{0,20}"
                onChange={() => {}}
                onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
                  setFormData({
                    ...formData,
                    firstName: formValidationSvc.validateInputEnteringPattern(
                      event,
                      formData.firstName
                    ),
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="radio-groups">
          <div className="label-txt">{t("role")}</div>
          <div className="two-radio">
            <div className="radio-wrapper">
              <RadioBox
                checked={formData.role === "viewer"}
                label={t("viewer")}
                subLabel={t("member_can_view_reports")}
                id={"role-type-viewer"}
                name={"role-type"}
                onChange={() => {
                  setFormData({
                    ...formData,
                    role: "viewer",
                  });
                }}
              />
            </div>
            {(isAdminMode || accountData?.organization.type !== "location") && (
              <div className="radio-wrapper">
                <RadioBox
                  checked={formData.role === "editor"}
                  label={t("editor")}
                  subLabel={t("member_can_create_charts_and_send_emails")}
                  id={"role-type-editor"}
                  name={"role-type"}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      role: "editor",
                    });
                  }}
                />
              </div>
            )}
            <div className="radio-wrapper">
              <RadioBox
                checked={formData.role === "manager"}
                label={t("manager")}
                subLabel={t(
                  "member_can_create_charts_send_emails_and_manage_members"
                )}
                id={"role-type-manager"}
                name={"role-type"}
                onChange={() => {
                  setFormData({
                    ...formData,
                    role: "manager",
                  });
                }}
              />
            </div>
          </div>
        </div>

        <div className="bottom-btns">
          <a
            className="btn btn-border"
            onClick={() => {
              onClose();
            }}
          >
            {t("cancel")}
          </a>
          <a
            className={`btn btn-blue ${!isEnabledBtn() ? "disabled" : ""}`}
            onClick={() => {
              setShownModalConfirm(true);
              setConfirmType("save");
            }}
          >
            {t("save")}
          </a>
        </div>
      </div>

      {shownModalConfirm && (
        <ModalConfirm
          title={
            confirmType === "save"
              ? "please_confirm_to_save_the_member"
              : "please_confirm_to_delete_the_member"
          }
          cancelLabel={"cancel"}
          confirmLabel={"confirm"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={() => {
            if (confirmType === "save") {
              onSave(formData);
            } else if (confirmType === "delete") {
              onDelete(dataList.id);
            }

            setShownModalConfirm(false);
          }}
        />
      )}
    </div>
  );
};

export default ModalEditMember;
