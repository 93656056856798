import dayjs from "dayjs";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import ActionMetrics from "./Parts/Actions";
import ChronoData from "./Parts/ChronoData";
import Keywords from "./Parts/Keywords";
import Media from "./Parts/Media";
import LocalPosts from "./Parts/PostTypes";
import QuickNav from "./Parts/QuickNav";
import ToolsChecklist from "./Parts/ToolsChecklist";
import { ToolsMetrics } from "./types";
import { GoogleInformation } from "../../../../models/GoogleInformation";
import { LocationData } from "../../../../models/LocationData";
import DataSvc from "../../../../services/dataSvc";
import { isMobile } from "../../../../utils/responsive";

import "./styles.scss";

const minDate = dayjs()
  .subtract(18, "months")
  .add(1, "day")
  .format("YYYY-MM-DD");
const minCompDate = dayjs().subtract(6, "months").format("YYYY-MM-DD");
const maxCompDate = dayjs().format("YYYY-MM-DD");
const minDateComponents = minCompDate.split("-");

export interface IGetMetricsProps extends RouteComponentProps<any> {
  onLoading(loadingText: string | void): void;
  onLoaded(): void;
  commonErrorHandler: (err: string) => string | void;
  googleInformation: GoogleInformation | undefined;
  locationData?: LocationData;
}
export const GetMetrics: React.FunctionComponent<IGetMetricsProps> = ({
  onLoading,
  onLoaded,
  commonErrorHandler,
  googleInformation,
  locationData,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`internaltoolsPage.metricsNew.${key}`);
  const [locationName, setLocationName] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [dataStartDate, setDataStartDate] = useState<string>("");
  const [dataEndDate, setDataEndDate] = useState<string>("");
  const [useWeekly, setUseWeekly] = useState<boolean>(false);
  const [comparisonStartDate, setComparisonStartDate] = useState<string>("");
  const [comparisonEndDate, setComparisonEndDate] = useState<string>("");
  const [comparisonTargetStartDate, setComparisonTargetStartDate] =
    useState<string>("");
  const [comparisonTargetEndDate, setComparisonTargetEndDate] =
    useState<string>("");
  const [skipNum, setSkipNum] = useState<number>(0);
  const [isRetryButtonEnable, setIsRetryButtonEnable] =
    useState<boolean>(false);
  // convert to boolean value
  const showToCustomer = !!locationData;

  const [metrics, setMetrics] = useState<ToolsMetrics | undefined>(undefined);

  /** return true if there should be an error, false if no error */
  const checkError = (
    accessToken: string | null,
    locationId: number | undefined
  ) => {
    // bypass all other checks if locationId is true because api called with locationId only needs locationId
    if (locationId) return false;

    let hasError = true;
    if (!accessToken) {
      setErrorMessage(t("please_login"));
    } else if (!locationName) {
      setErrorMessage(t("please_input_facility_name"));
    } else if (
      (dataStartDate && dataStartDate < minCompDate) ||
      (dataStartDate && dataEndDate && dataStartDate > dataEndDate)
    ) {
      setErrorMessage(t("date_error"));
    } else if (
      (comparisonStartDate && comparisonStartDate < minCompDate) ||
      (comparisonTargetStartDate && comparisonTargetStartDate < minCompDate)
    ) {
      setErrorMessage(t("comnpare_18_month_ago"));
    } else if (
      (comparisonEndDate && comparisonEndDate > maxCompDate) ||
      (comparisonTargetEndDate && comparisonTargetEndDate > maxCompDate)
    ) {
      setErrorMessage(t("end_date_can_not_the_future"));
    } else if (
      (comparisonStartDate &&
        comparisonEndDate &&
        comparisonStartDate > comparisonEndDate) ||
      (comparisonTargetStartDate &&
        comparisonTargetEndDate &&
        comparisonTargetStartDate > comparisonTargetEndDate)
    ) {
      setErrorMessage(t("end_date_can_not_before_start_date"));
    } else if (
      !comparisonTargetStartDate &&
      comparisonStartDate &&
      comparisonStartDate < minDate
    ) {
      setErrorMessage(t("compareism_not_allow"));
    } else {
      hasError = false;
    }

    return hasError;
  };

  const formatDatePickerInput = (date: Date | null) => {
    if (date === null) return "";
    return dayjs(date).format("YYYY-MM-DD");
  };

  const getMetrics = async (isRetryButton: boolean) => {
    const hasError = checkError(
      googleInformation?.accessToken ?? null,
      locationData?.id
    );
    if (hasError) {
      return;
    }

    setIsRetryButtonEnable(false);
    onLoading();

    const skip = isRetryButton ? skipNum : null;
    setErrorMessage("");

    if (locationData) {
      // User triggered get reviews
      ReactGA.event({
        category: "facility_details",
        action: "Get location metrics",
        label: useWeekly ? "by weekly" : "by monthly",
      });
    }

    const commonArgs = {
      dataStartDate,
      dataEndDate,
      useWeekly,
      comparisonStartDate,
      comparisonEndDate,
      comparisonTargetStartDate,
      comparisonTargetEndDate,
      skipNum: skip,
    };

    (locationData
      ? DataSvc.getToolsMetricsForLocation({
          locationId: locationData.id,
          ...commonArgs,
        })
      : DataSvc.getToolsMetrics({
          locationName,
          accountName: googleInformation?.accountName ?? "",
          accessToken: googleInformation?.accessToken ?? "",
          ...commonArgs,
        })
    )
      .then((data) => {
        setMetrics(data);
        setSkipNum(data.skipNum);
      })
      .catch((error) => {
        const decomposedError = commonErrorHandler(error);
        setErrorMessage(
          _t("internaltoolsPage.public.error", { error: decomposedError })
        );
      })
      .finally(() => {
        setIsRetryButtonEnable(true);
        onLoaded();
      });
  };

  const monthsToShow = isMobile() ? 1 : 2;

  return (
    <React.Fragment>
      <h1 className="main-title">
        {t(locationData ? "main_title_for_public" : "main_title")}
      </h1>
      <h2 className="sub-title">{t("input")}</h2>
      <section className="card">
        {!locationData && (
          <>
            <p>{t("section_1_title_1")}</p>
            <p>{t("section_1_title_2")}</p>
            <p>{t("section_1_title_3")}</p>
            <p>
              <strong>{t("section_1_title_4")}</strong>
              <br />
              <strong>{t("section_1_title_5")}</strong>
            </p>
            <input
              placeholder={t("location_name")}
              className="search-input"
              onChange={(e) => setLocationName(e.target.value)}
            />
            <p />
            <hr />
          </>
        )}
        <h3>{t("section_2_title_1")}</h3>
        <p>{t("section_2_title_2")}</p>
        <div>
          <label>{t("start_date")}</label>
          <ReactDatePicker
            locale="ja"
            selected={dataStartDate ? new Date(dataStartDate) : null}
            onChange={(startDate) => {
              setDataStartDate(formatDatePickerInput(startDate));
            }}
            monthsShown={monthsToShow}
            minDate={new Date(minDate)}
            maxDate={new Date(dataEndDate || maxCompDate)}
          />
          <br className="sp-only" />
          <label>{t("end_date")}</label>
          <ReactDatePicker
            locale="ja"
            selected={dataEndDate ? new Date(dataEndDate) : null}
            onChange={(startDate) => {
              setDataEndDate(formatDatePickerInput(startDate));
            }}
            monthsShown={monthsToShow}
            minDate={new Date(dataStartDate || minDate)}
            maxDate={new Date(maxCompDate)}
          />
          <p>
            <input
              type="checkbox"
              id="useWeekly"
              name="useWeekly"
              onChange={(e) => setUseWeekly(e.currentTarget.checked)}
            />
            <label>{t("weekly_check")}</label>
          </p>
        </div>
        {!showToCustomer && (
          <>
            <hr />
            <h3>{t("section_3_title_1")}</h3>
            <p>
              {_t("internaltoolsPage.metricsNew.min_date_detail", {
                year: Number(minDateComponents[0]) + 1,
                month: minDateComponents[1],
                day: minDateComponents[2],
              })}
            </p>
            <div>
              <label>{t("start_date")}</label>
              <ReactDatePicker
                locale="ja"
                selected={
                  comparisonStartDate ? new Date(comparisonStartDate) : null
                }
                onChange={(startDate) => {
                  setComparisonStartDate(formatDatePickerInput(startDate));
                }}
                monthsShown={monthsToShow}
                minDate={new Date(comparisonStartDate ? minDate : minCompDate)}
                maxDate={new Date(comparisonEndDate || maxCompDate)}
              />
              <label>{t("end_date")}</label>
              <br className="sp-only" />
              <ReactDatePicker
                locale="ja"
                selected={
                  comparisonEndDate ? new Date(comparisonEndDate) : null
                }
                onChange={(startDate) => {
                  setComparisonEndDate(formatDatePickerInput(startDate));
                }}
                monthsShown={monthsToShow}
                minDate={
                  comparisonTargetStartDate
                    ? new Date(comparisonStartDate || minCompDate)
                    : new Date(comparisonStartDate || minDate)
                }
                maxDate={new Date(maxCompDate)}
              />
            </div>
            <br />
            <br />
            <h3>{t("section_4_title_1")}</h3>
            <div>
              <label>{t("start_date")}</label>
              <ReactDatePicker
                locale="ja"
                selected={
                  comparisonTargetStartDate
                    ? new Date(comparisonTargetStartDate)
                    : null
                }
                onChange={(startDate) => {
                  setComparisonTargetStartDate(
                    formatDatePickerInput(startDate)
                  );
                }}
                monthsShown={monthsToShow}
                minDate={new Date(minCompDate)}
                maxDate={new Date(comparisonTargetEndDate || maxCompDate)}
              />
              <label>{t("end_date")}</label>
              <ReactDatePicker
                locale="ja"
                selected={
                  comparisonTargetEndDate
                    ? new Date(comparisonTargetEndDate)
                    : null
                }
                onChange={(startDate) => {
                  setComparisonTargetEndDate(formatDatePickerInput(startDate));
                }}
                monthsShown={monthsToShow}
                minDate={new Date(comparisonTargetStartDate || minCompDate)}
                maxDate={new Date(maxCompDate)}
              />
            </div>
          </>
        )}
        <p>
          <button
            type="button"
            className="internal-tools-button"
            onClick={() => getMetrics(false)}
          >
            {t("execute")}
          </button>
        </p>
        {errorMessage && <h3 id="error">{errorMessage}</h3>}
      </section>
      {metrics && (
        <>
          {!showToCustomer && (
            <>
              <h2>{t("facilities_information")}</h2>
              <section className="card">
                <p>{metrics.title}</p>
                <p>{metrics.address}</p>
                <p>
                  {t("section_5_title_1")}
                  <br />
                  <small>{t("section_5_title_2")}</small>
                </p>
                <p>
                  <button
                    type="button"
                    className="internal-tools-button"
                    onClick={() => getMetrics(true)}
                    disabled={!isRetryButtonEnable}
                  >
                    {t("retry")}
                  </button>
                </p>
              </section>
            </>
          )}
          <h2 className="big">{t("result")}</h2>
          <div id="metricResults">
            {!showToCustomer && (
              <div id="checklist" key="checklist">
                <h2>{t("checklist")}</h2>
                <section className="card">
                  <ToolsChecklist metrics={metrics} />
                </section>
              </div>
            )}
            <div id="chronoSections" key="chronoSections">
              <h2>{t("chronoSections")}</h2>
              <section className="card">
                <ChronoData
                  metrics={metrics}
                  useWeekly={useWeekly}
                  showToCustomer={showToCustomer}
                />
              </section>
            </div>
            {!showToCustomer && (
              <>
                <div id="posts" key="posts">
                  <h2>{t("posts")}</h2>
                  <section className="card">
                    <LocalPosts metrics={metrics} />
                  </section>
                </div>
                <div id="media" key="media">
                  <h2>{t("media")}</h2>
                  <section className="card">
                    <Media metrics={metrics} />
                  </section>
                </div>
              </>
            )}
            {!showToCustomer && (
              <div id="actions" key="actions">
                <h2>{t("actions")}</h2>
                <section className="card">
                  <ActionMetrics metrics={metrics} />
                </section>
              </div>
            )}
            <div id="searchKeywords" key="searchKeywords">
              <h2>{t("searchKeywords")}</h2>
              <section className="card">
                <Keywords metrics={metrics} />
              </section>
            </div>
          </div>
          {!showToCustomer && <QuickNav />}
        </>
      )}
    </React.Fragment>
  );
};

export default withRouter(GetMetrics);
