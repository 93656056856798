import dayjs from "dayjs";

export function createNestedFormData(data: any): FormData {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    append(formData, data[key], key);
  }
  return formData;
}

function append(formData: FormData, data: any, parentKey: string) {
  
  if (data && dayjs.isDayjs(data)) {
    formData.append(parentKey, data.format("YYYY/MM/DD HH:mm"));
  } else if (data && typeof data === "object" && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      append(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    formData.append(parentKey, data?.toString());
  }
}
