import React from "react";

import { ToolsMetrics } from "../../types";
import { convertMetricLabel } from "../ChronoData/utils";

const ActionMetrics: React.FunctionComponent<{
  metrics: ToolsMetrics;
}> = ({ metrics }) => {
  const { actionMetrics } = metrics;

  return (
    <div className="data-table">
      {actionMetrics.map(({ metric, totalValue }, index) => (
        <React.Fragment key={index}>
          <p>
            <strong>{convertMetricLabel(metric)}</strong>
          </p>
          <p>{totalValue}</p>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ActionMetrics;
