/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, KeyboardEvent } from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  PAGE_NUMBER_OPTIONS,
  FACILITY_SORT_BY,
  ORDER,
  FACILITY_SORT,
} from "../../../config";
import {
  FacilityFilterConditionData,
  hasConditionFilter,
} from "../../../models/FacilityFilterConditionData";
import DropdownSelect from "../../FormElement/DropdownSelect";
import { InputBox } from "../../FormElement/InputBox";
import ToggleBtns from "../../ToggleBtns";
import "./styles.scss";
import { CurrentFilters } from "../CurrentFilters/CurrentFilters";

export interface IListTopBarProps {
  conditionFormData: FacilityFilterConditionData;
  filterFormData: any;
  onClickFilter: () => void;
  onClickClearFilterCondition: () => void;
  onChangeFilterFormData: (filterFormData: any) => void;
}

export const getSortCriteria = (option: string) => {
  const criteria: any = {
    sortBy: option,
  };
  switch (option) {
    case FACILITY_SORT_BY.FACILITY_NAME_ASC:
      criteria.sort = FACILITY_SORT.NAME;
      criteria.order = ORDER.ASC;
      break;
    case FACILITY_SORT_BY.FACILITY_NAME_DESC:
      criteria.sort = FACILITY_SORT.NAME;
      criteria.order = ORDER.DESC;
      break;
    case FACILITY_SORT_BY.HEALTH_INDEX_ASC:
      criteria.sort = FACILITY_SORT.STATS_SCORE;
      criteria.order = ORDER.ASC;
      break;
    case FACILITY_SORT_BY.HEALTH_INDEX_DESC:
      criteria.sort = FACILITY_SORT.STATS_SCORE;
      criteria.order = ORDER.DESC;
      break;
    case FACILITY_SORT_BY.PROFILE_VIEWS_ASC:
      criteria.sort = FACILITY_SORT.STATS_PROFILE_VIEWS;
      criteria.order = ORDER.ASC;
      break;
    case FACILITY_SORT_BY.PROFILE_VIEWS_DESC:
      criteria.sort = FACILITY_SORT.STATS_PROFILE_VIEWS;
      criteria.order = ORDER.DESC;
      break;
    case FACILITY_SORT_BY.PROFILE_VIEWS_CHANGE_PERCENTAGE_ASC:
      criteria.sort = FACILITY_SORT.STATS_PROFILE_VIEWS_CHANGE_PERCENTAGE;
      criteria.order = ORDER.ASC;
      break;
    case FACILITY_SORT_BY.PROFILE_VIEWS_CHANGE_PERCENTAGE_DESC:
      criteria.sort = FACILITY_SORT.STATS_PROFILE_VIEWS_CHANGE_PERCENTAGE;
      criteria.order = ORDER.DESC;
      break;
    case FACILITY_SORT_BY.DIRECTION_SEARCH_COUNT_ASC:
      criteria.sort = FACILITY_SORT.STATS_DIRECTION_SEARCH_COUNT;
      criteria.order = ORDER.ASC;
      break;
    case FACILITY_SORT_BY.DIRECTION_SEARCH_COUNT_DESC:
      criteria.sort = FACILITY_SORT.STATS_DIRECTION_SEARCH_COUNT;
      criteria.order = ORDER.DESC;
      break;
    case FACILITY_SORT_BY.DIRECTION_SEARCH_COUNT_CHANGE_PERCENTAGE_ASC:
      criteria.sort =
        FACILITY_SORT.STATS_DIRECTION_SEARCH_COUNT_CHANGE_PERCENTAGE;
      criteria.order = ORDER.ASC;
      break;
    case FACILITY_SORT_BY.DIRECTION_SEARCH_COUNT_CHANGE_PERCENTAGE_DESC:
      criteria.sort =
        FACILITY_SORT.STATS_DIRECTION_SEARCH_COUNT_CHANGE_PERCENTAGE;
      criteria.order = ORDER.DESC;
      break;
    case FACILITY_SORT_BY.LOCATION_ASC:
      criteria.sort = FACILITY_SORT.LOCATION;
      criteria.order = ORDER.ASC;
      break;
    case FACILITY_SORT_BY.LOCATION_DESC:
      criteria.sort = FACILITY_SORT.LOCATION;
      criteria.order = ORDER.DESC;
      break;
    case FACILITY_SORT_BY.CATEGORY_ASC:
      criteria.sort = FACILITY_SORT.CATEGORY;
      criteria.order = ORDER.ASC;
      break;
    case FACILITY_SORT_BY.CATEGORY_DESC:
      criteria.sort = FACILITY_SORT.CATEGORY;
      criteria.order = ORDER.DESC;
      break;
    default:
      toast.error("有効ではないオプションです。");
      break;
  }
  return criteria;
};

export const ListTopBar: React.FunctionComponent<IListTopBarProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityListPage.facilityList.listTopBar.${key}`);

  const {
    conditionFormData,
    filterFormData,
    onClickFilter,
    onClickClearFilterCondition,
    onChangeFilterFormData,
  } = props;
  const [searchName, setSearchName] = useState<string>(
    filterFormData?.searchName || ""
  );

  return (
    <div
      className={`list-top-bar flex-grid ${
        hasConditionFilter(conditionFormData) ? "filter-after" : ""
      }`}
    >
      <div className="lefts">
        <div className="filter-wrap">
          <div className="filter-some-block">
            <a
              className="btn btn-filter"
              onClick={() => {
                onClickFilter();
              }}
            >
              <i className="icons icon-filter"></i>
              <span className="txt">
                {!hasConditionFilter(conditionFormData)
                  ? t("filter")
                  : t("filtered")}
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="rights flex">
        <div className="search-module">
          <div className="top-txt">&nbsp;</div>
          <InputBox
            value={searchName}
            placeholder={t("search_by_name")}
            isSearch={true}
            classNameContainer={"search-input"}
            onChange={(value: string) => {
              setSearchName(value);
            }}
            onKeyPress={(event: KeyboardEvent<HTMLImageElement>) => {
              if (event.key === "Enter") {
                ReactGA.event({
                  category: "Facility List",
                  action: "Text search",
                  label: searchName,
                });
                onChangeFilterFormData({
                  ...filterFormData,
                  searchName: searchName,
                  pageIndex: 1,
                });
              }
            }}
          />
        </div>
        <div className="sort-drop">
          <div className="top-txt">{t("sort")}</div>
          <DropdownSelect
            fieldLabel={""}
            value={filterFormData.sortBy}
            items={Object.values(FACILITY_SORT_BY)}
            classNameContainer={"sort-dropdown"}
            onChange={(value: string) => {
              ReactGA.event({
                category: "Facility List",
                action: "Change sort order",
                label: value,
              });
              onChangeFilterFormData({
                ...filterFormData,
                ...getSortCriteria(value),
                pageIndex: 1,
              });
            }}
          />
        </div>
        <div className="choose-area">
          <div className="top-txt">{t("number_of_facilities_per_page")}</div>
          <ToggleBtns
            selectedValue={filterFormData.numberPerPage}
            options={PAGE_NUMBER_OPTIONS}
            onClickBtn={(option: number) => {
              ReactGA.event({
                category: "Facility List",
                action: "Change page size",
                label: `${option}`,
              });
              onChangeFilterFormData({
                ...filterFormData,
                numberPerPage: option,
                pageIndex: 1,
              });
            }}
          />
        </div>
      </div>
      {hasConditionFilter(conditionFormData) && (
        <CurrentFilters
          conditionFormData={conditionFormData}
          onClickClearFilterCondition={onClickClearFilterCondition}
        />
      )}
    </div>
  );
};

export default ListTopBar;
