import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const LineButton: React.FunctionComponent = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`lineButton.${key}`);

  return (
    <div className="line-button">
      <span>
        {t("line_one")}
        <br />
        {t("line_two")}
      </span>
      <a
        className="line"
        href="https://lin.ee/gDIcKSE"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/assets/line-round.png" alt="LINE" />
      </a>
    </div>
  );
};

export default LineButton;
