import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ArrangedUseLimitData } from "../../../models/FeatureUseLimitsData";
import DropdownSelect from "../../FormElement/DropdownSelect";
import InputBox from "../../FormElement/InputBox";

import "./styles.scss";

export const ActionTypes = {
  EVERY_DAY: "EVERY_DAY",
  EVERY_WEEK: "EVERY_WEEK",
  EVERY_MONTH: "EVERY_MONTH",
  EVERY_YEAR: "EVERY_YEAR",
};

export interface IEditUseLimitItemProps {
  arrangedUseLimit: ArrangedUseLimitData;
  onChange: (needUpdate: boolean, item: any) => void;
}

const EditUseLimitItem: React.FunctionComponent<IEditUseLimitItemProps> = ({
  arrangedUseLimit,
  onChange,
}) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityManagementPage.modalEditUseLimit.${key}`);

  const [updateUseLimitItem, setUpdateUseLimitItem] =
    useState<ArrangedUseLimitData>(arrangedUseLimit);

  return (
    <>
      <div className="edit-feature-item" key={arrangedUseLimit.feature.name}>
        <div>{t(arrangedUseLimit.feature.name)}</div>
        <input
          className="feature-checkbox"
          type="checkbox"
          checked={updateUseLimitItem.can_use}
          onChange={(evt) => {
            const updatedItem = {
              ...updateUseLimitItem,
              can_use: evt.target.checked,
            };
            setUpdateUseLimitItem(updatedItem);

            onChange(
              arrangedUseLimit.can_use !== evt.target.checked,
              updatedItem
            );
          }}
        />
      </div>
      <div className="edit-feature-period">
        <DropdownSelect
          isTranslation={true}
          translationKey="facilityManagementPage.modalEditUseLimit.actionTypes"
          value={`EVERY_${arrangedUseLimit.feature.use_limit_cycle.toUpperCase()}`}
          items={Object.values(ActionTypes).map((item) => item.toString())}
          onChange={() => {}} // TBD
          isDisabled={!updateUseLimitItem.can_use}
        />
        <InputBox
          classNameContainer="feature-input-times"
          value={`${updateUseLimitItem.can_use_times || 0}`}
          placeholder={t("period")}
          isDisabled={!updateUseLimitItem.can_use}
          onChange={(value) => {
            const updatedItem = {
              ...updateUseLimitItem,
              can_use_times: Number(value),
            };

            setUpdateUseLimitItem(updatedItem);

            onChange(
              arrangedUseLimit.can_use_times !== Number(value),
              updatedItem
            );
          }}
        />
        <div className="times">{t("times")}</div>
      </div>
    </>
  );
};

export default EditUseLimitItem;
