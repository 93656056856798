/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import formValidationSvc from "../../../services/formValidationSvc";
import { modalViewGAEvent } from "../../../utils/ga";
import { InputBox } from "../../FormElement/InputBox";
import "./styles.scss";

export interface IModalCreateSaveCollectionProps {
  onClose: () => void;
  onCreateSaveCollection: (labelName: string) => void;
  title: string;
  isEdit: boolean;
}

export const ModalCreateSaveCollection: React.FunctionComponent<
  IModalCreateSaveCollectionProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.modalCreateSaveCollection.${key}`);

  const [labelName, setLabelName] = useState<string>(
    props.isEdit ? props.title : ""
  );

  const { onClose, onCreateSaveCollection, isEdit } = props;

  // Send GA event on open
  useEffect(() => {
    modalViewGAEvent(`${isEdit ? "edit" : "create"}-collection`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // on Create Save
  const onCreateSave = () => {
    onCreateSaveCollection(labelName);
  };

  return (
    <div className="modal-default modal-create-save-collection">
      <div className="modal-mains">
        <a
          className="btn-close"
          onClick={() => {
            onClose();
          }}
        ></a>
        <div className="top-title">
          {t(isEdit ? "save_collection" : "create_new_collection")}
        </div>
        <div className="title-bar">
          <div className="label-title flex">
            {t("title")}
            <span className="little-txt required">{t("required")}</span>
          </div>
          <InputBox
            value={labelName}
            placeholder={t("label_title_here")}
            classNameContainer={"create-label-input"}
            pattern="[\W\w]{0,40}"
            onChange={() => {}}
            onChangeEvent={(event: ChangeEvent<HTMLInputElement>) => {
              setLabelName(
                formValidationSvc.validateInputEnteringPattern(event, labelName)
              );
            }}
          />
        </div>
        <div className="bottom-btns">
          <a
            className="btn btn-border"
            onClick={() => {
              onClose();
            }}
          >
            {t("cancel")}
          </a>
          <a
            className={`btn btn-blue ${labelName === "" ? "disabled" : ""}`}
            onClick={() => {
              onCreateSave();
            }}
          >
            {t(isEdit ? "save" : "create")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalCreateSaveCollection;
