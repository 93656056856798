
import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useHistory } from 'react-router-dom';

export interface IAuth0ProviderWithHistoryProps {
  children: any;
  domain: string;
  clientId: string;
  redirectUri: string;
}

const Auth0ProviderWithHistory: React.FunctionComponent<IAuth0ProviderWithHistoryProps> = ({ children, domain, clientId, redirectUri, ...args }) => {
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider domain={domain} clientId={clientId} redirectUri={redirectUri} onRedirectCallback={onRedirectCallback} {...args}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
