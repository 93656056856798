/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler'
import './styles.scss';

export interface ICollectionsFilterBarProps {
  title: string;
  onClickSaveCollection: () => void
  onClickCreateCollection: () => void
}

export const CollectionsFilterBar: React.FunctionComponent<
  ICollectionsFilterBarProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.collectionsFilterBar.${key}`);

  const [shownPopup, setShownPopup] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { title, onClickSaveCollection, onClickCreateCollection } = props;

  return (
    <div className="collections-filter-bar">
      <div className="update-wrap">
        <div className="line-bar">
          <div className="center-area">
            <div className="left-date" title={title}>{title}</div>
            <div className="right-dropdown">
              <OutsideClickHandler
                onOutsideClick={() => {
                  setShownPopup(false)
                }}>
                <div className={`save-load-wrap ${shownPopup ? 'open' : ''}`}>
                  <a className='blue-block'
                    onClick={() => {
                      setShownPopup(!shownPopup)

                      if(!shownPopup) {
                        ReactGA.event({
                          category: 'analysis-chart',
                          action: 'Open chart-set save/load'
                        })
                      }
                    }}>
                    {t('save_load')}
                    <i className='icons icon-download'></i>
                  </a>
                  <div className="saveload-panel">
                    <div className="txt">
                      {t('you_can_save_charts_and_configurations')}
                    </div>
                    <div className="btn-wrap">
                      <a className='btn btn-blue'
                        onClick={() => {
                          onClickSaveCollection()
                          setShownPopup(false)
                        }}>
                        {t('save_collection')}
                      </a>
                      {/* TODO:
                      <a className='btn btn-border'>
                        {t('load_another_collection')}
                      </a>
                      <a className='btn btn-border'
                        onClick={() => {
                          onClickCreateCollection()
                          setShownPopup(false)
                        }}>
                        {t('create_new_collection')}
                      </a>
                      */}
                    </div>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionsFilterBar;
