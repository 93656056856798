import i18next from "i18next";
import _ from "lodash";

import { CategoryData } from "./CategoryData";
import { HealthIndexData } from "./HealthIndexData";
import { LabelData } from "./LabelData";
import { MunicipalityGroupData } from "./MunicipalityData";
import { OrganizationData } from "./OrganizationData";
import { ProfBooleanData } from "./ProfBooleanData";

export interface FacilityFilterConditionData {
  hasLodging?: ProfBooleanData;
  healthIndex: HealthIndexData[];
  municipalities: MunicipalityGroupData[];
  categories: CategoryData[];
  labels: LabelData[];
  profBooleans: ProfBooleanData[];
}

export const createDefaultConditionFormData =
  (): FacilityFilterConditionData => ({
    healthIndex: [],
    municipalities: [],
    categories: [],
    labels: [],
    profBooleans: []
  });

export const getScoreArrayString = (
  conditionData: FacilityFilterConditionData
) => {
  const scoreArrayString: string[] = [];
  const h = (key: string) =>
    i18next.t(`facilityListPage.modalFilterCondition.healthIndexLabels.${key}`);
  conditionData.healthIndex.forEach((element) => {
    if (element.checked) {
      switch (element.name) {
        case h("A"):
          scoreArrayString.push("A1");
          break;
        case h("B"):
          scoreArrayString.push("B1");
          break;
        case h("C"):
          scoreArrayString.push("C1");
          scoreArrayString.push("C2");
          break;
        case h("D"):
          scoreArrayString.push("D1");
          break;
      }
    }
  });
  return scoreArrayString;
};

export const getProfBoolQueryObject = (
  conditionData: FacilityFilterConditionData
): Record<string, boolean> => {
  const obj: Record<string, boolean> = {};
  conditionData.profBooleans.forEach(({ key, shouldInclude }) => {
    if (_.isBoolean(shouldInclude)) {
      obj[key] = shouldInclude;
    }
  });
  return obj;
};

export const hasConditionFilter = (
  conditionFormData: FacilityFilterConditionData
) => {
  const hasLodging = conditionFormData.hasLodging?.shouldInclude !== undefined;

  const hasAnyListCondition = [
    conditionFormData.healthIndex,
    conditionFormData.categories,
    conditionFormData.labels,
    conditionFormData.profBooleans
  ].some((item) => item.length !== 0);

  const hasMunicipalities = conditionFormData.municipalities.some(
    (item) => item.muniList.length > 0
  );

  return hasLodging || hasAnyListCondition || hasMunicipalities;
};

export interface FacilityManagementFilterConditionData {
  organizations: OrganizationData[];
  isArchived?: ProfBooleanData;
}
