/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";

import {
  SORT_FACILITY_MANAGEMENT_OPTIONS,
  PAGE_NUMBER_OPTIONS,
} from "../../../config";
import { FacilityManagementFilterConditionData } from "../../../models/FacilityFilterConditionData";
import DropdownSelect from "../../FormElement/DropdownSelect";
import { InputBox } from "../../FormElement/InputBox";
import ToggleBtns from "../../ToggleBtns";
import "../../FacilityListComponents/ListTopBar/styles.scss";

export interface IListTopBarProps {
  conditionFormData: FacilityManagementFilterConditionData;
  filterFormData: any;
  onClickFilter: () => void;
  onClickClearFilterCondition: () => void;
  onChangeFilterFormData: (filterFormData: any) => void;
}

export const ListTopBar: React.FunctionComponent<IListTopBarProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityManagementPage.facilityManagementList.listTopBar.${key}`);

  const [searchName, setSearchName] = useState<string>("");

  const {
    conditionFormData,
    filterFormData,
    onClickFilter,
    onClickClearFilterCondition,
    onChangeFilterFormData,
  } = props;

  // has Condition Filter
  const hasConditionFilter = () => {
    let has = true;

    if (conditionFormData.organizations.length === 0) {
      has = false;
    }

    return has;
  };

  // get Labels List
  const getLabelsList = (dataArray: any[]) => {
    const newDataArray: string[] = dataArray.map((item) => {
      return item.name;
    });

    return newDataArray.join(", ");
  };

  const getSortCriteria = (option: string) => {
    const criteria: any = {
      sortBy: option,
    };
    switch (option) {
      case "振り分け状況 ↑":
        criteria.sort = "count.organizations";
        criteria.order = "asc";
        break;
      case "振り分け状況 ↓":
        criteria.sort = "count.organizations";
        criteria.order = "desc";
        break;
      case "施設名 ↑":
        criteria.sort = "name";
        criteria.order = "asc";
        break;
      case "施設名 ↓":
        criteria.sort = "name";
        criteria.order = "desc";
        break;
      case "所在地 ↑":
        criteria.sort = "prefecture+city";
        criteria.order = "asc";
        break;
      case "所在地 ↓":
        criteria.sort = "prefecture+city";
        criteria.order = "desc";
        break;
      case "施設ユーザー狀況 ↑":
        criteria.sort = "has_loc_admins";
        criteria.order = "asc";
        break;
      case "施設ユーザー狀況 ↓":
        criteria.sort = "has_loc_admins";
        criteria.order = "desc";
        break;
      default:
        break;
    }
    return criteria;
  };

  return (
    <div
      className={`list-top-bar flex-grid ${
        hasConditionFilter() ? "filter-after" : ""
      }`}
    >
      <div className="lefts">
        <div className="filter-wrap">
          <div className="filter-some-block">
            <a
              className="btn btn-filter"
              onClick={() => {
                onClickFilter();
              }}
            >
              <i className="icons icon-filter"></i>
              <span className="txt">
                {!hasConditionFilter() ? t("filter") : t("filtered")}
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className="rights flex">
        <div className="search-module">
          <div className="top-txt">&nbsp;</div>
          <InputBox
            value={searchName}
            placeholder={t("search_by_name")}
            isSearch={true}
            classNameContainer={"search-input"}
            onChange={(value: string) => {
              setSearchName(value);
            }}
            onKeyPress={(event: KeyboardEvent<HTMLImageElement>) => {
              if (event.key === "Enter") {
                onChangeFilterFormData({
                  ...filterFormData,
                  searchName: searchName,
                  pageIndex: 1,
                });
              }
            }}
          />
        </div>
        <div className="sort-drop">
          <div className="top-txt">{t("sort")}</div>
          <DropdownSelect
            fieldLabel={""}
            value={filterFormData.sortBy}
            items={SORT_FACILITY_MANAGEMENT_OPTIONS}
            classNameContainer={"sort-dropdown"}
            onChange={(value: string) => {
              onChangeFilterFormData({
                ...filterFormData,
                ...getSortCriteria(value),
              });
            }}
          />
        </div>
        <div className="choose-area">
          <div className="top-txt">{t("number_of_facilities_per_page")}</div>
          <ToggleBtns
            selectedValue={filterFormData.numberPerPage}
            options={PAGE_NUMBER_OPTIONS}
            onClickBtn={(option: number) => {
              onChangeFilterFormData({
                ...filterFormData,
                numberPerPage: option,
                pageIndex: 1,
              });
            }}
          />
        </div>
      </div>
      <div className="current-filters">
        <div className="current-filters-top">
          <h3>{t("active_filters")}</h3>
          <a
            href="#javascript"
            className="btn btn-border"
            onClick={(event) => {
              onClickClearFilterCondition();
              event.preventDefault();
            }}
          >
            {t("clear_condition")}
          </a>
        </div>
        <div className="label-area">
          {conditionFormData.organizations.length > 0 && [
            // eslint-disable-next-line react/jsx-key
            <div className="left-txt">{t("organizations")} :</div>,
            // eslint-disable-next-line react/jsx-key
            <div className="labels">
              {getLabelsList(conditionFormData.organizations)}
            </div>,
          ]}
        </div>
      </div>
    </div>
  );
};

export default ListTopBar;
