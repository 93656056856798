import { isUndefined } from "lodash";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import { useMe } from "../../../hooks/queries";

import "./styles.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IFacilityRegistrationTopInfoProps {}

export const FacilityRegistrationTopInfo: React.FunctionComponent<
  IFacilityRegistrationTopInfoProps
> = () => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityRegistrationPage.topInfo.${key}`);
  const { data: me } = useMe();

  const getLocationPercentageLable = () => {
    if (isUndefined(me) || !me?.organization) {
      return "N/A";
    }
    const totalLocationCount = me.locations.length || 0;
    return `${Math.floor(
      (me.organization.sign_up_rate * totalLocationCount) / 100
    )} / ${totalLocationCount}`;
  };

  return (
    <div className="facility-registration-top-info-module">
      <div className="description-txt">{t("description")}</div>

      {me?.organization && (
        <div className="sign-up-container">
          <div className="sign-up-date">
            {_t("facilityRegistrationPage.topInfo.sign_up_rate", {
              rate: me?.organization?.sign_up_rate
            })}
            <div className="location-percentage">
              {_t("facilityRegistrationPage.topInfo.location_percentage", {
                location_percentage: getLocationPercentageLable()
              })}
            </div>
          </div>
          <div className="update-date">
            {_t("facilityRegistrationPage.topInfo.update_date", {
              date: moment().startOf("week").format(t("dateformat"))
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default FacilityRegistrationTopInfo;
