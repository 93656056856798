import React from "react";
import { useTranslation } from "react-i18next";

import { ComparisonData, ComparisonValue, YoyValue } from "../../../types";

const timePeriodForYoy = (comparisonData: ComparisonValue[]) => {
  const { startTime, endTime } = comparisonData[0].totalValue.timeDimension;
  return`${startTime.split('T')[0]} - ${endTime.split('T')[0]}`
}

const YoYSection: React.FunctionComponent<{ 
  metric: string,
  yoyComparison: ComparisonData,
  yoy?: YoyValue
}> = ({
  metric,
  yoyComparison,
  yoy
}) => {
  const { t: _t, } = useTranslation();
  const t = (key: string) => _t(`internaltoolsPage.metricsNew.chrono.${key}`);

  const { lastYearData, thisYearData } = yoyComparison;
  const thisYearMetric = yoy ? yoy.thisYearData : thisYearData.find(item => item.metric === metric)?.totalValue.value;
  const lastYearMetric = yoy ? yoy.lastYearData: lastYearData.find(item => item.metric === metric)?.totalValue.value;

  if(!thisYearMetric || !lastYearMetric) { return <></> }
  return <div className="comparisonSection">
    <h4>{t('comparison')}</h4>
    <p><span>{timePeriodForYoy(thisYearData)} :</span> {thisYearMetric}</p>
    <p><span>{timePeriodForYoy(lastYearData)} :</span> {lastYearMetric}</p>
    <p><span>{t('change_perc')}: </span>{Math.round(((thisYearMetric ?? 0)/(lastYearMetric ?? 1) - 1)*1000)/10}%</p>
  </div>
}

export default YoYSection