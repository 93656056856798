/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";

import {
  ScoreType,
  getScoreType,
  standardizeScoreType,
} from "../../../utils/score";
import "./styles.scss";

export interface ISummaryProps {
  statsPrime: any;
  firstSentenceValue: number | string | undefined;
  secondSentenceValue: number | string | undefined;
  pageType: "OrganizationDashboard" | "FacilityDetails";
}

export const Summary: React.FunctionComponent<ISummaryProps> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityDetailsPage.summary.${key}`);

  const { statsPrime, firstSentenceValue, secondSentenceValue, pageType } =
    props;

  // get First Sentence Text
  const getFirstSentenceText = () => {
    if (!firstSentenceValue) {
      return "";
    }

    if (pageType === "OrganizationDashboard") {
      const scoreType = getScoreType(Number(firstSentenceValue));
      switch (scoreType) {
        case ScoreType.A1:
          return t("dashboard_score-A1");
        case ScoreType.B1:
          return t("dashboard_score-B1");
        case ScoreType.C1:
          return t("dashboard_score-C1");
        case ScoreType.D1:
          return t("dashboard_score-D1");
      }
    } else if (pageType === "FacilityDetails") {
      const scoreType = standardizeScoreType(firstSentenceValue as string);
      switch (scoreType) {
        case ScoreType.A1:
          return t("facility_details_score_A1");
        case ScoreType.B1:
          return t("facility_details_score_B1");
        case ScoreType.C1:
          return t("facility_details_score_C1_C2");
        case ScoreType.D1:
          return t("facility_details_score_D1");
      }
    }

    return "";
  };

  // get Second Sentence Text
  const getSecondSentenceText = () => {
    if (!secondSentenceValue) {
      return "";
    }

    if (pageType === "OrganizationDashboard") {
      if (secondSentenceValue >= 0) {
        return _t("facilityDetailsPage.summary.dashboard_ratio>=0", {
          profile_view_change_ratio: secondSentenceValue,
        });
      }
      if (0 > secondSentenceValue) {
        return _t("facilityDetailsPage.summary.dashboard_ratio<0", {
          profile_view_change_ratio: secondSentenceValue,
        });
      }
    } else if (pageType === "FacilityDetails") {
      if (secondSentenceValue >= 0) {
        return _t("facilityDetailsPage.summary.facility_details_ratio>=0", {
          profile_view_change_ratio: secondSentenceValue,
        });
      }
      if (0 > secondSentenceValue) {
        return _t("facilityDetailsPage.summary.facility_details_ratio<0", {
          profile_view_change_ratio: secondSentenceValue,
        });
      }
    }

    return "";
  };

  return (
    <div className={`white-border-panel summary-module padding32`}>
      <div className="titles">{t("summary")}</div>
      {!statsPrime ? (
        <div className="row loading-row">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <ul className="summary-list">
          <li className={`${!firstSentenceValue ? "hide" : ""}`}>
            <div className="txt">
              <span className="points"></span>
              <div
                dangerouslySetInnerHTML={{ __html: getFirstSentenceText() }}
              ></div>
            </div>
          </li>
          <li className={`${!secondSentenceValue ? "hide" : ""}`}>
            <div className="txt">
              <span className="points"></span>
              <div
                dangerouslySetInnerHTML={{ __html: getSecondSentenceText() }}
              ></div>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Summary;
